import React from 'react'

const Edit = () => {
  return (
       <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#0F70B7" />
            <path d="M20.3432 13.0081L21.2328 12.1185C22.1567 11.1947 22.1567 9.69115 21.2328 8.76693C20.7855 8.31995 20.1908 8.07422 19.5568 8.07422C18.9228 8.07422 18.3278 8.32031 17.8808 8.76729L16.9916 9.65651L20.3432 13.0081ZM16.2183 10.4298L9.63066 17.0174C9.4903 17.1578 9.38348 17.331 9.3215 17.5188L8.10014 21.2087C8.03488 21.4048 8.08629 21.621 8.23249 21.7672C8.33712 21.8715 8.47639 21.9273 8.61931 21.9273C8.67691 21.9273 8.73488 21.9182 8.79139 21.8996L12.4802 20.6779C12.6687 20.6159 12.8423 20.5091 12.9826 20.3683L19.5699 13.781L16.2183 10.4298Z" fill="white" />
       </svg>

  )
}

export default Edit