import { t } from "i18next";
import React, { useEffect, useState } from "react";
import BlueButton from "../UI/Buttons/BlueButton";
import Input from "../UI/Input";
import Select from "react-select";
import axios from "axios";
import { config, url } from "../Helpers/Help";
import Map from "../UI/Map";
import alert from "../../Helpers/Modal";
import ButtonLoading from "../UI/ButtonLoading";
import Title from "./Title";
import { Link } from "react-router-dom";
import Registration360 from "../../assets/Icons/Registration_360";
import RegistrationBack from "../../assets/Icons/Registration_back";
import RegistrationFront from "../../assets/Icons/Registration_front";
import RegistrationLeft from "../../assets/Icons/Registration_left";
import RegistrationRight from "../../assets/Icons/Registration_right";

const pricesUnits = [
  { value: "1", label: `${t("Day")}` },
  { value: "2", label: `${t("Week")}` },
  { value: "3", label: `${t("Month")}` },
  { value: "4", label: `${t("Year")}` },
];
const AddRegisterAnimal = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setheight] = useState("");
  const [gender, setGender] = useState("1");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [passportNO, setPassportNO] = useState("");
  const [animalSpecies, setAnimalSpecies] = useState("");
  const [dateofBirthday, setDateofBirthday] = useState("");
  const [frontImage, setUploadFrontImage] = useState("");
  const [backtImage, setUploadBacktImage] = useState("");
  const [righttImage, setUploadRighttImage] = useState("");
  const [leftImage, setUploadLeftImage] = useState("");
  const [virtualImage, setUploadVirtualImage] = useState("");
  // const [uploadVideo, setUploadVideo] = useState("");
  const [categories, setCategories] = useState([]);
  const [priceType, setPriceType] = useState("");
  const [childs, setChilds] = useState([]);
  // const [images, setImages] = useState([]);
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [showIn, setShowIn] = useState(2);
  const [typeGenus, setTypeGenus] = useState("");
  const [cardRegistrationNumber, setCardRegistrationNumber] = useState("");
  const [The_animal_owner_phone_number, setTheAnimalOwnerPhoneNumber] =
    useState("");
  const [CategoryError, setCategoryError] = useState(false);
  const [TypeError, setTypeError] = useState(false);
  const [animalSpeciesError, setAnimalSpeciesError] = useState(false);

  const [dateofBirthdayError, setDateofBirthdayError] = useState(false);
  const [passportNoError, setPassportNoError] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [weightError, setweightError] = useState(false);
  // const [ImagesError, setImagesError] = useState(false)
  // const [videoError, setvideoError] = useState(false)
  // const [singleImageError, setsingleImageError] = useState(false)
  const [noteError, setnoteError] = useState(false);
  const [mapError, setmapError] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const [frontImageError, setFrontImageError] = useState(false);
  const [backtImageError, setBacktImageError] = useState(false);
  const [righttImageError, setRighttImageError] = useState(false);
  const [leftImageError, setLeftImageError] = useState(false);
  const [virtualImageError, setVirtualImageError] = useState(false);

  // upload malti image
  // useEffect(() => {
  //   if (images.length < 1) return;
  //   const newImageUrls = [];
  //   images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
  //   console.log(newImageUrls)

  // }, [images]);

  // Storage Images
  // function onImageChange(e) {
  //   setImages([...e.target.files]);
  // }

  // Get Categories from localStorage
  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem("__data_aW__a_start");
      const dataArr = JSON.parse(data);
      setCategories(dataArr.categories);
    }
  }, []);

  // Get Childes of Categories by ID
  useEffect(() => {
    if (category) {
      const item = categories.find((item) => item.id === category);
      setChilds(item.childs);
    }
  }, [category, categories]);

  //  Submit auction form
  const submitHandler = () => {
    // if (   !phone || !email || !note || !lat || !lng) {
    //      setIsError(true)
    // }
    if (!category) {
      setCategoryError(true);
      return;
    }
    if (!type) {
      setTypeError(true);
      return;
    }
    if (!dateofBirthday) {
      setDateofBirthdayError(true);
      return;
    }
    if (!passportNO) {
      setPassportNoError(true);
      return;
    }
    if (!color) {
      setColorError(true);
      return;
    }

    if (!weight) {
      setweightError(true);
      return;
    }
    if (!location) {
      setlocationError(true);
      return;
    }
    // if (!images || images.length < 3) {
    //   setImagesError(true)
    //   return
    // }
    // if (!uploadVideo) {
    //   setvideoError(true)
    //   return
    // }
    if (!frontImage) {
      setFrontImageError(true);
      return;
    }
    if (!backtImage) {
      setBacktImageError(true);
      return;
    }
    if (!righttImage) {
      setRighttImageError(true);
      return;
    }
    if (!leftImage) {
      setLeftImageError(true);
      return;
    }
    if (!virtualImage) {
      setVirtualImageError(true);
      return;
    }

    if (!note) {
      setnoteError(true);
      return;
    }
    if (!lat || !lng) {
      setmapError(true);
      return;
    }
    setLoading(true);
    axios
      .post(
        url + "animals/register/my",
        //  Data
        {
          category_id: category,
          show_in: showIn,
          sub_category_id: type,
          gender: gender,
          date_of_birth: dateofBirthday,
          passport_number: passportNO,
          color: color,
          weight: weight,
          // video: uploadVideo,
          height: height,
          // image: singleImage,
          "images[]": [
            frontImage,
            backtImage,
            righttImage,
            leftImage,
            virtualImage,
          ],
          platoon: typeGenus,
          notes: note,
          registration_number: cardRegistrationNumber,
          phone_number: The_animal_owner_phone_number,
          lat: lat,
          lng: lng,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setCategory("");
        setType("");
        setAnimalSpecies("");
        setDateofBirthday("");
        setPassportNO("");
        setColor("");
        setWeight("");
        setLocation("");
        setUploadFrontImage();
        setUploadBacktImage();
        setUploadRighttImage();
        setUploadLeftImage();
        setUploadVirtualImage();
        // setUploadSingleImage('')
        // setUploadVideo('')
        setPriceType("");
        setPrice("");
        setPhone("");
        setEmail("");
        setNote("");
        setLat("");
        setLng("");
        // setImages([])
        setLoading(false);
        if (response.data.data.payment_link) {
          window.location.href = `${response.data.data.payment_link}`;
        } else {
          alert({
            title: `${t("Done")}`,
            text: `${t(`Thank you for send`)}`,

            icon: "success",
          });
        }
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        setLoading(false);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };

  return (
    <>
      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <Title className="md:mb-8"> {t("Registering animal ownership")}</Title>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Category")}{" "}
            </label>
            <select
              onFocus={() => setCategoryError(false)}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${
                CategoryError ? "border-red" : "border-blue"
              }`}
            >
              <option value={""}> {t("chosse")}. . . </option>
              {categories &&
                categories.map((item) => (
                  <option value={item.id} key={item.id}>
                    {" "}
                    {item.title}{" "}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {childs && childs.length > 0 && (
          <div className="w-full md:w-1/2 p-2 my-2">
            <div className="flex flex-col">
              <label htmlFor="" className="font-medium text-[1.5] ">
                {" "}
                {t("Type")}{" "}
              </label>
              <div className="flex">
                <select
                  onFocus={() => setTypeError(false)}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${
                    TypeError ? "border-red" : "border-blue"
                  }`}
                >
                  <option value={""}> {t("chosse")} . . . </option>

                  {childs &&
                    childs.map((item) => (
                      <option value={item.id} key={item.id}>
                        {" "}
                        {item.title}{" "}
                      </option>
                    ))}
                </select>
                <Link
                  to="/add-animal/add-sub-categorey"
                  className="flex items-center justify-center hover:opacity-70 bg-blue text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all px-4 ml-3 rounded-lg"
                >
                  +
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setAnimalSpeciesError(false)} input_className={`border-2  ${animalSpeciesError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={animalSpecies} onChange={(e) => setAnimalSpecies(e.target.value)} type="text" placeholder={t("Animal Species")} id="AnimalSpecies" label={t("Animal Species")} />
        </div> */}
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            input_className="border-2 border-blue"
            div_className="w-full"
            value={height}
            onChange={(e) => setheight(e.target.value)}
            type="number"
            placeholder={t("height")}
            id="height"
            label={t("height")}
          />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setDateofBirthdayError(false)}
            input_className={`border-2 ${
              dateofBirthdayError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={dateofBirthday}
            onChange={(e) => setDateofBirthday(e.target.value)}
            type="date"
            placeholder={t("Date of Birthday")}
            id="DateBirthday"
            label={t("Date of Birthday")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setPassportNoError(false)}
            input_className={`border-2 ${
              passportNoError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={passportNO}
            onChange={(e) => setPassportNO(e.target.value)}
            type="text"
            placeholder={t("Passport NO")}
            id="Passport"
            label={t("Passport NO")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            input_className={`border-2 border-blue py-0 h-[4rem]  border-blue `}
            div_className="w-full "
            value={The_animal_owner_phone_number}
            onChange={(e) => setTheAnimalOwnerPhoneNumber(e.target.value)}
            type="tel"
            placeholder={t("The animal's owner's phone number")}
            id="The_animal_owner_phone_number"
            label={t("The animal's owner's phone number")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            input_className={`border-2 border-blue py-0 h-[4rem]  border-blue `}
            div_className="w-full "
            value={cardRegistrationNumber}
            onChange={(e) => setCardRegistrationNumber(e.target.value)}
            type="number"
            placeholder={t("Card registration number")}
            id="CardRegistrationNumber"
            label={t("Card registration number")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            input_className={`border-2 border-blue py-0 h-[4rem]  border-blue `}
            div_className="w-full "
            value={typeGenus}
            onChange={(e) => setTypeGenus(e.target.value)}
            type="text"
            placeholder={t("type genus")}
            id="typeGenus"
            label={t("type genus")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setColorError(false)}
            input_className={`border-2 border-blue py-0 h-[4rem] ${
              colorError ? "border-red" : "border-blue"
            }`}
            div_className="w-full "
            value={color}
            onChange={(e) => setColor(e.target.value)}
            type="color"
            placeholder={t("Color")}
            id="Color"
            label={t("Color")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setweightError(false)}
            input_className={`border-2  ${
              weightError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            type="number"
            placeholder={t("Weight")}
            id="Weight"
            label={t("Weight")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2 hidden">
          <Input
            onFocus={() => setlocationError(false)}
            input_className={`border-2 border-blue ${
              locationError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            placeholder={t("location")}
            id="location"
            label={t("location")}
          />
        </div>

        {/*  <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setpriceError(false)} input_className={`border-2 border-blue ${priceError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={price} onChange={(e) => setPrice(e.target.value)} type="number" placeholder={t("price")} id="price" label={t("Price")} />
        </div> */}

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload front Image")}{" "}
            </label>
            <div className="flex items-center justify-center flex-wrap gap-4">
              <div
                onFocus={() => setFrontImageError(false)}
                className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                  frontImageError ? "border-red" : "border-blue"
                }`}
              >
                <input
                  onChange={(e) => {
                    setUploadFrontImage(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                  accept="image/jpg, image/jpeg,image/png"
                  className=" mt-2 opacity-60 cursor-pointer"
                  type="file"
                />
                <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                  {t("Upload Image")}
                </span>
              </div>
              <RegistrationFront />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload back Image")}{" "}
            </label>
            <div className="flex items-center justify-center flex-wrap gap-4">
              <div
                onFocus={() => setBacktImageError(false)}
                className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                  backtImageError ? "border-red" : "border-blue"
                }`}
              >
                <input
                  onChange={(e) => {
                    setUploadBacktImage(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                  accept="image/jpg, image/jpeg,image/png"
                  className=" mt-2 opacity-60 cursor-pointer"
                  type="file"
                />
                <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                  {t("Upload Image")}
                </span>
              </div>
              <RegistrationBack />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload right Image")}{" "}
            </label>
            <div className="flex items-center justify-center flex-wrap gap-4">
              <div
                onFocus={() => setRighttImageError(false)}
                className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                  righttImageError ? "border-red" : "border-blue"
                }`}
              >
                <input
                  onChange={(e) => {
                    setUploadRighttImage(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                  accept="image/jpg, image/jpeg,image/png"
                  className=" mt-2 opacity-60 cursor-pointer"
                  type="file"
                />
                <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                  {t("Upload Image")}
                </span>
              </div>
              <RegistrationRight />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload left Image")}{" "}
            </label>
            <div className="flex items-center justify-center flex-wrap gap-4">
              <div
                onFocus={() => setLeftImageError(false)}
                className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                  leftImageError ? "border-red" : "border-blue"
                }`}
              >
                <input
                  onChange={(e) => {
                    setUploadLeftImage(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                  accept="image/jpg, image/jpeg,image/png"
                  className=" mt-2 opacity-60 cursor-pointer"
                  type="file"
                />
                <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                  {t("Upload Image")}
                </span>
              </div>
              <RegistrationLeft />
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload virtual Image")}
            </label>
            <div className="flex items-center justify-center flex-wrap gap-4">
              <div
                onFocus={() => setVirtualImageError(false)}
                className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                  virtualImageError ? "border-red" : "border-blue"
                }`}
              >
                <input
                  onChange={(e) => {
                    setUploadVirtualImage(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                  accept="image/jpg, image/jpeg,image/png"
                  className=" mt-2 opacity-60 cursor-pointer"
                  type="file"
                />
                <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                  {t("Upload Image")}
                </span>
              </div>
              <Registration360 />
            </div>
          </div>
        </div>

        {/* 
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="ltr:text-left rtl:text-right font-medium text-[1.5]"> {t('Upload three Images')} </label>
            <div onFocus={() => setImagesError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${ImagesError ? 'border-red' : 'border-blue'}`}>
              <input onChange={onImageChange} multiple accept='image/jpg, image/jpeg,image/png' className=" mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[11.5rem]">{t('Upload Image')}</span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="ltr:text-left rtl:text-right font-medium text-[1.5]"> {t('Upload Video')} </label>
            <div onFocus={() => setvideoError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16  ${videoError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => setUploadVideo(e.target.files[0])} className=" mt-2 opacity-60 cursor-pointer" type="file" accept="video/mp4,video/x-m4v,video/*" />
              <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">{t('Upload Video')}</span>
            </div>
          </div>
        </div> */}
        {/* <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Posted on')} </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('My page')}
              <input name="PostedOn" value='MyPage' checked={showIn === "1"} onChange={() => setShowIn('1')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('My page & AWA page')}
              <input name="PostedOn" value='AWAPage' checked={showIn === '2'} onChange={() => setShowIn('2')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

          </div>
        </div> */}
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2">
            {" "}
            {t("Gender")}{" "}
          </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Male")}
              <input
                name="radio"
                value="1"
                checked={gender === "1"}
                onChange={() => setGender("1")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Female")}
              <input
                name="radio"
                value="2"
                checked={gender === "2"}
                onChange={() => setGender("2")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t(`Don't Now`)}
              <input
                name="radio"
                value="3"
                checked={gender === "3"}
                onChange={() => setGender("3")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
          </div>
        </div>
        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]">
            {" "}
            {t("Note")}{" "}
          </label>
          <textarea
            onFocus={() => setnoteError(false)}
            className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${
              noteError ? "border-red" : "border-blue"
            }`}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            name=""
            id=""
            cols="30"
            rows="5"
            placeholder={t("Note . . .")}
          ></textarea>
        </div>
        {/* <hr className="block my-12 w-full " />
        <h3 className="w-full rtl:text-right ltr:text-left font-bold px-4">{t('Contact')}</h3>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setphoneError(false)} input_className={`border-2 ${phoneError ? 'border-red' : 'border-blue'} `} div_className="w-full" value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder={t("Phone")} id="Phone" label={t("Phone")} />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setemailError(false)} input_className={`border-2 ${emailError ? 'border-red' : 'border-blue'} `} div_className="w-full" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("Email")} id="Email" label={t("Email")} />
        </div>

        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]"> {t('Note')} </label>
          <textarea onFocus={() => setnoteError(false)} className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${noteError ? 'border-red' : 'border-blue'}`} value={note} onChange={(e) => setNote(e.target.value)} name="" id="" cols="30" rows="5" placeholder={t('Note . . .')}></textarea>
        </div>
*/}
        <div
          onFocus={() => setmapError(false)}
          className={`w-full p-2  ${mapError && "border-red border"}`}
        >
          <Map
            address={(e) => setLocation(e)}
            lat={(e) => setLat(e)}
            lng={(e) => setLng(e)}
          />
        </div>

        <div className="w-full flex items-center justify-center">
          <BlueButton
            disabled={loading}
            className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${
              loading && "cursor-not-allowed"
            }`}
            onClick={submitHandler}
          >
            {loading ? <ButtonLoading /> : `${t("Send")}`}
          </BlueButton>
        </div>
        <div className="my-8 w-full flex items-end justify-center">
          <p className="font-bold text-red capitalize">
            {CategoryError && `${t("Please enter your animal category")}`}
            {TypeError && `${t("Please enter your animal category type")}`}
            {animalSpeciesError && `${t("Please enter your  animal species")}`}
            {dateofBirthdayError &&
              `${t("Please enter your animal date of birthday")}`}
            {passportNoError && `${t("Please enter your animal passport No")}`} 
            {colorError && `${t("Please enter your animal color")}`}
            {weightError && `${t("Please enter your animal weight")}`}
            {/* {singleImageError && `${t('Please enter your Image')}`} */}
            {frontImageError && `${t("Please enter your Front Image")}`}
            {backtImageError && `${t("Please enter your back Image")}`}
            {righttImageError && `${t("Please enter your right Image")}`}
            {leftImageError && `${t("Please enter your left Image")}`}
            {virtualImageError && `${t("Please enter your virtual Image")}`}
            {/* {ImagesError && `${t('Please enter your single Image')}`}
            {videoError && `${t('Please enter your video')}`} */}
            {mapError && `${t("Please enter your map location")}`}
            {locationError && `${t("Please enter your location")}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default AddRegisterAnimal;
