import axios from 'axios'
import { t } from 'i18next'
import React, { useState } from 'react'
import { config, url } from '../Helpers/Help'
import ButtonLoading from '../UI/ButtonLoading'
import BlueButton from '../UI/Buttons/BlueButton'
import Input from '../UI/Input'
import alert from "../../Helpers/Modal";

const AddVideos = () => {
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [uploadVideo, setUploadVideo] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [Video, setVideo] = useState('1');

  const [thumbnailError, setthumbnailError] = useState(false)
  const [videoError, setvideoError] = useState(false)
  const [titleError, setTitleError] = useState(false);
  const [noteError, setnoteError] = useState(false)
  const [loading, setLoading] = useState(false);

  const uploadVideoHandler = () => {
    if (!title) {
      setTitleError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!thumbnail) {
      setthumbnailError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!uploadVideo) {
      setvideoError(true)
      window.scrollTo(0, 0)
      return
    }
    if (!note) {
      setnoteError(true)
      window.scrollTo(0, 0)
      return
    }
    setLoading(true)
    axios.post(url + 'videos',
      //  Data
      {
        title: title,
        content: note,
        file: uploadVideo,
        thumbnail: thumbnail,
        type: Video
      },
      // Configurations
      config)
      // Response
      .then(response => {
        console.log(response.data)
        setLoading(false)
        setTitle('')
        setNote('')
        setUploadVideo('')
        alert({
          title: `${t('Your video is added')}`,
          text: 'Success',
          icon: 'success',
          timer: 2000
        })
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        setLoading(false)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })

      })
  }


  return (
    <>
      <div className="w-full">
        <h3 className='font-bold text-center text-blue text-[2rem] mt-6'>{t('Add Video')}</h3>
      </div>
      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input onFocus={() => setTitleError(false)} input_className={`border-2  ${titleError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder={t("Title")} id="Title" label={t("Title")} />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full"> {t('Thumbnail Image')} </label>
            <div onFocus={() => setthumbnailError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16 ${thumbnailError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => { setThumbnail(e.target.files[0]); console.log(e.target.files[0]) }} accept='image/jpg, image/jpeg,image/png' className="pl-5 mt-2 opacity-60 cursor-pointer" type="file" />
              <span className="absolute  rtl:-right-[0.2rem] ltr:-left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[11.5rem]"> {t('Upload Image')}  </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full">  {t('Upload Video')}   </label>
            <div onFocus={() => setvideoError(false)} className={`border-2 rounded-lg relative overflow-hidden h-16  ${videoError ? 'border-red' : 'border-blue'}`}>
              <input onChange={(e) => setUploadVideo(e.target.files[0])} className="pl-5 mt-2 opacity-60 cursor-pointer" accept="video/mp4,video/x-m4v,video/*" type="file" />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[11.5rem]">{t('Upload Video')}</span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Video Duration')} </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('Short Video')}
              <input name="radio" value='shortVideo' checked={Video === '1'} onChange={() => setVideo('1')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t('Long Video')}
              <input name="radio" value='longVideo' checked={Video === '2'} onChange={() => setVideo('2')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

          </div>
        </div>
        <hr className="block my-12 w-full" />

        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]"> {t('Note')} </label>
          <textarea onFocus={() => setnoteError(false)} className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${noteError ? 'border-red' : 'border-blue'}`} value={note} onChange={(e) => setNote(e.target.value)} name="" id="" cols="30" rows="5" placeholder={t('Note . . .')}></textarea>
        </div>
        <div className="w-full flex items-center justify-center">
          <BlueButton disabled={loading} className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${loading && 'cursor-not-allowed'} `} onClick={uploadVideoHandler}>
            {loading ?
              <ButtonLoading />
              :
              `${t("Send")}`
            }
          </BlueButton>

        </div>
        <div className="my-8 w-full flex items-end justify-center">
          <p className='font-bold text-red capitalize'>
            {titleError && 'please enter your title'}
            {noteError && 'please enter your note'}
            {videoError && `${t('Please enter your video')}`}
            {thumbnailError && 'please enter your Thumbnail image'}

          </p>
        </div>
      </div>
    </>
  )
}

export default AddVideos