import React from 'react'
import Layout from '../Components/Layout'

const Notification = () => {
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-[80vh] flex items-center justify-center">
                    <div className="flex items-center justify-center flex-wrap">
                    </div>
               </main>
          </Layout>
     )
}

export default Notification