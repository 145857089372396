import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Styles/index.scss';
import App from './App';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './Store/Store';


    // console.log = console.warn = console.error = () => { };
    // console.error('Something bad happened.');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <React.StrictMode> 
  <ContextProvider>
    <BrowserRouter> 
      <App />
    </BrowserRouter>
  </ContextProvider>
 </React.StrictMode>
);
