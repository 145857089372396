import React from 'react'

const Advirtisings = (props) => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M26.6663 28L5.33301 20V12L26.6663 4V28Z" fill={props.fill} />
               <path d="M6.66699 19.3334C6.66699 18.8187 6.66699 13.1814 6.66699 12.6667C6.66699 11.5627 5.77099 10.6667 4.66699 10.6667C3.56299 10.6667 2.66699 11.5627 2.66699 12.6667C2.66699 13.1814 2.66699 18.8187 2.66699 19.3334C2.66699 20.4374 3.56299 21.3334 4.66699 21.3334C5.77099 21.3334 6.66699 20.4374 6.66699 19.3334Z" fill={props.fill} />
               <path d="M29.333 27.3334C29.333 26.8187 29.333 5.18141 29.333 4.66675C29.333 3.56275 28.437 2.66675 27.333 2.66675C26.229 2.66675 25.333 3.56275 25.333 4.66675C25.333 5.18141 25.333 26.8187 25.333 27.3334C25.333 28.4374 26.229 29.3334 27.333 29.3334C28.437 29.3334 29.333 28.4374 29.333 27.3334Z" fill={props.fill} />
               <path d="M10.6596 21.9973L10.6129 22.1213C10.0943 23.5 10.7916 25.0373 12.1703 25.556L14.6663 26.4946C16.0449 27.0133 17.5823 26.316 18.1009 24.9373L18.1503 24.8053L10.6596 21.9973Z" fill={props.fill} />
          </svg>

     )
}

export default Advirtisings