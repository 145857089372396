import React from 'react'

const Female = (props) => {
     return (
          <svg className={`${props.className}`} width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fillRule="evenodd" clipRule="evenodd" d="M2.08084 10.4817C3.02488 11.4257 4.21272 11.9692 5.44198 12.1266V14.2219H3.67296C3.24334 14.2219 2.91481 14.5504 2.91481 14.98C2.91481 15.4096 3.24334 15.7382 3.67296 15.7382H5.44198V17.5072C5.44198 17.9368 5.77052 18.2654 6.20014 18.2654C6.62976 18.2654 6.95829 17.9116 6.95829 17.5072V15.7382H8.72731C9.15693 15.7382 9.48546 15.4096 9.48546 14.98C9.48546 14.5504 9.15693 14.2219 8.72731 14.2219H6.95829V12.1248C8.1869 11.962 9.37113 11.4047 10.2942 10.4817C12.5686 8.23247 12.5686 4.5428 10.2942 2.26834C8.04497 -0.00611407 4.3553 -0.00611407 2.08084 2.26834C-0.193614 4.51753 -0.193614 8.2072 2.08084 10.4817ZM6.20014 2.06617C5.08818 2.06617 4.00149 2.49579 3.16753 3.32976C1.49959 4.99769 1.49959 7.72704 3.16753 9.39497C4.83546 11.0629 7.56481 11.0629 9.23275 9.39497C10.9007 7.72704 10.9007 4.99769 9.23275 3.32976C8.39878 2.49579 7.31209 2.06617 6.20014 2.06617Z" fill="white" />
          </svg>
     )
}

export default Female