import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
import MobileNav from './MobileNav'

const index = (props) => {
  return (
    <>
      {window.innerWidth > 767 ? <Nav /> : <MobileNav />}
      {props.children}
      <Footer />
    </>
  )
}

export default index