import { t } from 'i18next'
import React, { useContext } from 'react'
import Lottie from 'react-lottie'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import LineHeart from '../../../assets/Icons/LineHeart'
import Phone from '../../../assets/Icons/Phone'
import BlueButton from '../Buttons/BlueButton'
import RedButton from '../Buttons/RedButton'
import InMarket from '../../Lottie/InMarket.json'
import Male from '../../../assets/Icons/Male'
import Female from '../../../assets/Icons/Female'
import Whatsapp from '../../../assets/Icons/Whatsapp'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Context from '../../../Store/Store'
import Verified from '../../../assets/Icons/Verified'
import WhatsappFlat from '../../../assets/Icons/WhatsappFlat'

const defaultOptions = {
     loop: true,
     autoplay: true,
     animationData: InMarket,
     rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
     }
};
const FridayMarket = (props) => {
     const { id, slug, img, userImage, name, MarketType, badge, age, weight, whatsapp, type, price, gender, isFav, inMarket, action, phone, email, averagePrice, photographer, userId } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${photographer ? 'flex-col-reverse pb-0' : 'flex-col'}`}>
                    {img &&
                         <Link to={`/details/markets/${id}`} className="w-full">
                              <img src={img} alt="" className='rounded-2xl mx-auto h-[25rem] w-auto object-cover' />
                         </Link>
                    }
                    <div className="w-full min-h-[10rem] max-h-[27rem]">
                         <div className="w-full p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap dir-rtl">

                              {(userImage || name || badge) &&
                                   <>
                                        <div className="user flex items-center gap-2 w-1/2">
                                             <Link to={`/user-page/${userId}`} className="flex items-center gap-2">
                                                  {userImage &&
                                                       <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                                            <img src={userImage ? userImage : 'http://front.awasell.co/static/media/logo%20+%20words@2x.858e5a21f7aa91285f56.png'} alt="" className='h-full w-full object-cover' />
                                                       </div>
                                                  }
                                                  {name &&
                                                       <div className="name">
                                                            {name}
                                                       </div>
                                                  }
                                             </Link>
                                             <div className="flex items-center">
                                             {badge === 'gold' ? 
                                                       <Badge />
                                                       :
                                                       <BadgeGray />
                                                  }
                                                  {props.is_verified === 1 && <div className=""><Verified /></div>}

                                             </div>
                                        </div>

                                        <div className=" no-wrap w-1/3 rtl:text-left ltr:text-right ">
                                             {props.rate &&
                                                  <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                                                       {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                       {props.rate === '1' && '★☆☆☆☆'}
                                                       {props.rate === '2' && '★★☆☆☆'}
                                                       {props.rate === '3' && '★★★☆☆'}
                                                       {props.rate === '4' && '★★★★☆'}
                                                       {props.rate === '5' && '★★★★★'}
                                                       {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                                  </span>}
                                        </div>
                                   </>
                              }
                         </div>
                         <ul className='px-2 md:px-4'>
                              <Link to={`/details/markets/${id}`} className="w-full">
                                   {type && MarketType !== 'wholesale-marke' && <li className='mt-2 no-wrap'>{t('Type')}: <b>{type}</b></li>}
                                   {price && MarketType !== 'today-s-market' && <li className='mt-4 no-wrap'>{t('Price')} :<b>{price} {t('AED')}</b></li>}
                              </Link>
                              <li className='w-full mt-2 flex items-center justify-start flex-wrap'>
                                   {MarketType !== 'wholesale-marke' ?
                                        <p className='w-4/6 flex items-center justify-start flex-wrap gap-2'>
                                             {gender && <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>}
                                             {age && <span className='bg-[#ececec] px-1 md:px-4 py-2 rounded-2xl text-[1.2rem] md:text-[1.4rem]'>{age}</span>}
                                             {weight && <span className='bg-[#ececec] px-1 md:px-4 py-2 rounded-2xl text-[1.2rem] md:text-[1.4rem]'>{weight}</span>}
                                        </p>
                                        :
                                        <span className='mr-3'> {t('Contact')} : </span>
                                   }

                                   {(phone || email || whatsapp) &&
                                        <p className={`flex items-center flex-wrap gap-1 w-2/6 ${MarketType === 'wholesale-marke' ? 'justify-start' : 'justify-end'}`}>
                                             {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                             {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                             {whatsapp && <a target='_blank' rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${whatsapp}`}> <WhatsappFlat /> </a>}
                                        </p>
                                   }
                              </li>
                              <li className='flex items-center justify-center mt-4 gap-4'>
                                   <Link to={`/details/${slug}/${id}`} className='border-2 border-blue bg-blue rounded-xl text-white flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full text-lg md:text-[1.6rem]'>{t('Your Offer Price')}</Link>
                                   <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                              </li>
                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default FridayMarket