import React from 'react'

const TermCondition = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M29.3337 13C29.3337 9.46667 26.7337 6.53333 23.3337 6.06667C23.267 3.8 21.4003 2 19.2003 2C17.9337 2 16.8003 2.6 16.0003 3.46667C15.267 2.6 14.067 2 12.8003 2C10.5337 2 8.66699 3.8 8.66699 6.06667C5.26699 6.53333 2.66699 9.46667 2.66699 13C2.66699 14.4667 3.13366 15.8667 3.93366 17C3.53366 17.9333 3.33366 18.8667 3.33366 19.8667C3.33366 23.0667 5.66699 25.8667 8.73366 26.5333C9.06699 28.5333 10.8003 30 12.867 30C14.1337 30 15.267 29.4 16.067 28.5333C16.8003 29.4 18.0003 30 19.267 30C21.3337 30 23.067 28.4667 23.4003 26.5333C26.467 25.8667 28.8003 23.0667 28.8003 19.8667C28.8003 18.8667 28.6003 17.9333 28.2003 17.0667C28.867 15.8667 29.3337 14.4667 29.3337 13ZM15.0003 10.3333C15.0003 9.8 15.467 9.33333 16.0003 9.33333C16.5337 9.33333 17.0003 9.8 17.0003 10.3333V17C17.0003 17.5333 16.5337 18 16.0003 18C15.467 18 15.0003 17.5333 15.0003 17V10.3333ZM16.0003 22.6667C15.267 22.6667 14.667 22.0667 14.667 21.3333C14.667 20.6 15.267 20 16.0003 20C16.7337 20 17.3337 20.6 17.3337 21.3333C17.3337 22.0667 16.7337 22.6667 16.0003 22.6667Z" fill="#619B8A" />
          </svg>
     )
}

export default TermCondition