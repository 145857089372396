import React, { useState } from "react";
import Jiuist from "../../../assets/Icons/Jiuist";
import BlueButton from "../Buttons/BlueButton";
import Report from "../../../assets/Icons/Report";
import Remove from "../../../assets/Icons/Remove";
import TurnOff from "../../../assets/Icons/TurnOff";
import { Link } from "react-router-dom";
import axios from "axios";
import { config, url } from "../../Helpers/Help";
import ButtonLoading from "../ButtonLoading";

const NotificationCard = (props) => {
  const { slug, title, body, id, item, getNotifications } = props;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const handleDelete = () => {
    setLoadingDelete(true);
    axios
      .delete(url + `users/notifications/${item.id}`, config)
      .then(() => {
        getNotifications().then(() => {
          setLoadingDelete(false);
        });
      })
      .catch(() => {
        setLoadingDelete(false);
      });
  };
  return (
    //     <Link
    //     dir="ltr"
    //       to={`/details/${slug}/${id}`}
    //       className="w-full flex-wrap p-2 rounded-xl  md:p-4 my-2 bg-white relative overflow-hidden flex items-start justify-start gap-4"
    //     >
    //       <div className="img w-12 md:w-36 h-12 md:h-36 relative overflow-hidden rounded-2xl">
    //         <img
    //           className="h-full w-full object-cover rounded"
    //           src={item.data.image}
    //           alt=""
    //         />
    //       </div>
    //       <div className="data w-4/6">
    //         <p className="my-2 text-mint"> {title} </p>
    //         <p>{body} </p>
    //         <div className="icon absolute h-full right-4 top-4 flex flex-col gap-4 bg-white">
    //           <button className="scale-75">
    //             <Remove />
    //           </button>
    //           <button className="scale-75">
    //             <TurnOff />
    //           </button>
    //           <button className="scale-75">
    //             <Report fill="red" />
    //           </button>
    //         </div>
    //       </div>
    //     </Link>
    <>
      <div
        dir="ltr"
        className="flex w-full gap-4 overflow-hidden flex-wrap p-2 rounded-xl  md:p-4 my-2"
      >
        <div className="img md:w-36 h-12 md:h-36 relative overflow-hidden rounded-2xl">
          <img
            className="h-full w-full object-cover radius-50"
            src={item.data.image}
            alt=""
          />
        </div>
        <div className="notification-card">
          <div className="data flex-1 h-32">
            <Link to={`/details/${slug}/${id}`}>
              <div>
                <p className="my-2 text-mint"> {title} </p>
                <p>{body} </p>
              </div>
            </Link>
          </div>
          <button disabled={loadingDelete} onClick={handleDelete}>
            {loadingDelete ? (
              <span className="loader"></span>
            ) : (
              <Remove height="20" width="20" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
