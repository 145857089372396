import React from 'react'

const TurnOff = () => {
     return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M11.9997 0.333374C5.56667 0.333374 0.333008 5.56704 0.333008 12C0.333008 18.433 5.56667 23.6667 11.9997 23.6667C18.4327 23.6667 23.6663 18.433 23.6663 12C23.6663 5.56704 18.4327 0.333374 11.9997 0.333374ZM16.958 12.875H7.04134C6.55776 12.875 6.16634 12.4836 6.16634 12C6.16634 11.5165 6.55776 11.125 7.04134 11.125H16.958C17.4416 11.125 17.833 11.5165 17.833 12C17.833 12.4836 17.4416 12.875 16.958 12.875Z" fill="#0F70B7" />
          </svg>

     )
}

export default TurnOff