import React from 'react'
import Male from '../../../assets/Icons/Male'
import Bill from '../../../assets/Icons/Bill'
import BlueButton from '../Buttons/BlueButton'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import { t } from 'i18next'
import Countdown from "react-countdown";
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import Context from '../../../Store/Store'
const Completionist = () => <span>You are good to go!</span>;

const AuctionCard = (props) => {
     const { id, slug, img, start, type, price, age, weight, gender, tags, isFav, action, location, loastDate, sectionType } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }
     return ( 
          <div className={`AuctionCard md:mx-2 flex items-start justify-center bg-white rounded-2xl no-wrap ${sectionType === 'lostAnimal' ? 'h-[18rem]' : 'h-[22.4rem]'}`}>
               <Link to={`/details/auctions/${id}`} className="w-full sm:w-1/2 h-full ">
                    <img src={img} alt="" className='h-full w-full object-cover rounded-2xl' />
               </Link>
               <div className="w-full sm:w-1/2">
                    <ul className='p-2 md:p-4'>
                         {start && <li className='mt-2 no-wrap'>Start in: <b><span className="digital text-3xl">
                              <Countdown date={Date.now() + 3600000 * `${17}`}>
                                   <Completionist />
                              </Countdown>
                         </span> </b> </li>}
                         {type && <li className='mt-4 no-wrap'>Type: <b>{type}</b></li>}
                         {price && <li className='mt-4 no-wrap'>Price start: <b>{price} {t('AED')}</b></li>}
                         {location && <li className='mt-4 no-wrap'>Location: <b>{location}</b></li>}
                         {loastDate && <li className='mt-4 no-wrap'>{t('Loast Date')}: <b>{loastDate}</b></li>}

                         {(gender || tags) &&
                              <li className='mt-4 no-wrap flex items-center justify-start gap-1 flex-wrap'>
                                   {gender && <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>}
                                   {weight && <span className='bg-[#EBEBEB] px-4 py-2 rounded-2xl text-[1.4rem]'>{weight} </span>}
                                   {weight && <span className='bg-[#EBEBEB] px-4 py-2 rounded-2xl text-[1.4rem]'>{age} </span>}

                                   {tags &&
                                        <>
                                             {
                                                  tags.slice(0, 2).map((item, index) => (
                                                       <span key={index} className='bg-[#EBEBEB] px-4 py-2 rounded-2xl text-[1.4rem]'>{item}</span>
                                                  ))
                                             }
                                        </>
                                   }
                              </li>
                         }

                         {
                              action ?
                                   <li className='flex items-center justify-between mt-4 gap-4'>
                                        <BlueButton className='w-full flex items-center justify-center gap-2 p-4 hover:bg-mint'> <Bill /> Remind me </BlueButton>
                                        <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${isFav ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                                   </li>
                                   :
                                   <li className='flex items-center justify-between mt-4'>
                                        <BlueButton className='w-full flex items-center justify-center gap-2 p-4 hover:bg-mint'> Contact</BlueButton>
                                   </li>
                         }

                    </ul>
               </div>
          </div>
     )
}

export default AuctionCard