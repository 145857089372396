import axios from 'axios'
import { t } from 'i18next'
import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import LeftArrow from '../../assets/Icons/LeftArrow'
import PasswordIcon from '../../assets/Icons/PasswordIcon'
import { config, url } from '../Helpers/Help'
import BlueButton from '../UI/Buttons/BlueButton'
import Input from '../UI/Input'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import alert from '../../Helpers/Modal'


const PasswordSecurity = (props) => {
     // const [oldPassword, setOldPassword] = useState('')
     const [password, setPassword] = useState('')
     const [rePassword, setRePassword] = useState('')
     // const navigate = useNavigate()
     const [errorMessageAlert, setErrorMessageAlert] = useState('')

     // send new password server
     const submitHandler = (e) => {
          e.preventDefault()
          // If Password dosen't match
          if (password !== rePassword || password.trim() === '' || password.length < 6) {
               alert({
                    title: `${t('Alert')}`,
                    text: `${t(`Enter the correct password and it must be the same and more than 6 characters long`)}`,
                    icon: 'error',
                    timer: 3500
               })
               return
          }
          axios.post(url + 'users/update',
               //  Data
               {
                    password: password,
                    password_confirmation: rePassword

               },
               // Configrations
               config)
               // Response
               .then(response => {
                    props.onCloseSidebar()
                    alert({
                         title: `${t('Alert')}`,
                         text: `${t(`Your password has been changed`)}`,
                         icon: 'success',
                         timer: 3500
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 3500
                    })
               })

     }

     return (
          <div className={`fixed shadow-xl top-0 min-w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onBack} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> {t('Password & security')} </h3>
               <li className='cursor-pointer flex items-center justify-start gap-4 my-8 mb-16'> <PasswordIcon /> {t('Password & security')}</li>
               <form onSubmit={submitHandler} action="" className='mt-4'>
                    {/* <Input value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} type='password' placeholder={t('Your password')} id='password' label={t('Re-Password')} /> */}
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder={t('Your password')} id='password' label={t('Password')} />
                    <Input value={rePassword} onChange={(e) => setRePassword(e.target.value)} type='password' placeholder={t('Your password')} id='password' label={t('Re-Password')} />
                    {errorMessageAlert && <p className='text-red'>{errorMessageAlert}</p>}
                    <BlueButton className='w-full p-6 mt-4'> {t('Submit')} </BlueButton>
               </form>
          </div>
     )
}

export default PasswordSecurity 