import React from 'react'

const Delete = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.99935 1.66667C5.39685 1.66667 1.66602 5.39751 1.66602 10C1.66602 14.6025 5.39685 18.3333 9.99935 18.3333C14.6018 18.3333 18.3327 14.6025 18.3327 10C18.3327 5.39751 14.6018 1.66667 9.99935 1.66667ZM13.1498 12.2663C13.3939 12.5104 13.3939 12.9058 13.1498 13.15C13.0277 13.2725 12.8677 13.3333 12.7077 13.3333C12.5477 13.3333 12.3877 13.2725 12.2656 13.1504L9.99935 10.8838L7.7331 13.15C7.61102 13.2725 7.45102 13.3333 7.29102 13.3333C7.13102 13.3333 6.97101 13.2725 6.84893 13.1504C6.60476 12.9063 6.60476 12.5108 6.84893 12.2667L9.1156 10L6.84935 7.73376C6.60518 7.48959 6.60518 7.09417 6.84935 6.85001C7.09352 6.60584 7.48893 6.60584 7.7331 6.85001L9.99935 9.11625L12.2656 6.85001C12.5098 6.60584 12.9052 6.60584 13.1493 6.85001C13.3935 7.09417 13.3935 7.48959 13.1493 7.73376L10.8831 10L13.1498 12.2663Z" fill="#D00000" />
          </svg>

     )
}

export default Delete