import React from 'react'
import Layout from '../Components/Layout'
import ErrorPages from '../assets/Icons/ErrorPage'

const ErrorPage = () => {
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-[80vh] flex items-center justify-center">
                    <div className="flex items-center justify-center flex-wrap">
                         <h3 className='font-bold text-[2rem] xl:text-[6rem] capitalize w-full text-center mb-8'>Page not found</h3>
                         <ErrorPages />
                    </div>
               </main>
          </Layout>
     )
}

export default ErrorPage