import { Route, Routes } from "react-router-dom";
import { Home, Auth, Introduction, UserLocation, ComitmentOwnership, SpecialOffer, Details, Question, LookFor, Advirtisings, Videos, Sell, UserOther, Buy, MyAds, ContactUs, AboutUs, HelpSupport, Notification, Gallery, MyBids, ErrorPage, Favorite, Terms, MyOffersPrice, AnimalsType, AddAnimals, Dashboard, AccessCard, SingleAccessCard, UserPage, Privacy, MobileSearch, LiveStreaming, Ads, Reports, RefundPolicy, MyInterested, PaymentSuccess, PaymentCanceld, MyAccessCard, WebSearch, TermsApp, PaymentFail, Followers, Following, FrindesAds, Country } from "./Pages";
// import firebase from './firebase';
import { useEffect } from "react";
import { config, url } from "./Components/Helpers/Help";
import axios from "axios";
import Video from "./Pages/Public/Video";

function App() {
  // useEffect(() => {
  //   const msg = firebase.messaging();
  //   msg.requestPermission().then(() => {
  //     return msg.getToken();
  //   }).then((data) => {
  //     console.warn("token", data)
  //     axios.post(url + 'users/update',
  //       //  Data
  //       {
  //         fcm_token: data,
  //       },
  //       // Configrations
  //       config)
  //       // Response
  //       .then(response => {
  //         console.log(response)
  //       })
  //   })
  // }, []);
 
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login-signup" element={<Auth />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/my-ads" element={<MyAds />} />
        <Route path="/user-location" element={<UserLocation />} />
        <Route path="/special-offer" element={<SpecialOffer />} />
        <Route path="/details/:type/:id" element={<Details />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/access-card' element={<AccessCard />} />
        <Route path='/single-access-card/:slug' element={<SingleAccessCard />} />
        <Route path="/advirtisings" element={<Advirtisings />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/my-offers-price" element={<MyOffersPrice />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/my-bids" element={<MyBids />} />
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/search" element={<WebSearch />} />
        <Route path="/app-search" element={<MobileSearch />} />
        <Route path="/live-streaming/:slug" element={<LiveStreaming />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/ads" element={<Ads />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="public/question" element={<Question />} />
        <Route path="public/questions" element={<Question />} />
        <Route path="profile/question" exact element={<Question />} />
        <Route path="public/videos" element={<Videos />} />
        <Route path="public/videos/:slug" element={<Video />} />
        <Route path="profile/videos" exact element={<Videos />} />
        <Route path="/public/animals-type/:slug" element={<AnimalsType />} />
        <Route path="/profile/animals-type/:slug" element={<AnimalsType />} />
        <Route path="/add-animal/:slug" element={<AddAnimals />} /> 
        <Route path="/country" element={<Country />} /> 
        <Route path='/user-other' element={<UserOther />} />
        <Route path='/user-page/:slug' element={<UserPage />} />
        <Route path='/commitment-ownership' element={<ComitmentOwnership />} />
        <Route path="/my-interested" element={<MyInterested />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-fail" element={<PaymentFail />} />
        <Route path="/payment-canceld" element={<PaymentCanceld />} />
        <Route path="/my-access-card" element={<MyAccessCard />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/help-support" element={<HelpSupport />} />
        <Route path="/term-condition" element={<Terms />} />
        <Route path="/term-condition-app" element={<TermsApp />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/followers/:slug" element={<Followers />} />
        <Route path="/following/:slug" element={<Following />} />
        <Route path="/frindes-ads" element={<FrindesAds />} />
      </Routes>
    </>
  );
}

export default App;
