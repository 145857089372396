import { t } from 'i18next'
import React, { useState } from 'react'
import PasswordEye from '../../assets/Icons/PasswordEye'
const Input = (props) => {
     const [typePass, setTypePass] = useState('password')
     const { id, value, type, onChange, onKeyPress, onFocus, onBlur, placeholder, input_className, readOnly } = props

     const togglePassword = () => {
          if (typePass === 'password') {
               setTypePass('text')
          } else {
               setTypePass('password')
          }
     }

     return (
          <div className={`form-group w-full mb-4 relative ${props.div_className}`}>
               <label htmlFor={props.id} className={`font-medium text-[1.5] ltr:text-left rtl:text-right w-full flex ${props.label_className}`}>{props.label}</label>
               <input
                    readOnly={readOnly}
                    id={id}
                    value={value}
                    type={type === 'password' ? typePass : type}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    className={`${input_className} bg-transparent w-full text-[1.5rem] rounded-lg border-2 border-gold py-3 px-5`} />
               {
                    type === 'password' &&
                    <div className="absolute top-2/3 rtl:left-4 ltr:right-4 mt-2 -translate-y-2/3 cursor-pointer" onClick={togglePassword}>
                         <PasswordEye />
                         {typePass === 'password' && <span className='absolute top-0 font-thin text-blue left-1/2 rtl:-mr-2 ltr:-ml-2 rotate-45 text-5xl'> | </span>}
                    </div>
               }
          </div>
     )
}

export default Input