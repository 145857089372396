import axios from 'axios'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import LineHeart from '../../../assets/Icons/LineHeart'
import Context from '../../../Store/Store'
import { config, url } from '../../Helpers/Help'
import BlueButton from '../Buttons/BlueButton'
import Verified from '../../../assets/Icons/Verified'
import { Link } from 'react-router-dom'

const QuestionsCard = (props) => {

  const { id, userImage, description, slug, name, badge, userId, hashtags } = props
  const ctx = useContext(Context);
  const itemIsFavorite = ctx.itemIsFavorite(id, slug);
  function toggleFavoriteStatusHandler() {
    console.log(id, slug)
    if (itemIsFavorite) {
      ctx.removeQuestionFavorite(id, slug);
    } else {
      ctx.addQuestionFavorite(id, slug)
    }
  }


  return (
    <>
      <div className={`AuctionCard dir-rtl m-2 flex items-start justify-center bg-white rounded-2xl pb-4`}>
        <div className="w-full min-h-[8rem] max-h-[27rem]">
          <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap">
            {userImage &&
              <>
                <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                  {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                    <img src={userImage} alt="" className='h-full w-full object-cover' />
                  </div>
                  }
                  {name && <div className="name">
                    {name}
                  </div>
                  }
                  <div className="flex items-center">
                    {badge === 'gold' ?
                      <Badge />
                      :
                      <BadgeGray />
                    }
                    {props.is_verified === 1 && <div className=""><Verified /></div>}
                  </div>
                </Link>

                <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
                  {props.rate &&
                    <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                      {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                      {props.rate === '1' && '★☆☆☆☆'}
                      {props.rate === '2' && '★★☆☆☆'}
                      {props.rate === '3' && '★★★☆☆'}
                      {props.rate === '4' && '★★★★☆'}
                      {props.rate === '5' && '★★★★★'}
                      {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                    </span>}
                </div>
              </>
            }
          </div>
          <ul className='px-2 md:px-4'>
            {description && <p className='text-[1.6rem] lines one'>{description}</p>}
            {hashtags ? <b className='text-[1.6rem] text-blue lines one'>{hashtags} </b> : <b className='opacity-25'>{t('No hashtag')}</b>}
            <li className='flex items-center justify-center mt-4 gap-4'>
              <BlueButton onClick={props.openModal} className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full mt-4'> {t('Answer')} </BlueButton>
              <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default QuestionsCard