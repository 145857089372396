import React from 'react'

const AddIcon = () => {
     return (
          <svg className='mx-auto flex' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M20 0C8.972 0 0 8.972 0 20C0 31.028 8.972 40 20 40C31.028 40 40 31.028 40 20C40 8.972 31.028 0 20 0ZM28.5 21.5H21.5V28.5C21.5 29.329 20.829 30 20 30C19.171 30 18.5 29.329 18.5 28.5V21.5H11.5C10.671 21.5 10 20.829 10 20C10 19.171 10.671 18.5 11.5 18.5H18.5V11.5C18.5 10.671 19.171 10 20 10C20.829 10 21.5 10.671 21.5 11.5V18.5H28.5C29.329 18.5 30 19.171 30 20C30 20.829 29.329 21.5 28.5 21.5Z" fill="#0F70B7" />
          </svg>
     )
}

export default AddIcon