export const categoriesSlider = {
     dots: false,
     arrows: true,
     infinite: true,
     slidesToShow: 14,
     slidesToScroll: 1,
     autoplay: false,
     // rtl: true,
     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [
          {
               breakpoint: 1600,
               settings: {
                    slidesToShow: 12,
                    slidesToScroll: 12
               }
          },
          {
               breakpoint: 1536,
               settings: {
                    slidesToShow: 10,
                    slidesToScroll: 10
               }
          },
          {
               breakpoint: 1280,
               settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8
               }
          }, {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
               }
          }
     ]
};

export const adsSlider = {
     dots: false,
     arrows: false,
     infinite: true,
     slidesToShow: 5, 
     slidesToScroll: 1,
     autoplay: true,
     // rtl: true,
     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [

          {
               breakpoint: 1536,
               settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
               }
          }, {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
               }
          },
          {
               breakpoint: 640,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          }
     ]
};

export const auctionSlider = {
     dots: false,
     arrows: false,
     infinite: false,
     slidesToShow: 3,
     slidesToScroll: 1,
     autoplay: false,
     centerPadding: "60px",
     // rtl:props.c,
     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [
          {
               breakpoint: 1280,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          }
     ]
};

export const productsSlider = {
     dots: false,
     arrows: true,
     infinite: false,
     slidesToShow: 5,
     slidesToScroll: 1,
     // slidesToShow: list.length > 4 ? 4 : list.length,
     autoplay: false,
     // ltr: true, 
     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [

          {
               breakpoint: 1536,
               settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
               }
          },
          {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          },
          {
               breakpoint: 640,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          }
     ]
};


export const advertisesSlider = {
     dots: false,
     arrows: false,
     infinite: false,
     slidesToShow: 5,
     slidesToScroll: 1,
     autoplay: true,
     centerPadding: "60px",
     // rtl:props.c,
     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [
          {
               breakpoint: 1280,
               settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
               }
          },
          {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          }
     ]
};
// export const adsBannerSlider = {
//      dots: false,
//      arrows: true,
//      infinite: false,
//      slidesToShow: 2,
//      slidesToScroll: 1,
//      // slidesToShow: list.length > 4 ? 4 : list.length,
//      autoplay: true,
//      // ltr: true, 
//      // speed: 4000,
//      // autoplaySpeed: 500,
//      // cssEase: 'linear',

// };


export const videosSlider = {
     dots: false,
     arrows: false,
     infinite: false,
     slidesToShow: 6,
     slidesToScroll: 6,
     autoplay: false,
     // rtl: true,

     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [

          {
               breakpoint: 1536,
               settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
               }
          },
          {
               breakpoint: 1280,
               settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
               }
          },
          {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          }
     ]
};

export const shortVideosSlider = {
     dots: false,
     arrows: false,
     infinite: false,
     slidesToShow: 9,
     slidesToScroll: 9,
     autoplay: false,
     // rtl: true,

     // speed: 4000,
     // autoplaySpeed: 500,
     // cssEase: 'linear',
     responsive: [

          {
               breakpoint: 1536,
               settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8
               }
          },
          {
               breakpoint: 1280,
               settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
               }
          },
          {
               breakpoint: 1024,
               settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
               }
          },
          {
               breakpoint: 768,
               settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
               }
          },
          {
               breakpoint: 500,
               settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
               }
          }
     ]
};

export const ProductSliderOptions = {
     dots: false,
     arrows: false,
     infinite: true,
     speed: 500,
     slidesToShow: 1,
     lazyLoad: true,
     fade: true,
     autoplay: true,
     slidesToScroll: 1,
     // rtl: true,

};

export const ProductsliderNav = {
     arrows: false,
     infinite: false,
     asNavFor: 'nav1',
     slidesToShow: 100,
     focusOnSelect: true,
     swipeToSlide: true,
}

export const ProductsliderNav_small = {
     arrows: false,
     infinite: false,
     asNavFor: 'nav1',
     focusOnSelect: true,
     slidesToShow: 6,
     swipeToSlide: true,
}