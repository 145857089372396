import React from 'react'

const UserIcon = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M10.0007 1.66667C7.70687 1.66667 5.83398 3.53955 5.83398 5.83333C5.83398 8.12712 7.70687 10 10.0007 10C12.2944 10 14.1673 8.12712 14.1673 5.83333C14.1673 3.53955 12.2944 1.66667 10.0007 1.66667ZM5.20898 11.6667C4.18086 11.6667 3.33398 12.5135 3.33398 13.5417V14.0413C3.33398 15.2667 4.11095 16.3658 5.29606 17.1216C6.48117 17.8773 8.10267 18.3333 10.0007 18.3333C11.8986 18.3333 13.5201 17.8773 14.7052 17.1216C15.8904 16.3658 16.6673 15.2667 16.6673 14.0413V13.5417C16.6673 12.5135 15.8204 11.6667 14.7923 11.6667H5.20898Z" fill="#0F70B7" />
          </svg>

     )
}

export default UserIcon