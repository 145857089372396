import { t } from "i18next";
import { config, url } from '../Helpers/Help'
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Hours from "../../assets/Icons/UserIcons/Colors/Hours";
import Public from "../../assets/Icons/UserIcons/White/Public";
import Quick from "../../assets/Icons/UserIcons/White/Quick";
import BlueButton from "../UI/Buttons/BlueButton";
import Input from "../UI/Input";
import PublicIcon from "../../assets/Icons/UserIcons/Colors/Public";
import VipWhite from "../../assets/Icons/UserIcons/White/VipWhite";
import Vip from "../../assets/Icons/Vip";
import Title from "./Title";
import alert from "../../Helpers/Modal";
import axios from "axios";
import Map from "../UI/Map";
import ButtonLoading from "../UI/ButtonLoading";
import { Link, useNavigate } from "react-router-dom";

const pricesType = [
     { value: '1', label: localStorage.awaLang === 'en' ? 'Open Price' : 'سعر الفتح' },
     { value: '2', label: localStorage.awaLang === 'en' ? 'Start Price' : 'السعر المبدئي' },
     { value: '3', label: localStorage.awaLang === 'en' ? 'End Price' : 'السعر النهائي' },
];

const AddSubCategory = (props) => {
     const navigate = useNavigate()
     const [category, setCategory] = useState("");
     const [categories, setCategories] = useState([])
     const [childs, setChilds] = useState([])

     const [CategoryError, setCategoryError] = useState(false)
     const [TypeError, setTypeError] = useState(false)

     const [modal, setModal] = useState(false)
     const [title, setTitle] = useState()
     const [titleAr, setTitleAr] = useState()

     // Get Childes of Categories by ID
     useEffect(() => {
          if (category) {
               const item = categories.find(item => item.id === category);
               setChilds(item.childs)
          }

     }, [category, categories])



     // Get Categories from localStorage
     useEffect(() => {
          axios(url + 'categories', config)
               // Response
               .then(response => {
                    console.log(response)
                    setCategories(response.data.data)

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }, [])




     const addCategories = () => {
          axios.post(url + 'categories',
               {
                    title: title,
                    title_ar: titleAr,
                    parent_id: category,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    alert({
                         title: `${t('Success')}`,
                         text: `${t('Your sub category is added')}`,
                         icon: 'success',
                         timer: 2000
                    })
                   setTimeout(() => {
                        navigate(-1)
                   }, 2000);
                    
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }

     return (
          <>
               {modal &&

                    <>
                         <div className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40" onClick={() => setModal(false)} />
                         <div className={`fixed shadow-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[50rem] bg-[#F9F9F9] rounded-2xl z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}>
                              <button className='w-12 h-12 border-2 font-bold text-[2rem] flex items-center justify-center rounded-full absolute right-8 top-4 border-gray-600' onClick={() => setModal(false)}>x</button>
                              <div className="flex flex-col justify-center items-center">
                                   <h3 className='font-bold text-[1.8rem] mb-8'>New Title</h3>
                                   <Input onChange={(e) => setTitle(e.target.value)} input_className={`border-2`} div_className="w-full" value={title} type="text" placeholder={t("Title")} id="Title" label={t("Title")} />
                                   <Input onChange={(e) => setTitleAr(e.target.value)} input_className={`border-2`} div_className="w-full" value={titleAr} type="text" placeholder={t("Arabic Title")} id="ArabicTitle" label={t("Arabic Title")} />
                                   <BlueButton onClick={addCategories} className='py-6 px-8'>Submit</BlueButton>
                              </div>
                         </div>
                    </>
               }


               <div className="row flex items-start justify-center flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
                    <Title className="md:mb-8"> {t('Add Sub Category')}</Title>



                    <div className="w-full md:w-1/2 p-2 my-2">
                         <div className="flex flex-col">
                              <label htmlFor="" className="font-medium text-[1.5] rtl:text-right block w-full"> {t('Category')} </label>
                              <select onFocus={() => setCategoryError(false)} onChange={(e) => { setCategory(e.target.value) }} className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${CategoryError ? 'border-red' : 'border-blue'}`}>
                                   <option value={''}> {t('chosse')}. . . </option>
                                   {
                                        categories &&
                                        categories.map(item => (
                                             <option value={item.id} key={item.id}> {item.title} </option>
                                        ))
                                   }
                              </select>
                         </div>
                         <Input onChange={(e) => setTitle(e.target.value)} input_className={`border-2 border-blue`} div_className="w-full mt-8" value={title} type="text" placeholder={t("Title")} id="Title" label={t("Title")} />
                         <Input onChange={(e) => setTitleAr(e.target.value)} input_className={`border-2 border-blue`} div_className="w-full mt-8" value={titleAr} type="text" placeholder={t("Arabic Title")} id="ArabicTitle" label={t("Arabic Title")} />
                         <div className="flex justify-center">
                              <BlueButton onClick={addCategories} className='py-6 px-8 mt-10'>Submit</BlueButton>
                         </div>
                    </div>


               </div>
          </>
     );
};

export default AddSubCategory;
