import React, { useContext } from 'react'
import Email from '../../../assets/Icons/Email'
import LineHeart from '../../../assets/Icons/LineHeart'
import Phone from '../../../assets/Icons/Phone'
import AwaImg from '../../../assets/images/AwaImg.png'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Context from '../../../Store/Store'
import { t } from 'i18next'
import WhatsappFlat from '../../../assets/Icons/WhatsappFlat'

const Delivery = (props) => {
     const { id, img, location, phone, email, first_name, last_name, slug, whatsapp } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center flex-wrap min-h-[20rem] bg-white rounded-2xl pb-4`}>
                    <Link to={`/details/${slug}/${id}`} className="w-full">
                         <img src={img ? img : AwaImg} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                    </Link>
                    <div className="w-full h-[13rem]">
                         <ul className='px-2 md:px-4 py-3'>
                              {(first_name && last_name) && <li className='mt-2 no-wrap'> <b>{first_name} {last_name}</b> </li>}
                              {location && <li className='mt-2 no-wrap'>{t('Location')}: <b>{location}</b> </li>}
                              <li className='mt-2 no-wrap flex items-center justify-start'>{t('Rate')} :
                                   {props.rate &&
                                        <span className="text-[1.5rem] text-yellow-500 flex">
                                             {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                             {props.rate === '1' && '★☆☆☆☆'}
                                             {props.rate === '2' && '★★☆☆☆'}
                                             {props.rate === '3' && '★★★☆☆'}
                                             {props.rate === '4' && '★★★★☆'}
                                             {props.rate === '5' && '★★★★★'}
                                             {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                        </span>
                                   }
                              </li>
                              <div className="flex justify-between mt-3">
                                   <div className='flex gap-3 '>
                                        {t('Contact')}:
                                        {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                        {email && <a href={`mailto:${email}`}>   <Email /> </a>}
                                        {whatsapp && <a target='_blank' rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${whatsapp}`}> <WhatsappFlat /> </a>}
                                   </div>
                                   <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                              </div>

                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default Delivery