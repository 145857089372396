import React from 'react'

const VipWhite = () => {
  return (
       <svg width="35" height="42" viewBox="0 0 35 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2339 0.5C9.99516 0.500039 9.75641 0.590716 9.57479 0.772486L5.61901 4.72688C5.25577 5.09042 5.25577 5.68207 5.61901 6.04561C5.80079 6.22738 6.03946 6.3181 6.27811 6.3181C6.39743 6.3181 6.51702 6.29549 6.6295 6.25013L7.62239 7.7048C7.92691 8.14913 8.25034 8.57819 8.59525 8.98834L0.581137 16.371C0.21758 16.7065 0.00965574 17.1634 0.000333757 17.6606C-0.00898823 18.1546 0.177209 18.6203 0.528337 18.9714C0.870143 19.3132 1.32119 19.5 1.80283 19.5H1.83985C2.33391 19.4876 2.79392 19.2822 3.12951 18.9186L10.5155 10.9024C10.9225 11.2504 11.3517 11.5763 11.796 11.8777L13.2508 12.8681C13.1134 13.2052 13.181 13.6066 13.4541 13.8797C13.6359 14.0612 13.8745 14.1522 14.1132 14.1522C14.3521 14.1522 14.5905 14.0615 14.7723 13.8797L18.7274 9.92475C19.0907 9.56152 19.091 8.96985 18.7274 8.60662C18.4543 8.3335 18.0529 8.26568 17.7157 8.40271L16.7241 6.94682C15.6023 5.30001 14.1979 3.89565 12.551 2.77396L11.0969 1.78414C11.2339 1.44701 11.1658 1.0453 10.893 0.772486C10.711 0.59056 10.4726 0.499961 10.2339 0.5Z" fill="white" />
            <path d="M13.6016 24.5C13.9423 24.5 14.2343 24.613 14.4776 24.839C14.7209 25.0651 14.8426 25.3395 14.8426 25.6624C14.8426 25.84 14.802 26.0256 14.7209 26.2194L8.5649 40.7009C8.43512 40.9753 8.25669 41.1771 8.02959 41.3063C7.81871 41.4354 7.59972 41.5 7.37262 41.5C7.14552 41.4839 6.92654 41.4112 6.71566 41.2821C6.521 41.1529 6.3669 40.9672 6.25335 40.7251L0.0973284 26.2194C0.0648856 26.1387 0.0405535 26.0579 0.024332 25.9772C0.00811067 25.8803 0 25.7835 0 25.6866C0 25.3153 0.137882 25.0247 0.413646 24.8148C0.689409 24.6049 0.957062 24.5 1.2166 24.5C1.73569 24.5 2.11689 24.7664 2.36021 25.2991L7.93226 38.4487H7.22663L12.4824 25.2749C12.7095 24.7583 13.0826 24.5 13.6016 24.5Z" fill="white" />
            <path d="M19.7778 40.265C19.7616 40.6201 19.6237 40.9188 19.3642 41.161C19.1208 41.387 18.8289 41.5 18.4882 41.5C18.1151 41.5 17.8069 41.387 17.5636 41.161C17.3365 40.9188 17.2229 40.6201 17.2229 40.265V25.7835C17.2229 25.4283 17.3446 25.1377 17.5879 24.9117C17.8312 24.6695 18.1476 24.5484 18.5369 24.5484C18.8613 24.5484 19.1452 24.6695 19.3885 24.9117C19.648 25.1377 19.7778 25.4283 19.7778 25.7835V40.265Z" fill="white" />
            <path d="M30.2066 24.5484C31.0663 24.5484 31.8612 24.7825 32.5911 25.2507C33.3211 25.7028 33.9051 26.3243 34.343 27.1154C34.781 27.9065 35 28.7944 35 29.7792C35 30.764 34.781 31.66 34.343 32.4672C33.9051 33.2583 33.3211 33.896 32.5911 34.3803C31.8612 34.8485 31.0663 35.0826 30.2066 35.0826H25.5105L25.7051 34.6952V40.265C25.7051 40.6201 25.5916 40.9188 25.3645 41.161C25.1536 41.387 24.8697 41.5 24.5129 41.5C24.1722 41.5 23.8883 41.387 23.6612 41.161C23.4504 40.9188 23.3449 40.6201 23.3449 40.265V25.7835C23.3449 25.4283 23.4666 25.1377 23.7099 24.9117C23.9532 24.6695 24.2452 24.5484 24.5859 24.5484H30.2066ZM30.2066 32.7578C30.6283 32.7578 31.0095 32.6206 31.3502 32.3462C31.7071 32.0717 31.9909 31.7085 32.2018 31.2564C32.4289 30.8044 32.5425 30.312 32.5425 29.7792C32.5425 29.2464 32.4289 28.7621 32.2018 28.3262C31.9909 27.8903 31.7071 27.5432 31.3502 27.2849C31.0095 27.0104 30.6283 26.8732 30.2066 26.8732H25.4618L25.7051 26.51V33.0726L25.4862 32.7578H30.2066Z" fill="white" />
       </svg>

  )
}

export default VipWhite