import React from 'react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

const SectionTitle = (props) => {
     return (
          <div className={`flex items-center justify-between mb-8 ${props.className}`}>
               <h3 className={` flex items-center md:items-start  justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem] ${props.className}`}>
                    {props.children}
               </h3>
               <span className='flex items-center justify-between'>
                    {props.to &&
                         <Link className='text-blue font-bold text-[1.4rem] underline' to={props.to}>
                              {t('View All')}
                         </Link>
                    }

                    {props.number && <span className="relative w-8 h-8 -mt-2 inline-flex rounded-md items-center mx-4 justify-center bg-blue text-white text-[1.4rem]">
                         {props.number}
                    </span>}
               </span>
          </div>
     )
}

export default SectionTitle