import React, { useContext } from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import Male from '../../../assets/Icons/Male'
import Phone from '../../../assets/Icons/Phone'
import Whatsapp from '../../../assets/Icons/Whatsapp'
import BlueButton from '../Buttons/BlueButton'
import RedButton from '../Buttons/RedButton'
import Lottie from 'react-lottie';
import InMarket from '../../../Components/Lottie/InMarket.json'
import { t } from 'i18next'
import { Veterinary } from '../../../Pages'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Context from '../../../Store/Store'
import Verified from '../../../assets/Icons/Verified'

const Mating = (props) => {
     const { img, slug, userImage, noImage, description, name, veterinary, id, badg, age, weight, userId, type, badge, myBid, myPrice, lastBid, price, gender, tags, isFav, inMarket, offer, oldPrice, action, phone, email, contact, question, averagePrice, title, locationAnimal, duration, whatsapp, photographer } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${photographer ? 'flex-col-reverse pb-0' : 'flex-col'}`}>
                    <Link to={`/details/matings/${id}`} className="w-full h-[25rem] flex items-center relative overflow-hidden">
                         <img src={img} alt="" className={`w-auto max-h-full rounded-2xl mx-auto `} />
                    </Link>
                    <div className="w-full min-h-[10rem] max-h-[27rem]">
                         <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap">
                              {
                                   title && !veterinary && <h3 className='font-semibold '>{title}</h3>
                              }
                              {(userImage || name || badg) &&
                                   <>
                                        <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                                             {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                                  <img src={userImage} alt="" className='h-full w-full object-cover' />
                                             </div>
                                             }
                                             {name && <div className="name">
                                                  {name}
                                             </div>
                                             }
                                             <div className="flex items-center">
                                                  {badge === 'gold' ?
                                                       <Badge />
                                                       :
                                                       <BadgeGray />
                                                  }
                                                  {props.is_verified === 1 && <div className=""><Verified /></div>}
                                             </div>
                                        </Link>

                                        <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
                                             {props.rate &&
                                                  <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                                                       {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                       {props.rate === '1' && '★☆☆☆☆'}
                                                       {props.rate === '2' && '★★☆☆☆'}
                                                       {props.rate === '3' && '★★★☆☆'}
                                                       {props.rate === '4' && '★★★★☆'}
                                                       {props.rate === '5' && '★★★★★'}
                                                       {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                                  </span>}
                                        </div>
                                   </>
                              }
                              {
                                   veterinary && <h3 className='font-semibold mt-2'>{title}</h3>
                              }
                         </div>
                         <ul className='px-2 md:px-4'>
                              <Link to={`/details/matings/${id}`} className="w-full">
                                   {description && <p className='text-[1.6rem]'>{description}</p>}
                                   {type && <li className='mt-2 no-wrap'>{t('Type')}: <b>{type}</b></li>}
                                   {myBid && <li className='mt-2 no-wrap'>{t('My Bid')}: <b>{myBid}</b></li>}
                                   {myPrice && <li className='mt-2 no-wrap'>{t('My Price')}: <b>{myPrice}</b></li>}
                                   {lastBid && <li className='mt-2 no-wrap'>{t('Last Bid')}: <b>{lastBid}</b></li>}
                                   {averagePrice && <li className='mt-2 no-wrap'>{t('Average Price')}: <b>{averagePrice}</b></li>}
                                   {locationAnimal && <li className='mt-2 no-wrap'>{t('Location')}: <b>{locationAnimal}</b> </li>}
                                   {price && <li className='mt-2 no-wrap'>{t('Price')} {!offer && 'start'}: <b>{price} {t('AED')} {duration && `/ ${duration}`}</b> {oldPrice && <span className='text-[1.2rem] text-red line-through'>{oldPrice}</span>} </li>}
                                   {whatsapp && <a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} className='mt-2 w-full no-wrap flex flex-wrap items-center justify-start gap-2'>{t('Contact')}: <b className='flex flex-wrap items-center justify-start gap-2'> <Whatsapp /> {whatsapp}</b></a>}
                              </Link>
                              <li className='w-full mt-2 flex items-center justify-start flex-wrap'>
                                   <p className='w-4/6 flex items-center justify-start flex-wrap gap-2'>
                                        {gender && <span className='bg-blue w-8 md:w-12 h-8 md:h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>}
                                        {age && <span className='bg-[#ececec] px-1 md:px-4 py-2 rounded-2xl text-[1.2rem] md:text-[1.4rem]'>{age}</span>}
                                        {weight && <span className='bg-[#ececec] px-1 md:px-4 py-2 rounded-2xl text-[1.2rem] md:text-[1.4rem]'>{weight}</span>}
                                   </p>

                                   {(phone || email) &&
                                        <p className='flex items-center flex-wrap gap-1 w-2/6 justify-end'>
                                             {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                             {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                        </p>
                                   }

                                   {/* {(tags || gender) &&
                                   <p className='w-4/6 flex items-center justify-start flex-wrap gap-2'>
                                        {gender &&
                                             <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>
                                        }
                                        {tags && <>{tags && <>{tags.slice(0, 2).map((item, index) => (
                                             <span className='bg-[#ececec] px-4 py-2 rounded-2xl text-[1.4rem]' key={index}>{item}</span>
                                        ))}</>}</>}
                                   </p>
                              } */}
                                   {/* {(phone || email) &&
                                   <p className={` flex items-center  flex-wrap gap-1 ${!tags ? 'w-full justify-start' : 'w-2/6 justify-end'}`}>
                                        {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                        {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                   </p>
                              } */}
                              </li>
                              {!action &&
                                   <li className='flex items-center justify-center mt-4 gap-4'>
                                        <Link to={`/details/matings/${id}`} className="w-full">
                                             <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full text-xl md:text-[1.6rem]'> {t('Details')} </BlueButton>
                                        </Link>
                                        <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                                   </li>
                              }
                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default Mating