import React from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import Male from '../../../assets/Icons/Male'
import Phone from '../../../assets/Icons/Phone'
import BlueButton from '../Buttons/BlueButton'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import WhatsappFlat from '../../../assets/Icons/WhatsappFlat'
import Verified from '../../../assets/Icons/Verified'

const LookFor = (props) => {
     const { id, userImage, age_range_from, age_range_to, name, veterinary, price_range_from, badg, img, price_range_to, userId, type, badge, myBid, myPrice, lastBid, price, gender, tags, isFav, inMarket, offer, oldPrice, action, phone, email, contact, question, averagePrice, title, locationAnimal, duration, whatsapp, photographer } = props
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl my-2 mx-2 flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${photographer ? 'flex-col-reverse pb-0' : 'flex-col'}`}>
                    <Link to={`/details/looking-for/${id}`} className='w-full'>
                              <img src={img} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                         </Link>
                    <div className="w-full min-h-[10rem] max-h-[27rem]">
                         <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap no-wrap">
                              {
                                   title && !veterinary && <h3 className='font-semibold '>{title}</h3>
                              }
                              {(userImage || name || badg) &&
                                   <>
                                   <Link to={`/user-page/${userId}`} className="user flex items-center gap-2  w-2/3">
                                             {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                                  <img src={userImage} alt="" className='h-full w-full object-cover' />
                                             </div>
                                             }
                                             {name && <div className="name">
                                                  {name}
                                             </div>
                                             }
                                        <div className="flex items-center">
                                             {badge === 'gold' ?
                                                  <Badge />
                                                  :
                                                  <BadgeGray />
                                             }
                                             {props.is_verified === 1 && <div className=""><Verified /></div>}
                                        </div>
                                        </Link>

                                   <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
                                        {props.rate &&
                                             <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                                                  {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                  {props.rate === '1' && '★☆☆☆☆'}
                                                  {props.rate === '2' && '★★☆☆☆'}
                                                  {props.rate === '3' && '★★★☆☆'}
                                                  {props.rate === '4' && '★★★★☆'}
                                                  {props.rate === '5' && '★★★★★'}
                                                  {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                             </span>}
                                   </div>
                                   </>
                              }
                         </div>
                         <Link to={`/details/looking-for/${id}`}>
                              <ul className='px-2 md:px-4'>
                                   {type && <li className='mt-2 no-wrap'>{t('Type')}: <b>{type}</b></li>}
                                   {age_range_from && age_range_to && <li className='mt-2 no-wrap'>{t('Age')}: <b>{age_range_from} - {age_range_to}</b> </li>}
                                   <p className='w-full  flex items-center justify-start flex-wrap gap-2 '>
                                        {t('gender')}:
                                        <span className='bg-blue p-2 rounded-full w-10 h-10 flex items-center justify-center'>
                                             {gender && gender === 'male' ? <Male /> : <Female />}
                                        </span>
                                        {gender && gender === 'male' ? 'Male' : 'Female'}
                                   </p>
                                   {/* {myBid && <li className='mt-2 no-wrap'>My Bid: <b>{myBid}</b></li>} */}
                                   {/* {myPrice && <li className='mt-2 no-wrap'>My Price: <b>{myPrice}</b></li>} */}
                                   {/* {lastBid && <li className='mt-2 no-wrap'>Last Bid: <b>{lastBid}</b></li>} */}
                                   {/* {averagePrice && <li className='mt-2 no-wrap'>Average Price: <b>{averagePrice}</b></li>} */}
                                   {/* {locationAnimal && <li className='mt-2 no-wrap'>Location: <b>{locationAnimal}</b> </li>} */}
                                   {price_range_from && price_range_to && <li className='mt-2 no-wrap'>{t('Price')}: <b>{price_range_from} - {price_range_to}</b>  AED </li>}

                                   {/* <li className='flex items-center justify-center mt-4 gap-4'>
                              <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full mt-4'> Contact </BlueButton>
                              <button className=''> <LineHeart className={` hover:fill-blue ${isFav ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                         </li> */}
                              </ul>
                         </Link>
                         <ul className='px-2 md:px-4'>
                              <li className='w-full mt-2 flex items-center justify-start flex-wrap gap-4'>
                                   {t('Contact')} :
                                   {(phone || email) &&
                                        <p className='flex items-center flex-wrap gap-1   justify-end'>
                                             {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                             {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                             {whatsapp && <a target='_blank' rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${whatsapp}`}> <WhatsappFlat /> </a>}

                                        </p>
                                   }
                              </li>
                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default LookFor