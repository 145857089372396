import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { t } from 'i18next';
const onLoad = marker => {
     // console.log('marker: ', marker)
}

const MAP_OPTIONS = {
     scrollwheel: true,
     minZoom: 8,
}

const containerStyle = {
     width: '100%',
     height: '162px',
     borderRadius: '1rem',
     overFlow: 'hidden',
     position: 'relative'
};


const ProductMap = (props) => {
     const center = {
          lng: props.lng,
          lat: props.lat
     };
     const position = {
          lng: props.lng,
          lat: props.lat
     }
     return (
          <>
               <h3 className='text-[1.8rem] font-bold my-2 flex items-center mb-16 md:mb-4'>{t('location')} </h3>
               {/* <p className='my-8'>Dubai, Dubai, UAE</p> */}
               <LoadScript googleMapsApiKey="AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc">
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} options={MAP_OPTIONS}>
                         <Marker onLoad={onLoad} position={position} />
                    </GoogleMap>
               </LoadScript>
          </>
     )
}

export default ProductMap
