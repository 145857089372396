import React from 'react'

const SearchIcon = () => {
     return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.8125 0.75C4.82222 0.75 0.75 4.82223 0.75 9.8125C0.75 14.8028 4.82222 18.875 9.8125 18.875C11.8582 18.875 13.7431 18.1831 15.2629 17.0317L21.1162 22.8838C21.2314 23.0038 21.3694 23.0995 21.522 23.1655C21.6747 23.2315 21.839 23.2664 22.0053 23.2681C22.1716 23.2697 22.3365 23.2382 22.4905 23.1754C22.6445 23.1125 22.7844 23.0196 22.902 22.902C23.0196 22.7844 23.1125 22.6445 23.1754 22.4905C23.2382 22.3365 23.2697 22.1716 23.2681 22.0053C23.2664 21.839 23.2315 21.6747 23.1655 21.522C23.0995 21.3694 23.0038 21.2314 22.8838 21.1162L17.0317 15.2629C18.1831 13.7431 18.875 11.8582 18.875 9.8125C18.875 4.82223 14.8028 0.75 9.8125 0.75ZM9.8125 3.25C13.4517 3.25 16.375 6.17332 16.375 9.8125C16.375 11.5598 15.6963 13.1379 14.5928 14.3108C14.4854 14.3886 14.391 14.4829 14.3132 14.5903C13.1401 15.6953 11.5611 16.375 9.8125 16.375C6.17332 16.375 3.25 13.4517 3.25 9.8125C3.25 6.17332 6.17332 3.25 9.8125 3.25Z" fill="white" />
          </svg>
     )
} 

export default SearchIcon