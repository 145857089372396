import { t } from 'i18next'
import React, { useContext } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Lottie from 'react-lottie'
import { Link } from 'react-router-dom'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import LineHeart from '../../../assets/Icons/LineHeart'
import Phone from '../../../assets/Icons/Phone'
import Context from '../../../Store/Store'
import BlueButton from '../Buttons/BlueButton'
import Verified from '../../../assets/Icons/Verified'
import WhatsappFlat from '../../../assets/Icons/WhatsappFlat'

const WholesaleMarket = (props) => {
     const { id, slug, img, userImage, description, name, badge, whatsapp, userId, price, isFav, oldPrice, phone, email, duration, } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 flex-col`}>
                    {img &&
                         <Link to={`/details/markets/${id}`} className="w-full">
                              <img src={img} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                         </Link>
                    }
                    <div className="w-full min-h-[10rem] max-h-[27rem]">
                         <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap">
                              {(userImage || name || badge) && <>
                                   <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                                        {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                             <img src={userImage} alt="" className='h-full w-full object-cover' />
                                        </div>
                                        }
                                        {name && <div className="name">
                                             {name}
                                        </div>
                                        }
                                        <div className="flex items-center">
                                             {badge === 'gold' ? 
                                                  <Badge />
                                                  :
                                                  <BadgeGray />
                                             }
                                             {props.is_verified === 1 && <div className=""><Verified /></div>}

                                        </div>
                                   </Link>

                                   <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
                                        {props.rate &&
                                             <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                                                  {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                  {props.rate === '1' && '★☆☆☆☆'}
                                                  {props.rate === '2' && '★★☆☆☆'}
                                                  {props.rate === '3' && '★★★☆☆'}
                                                  {props.rate === '4' && '★★★★☆'}
                                                  {props.rate === '5' && '★★★★★'}
                                                  {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                             </span>}
                                   </div>
                              </>
                              }
                         </div>
                         <ul className='px-2 md:px-4'>
                              <Link to={`/details/markets/${id}`} className="w-full">
                                   {description && <p className='text-[1.6rem] lines three min-h-[6.5rem]'>{description}</p>}
                                   {price && <li className='mt-2 no-wrap'>{t('Price')}  : <b>{price} {t('AED')} {duration && `/ ${duration}`}</b> {oldPrice && <span className='text-[1.2rem] text-red line-through'>{oldPrice}</span>} </li>}

                              </Link>
                              <li className='w-full mt-2 flex items-center justify-start flex-wrap'>

                                   {(phone || email) &&
                                        <p className='flex items-center flex-wrap gap-1 w-full justify-start'>
                                             {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                             {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                             {whatsapp && <a target='_blank' rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${whatsapp}`}> <WhatsappFlat /> </a>}

                                        </p>
                                   }
                              </li>
                              <li className='flex items-center justify-center mt-4 gap-4'>
                                   <Link to={`/details/markets/${id}`} className='hover:opacity-70 bg-blue rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all border-2 border-blue flex items-center justify-center py-4 px-2 md:p-4 hover:bg-mint hover:border-mint w-full text-xl md:text-[1.6rem]'> {t('Your Offer Price')} </Link>
                                   <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                              </li>

                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default WholesaleMarket