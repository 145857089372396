import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LeftArrow from '../../assets/Icons/LeftArrow'
import UserIcon from '../../assets/Icons/UserIcon'
import { userImage } from '../Helpers/Data'
import BlueButton from '../UI/Buttons/BlueButton'
import Input from '../UI/Input'
import AvatarImageCropper from 'react-avatar-image-cropper';
import axios from 'axios'
import { config, url } from '../Helpers/Help'
import alert from '../../Helpers/Modal'

const AccountInformation = (props) => {
     const [email, setEmail] = useState('')
     const [phone, setPhone] = useState('')
     const [f_name, setFname] = useState('')
     const [l_name, setLname] = useState('')
     const [Whatsapp, setWhatsapp] = useState('') 
     const [upload, setUpload] = useState()
     const [updatePhoto, setUpdatePhoto] = useState(false)
     const [errorMessageAlert, setErrorMessageAlert] = useState('')
     const [nickname, setNickname] = useState('')
     const [bio, setBio] = useState('')

     const submitHandler = (e) => {
          e.preventDefault()
          console.log(upload)
          axios.post(url + 'users/update',
               //  Data
               {
                    f_name: f_name,
                    l_name: l_name,
                    email: email,
                    phone: phone,
                    whatsapp: Whatsapp,
                    personal_picture: upload,
                    nickname: nickname,
                    bio: bio
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setFname()
                    setLname()
                    setEmail()
                    setPhone()
                    setWhatsapp()
                    setNickname()
                    setUpload()
                    setBio()
                    window.location.reload(true);
                    console.log(response.data.data.user)
                    localStorage.setItem('__User_aW__a_Info', JSON.stringify(response.data.data.user))

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 3500
                    })
               })
     }


     useEffect(() => {
          if (localStorage.__User_aW__a_Info) {
               const userData = localStorage.getItem('__User_aW__a_Info')
               const userInfo = JSON.parse(userData)
               setFname(userInfo.f_name)
               setLname(userInfo.l_name)
               setNickname(userInfo.nickname)
               setEmail(userInfo.email)
               setPhone(userInfo.phone)
               setWhatsapp(userInfo.whatsapp)
               setUpload(userInfo.personal_picture)
          }
     }, []);

     const HandleMenu = () => {

     }
     return (
          <div className={`fixed shadow-xl top-0 pt-16 md:pt-0 w-full md:w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onBack} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> {t('Account information')}  </h3>
               <li className='mb-6 cursor-pointer flex items-center justify-start gap-4'> <UserIcon /> {t('Personal & Account information')} </li>
               <button onClick={HandleMenu}>
                    {updatePhoto ?
                         <div className='bg-cover' style={{ backgroundImage: `url(${upload})`, width: '200px', height: '200px', margin: 'auto' }}>
                              <AvatarImageCropper apply={(file) => setUpload(file)} isBack={true} />
                         </div>
                         :
                         <div className="user flex items-center gap-2">
                              <div className="userImage w-36 h-36 rounded-full relative overflow-hidden border-blue border-2 p-2">
                                   <img src={upload} alt="" className='h-full w-full object-cover rounded-full' />
                              </div>
                              <h3 className="font-semibold" onClick={() => setUpdatePhoto(true)}> {t('Change pic')} </h3>
                         </div>
                    }
               </button>
               <form onSubmit={submitHandler} action="" className={` ${updatePhoto && 'mt-24'}`}>
                    <Input div_className='my-8' value={f_name} onChange={(e) => setFname(e.target.value)} type='text' placeholder={t('First name')} id='f_name' label={t('First name')} />
                    <Input div_className='my-8' value={l_name} onChange={(e) => setLname(e.target.value)} type='text' placeholder={t('Last name')} id='l_name' label={t('Last name')} />
                    <Input div_className='my-8' value={bio} onChange={(e) => setBio(e.target.value)} type='text' placeholder={t('Bio')} id='Bio' label={t('Bio')} />
                    <Input div_className='my-8' value={nickname} onChange={(e) => setNickname(e.target.value)} type='text' placeholder={t('Your Nickname')} id='nickname' label={t('Your Nickname')} />
                    <Input div_className='my-8' value={email} onChange={(e) => setEmail(e.target.value)} type='email' placeholder={t('Your Email')} id='Email' label={t('Email address')} />
                    <Input div_className='my-8' value={phone} onChange={(e) => setPhone(e.target.value)} type='tel' placeholder={t('Your  Phone')} id='Phone' label={t('Phone')} />
                    <Input div_className='my-8' value={Whatsapp} onChange={(e) => setWhatsapp(e.target.value)} type='tel' placeholder={t('Whatsapp')} id='Whatsapp' label={t('Whatsapp')} />
                    {/* <Input value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder={t('Your password')} id='password' label={t('Password')} /> */}
                    {/* <Input value={rePassword} onChange={(e) => setRePassword(e.target.value)} type='password' placeholder={t('Your password')} id='repassword' label={t('Re-New Password')} /> */}
                    {errorMessageAlert && <p className='text-red'>{errorMessageAlert}</p>}
                    <BlueButton className='w-full p-6 mt-4'> {t('Submit')} </BlueButton>
               </form>
               {/* <ul>
                    <li className='font-semibold text-blue my-8'>Add phone number</li>
                    <li className='font-semibold text-blue my-8'>Add E-mail Address</li>
               </ul> */}
          </div>
     )
}

export default AccountInformation