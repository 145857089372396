import React, { useEffect, useState } from 'react'
import InterestedIn from '../Components/introduction/InterestedIn'
import InterestedType from '../Components/introduction/InterestedType'
import Layout from '../Components/Layout'
import { useNavigate } from 'react-router-dom'
import alert from '../Helpers/Modal'
import { t } from 'i18next'
import axios from 'axios'
import { config, url } from '../Components/Helpers/Help'

const MyInterested = (props) => {
     const [mainInterested, setMainInterested] = useState()
     const [subInterested, setSubInterested] = useState()
     const [ids, setIds] = useState()
     const [apiValue, setApiValue] = useState()

     const navigate = useNavigate()

     useEffect(() => {
          if (!mainInterested || !subInterested) {
               return
          }
     }, [mainInterested, subInterested, navigate])

     // useEffect(() => {
     //      const animalsType = localStorage.getItem('__User_aW__a_Info')
     //      const types = JSON.parse(animalsType).categories
     //      console.log(types)
     // }, [])
 


     useEffect(() => {
          if (mainInterested) {
               const val = mainInterested.toString()
               setApiValue(val+','+ ids)
          }
     }, [ids]);


     const handleSubmit = (e) => {
          e.preventDefault()
          axios.post(url + 'users/update-animal-categories',
               //  Data
               {
                    'categories': apiValue,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setTimeout(() => {
                       window.location.href = '/'
                    }, 1100);
                    alert({
                         title: `${t('Alert')}`,
                         text: `${t('Thanks for sharing your Interesting')}`,
                         icon: 'success',
                         timer: 1000
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 1000
                    })
               })
     }


     return (
          <Layout>
               <main id="Introduction" className="min-h-screen flex items-center justify-center pt-8">
                    <div className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                         <div id='' className='container mx-auto'>
                              <div className="row flex justify-center items-start flex-wrap">
                                   {mainInterested ?
                                        <div className="w-full md:w-10/12 xl:w-8/12">
                                             {subInterested &&
                                                  <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl p-2 md:p-8 xl:p-12">
                                                       <InterestedType handleSubmit={handleSubmit} id={(id) => setIds(id)} items={subInterested} onSelectInterested={(interestedArray) => {  setSubInterested(interestedArray) }} onSkip={() => navigate('/')} />
                                                  </div>
                                             }
                                        </div>
                                        :
                                        <div className="w-full sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-8/12">
                                             <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl p-2 md:p-8 xl:p-12">
                                                  <InterestedIn onSelectInterested={(isInterested, childs, mine) => { setSubInterested(childs, mine); setMainInterested(isInterested) }} onSkip={() => navigate('/')} />
                                             </div>
                                        </div>
                                   }
                              </div>
                         </div>
                    </div>
               </main>
          </Layout>

     )
}

export default MyInterested