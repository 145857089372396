import { t } from "i18next";
import { config, url } from "../Helpers/Help";
import React, { useEffect, useState } from "react";
import BlueButton from "../UI/Buttons/BlueButton";
import Input from "../UI/Input";
import Title from "./Title";
import alert from "../../Helpers/Modal";
import axios from "axios";
import Map from "../UI/Map";
import ButtonLoading from "../UI/ButtonLoading";

const AddPhotographer = () => {
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("1");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState("");
  const [dateofBirthday, setDateofBirthday] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [personalImage, setPersonalImage] = useState("");
  const [images, setImages] = useState([]);
  const [note, setNote] = useState("");
  const [uploadVideo, setUploadVideo] = useState("");
  const [showIn, setShowIn] = useState("1");
  const [priceValue, setPriceValue] = useState();

  const [FirstNameError, setFirstNameError] = useState(false);
  const [LastNameError, setLastNameError] = useState(false);
  const [dateofBirthdayError, setDateofBirthdayError] = useState(false);
  const [personalImageError, setPersonalImageError] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [mapError, setmapError] = useState(false);
  const [ImagesError, setImagesError] = useState(false);
  const [noteError, setnoteError] = useState(false);
  const [videoError, setvideoError] = useState(false);

  // Storage Images
  function onImageChange(e) {
    setImages([...e.target.files]);
  }
  //  Submit auction form
  const submitHandler = () => {
    if (!firstName) {
      setFirstNameError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!lastName) {
      setLastNameError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!dateofBirthday) {
      setDateofBirthdayError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!location) {
      setlocationError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!personalImage) {
      setPersonalImageError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!images || images.length < 3) {
      setImagesError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!uploadVideo) {
      setvideoError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!phone) {
      setphoneError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!email) {
      setemailError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!note) {
      setnoteError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!lat || !lng) {
      setmapError(true);
      return;
    }

    setLoading(true);
    axios
      .post(
        url + "animals",
        //  Data
        {
          slug: "photographers",
          show_in: showIn,
          first_name: firstName,
          last_name: lastName,
          gender: gender,
          date_of_birth: dateofBirthday,
          profile_image: personalImage,
          "images[]": images,
          phone: phone,
          email: email,
          address: location,
          note: note,
          lat: lat,
          lng: lng,
          video: uploadVideo,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        setLoading(false);
        console.log(response);
        setFirstName("");
        setLastName("");
        setDateofBirthday("");
        setLocation("");
        setPersonalImage("");
        setPhone("");
        setEmail("");
        setLat("");
        setLng("");
        setNote("");
        setImages([]);
        if (response.data.data.payment_link) {
          window.location.href = `${response.data.data.payment_link}`;
        } else {
          alert({
            title: `${t("Done")}`,
            text: `${t(`Thank you for send`)}`,

            icon: "success",
          });
        }
      })
      // Check Error
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    axios(url + `type/photographers`, config)
      // Response
      .then((response) => {
        console.log(response.data.data.price);
        setPriceValue(response?.data?.data?.price);
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);
  return (
    <>
      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <Title className="mb-8"> {t("Add Photographers")}</Title>
        {priceValue && (
          <h3 className="w-full flex items-center justify-center font-bold my-8 ">
            {" "}
            {t("Special Offer value")} :{" "}
            {showIn === "1" ? `0 ${t("AED")}` : priceValue}{" "}
          </h3>
        )}

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setFirstNameError(false)}
            input_className={`border  ${
              FirstNameError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            placeholder={t("First name")}
            id="FirstName"
            label={t("First name")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setLastNameError(false)}
            input_className={`border ${
              LastNameError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            placeholder={t("Last name")}
            id="LastName"
            label={t("Last name")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setDateofBirthdayError(false)}
            input_className={`border ${
              dateofBirthdayError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={dateofBirthday}
            onChange={(e) => setDateofBirthday(e.target.value)}
            type="date"
            placeholder={t("Date of Birthday")}
            id="DateBirthday"
            label={t("Date of Birthday")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2 hidden">
          <Input
            onFocus={() => setlocationError(false)}
            input_className={`border-2  ${
              locationError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            placeholder={t("location")}
            id="location"
            label={t("location")}
          />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Profile Photo")}{" "}
            </label>
            <div
              onFocus={() => setPersonalImageError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                personalImageError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => {
                  setPersonalImage(e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                accept="image/jpg, image/jpeg,image/png"
                className="pl-5 mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute  rtl:-right-[0.2rem] ltr:-left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[11.5rem]">
                {" "}
                {t("Upload Image")}{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Upload three Images")}{" "}
            </label>
            <div
              onFocus={() => setImagesError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                ImagesError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={onImageChange}
                multiple
                accept="image/jpg, image/jpeg,image/png"
                className="pl-5 mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-4 border-r text-center w-[12rem]">
                {" "}
                {t("Upload Image")}{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Upload Video")}{" "}
            </label>
            <div
              onFocus={() => setvideoError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16  ${
                videoError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => setUploadVideo(e.target.files[0])}
                className="pl-5 mt-2 opacity-60 cursor-pointer"
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
              />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-3 border-r text-center w-[11.5rem]">
                {t("Upload Video")}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2">
            {" "}
            {t("Posted on")}{" "}
          </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("My page")}
              <input
                name="PostedOn"
                value="MyPage"
                checked={showIn === "1"}
                onChange={() => setShowIn("1")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("My page & AWA page")}
              <input
                name="PostedOn"
                value="AWAPage"
                checked={showIn === "2"}
                onChange={() => setShowIn("2")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2">
            {" "}
            {t("Gender")}{" "}
          </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Male")}
              <input
                name="gender"
                value="1"
                checked={gender === "1"}
                onChange={() => setGender("1")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Female")}
              <input
                name="gender"
                value="2"
                checked={gender === "2"}
                onChange={() => setGender("2")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
          </div>
        </div>

        <hr className="block my-12 w-full" />
        <h3 className="w-full rtl:text-right ltr:text-left font-bold px-4">
          {t("Contact")}
        </h3>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setphoneError(false)}
            input_className={`border-2 ${
              phoneError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="tel"
            placeholder={t("Phone")}
            id="Phone"
            label={t("Phone")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setemailError(false)}
            input_className={`border-2 ${
              emailError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder={t("Email")}
            id="Email"
            label={t("Email")}
          />
        </div>
        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]">
            {" "}
            {t("Note")}{" "}
          </label>
          <textarea
            onFocus={() => setnoteError(false)}
            className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${
              noteError ? "border-red" : "border-blue"
            }`}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            name=""
            id=""
            cols="30"
            rows="5"
            placeholder={t("Note . . .")}
          ></textarea>
        </div>
        <div
          onFocus={() => setmapError(false)}
          className={`w-full p-2  ${mapError && "border-red border"}`}
        >
          <Map
            address={(e) => setLocation(e)}
            lat={(e) => setLat(e)}
            lng={(e) => setLng(e)}
          />
        </div>

        <div className="w-full flex items-center justify-center">
          <BlueButton
            disabled={loading}
            className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${
              loading && "cursor-not-allowed"
            }`}
            onClick={submitHandler}
          >
            {loading ? <ButtonLoading /> : `${t("Send")}`}
          </BlueButton>
        </div>
        <div className="my-8 w-full flex items-end justify-center">
          <p className="font-bold text-red capitalize">
            {FirstNameError && `${t("Please enter your First Name")}`}
            {LastNameError && `${t("Please enter your Last Name")}`}
            {dateofBirthdayError &&
              `${t("Please enter your animal date of birthday")}`}
            {locationError && `${t("Please enter your location")}`}
            {ImagesError && `${t("Please enter your single Image")}`}
            {phoneError && `${t("Please enter your phone")}`}
            {emailError && `${t("Please enter your email")}`}
            {mapError && `${t("Please enter your map location")}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default AddPhotographer;
