import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import tree from '../assets/images/tree.png'
import AccessCardComponent from '../Components/Seactions/AccessCardComponent'
import { config, url } from '../Components/Helpers/Help'
import axios from 'axios'

import { t } from 'i18next'
import { Link } from 'react-router-dom'
const AccessCard = () => {
     const [accessCard, setAccessCard] = useState([]);

     useEffect(() => {
          axios(url + "access-card", config).then((response) => {
               setAccessCard(response.data.data);
               console.log(response.data.data)
          });
     }, []);
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    <div className="row flex items-center justify-center flex-wrap">
                         <div className="w-full md:w-8/12 text-center">
                              <h1 className='w-full my-8 text-[3.2rem] font-bold'>{t('Access Card')}</h1>
                              <p className='w-full my-8 text-[1.4rem] opacity-75'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit in leo quis sit sit adipiscing. Eget tristique mollis tellus dolor est. Dui arcu nec nunc pulvinar felis, condimentum sodales gravida. Sit urna felis tortor sed venenatis consequat in. Mollis enim, vitae nibh augue tortor. Erat orci commodo volutpat augue tempus et. Scelerisque nibh mi varius pharetra sed lacus maecenas. Vestibulum laoreet orci nunc bibendum sodales metus.</p>
                         </div>
                    </div>
                    <div className="row flex items-center justify-center flex-wrap">
                         {accessCard &&
                              accessCard.map(item => (
                                   <div className="w-full sm:w-6/12 md:w-4/12 xl:w-3/12 text-center px-2">
                                        {/* <AccessCardComponent
                                             sub_title={item.sub_title}
                                             title={item.title}
                                             slug={item.slug}
                                             id={item.id}
                                             features={item.features}
                                             photo={item.icon}
                                             bordercolor={item.color}
                                             bgcolor={item.color}
                                             textcolor={item.color}
                                             tree={item.footer_img}
                                             singleCard={false} 
                                        />  */}
                                        <Link to={`/single-access-card/${item.slug}`} className="card_data flex items-center justify-start flex-wrap">
                                             <img id={item.id} src={item.book} alt="" className='my-8' />
                                        </Link>
                                        {/* <img src={item.back} alt="" className='my-8' /> */}
                                   </div>
                              ))
                         }

                    </div>
               </main>
          </Layout>
     )
}

export default AccessCard