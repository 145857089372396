import React from 'react'

const Location = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.99935 1.66667C6.09352 1.66667 2.91602 4.84417 2.91602 8.75C2.91602 10.4317 3.51977 12.0638 4.62143 13.3533C4.74727 13.4963 7.71602 16.8696 8.70768 17.815C9.06977 18.1604 9.53435 18.3333 9.99935 18.3333C10.4643 18.3333 10.9289 18.1604 11.2914 17.815C12.4443 16.7154 15.2598 13.4879 15.3831 13.3463C16.4789 12.0638 17.0827 10.4317 17.0827 8.75C17.0827 4.84417 13.9052 1.66667 9.99935 1.66667ZM9.99935 10.8333C8.84893 10.8333 7.91602 9.90042 7.91602 8.75C7.91602 7.59959 8.84893 6.66667 9.99935 6.66667C11.1498 6.66667 12.0827 7.59959 12.0827 8.75C12.0827 9.90042 11.1498 10.8333 9.99935 10.8333Z" fill="#0F70B7" />
          </svg>

     )
}

export default Location