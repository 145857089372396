import React from 'react'

const WhiteMarket = () => {
     return (
          <svg width="39" height="36" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fillRule="evenodd" clipRule="evenodd" d="M29.0002 22.2105H30.0002C30.7002 22.2105 31.2002 22.7603 31.2002 23.3101C31.2002 23.8599 30.7002 24.4097 30.0002 24.4097H29.0002C28.4002 24.4097 27.8002 23.9515 27.8002 23.3101C27.8002 22.7603 28.3002 22.2105 29.0002 22.2105ZM38.7002 4.8001V6.63277L38.6002 8.0989C38.6002 9.65667 37.3002 10.8479 35.6002 10.8479H35.4002C33.7002 10.8479 32.4002 9.65667 32.4002 8.0989V6.4495C32.4002 5.8997 31.9002 5.3499 31.2002 5.3499C30.6002 5.3499 30.0002 5.80806 30.0002 6.4495V8.0989C30.0002 9.65667 28.7002 10.8479 27.0002 10.8479H26.6002C24.9002 10.8479 23.6002 9.65667 23.6002 8.0989V7.45747V6.4495C23.6002 5.8997 23.1002 5.3499 22.4002 5.3499C21.8002 5.3499 21.2002 5.80806 21.2002 6.4495V6.63277V8.0989C21.2002 9.65667 19.9002 10.8479 18.2002 10.8479H18.0002C16.3002 10.8479 15.0002 9.65667 15.0002 8.0989V6.4495C15.0002 5.80806 14.5002 5.3499 13.6002 5.3499C13.0002 5.3499 12.4002 5.80806 12.4002 6.4495V8.0989C12.4002 9.65667 11.1002 10.8479 9.4002 10.8479H9.2002C7.5002 10.8479 6.2002 9.65667 6.2002 8.0989V7.45747V4.8001C6.2002 4.43356 6.3002 4.15866 6.5002 3.88376L8.4002 0.951491C8.7002 0.40169 9.4002 0.0351562 10.1002 0.0351562H34.9002C35.6002 0.0351562 36.2002 0.40169 36.6002 0.951491L38.4002 3.88376C38.6002 4.15866 38.7002 4.5252 38.7002 4.8001ZM38 27.5252H36V13.0471H35.7H35.5C33.8 13.0471 32.3 12.314 31.3 11.2144C30.3 12.314 28.8 13.0471 27.1 13.0471H26.9C25.2 13.0471 23.7 12.314 22.7 11.2144C21.7 12.314 20.2 13.0471 18.5 13.0471H18C16.3 13.0471 14.8 12.314 13.7 11.2144C12.7 12.314 11.2 13.0471 9.5 13.0471H9.3H9V23.9634H12V16.9873C12 16.7124 12.2 16.5292 12.5 16.5292H21.5C21.8 16.5292 22 16.7124 22 16.9873V23.9634V26.1507V32.1069H38.5C38.8 32.1069 39 31.9236 39 31.6487V28.4415C39 27.8917 38.6 27.5252 38 27.5252ZM33 28.8997C33 29.1746 32.8 29.3579 32.5 29.3579H26.5C26.2 29.3579 26 29.1746 26 28.8997V16.9873C26 16.7124 26.2 16.5292 26.5 16.5292H32.5C32.8 16.5292 33 16.7124 33 16.9873V28.8997Z" fill="white" />
               <path d="M0.93098 35.9629C0.649929 35.9629 0.421576 35.8899 0.245919 35.7438C0.0819731 35.5874 0 35.3944 0 35.1649V25.8078C0 25.5783 0.0878283 25.3906 0.263485 25.2445C0.439142 25.0881 0.649929 25.0098 0.895848 25.0098H6.83304C7.09067 25.0098 7.30146 25.0829 7.4654 25.2289C7.64106 25.3749 7.72889 25.5575 7.72889 25.7765C7.72889 25.9747 7.64106 26.1469 7.4654 26.2929C7.30146 26.4389 7.09067 26.512 6.83304 26.512H1.70387L1.82683 26.3555V29.8448L1.72143 29.6571H6.04259C6.30022 29.6571 6.511 29.7301 6.67495 29.8761C6.85061 30.0222 6.93844 30.2047 6.93844 30.4238C6.93844 30.622 6.85061 30.7941 6.67495 30.9401C6.511 31.0862 6.30022 31.1592 6.04259 31.1592H1.6863L1.82683 31.034V35.1649C1.82683 35.3944 1.739 35.5874 1.56334 35.7438C1.38769 35.8899 1.1769 35.9629 0.93098 35.9629Z" fill="white" />
               <path d="M10.6042 35.9629C10.3465 35.9629 10.1357 35.8899 9.9718 35.7438C9.80786 35.5874 9.72588 35.3944 9.72588 35.1649V28.5305C9.72588 28.301 9.80786 28.1132 9.9718 27.9672C10.1357 27.8107 10.3465 27.7324 10.6042 27.7324C10.8501 27.7324 11.055 27.8107 11.219 27.9672C11.3946 28.1132 11.4825 28.301 11.4825 28.5305V30.2829L11.3068 29.6101C11.4005 29.318 11.541 29.0468 11.7284 28.7965C11.9274 28.5461 12.1617 28.327 12.431 28.1393C12.7003 27.9515 12.999 27.8055 13.3268 27.7012C13.6664 27.5968 14.0178 27.5447 14.3808 27.5447C14.6853 27.5447 14.9487 27.6177 15.1712 27.7637C15.3937 27.9098 15.505 28.0923 15.505 28.3114C15.505 28.593 15.423 28.8017 15.2591 28.9373C15.0951 29.0625 14.9136 29.1251 14.7145 29.1251C14.5389 29.1251 14.3691 29.0938 14.2051 29.0312C14.0412 28.9581 13.848 28.9216 13.6255 28.9216C13.3795 28.9216 13.1278 28.979 12.8701 29.0938C12.6125 29.2085 12.3783 29.3806 12.1675 29.6101C11.9567 29.8292 11.7869 30.09 11.6581 30.3925C11.541 30.695 11.4825 31.034 11.4825 31.4095V35.1649C11.4825 35.3944 11.3946 35.5874 11.219 35.7438C11.055 35.8899 10.8501 35.9629 10.6042 35.9629Z" fill="white" />
               <path d="M18.8946 35.1649C18.8946 35.3944 18.8126 35.5874 18.6487 35.7438C18.4847 35.8899 18.274 35.9629 18.0163 35.9629C17.7704 35.9629 17.5596 35.8899 17.384 35.7438C17.22 35.5874 17.138 35.3944 17.138 35.1649V28.4679C17.138 28.2384 17.22 28.0506 17.384 27.9046C17.5596 27.7481 17.7704 27.6699 18.0163 27.6699C18.274 27.6699 18.4847 27.7481 18.6487 27.9046C18.8126 28.0506 18.8946 28.2384 18.8946 28.4679V35.1649ZM17.9988 26.6371C17.6592 26.6371 17.4132 26.585 17.261 26.4807C17.1088 26.3659 17.0326 26.1782 17.0326 25.9174V25.667C17.0326 25.4062 17.1146 25.2237 17.2786 25.1194C17.4425 25.015 17.6884 24.9629 18.0163 24.9629C18.3676 24.9629 18.6194 25.0203 18.7716 25.135C18.9239 25.2393 19 25.4167 19 25.667V25.9174C19 26.1886 18.918 26.3764 18.7541 26.4807C18.6018 26.585 18.3501 26.6371 17.9988 26.6371Z" fill="white" />
          </svg>

     )
}

export default WhiteMarket