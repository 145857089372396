import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import uae from '../../assets/images/uae.png'
import en from '../../assets/images/en.png'
const languages = [
     {
          code: 'ar',
          name: 'Arabic',
          dir: 'rtl',
          country_code: 'uae',
          lang: 'ar',
          img: uae
     },
     {
          code: 'en',
          name: 'English',
          country_code: 'usa',
          img: en
     },
]

const Lang = () => {
     const { t } = useTranslation()
     const currentLanguageCode = localStorage.getItem('awaLang') || 'en'
     const currentLanguage = languages.find(l => l.code === currentLanguageCode)
     useEffect(() => {
          if (localStorage.awaLang === 'ar' || localStorage.awaLang === 'en') {
               document.documentElement.lang   = i18next.language;
               document.documentElement.dir  =  currentLanguage.dir || 'ltr'
          } else {
               localStorage.setItem("awaLang", 'en');
          }
     }, [currentLanguage, t])


     return (
          <>
               {
                    languages.map(({ code, name, country_code, img }) => (
                         <div key={country_code}>
                              {

                              }
                              <button className={`  ${code === 'ar' && 'ArabicFont'}`}
                                   onClick={() => { window.location.reload(true); setTimeout(i18next.changeLanguage(code), 1000) }}
                                   style={{ display: code === currentLanguageCode ? 'none' : 'flex' }} >
                                   <span className="flex text-light-color text-2xl md:text-[1.5rem] font-semibold">
                                        {
                                             window.innerWidth > 767 ?
                                                  <>{name}</>
                                                  :
                                                  <img src={img} alt="website language" className='w-12 rounded-lg mx-4' />
                                        }
                                   </span>
                              </button>
                         </div>
                    ))
               }
          </>
     )
}

export default Lang