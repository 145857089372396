import { t } from "i18next"

const Copyright = () => {
     return (
          <section className='relative bg-blue'>
               <p className='py-2 text-white text-[1.2rem] font-bold text-center capitalize'> {t('All Rights Reserved')} <b>AWA</b> {t('designed by')} <a href="https://brainclickads.com" className='font-bold'><b>Brain Click L.L.C.</b></a>  © {new Date().getFullYear()}</p>
          </section>
     )
}

export default Copyright 