import React, { useEffect, useState } from "react";
import CountryIcon from "../../assets/Icons/Country";
import Delete from "../../assets/Icons/Delete";
import Language from "../../assets/Icons/Language";
import LeftArrow from "../../assets/Icons/LeftArrow";
import LocationIcon from "../../assets/Icons/Location";
import PasswordIcon from "../../assets/Icons/PasswordIcon";
import Uae from "../../assets/Icons/Uae";
import UserIcon from "../../assets/Icons/UserIcon";
import Lang from "../Lang/Lang";
import AccountInformation from "./AccountInformation";
import PasswordSecurity from "./PasswordSecurity";
import Country from "./Country";
import Location from "./Location";
import axios from "axios";
import { t } from "i18next";

import { url, config } from "../Helpers/Help";
const Setting = (props) => {
  const [accountInformation, setAccountInformation] = useState(false);
  const [setting, setSetting] = useState(true);
  const [passwordSecurity, setPasswordSecurity] = useState(false);
  const [country, setCountry] = useState(false);
  const [location, setLocation] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  const onBack = () => {
    setSetting(true);
    setAccountInformation(false);
    setPasswordSecurity(false);
    setCountry(false);
    setLocation(false);
  };

  const handleAccountInformation = () => {
    setSetting(false);
    setAccountInformation(true);
  };

  const handlePasswordSecurity = () => {
    setSetting(false);
    setPasswordSecurity(true);
  };

  const handleCountry = () => {
    setSetting(false);
    setCountry(true);
  };

  const handleLocation = () => {
    setSetting(false);
    setLocation(true);
  };

  const deleteAccount = () => {
    axios
      .post(url + "users/delete", {}, config)
      // Response
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
        console.log(response);
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        // setErrorMessageAlert(error.response.data.message)
        // setIsLoading(false)
        // setTimeout(() => {
        //      setErrorMessageAlert('')
        // }, 3000);
      });
  };

  useEffect(() => {
    if (localStorage.__User_aW__a_Info) {
      const userData = localStorage.getItem("__User_aW__a_Info");
      const userInfo = JSON.parse(userData);
      setUserInfo(userInfo);
    }
  }, []);
  return (
    <>
      {setting && (
        <div
          className={`fixed shadow-xl top-0 pt-16 md:pt-0 w-full md:w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}
        >
          <h3
            onClick={props.onBack}
            className="dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4"
          >
            {" "}
            <LeftArrow /> {t("Settings")}{" "}
          </h3>
          <h4 className="underline text-[1.6rem] font-semibold">
            {t("Account")}
          </h4>
          <ul className="pl-12 mb-8 mt-4">
            <li
              className="mb-6 cursor-pointer flex items-center justify-start gap-4"
              onClick={handleAccountInformation}
            >
              {" "}
              <UserIcon /> {t("Personal & Account information")}
            </li>
            <li
              className="mb-6 cursor-pointer flex items-center justify-start gap-4"
              onClick={handlePasswordSecurity}
            >
              <PasswordIcon /> {t("Password & security")}
            </li>
          </ul>
          <h4 className="underline text-[1.6rem] font-semibold">
            {t("Preferences")}
          </h4>
          <ul className="pl-12 mb-8 mt-4">
            {userInfo && (
              <li
                className="mb-6 cursor-pointer flex items-center justify-between gap-4"
                onClick={handleCountry}
              >
                {" "}
                <CountryIcon />
                <span className="w-1/2">{t("Country")}</span>
                <span className="flex items-center justify-end gap-4 w-1/2">
                  {userInfo.country && (
                    <>
                      <img src={userInfo.country.flag} alt="" />
                      {userInfo.country.name}
                    </>
                  )}
                </span>
              </li>
            )}
            <li className="mb-6 cursor-pointer flex items-center justify-between gap-4">
              <Language /> <span className="w-1/2">{t("Language")}</span>{" "}
              <span className="flex items-center justify-end gap-4 w-1/2">
                <Uae /> <Lang />
              </span>
            </li>
            <li
              className="mb-6 cursor-pointer flex items-center justify-between gap-4"
              onClick={handleLocation}
            >
              <LocationIcon /> <span className="w-1/2">{t("Location")}</span>{" "}
              <span className="flex items-center justify-end gap-4 w-1/2">
                {" "}
                {userInfo && userInfo.city && `${userInfo.city.name}`}
              </span>
            </li>
          </ul>
          <button
            onClick={deleteAccount}
            className="text-red cursor-pointer font-semibold flex items-center justify-start gap-4 mt-8"
          >
            <Delete /> {t("Deletet Account")}{" "}
          </button>
        </div>
      )}
      {accountInformation && (
        <AccountInformation
          onBack={onBack}
          className={`${
            accountInformation
              ? "right-0 transition-all duration-1000"
              : "-right-[50rem] transition-all duration-1000"
          }`}
        />
      )}
      {passwordSecurity && (
        <PasswordSecurity
          onCloseSidebar={props.onBack}
          onBack={onBack}
          className={`${
            passwordSecurity
              ? "right-0 transition-all duration-1000"
              : "-right-[50rem] transition-all duration-1000"
          }`}
        />
      )}
      {country && (
        <Country
          onCloseSidebar={props.onBack}
          onBack={onBack}
          className={`${
            country
              ? "right-0 transition-all duration-1000"
              : "-right-[50rem] transition-all duration-1000"
          }`}
        />
      )}
      {location && (
        <Location
          onCloseSidebar={props.onBack}
          onBack={onBack}
          className={`${
            location
              ? "right-0 transition-all duration-1000"
              : "-right-[50rem] transition-all duration-1000"
          }`}
        />
      )}
    </>
  );
};

export default Setting;
