import React from 'react'

const PublicAuction = () => {
     return (
          <svg width="35" height="43" viewBox="0 0 35 43" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.15663 1.18257e-08C8.94304 3.68075e-05 8.72942 0.085941 8.56692 0.258145L5.02754 4.00441C4.70253 4.34882 4.70253 4.90933 5.02754 5.25374C5.19018 5.42594 5.40373 5.51188 5.61725 5.51188C5.72402 5.51188 5.83102 5.49047 5.93166 5.44749L6.82003 6.8256C7.0925 7.24655 7.38188 7.65302 7.69049 8.04159L0.519965 15.0356C0.194677 15.3536 0.00863935 15.7864 0.000298625 16.2574C-0.0080421 16.7254 0.158555 17.1666 0.472722 17.4992C0.778549 17.823 1.18212 18 1.61306 18H1.64618C2.08824 17.9882 2.49982 17.7936 2.80009 17.4492L9.40859 9.85493C9.7728 10.1846 10.1568 10.4933 10.5544 10.7788L11.856 11.7171C11.7331 12.0365 11.7935 12.4168 12.0379 12.6755C12.2005 12.8475 12.4141 12.9337 12.6276 12.9337C12.8414 12.9337 13.0547 12.8478 13.2173 12.6755L16.7561 8.92871C17.0811 8.5846 17.0814 8.02407 16.7561 7.67995C16.5118 7.42121 16.1526 7.35696 15.8509 7.48678L14.9636 6.10751C13.96 4.54737 12.7034 3.21693 11.2299 2.15427L9.9288 1.21656C10.0514 0.897171 9.99045 0.516598 9.74635 0.258145C9.58356 0.0857938 9.37022 -3.67839e-05 9.15663 1.18257e-08Z" fill="#17C3B2" />
               <path d="M6.51961 33.7742C8.9902 33.7742 10.9804 31.7161 10.9804 29.1613C10.9804 26.6065 8.9902 24.5484 6.51961 24.5484C4.04902 24.5484 2.05882 26.6065 2.05882 29.1613C2.05882 31.7161 4.04902 33.7742 6.51961 33.7742ZM1.02941 43C1.61275 43 2.05882 42.5387 2.05882 41.9355C2.05882 39.3806 4.04902 43 6.51961 43C7.10294 43 7.54902 36.8613 7.54902 36.2581C7.54902 35.6548 7.10294 35.1935 6.51961 35.1935C2.91667 35.1935 0 38.2097 0 41.9355C0 42.5387 0.446078 43 1.02941 43ZM32.9412 29.1613C32.9412 26.6065 30.951 24.5484 28.4804 24.5484C26.0098 24.5484 24.0196 26.6065 24.0196 29.1613C24.0196 31.7161 26.0098 33.7742 28.4804 33.7742C30.951 33.7742 32.9412 31.7161 32.9412 29.1613ZM21.9608 25.6129C21.9608 23.0581 19.9706 21 17.5 21C15.0294 21 13.0392 23.0581 13.0392 25.6129C13.0392 28.1677 15.0294 30.2258 17.5 30.2258C19.9706 30.2258 21.9608 28.1677 21.9608 25.6129ZM22.9902 43C23.5735 43 24.0196 42.5387 24.0196 41.9355V38.3871C24.0196 34.6613 21.1029 31.6452 17.5 31.6452C13.8971 31.6452 10.9804 34.6613 10.9804 38.3871V41.9355C10.9804 42.5387 11.4265 43 12.0098 43C12.5931 43 13.0392 43 13.0392 43C13.0392 43 15.0294 43 17.5 43C19.9706 43 23 43 23 43C23 43 22.4069 43 22.9902 43ZM28.4804 43C30.951 43 32.9412 39.3806 32.9412 41.9355C32.9412 42.5387 33.3873 43 33.9706 43C34.5539 43 35 42.5387 35 41.9355C35 38.2097 32.0833 35.1935 28.4804 35.1935C27.8971 35.1935 27.451 35.6548 27.451 36.2581C27.451 36.8613 27.8971 43 28.4804 43Z" fill="#0F70B7" />
          </svg>

     )
}

export default PublicAuction