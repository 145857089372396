import React from 'react'

const Uae = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M0.833984 12.0833H19.1673V16.25H0.833984V12.0833Z" fill="#424242" />
               <path d="M0.833984 7.91666H19.1673V12.0833H0.833984V7.91666Z" fill="#ECEFF1" />
               <path d="M0.833984 3.75H19.1673V7.91667H0.833984V3.75Z" fill="#388E3C" />
               <path d="M0.833984 3.75H5.00065V16.25H0.833984V3.75Z" fill="#FF3D00" />
               <path d="M0.833984 3.75H5.00065V16.25H0.833984V3.75Z" fill="#F44336" />
          </svg>

     )
}

export default Uae