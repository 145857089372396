import React from 'react'
import Layout from '../../Components/Layout'
import { lookForDataPage } from '../../Components/Helpers/Data'
import Search from '../../Components/Search/Search'
import ProductCard from '../../Components/UI/Cards/Product-card'
const LookFor = () => {
  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        <section className="mt-12">
          <Search />
        </section>

        <section className="mt-12">
          <div className="row flex items-start justify-center flex-wrap">
            {
              lookForDataPage.map((item, index) => (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 my-4" key={index}>
                  <ProductCard
                    key={index}
                    img={item.img}
                    name={item.name}
                    type={item.type}
                    badg={item.badg}
                    tags={item.tags}
                    title={item.title}
                    start={item.start}
                    price={item.price}
                    email={item.email}
                    isFav={item.isFav}
                    phone={item.phone}
                    gender={item.gender}
                    action={true}
                    contact={true}
                    location={item.location}
                    duration={item.duration}
                    oldPrice={item.oldPrice}
                    inMarket={item.inMarket}
                    whatsapp={item.whatsapp}
                    userImage={item.userImage}
                    description={item.description}
                    averagePrice={item.averagePrice}
                    locationAnimal={item.locationAnimal}
                  />
                </div>
              ))
            }
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default LookFor
