import React from 'react'

const BlueAuctionIcon = () => {
     return (
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M14.1918 0.407227C13.8797 0.407277 13.5675 0.525833 13.3301 0.763489L8.15833 5.93365C7.68343 6.40897 7.68343 7.18252 8.15833 7.65784C8.39599 7.89549 8.70803 8.0141 9.02003 8.0141C9.17603 8.0141 9.33238 7.98454 9.47944 7.92523L10.7775 9.82715C11.1757 10.4081 11.5985 10.9691 12.0494 11.5053L1.57185 21.1577C1.09653 21.5965 0.824697 22.1938 0.812509 22.8438C0.800322 23.4897 1.04375 24.0986 1.50282 24.5577C1.94969 25.0046 2.53939 25.2488 3.16908 25.2488H3.21748C3.86341 25.2325 4.46482 24.964 4.90357 24.4886L14.5599 14.0079C15.0921 14.4629 15.6532 14.8889 16.2341 15.283L18.1361 16.5779C17.9565 17.0187 18.0448 17.5435 18.4019 17.9006C18.6395 18.1378 18.9516 18.2568 19.2636 18.2568C19.576 18.2568 19.8876 18.1382 20.1253 17.9006L25.2962 12.7296C25.7711 12.2547 25.7715 11.4811 25.2962 11.0062C24.9391 10.6491 24.4143 10.5605 23.9735 10.7396L22.677 8.83612C21.2104 6.683 19.3743 4.84687 17.2212 3.38031L15.3201 2.08618C15.4992 1.6454 15.4102 1.12018 15.0535 0.763489C14.8156 0.525629 14.5039 0.407176 14.1918 0.407227Z" fill="#0F70B7" />
          </svg>

     )
}

export default BlueAuctionIcon