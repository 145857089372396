import React, { useContext } from "react";
import Email from "../../../assets/Icons/Email";
import LineHeart from "../../../assets/Icons/LineHeart";
import Phone from "../../../assets/Icons/Phone";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Context from "../../../Store/Store";
import WhatsappFlat from "../../../assets/Icons/WhatsappFlat";

const VeterinaryClinic = (props) => {
  const {
    img,
    slug,
    id,
    noImage,
    location,
    phone,
    email,
    photographer,
    whatsapp,
  } = props;
  const ctx = useContext(Context);
  const itemIsFavorite = ctx.itemIsFavorite(id, slug);

  function toggleFavoriteStatusHandler() {
    console.log(id, slug);
    if (itemIsFavorite) {
      ctx.removeFavorite(id, slug);
    } else {
      ctx.addFavorite(id, slug);
    }
  }
  return (
    <LazyLoadComponent delaytime="500">
      <div
        className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${
          photographer ? "flex-col-reverse pb-0" : "flex-col"
        }`}
      >
        {img && !noImage ? (
          <Link to={`/details/veterinaries/${id}`} className="w-full">
            <img
              src={img}
              alt=""
              className="w-full object-cover rounded-2xl h-[25rem]"
            />
          </Link>
        ) : (
          <div className="h-[25rem]"></div>
        )}
        <div className="w-full h-[7rem]">
          <ul className="px-2 md:px-4 py-3">
            <Link to={`/details/veterinaries/${id}`} className="w-full">
              {location && (
                <li className="mt-2 no-wrap">
                  {t("Location")}: <b>{location}</b>{" "}
                </li>
              )}
            </Link>
            <div className="flex justify-between mt-3">
              <div className="flex gap-3 ">
                {t("Contact")}:
                {phone && (
                  <a href={`tel:${phone}`}>
                    {" "}
                    <Phone />{" "}
                  </a>
                )}
                {email && (
                  <a href={`mailto:${email}`}>
                    {" "}
                    <Email />{" "}
                  </a>
                )}
                {whatsapp && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                  >
                    {" "}
                    <WhatsappFlat />{" "}
                  </a>
                )}
              </div>
              <button className="" onClick={toggleFavoriteStatusHandler}>
                {" "}
                <LineHeart
                  className={` hover:fill-blue ${
                    itemIsFavorite ? "fill-blue" : "fill-[#d9d9d9]"
                  }`}
                />{" "}
              </button>
            </div>
          </ul>
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export default VeterinaryClinic;
