import React from 'react'

const MyRequest = () => {
     return (
          <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_92_18275)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.2207 3.86002C7.2207 3.39408 7.59477 3.02002 8.0607 3.02002H34.9407C35.4066 3.02002 35.7807 3.39408 35.7807 3.86002V23.364C34.4649 22.8076 33.0184 22.5 31.5 22.5C25.4249 22.5 20.5 27.4249 20.5 33.5C20.5 35.2209 20.8952 36.8495 21.5997 38.3H20.6607H8.0607C7.59477 38.3 7.2207 37.926 7.2207 37.46V3.86002ZM28.2207 8.90002H13.1007V10.58H28.2207V8.90002ZM29.9007 13.94H13.1007V15.62H29.9007V13.94ZM25.7007 18.98H13.1007V20.66H25.7007V18.98ZM18.1407 24.02H13.1007V25.7H18.1407V24.02Z" fill="#0F70B7" />
                    <path d="M31.9997 26.0835C27.6344 26.0835 24.083 29.6349 24.083 34.0002C24.083 38.3654 27.6344 41.9168 31.9997 41.9168C36.3649 41.9168 39.9163 38.3654 39.9163 34.0002C39.9163 29.6349 36.3649 26.0835 31.9997 26.0835ZM33.8051 36.5972C33.6891 36.7132 33.5371 36.771 33.3851 36.771C33.2331 36.771 33.0811 36.7132 32.9651 36.5972L30.9859 34.6181C30.8747 34.5064 30.8122 34.3556 30.8122 34.1981V29.8439C30.8122 29.5158 31.0778 29.2502 31.4059 29.2502C31.7341 29.2502 31.9997 29.5158 31.9997 29.8439V33.9523L33.8051 35.7577C34.0366 35.9892 34.0366 36.3653 33.8051 36.5972Z" fill="#17C3B2" />
               </g>
               <defs>
                    <clipPath id="clip0_92_18275">
                         <rect width="42" height="42" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
               </defs>
          </svg>

     )
}

export default MyRequest