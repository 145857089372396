import React from 'react'

const PreLoader = () => {
     return (
          <div className="preLoader flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto overflow-hidden">
               <span className="loader"></span>
          </div>
     )
}

export default PreLoader