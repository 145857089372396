import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import LeftArrow from "../../assets/Icons/LeftArrow";
import { NotificationApi } from "../Helpers/Data";
import { config, url } from "../Helpers/Help";
import NotificationCard from "../UI/Cards/Notification-Card";
import Remove from "../../assets/Icons/Remove";

const Notification = (props) => {
  const [noti, setNoti] = useState([]);
  const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);
  const getNotifications = async () => {
    await axios
      .get(url + `users/notifications?limit=100&offset=1`, config)
      .then((response) => {
        console.log(response.data.data.data);
        setNoti(response.data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const handleDeleteAll = () => {
    setLoadingDeleteAll(true);
    axios
      .post(url + `clear_my_notifications`,{}, config)
      .then(() => {
        getNotifications().then(() => {
          setLoadingDeleteAll(false);
        });
      })
      .catch(() => {
        setLoadingDeleteAll(false);
      });
  };
  return (
    <div
      className={`divide-y-4 divide-blue-100 fixed shadow-xl pt-16 md:pt-24 top-0 w-full md:w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}
    >
      <div dir="ltr" className="flex w-full content-between">
        <h3
          onClick={props.onBack}
          className=" flex-1 dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4"
        >
          {" "}
          <LeftArrow /> {t("Notification")}{" "}
        </h3>
        <button
          className="mx-10"
          disabled={loadingDeleteAll}
          onClick={handleDeleteAll}
        >
          {loadingDeleteAll ? (
            <span className="loader"></span>
          ) : (
            <div className="flex gap-2 items-center text-red">
              ({noti.length}){t("delete all")}
              <Remove color="rgb(208 0 0 / var(--tw-text-opacity))" />
            </div>
          )}
        </button>
      </div>
      {/* <div className="border-solid w-full border-gray-100 divide-gray-100"></div> */}
      <ul className="text-[1.4rem] font-semibold flex items-center justify-around flex-wrap gap-2 w-full">
        {noti.map((item, index) => {
          console.log("🚀 ~ {noti.map ~ item:", item);
          return (
            <NotificationCard
              getNotifications={getNotifications}
              item={item}
              key={index}
              slug={item.data && item.data.slug}
              id={item.data && item.data.id}
              title={item.title}
              body={item.body}
              price={item.data && item.data.price}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Notification;
