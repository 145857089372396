import React from 'react'

const Phone = () => {
     return (
          <svg width="26" height="26" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect width="17" height="17" rx="8.5" fill="#0FB734" />
               <path d="M7.97945 4.59088L8.40919 5.50327C8.59644 5.90085 8.49269 6.3684 8.15219 6.6591L7.34921 7.34491C7.25871 7.42231 7.21171 7.53459 7.23196 7.64529C7.30946 8.06852 7.4812 8.4859 7.7472 8.89765C8.0127 9.30851 8.33594 9.65816 8.71718 9.94639C8.81818 10.0227 8.95843 10.0425 9.08343 10.0049L10.0202 9.72363C10.4514 9.59426 10.9209 9.74298 11.1854 10.0926L11.8016 10.9074C12.1091 11.314 12.0539 11.8747 11.6724 12.2194L11.2634 12.5888C10.8564 12.9565 10.2752 13.0899 9.73742 12.9389C8.46794 12.5825 7.30096 11.5246 6.23598 9.76481C5.16949 8.00259 4.79325 6.50723 5.10699 5.27916C5.23899 4.76233 5.64774 4.3508 6.18148 4.19735L6.71972 4.04255C7.22471 3.89788 7.76345 4.13233 7.97945 4.59088Z" fill="white" />
          </svg>
     )
}

export default Phone