import { t } from "i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Input from "../../UI/Input";
import ResetPassword from "../ResetPassword/ResetPassword";
import "../../../Styles/_Auth.scss";
import Button from "../../UI/Buttons/BlueButton";
import Ring from "../../UI/Loader/Ring";
import axios from "axios";
import { config, url } from "../../Helpers/Help";
import alert from "../../../Helpers/Modal";

const Login = (props) => {
  const [login, setLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageAlert, setErrorMessageAlert] = useState("");

  const submitHandler = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // If Password dosen't match
    if (!email.includes("@") || email.trim() === "" || password.trim() === "") {
      alert({
        title: `${t("Error")}`,
        text: "Please check your email or password",
        icon: "error",
        timer: 2000,
      });
      setIsLoading(false);
      return;
    }
    //  set loadin true
    setIsLoading(true);
    axios
      .post(
        url + "users/login",
        //  Data
        {
          email: email,
          password: password,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        setEmail("");
        setPassword("");
        setIsLoading(false);
        localStorage.setItem(
          "__authtk__awasell_secure",
          response.data.data.token
        );
        localStorage.setItem(
          "__User_aW__a_Info",
          JSON.stringify(response.data.data.user)
        );
        window.location.href = "/";
      })
      // Check Error
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        console.log(error?.response?.data?.message);
        alert({
          title: error?.response ? `${t(`Error`)}` : `${t(`Server Error`)}`,
          text: error?.response
            ? error?.response?.data?.message
            : `${t(`There is an error on the server`)}`,
          icon: "error",
          timer: 2000,
        });
      });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      submitHandler();
    }
  };

  return (
    <>
      {login ? (
        <>
          <h3 className="w-full font-bold text-[3.4rem] Abril ltr:tracking-widest">
            {t("Welcome")}
          </h3>
          <p className="w-full font-light text-[1.5] mb-8">
            {t("Please enter your contact details to log in")}
          </p>
          <h3 className="text-red font-bold text-left w-full capitalize">
            {errorMessageAlert}
          </h3>
          <div className="mt-4 flex flex-wrap items-center justify-between">
            <Input
              onKeyPress={handleKeypress}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder={t("Your Email / Phone")}
              id="Email"
              label={t("Email address")}
            />
            <Input
              onKeyPress={handleKeypress}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder={t("Your password")}
              id="password"
              label={t("Password")}
            />

            <div className="flex items-center justify-end flex-wrap w-full mb-8">
              <button
                onClick={() => setLogin(false)}
                className="w-1/2 text-left flex justify-end underline font-medium text-[1.2rem]"
              >
                {t("Forgot Password")}
              </button>
            </div>
            <Button
              onClick={submitHandler}
              className={`w-full text-[1.5rem] p-5 ${
                isLoading && "cursor-not-allowed"
              }`}
              disabled={isLoading}
            >
              {isLoading ? <Ring /> : `${t("Log in")}`}
            </Button>
            <div className="loginByGoogle">
              <SocialMedia />
            </div>
            <p className="text-[1.5rem] font-light flex items-center justify-center gap-4 mt-8 text-center w-full">
              {t("Don’t have account?")}
              <button
                onClick={() => props.onSignup()}
                className="font-medium text-blue"
              >
                {t("Sign up Now")}{" "}
              </button>
            </p>
          </div>
        </>
      ) : (
        <ResetPassword onBackToLogin={() => setLogin(true)} />
      )}
    </>
  );
};

export default Login;
