import axios from 'axios';
import { t } from 'i18next'
import React from 'react'
import { useState, useEffect } from 'react';
import { config, url } from '../Components/Helpers/Help';
import Layout from '../Components/Layout'
import { useLocation } from 'react-router-dom';
import UserCard from '../Components/UI/AnimalCard/UserCard';
import SectionTitle from '../Components/UI/Titles/SectionTitle';
import NotItems from '../Components/UI/NotItems';
import PreLoader from '../Components/UI/PreLoader';
import errorImage from '../assets/images/error.jpg'

const Following = () => {
     const [following, setFollowing] = useState([]);
     const [isLoading, setIsLoading] = useState(false);
     const [serverError, setServerError] = useState(false);
     const params = useLocation();
     const slug = +params.pathname.split("/")[2]

     useEffect(() => {
          setIsLoading(true)
          axios(url + `users/following?user_id=${slug}`, config).then((response) => {
               setFollowing(response.data.data)
               setIsLoading(false)
          }).catch((error) => {
               console.log(error);
               setIsLoading(false)
               if (error && error.response.status === 500) {
                    setServerError(true)
               } else {
                    setServerError(false)
               }
          });
     }, [slug]) 

     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    {
                         isLoading ?
                              <PreLoader />
                              :
                              <div className="row flex items-start justify-center flex-wrap min-h-[40vh]">
                                   {serverError ?
                                        <img src={errorImage} alt="server error" className="w-[50rem] h-[50rem] xl:mt-48 block mx-auto mix-blend-multiply object-contain" />
                                        :
                                        <>
                                             <SectionTitle className='w-full mt-12 justify-center capitalize flex'>
                                                  {t('following')}
                                             </SectionTitle>
                                             {following?.length > 0 ?
                                                  <>
                                                       {following?.map(item => (
                                                            <div key={item.id} className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-4 xl:px-6">
                                                                 <UserCard id={item.id} badge={item.badge} is_verified={item.is_verified} rate={item.avg_rate_int} img={item.personal_picture} name={item.show_name} whatsapp={item.whatsapp} email={item.email} />
                                                            </div>

                                                       ))}
                                                  </>
                                                  :
                                                  <NotItems />
                                             }
                                        </>
                                   }
                              </div>
                    }
               </main>
          </Layout>

     )
}

export default Following