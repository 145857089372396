import React from 'react'

const Verified = () => {
     return (
          <svg width="20" height="20" version="1.1" viewBox="0 0 700 500" xmlns="http://www.w3.org/2000/svg">
               <path d="m303.96 333.74-74.969-71.75-28 26.336 103.04 98.191 201.46-194.02-27.859-26.336zm42.543-281.24 189 79.555v154.32c-0.81641 55.039-19.785 108.27-53.961 151.42-34.18 43.152-81.648 73.809-135.04 87.207-53.391-13.398-100.86-44.055-135.04-87.207-34.176-43.152-53.145-96.383-53.961-151.42v-154.32z" fillRule="evenodd" fill='#287ebe' />
          </svg>
     )
}

export default Verified