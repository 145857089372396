import React from 'react'

const Favorites = () => {
     return (
          <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M6.1875 0C2.78012 0 0 2.82428 0 6.28577C0 8.62645 1.7368 10.8303 3.854 13.0905C5.97121 15.3507 8.57721 17.6398 10.6534 19.749C10.8117 19.9097 11.0263 20 11.25 20C11.4737 20 11.6883 19.9097 11.8466 19.749C13.9228 17.6398 16.5288 15.3507 18.646 13.0905C20.7632 10.8303 22.5 8.62645 22.5 6.28577C22.5 2.82428 19.7199 0 16.3125 0C14.357 0 12.6545 1.06657 11.25 2.87837C9.84554 1.06657 8.14299 0 6.1875 0Z" fill="#0F70B7" />
               <circle cx="18" cy="15" r="4" fill="#17C3B2" />
          </svg>
     )
}

export default Favorites