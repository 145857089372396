import React from 'react'
import Barcode from '../../../assets/Icons/Barcode'
import logo from '../../../assets/images/logo.png'
import card from '../../../assets/images/card.png'
import cat from '../../../assets/images/cat.png'
import { t } from 'i18next'
const AnimalCard = (props) => {
     const { img, Birthday, color, gender, type, category, country, id, end_at, qrCode, user, f_name, l_name, show_name, email, whatsapp, phone, city } = props
     return (
          <div className='card h-[24rem]'>
               <div className='front relative overflow-hidden p-4 text-white mb-2 shadow-xl rounded-lg cursor-pointer'>
                    <img src={card} alt="" className='absolute top-0 left-0 w-full h-full z-0' />
                    <div className="flex justify-between items-center w-full mb-4 relative z-10">
                         <h3 className="text-[2.4rem] font-bold">{t('Pet identification')} </h3>
                         <div className="img bg-white w-48 h-16 px-4 rounded-2xl absolute top-12 py-2 rtl:-left-8 ltr:-right-8">
                              <img className='h-full mx-auto block' src={logo} alt="" />
                         </div>
                    </div>

                    <div className="flex justify-between items-start w-full">
                         <div className="img w-2/6 h-64 rounded-xl relative overflow-hidden flex items-start justify-center flex-wrap">
                              <img className='w-full h-48 object-contain block' src={img} alt="" />
                              <p className='text-xl w-full block mt-2' >{t('N. OF')}: <b className='text-yellow-200'>AWA00{id}</b> </p>
                              <p className='text-xl w-full block' >{t('EXP')}: <b className='text-yellow-200'>{end_at}</b> </p>
                         </div>
                         <div className="data w-3/6">
                              <ul className='px-4 leading-[1.5] no-wrap '>
                                   <li><span className='text-yellow-200'>{t('Animal')}</span>: <b>{category}</b> </li>
                                   <li>{t('Type')}: <b>{type}</b> </li>
                                   {/* <li>{t('age')}: <b>{age}</b> </li> */}
                                   <li>{t('Gender')}: <b>{gender}</b> </li>
                                   <li>{t('Birthday')}: <b>{Birthday} </b> </li>
                                   {/* <li>{t('weight')}: <b>{weight}</b> </li> */}
                                   <li>{t('color')}: <b className={`w-12 h-4 inline-block`} style={{ background: color }} />  </li>
                              </ul>
                         </div>

                         <div className="barcode w-1/6 relative h-64  flex items-end justify-center">
                              <img src={'data:image/png;base64,' + qrCode} alt="" className=' ' />
                         </div>
                    </div>
               </div>
               <div className='back relative overflow-hidden p-4 text-white mb-2 shadow-xl rounded-lg cursor-pointer'>
                    <img src={card} alt="" className='absolute top-0 left-0 w-full h-full z-0' />
                    <div className="flex justify-between items-center w-full mb-4 relative z-10">
                         <h3 className="text-[2.4rem] font-bold">{t('user identification')} </h3>
                         <div className="img bg-white h-12 w-48 px-4 rounded-2xl absolute top-0 py-1 rtl:left-0 ltr:right-0">
                              <img className='h-full mx-auto block' src={logo} alt="" />
                         </div>
                    </div>

                    <div className="flex justify-between items-start w-full">
                         <div className="img w-2/6 h-52 rounded-xl relative overflow-hidden flex items-start justify-center">
                              <img className='w-full h-full object-contain block' src={user} alt="" />
                         </div>
                         <div className="data w-3/6">
                              <ul className='px-4 leading-[1.7] no-wrap '>
                                   <li>{t('First Name')}: <b>{f_name}</b> </li>
                                   <li>{t('Last Name')}: <b>{l_name}</b> </li>
                                   {/* <li>{t('email')}: <b>{email}</b> </li> */}
                                   {/* <li>{t('whatsapp')}: <b>{whatsapp} </b> </li> */}
                                   <li>{t('country')}: <b>{country}</b> </li>
                                   <li>{t('city')}: <b>{city}</b> </li>
                                   <li>{t('phone ')}: <b>{phone}</b> </li>
                              </ul>
                         </div>

                         <div className="barcode w-1/6 relative h-64  flex items-end justify-center">
                              <img src={'data:image/png;base64,' + qrCode} alt="" className=' ' />
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default AnimalCard

