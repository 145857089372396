import React from 'react'

const Search = (props) => {
     return (
          <svg className='w-24' id="Layer_1" fill={props.fill} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 48.53">
               <path d="M10.29,4.35H47.77a29.11,29.11,0,0,0-2.44,4.2h-35A6.11,6.11,0,0,0,4.2,14.64V30.31a6.11,6.11,0,0,0,6.09,6.09H46.84A29,29,0,0,0,50,40.6H10.29A10.31,10.31,0,0,1,0,30.31V14.64A10.31,10.31,0,0,1,10.29,4.35Zm5.05,11.28a1.91,1.91,0,1,1,3.82,0V29.32a1.91,1.91,0,0,1-3.82,0V15.63ZM71.93,0a21.09,21.09,0,0,1,8.15,1.62l0,0A21.31,21.31,0,0,1,93.24,21.31a21.37,21.37,0,0,1-3,11l8.25,9a1,1,0,0,1-.06,1.45l-6.06,5.53a1,1,0,0,1-1.44-.07L83,39.51A20.89,20.89,0,0,1,78.1,41.7,21.34,21.34,0,0,1,63.78,41l-.05,0A21.33,21.33,0,0,1,52.24,13.16l0-.06A21.34,21.34,0,0,1,63.78,1.62,21.13,21.13,0,0,1,71.93,0ZM84,9.28a17.14,17.14,0,0,0-5.52-3.69l-.05,0A17,17,0,0,0,59.91,9.28l0,.06a16.79,16.79,0,0,0-3.64,5.47l0,0a17.1,17.1,0,0,0,0,13A17,17,0,0,0,78.43,37,17,17,0,0,0,84,33.32l.06,0a17.18,17.18,0,0,0,3.64-5.46l0,0a17.17,17.17,0,0,0,0-13A17.28,17.28,0,0,0,84,9.28ZM105,38.5h0l-.06-.06,0,0-6-6.56a24.86,24.86,0,0,0,.84-2.43,29,29,0,0,0-1-19.5,29.49,29.49,0,0,0-3-5.57h16.91a10.31,10.31,0,0,1,10.29,10.29V30.31a10.29,10.29,0,0,1-3,7.26h0a10.24,10.24,0,0,1-7.26,3H106.3A7.37,7.37,0,0,0,105,38.5Z" />
          </svg>
     )
}

export default Search