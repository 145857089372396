import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import MyOffersPriceIcon from '../assets/Icons/Activities/MyOffersPrice'
import { MyOfferApi } from '../Components/Helpers/Data'
import { config, url } from '../Components/Helpers/Help'
import Layout from '../Components/Layout'
import FridayMarket from '../Components/UI/AnimalCard/FridayMarket'
import ProductCard from '../Components/UI/Cards/Product-card'
import NotItems from '../Components/UI/NotItems'
import PreLoader from '../Components/UI/PreLoader'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment } from 'react'

const MyOffersPrice = () => {
     const [offersPrice, setOffersPrice] = useState([]);
     const [page, setPage] = useState(1);
     const [hasMore, setHasMore] = useState(true);
     const [isLoading, setIsLoading] = useState(false);

     useEffect(() => {
          setIsLoading(true)
          axios.get(url + `users/my-offer-prices-owner?limit=10&offset=${page}&slug=auctions`, config).then(response => {
               setIsLoading(false)
               const data = response.data.data.data
               setOffersPrice(data);
          });
     }, []);

     const onLoadMore = () => {
          setIsLoading(true)
          axios.get(url + `users/my-offer-prices-owner?limit=10&offset=${page + 1}&slug=auctions`, config).then(response => {
               setIsLoading(false)
               if (response.data.data.data.length === 0) {
                    setHasMore(false);
               } else {
                    setOffersPrice(prevItems => [...prevItems, ...response.data.data.data]);
                    setPage(prevPage => prevPage + 1);

               }
          });
     };


     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
                    <section className="mt-12">
                         <h3 className='flex items-center justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem]'>  <MyOffersPriceIcon /> {t('My Offers Price')} </h3>
                         <InfiniteScroll
                              dataLength={offersPrice.length}
                              next={onLoadMore}
                              hasMore={hasMore}
                              endMessage={<p className='mt-12 flex items-center justify-center'> <b> {t('You have seen it all')}</b> </p>}
                              loader={<PreLoader />}
                         >
                              <div className="row flex items-start justify-center flex-wrap">
                                   {offersPrice ?
                                        offersPrice.map((item, index) => (
                                             <Fragment key={index}>
                                                  {
                                                       item.object &&
                                                       <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3' >
                                                            <ProductCard
                                                                 id={item.object && item.object.id}
                                                                 slug={item.object && item.object.slug}
                                                                 key={index}
                                                                 img={item.object && item.object.image}
                                                                 type={item.object && item.object.type.title}
                                                                 myBid={item.object && item.object.price}
                                                                 lastBid={item.object && item.object.max_price}
                                                                 action={true}
                                                                 gender={item.object && item.object.gender}
                                                                 age={item.object && item.object.age}
                                                                 weight={item.object && item.object.weight}
                                                                 offerPrice={true}
                                                                 isMyBids={false}
                                                                 inMarket={item.object && item.object.in_market}
                                                            />
                                                       </div>
                                                  }
                                             </Fragment>
                                        ))
                                        :
                                        <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                                             <NotItems />
                                        </div>

                                   }

                              </div>
                         </InfiniteScroll>
                         {
                              !isLoading && offersPrice.length === 0 &&
                              <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                                   <NotItems />
                              </div>
                         }
                    </section>
               </main>
          </Layout>
     )
}

export default MyOffersPrice