import React from 'react'

const HelpSupport = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M16.0003 2.66675C8.64833 2.66675 2.66699 8.64808 2.66699 16.0001C2.66699 23.3521 8.64833 29.3334 16.0003 29.3334C23.3523 29.3334 29.3337 23.3521 29.3337 16.0001C29.3337 8.64808 23.3523 2.66675 16.0003 2.66675ZM16.0003 24.0001C15.2637 24.0001 14.667 23.4034 14.667 22.6667C14.667 21.9301 15.2637 21.3334 16.0003 21.3334C16.737 21.3334 17.3337 21.9301 17.3337 22.6667C17.3337 23.4034 16.737 24.0001 16.0003 24.0001ZM17.9937 16.6514C17.3323 17.1667 17.0003 17.4254 17.0003 18.3334C17.0003 18.8861 16.553 19.3334 16.0003 19.3334C15.4477 19.3334 15.0003 18.8861 15.0003 18.3334C15.0003 16.4481 16.0197 15.6541 16.7643 15.0741C17.4817 14.5147 18.0003 14.1107 18.0003 12.6667C18.0003 11.5641 17.103 10.6667 16.0003 10.6667C14.8977 10.6667 14.0003 11.5641 14.0003 12.6667V13.0001C14.0003 13.5527 13.553 14.0001 13.0003 14.0001C12.4477 14.0001 12.0003 13.5527 12.0003 13.0001V12.6667C12.0003 10.4607 13.7943 8.66675 16.0003 8.66675C18.2063 8.66675 20.0003 10.4607 20.0003 12.6667C20.0003 15.0874 18.8403 15.9914 17.9937 16.6514Z" fill="#17C3B2" />
          </svg>

     )
}

export default HelpSupport