import React from 'react'

const BlueHomeIcon = () => {
     return (
          <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M22.3333 26H16.3333C15.4127 26 14.6667 25.254 14.6667 24.3333V18.3333C14.6667 17.5967 14.07 17 13.3333 17H10.6667C9.93 17 9.33333 17.5967 9.33333 18.3333V24.3333C9.33333 25.254 8.58733 26 7.66667 26H1.66667C0.746 26 0 25.254 0 24.3333V11.6087C0 10.076 0.702667 8.628 1.906 7.68L11.3807 0.214C11.744 -0.0713333 12.256 -0.0713333 12.6187 0.214L22.0947 7.68C23.298 8.628 24 10.0753 24 11.6073V24.3333C24 25.254 23.254 26 22.3333 26Z" fill="#0F70B7" />
          </svg>
     )
}

export default BlueHomeIcon