import React from 'react'

const Scan = (props) => {
  return (
       <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_450_39483)">
                 <path d="M52.3125 13.5C51.5953 13.5 51.0469 12.9516 51.0469 12.2344V5.90625C51.0469 4.26094 49.7391 2.95312 48.0938 2.95312H41.7656C41.0484 2.95312 40.5 2.40469 40.5 1.6875C40.5 0.970312 41.0484 0.421875 41.7656 0.421875H48.0938C51.1313 0.421875 53.5781 2.86875 53.5781 5.90625V12.2344C53.5781 12.9516 53.0297 13.5 52.3125 13.5Z" fill="#17C3B2" />
                 <path d="M1.6875 13.5C0.970312 13.5 0.421875 12.9516 0.421875 12.2344V5.90625C0.421875 2.86875 2.86875 0.421875 5.90625 0.421875H12.2344C12.9516 0.421875 13.5 0.970312 13.5 1.6875C13.5 2.40469 12.9516 2.95312 12.2344 2.95312H5.90625C4.26094 2.95312 2.95312 4.26094 2.95312 5.90625V12.2344C2.95312 12.9516 2.40469 13.5 1.6875 13.5Z" fill="#17C3B2" />
                 <path d="M12.2344 53.5781H5.90625C2.86875 53.5781 0.421875 51.1313 0.421875 48.0938V41.7656C0.421875 41.0484 0.970312 40.5 1.6875 40.5C2.40469 40.5 2.95312 41.0484 2.95312 41.7656V48.0938C2.95312 49.7391 4.26094 51.0469 5.90625 51.0469H12.2344C12.9516 51.0469 13.5 51.5953 13.5 52.3125C13.5 53.0297 12.9516 53.5781 12.2344 53.5781Z" fill="#17C3B2" />
                 <path d="M48.0938 53.5781H41.7656C41.0484 53.5781 40.5 53.0297 40.5 52.3125C40.5 51.5953 41.0484 51.0469 41.7656 51.0469H48.0938C49.7391 51.0469 51.0469 49.7391 51.0469 48.0938V41.7656C51.0469 41.0484 51.5953 40.5 52.3125 40.5C53.0297 40.5 53.5781 41.0484 53.5781 41.7656V48.0938C53.5781 51.1313 51.1313 53.5781 48.0938 53.5781Z" fill="#17C3B2" />
                 <path d="M35.4375 45.1406H43.875C44.5922 45.1406 45.1406 44.5922 45.1406 43.875V35.4375C45.1406 34.7203 44.5922 34.1719 43.875 34.1719H35.4375C34.7203 34.1719 34.1719 34.7203 34.1719 35.4375V43.875C34.1719 44.5922 34.7203 45.1406 35.4375 45.1406Z" fill="#0F70B7" />
                 <path d="M24.4688 45.1406C23.7516 45.1406 23.2031 44.5922 23.2031 43.875V39.6562C23.2031 38.9391 23.7516 38.3906 24.4688 38.3906H28.2656V31.2188C28.2656 30.5016 28.8141 29.9531 29.5312 29.9531C30.2484 29.9531 30.7969 30.5016 30.7969 31.2188V39.6562C30.7969 40.3734 30.2484 40.9219 29.5312 40.9219H25.7344V43.875C25.7344 44.5922 25.1859 45.1406 24.4688 45.1406Z" fill="#0F70B7" />
                 <path d="M43.875 19.8281H35.4375C34.7203 19.8281 34.1719 19.2797 34.1719 18.5625V10.125C34.1719 9.40781 34.7203 8.85938 35.4375 8.85938H43.875C44.5922 8.85938 45.1406 9.40781 45.1406 10.125V18.5625C45.1406 19.2797 44.5922 19.8281 43.875 19.8281Z" fill="#0F70B7" />
                 <path d="M10.125 19.8281H18.5625C19.2797 19.8281 19.8281 19.2797 19.8281 18.5625V10.125C19.8281 9.40781 19.2797 8.85938 18.5625 8.85938H10.125C9.40781 8.85938 8.85938 9.40781 8.85938 10.125V18.5625C8.85938 19.2797 9.40781 19.8281 10.125 19.8281Z" fill="#0F70B7" />
                 <path d="M12.2344 28.2656H10.125C9.40781 28.2656 8.85938 27.7172 8.85938 27C8.85938 26.2828 9.40781 25.7344 10.125 25.7344H12.2344C12.9516 25.7344 13.5 26.2828 13.5 27C13.5 27.7172 12.9516 28.2656 12.2344 28.2656Z" fill="#0F70B7" />
                 <path d="M24.4688 28.2656H20.6719C19.9547 28.2656 19.4062 27.7172 19.4062 27C19.4062 26.2828 19.9547 25.7344 20.6719 25.7344H23.2031V18.5625C23.2031 17.8453 23.7516 17.2969 24.4688 17.2969H28.2656V10.125C28.2656 9.40781 28.8141 8.85938 29.5312 8.85938C30.2484 8.85938 30.7969 9.40781 30.7969 10.125V18.5625C30.7969 19.2797 30.2484 19.8281 29.5312 19.8281H25.7344V27C25.7344 27.7172 25.1859 28.2656 24.4688 28.2656Z" fill="#0F70B7" />
                 <path d="M43.875 30.7969H38.3906C37.6734 30.7969 37.125 30.2484 37.125 29.5312V25.7344H33.3281C32.6109 25.7344 32.0625 25.1859 32.0625 24.4688C32.0625 23.7516 32.6109 23.2031 33.3281 23.2031H38.3906C39.1078 23.2031 39.6562 23.7516 39.6562 24.4688V28.2656H43.875C44.5922 28.2656 45.1406 28.8141 45.1406 29.5312C45.1406 30.2484 44.5922 30.7969 43.875 30.7969Z" fill="#0F70B7" />
                 <path d="M18.5625 45.1406H10.125C9.40781 45.1406 8.85938 44.5922 8.85938 43.875V35.4375C8.85938 34.7203 9.40781 34.1719 10.125 34.1719H18.5625C19.2797 34.1719 19.8281 34.7203 19.8281 35.4375V43.875C19.8281 44.5922 19.2797 45.1406 18.5625 45.1406Z" fill="#0F70B7" />
            </g>
            <defs>
                 <clipPath id="clip0_450_39483">
                      <rect width="54" height="54" fill="white" />
                 </clipPath>
            </defs>
       </svg>

  )
}

export default Scan