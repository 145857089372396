import React from 'react'

const Public = () => {
     return (
          <svg width="35" height="44" viewBox="0 0 35 44" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.15663 0.5C8.94304 0.500037 8.72942 0.585941 8.56692 0.758145L5.02754 4.50441C4.70253 4.84882 4.70253 5.40933 5.02754 5.75374C5.19018 5.92594 5.40373 6.01188 5.61725 6.01188C5.72402 6.01188 5.83102 5.99047 5.93166 5.94749L6.82003 7.3256C7.0925 7.74655 7.38188 8.15302 7.69049 8.54159L0.519965 15.5356C0.194677 15.8536 0.00863935 16.2864 0.000298625 16.7574C-0.0080421 17.2254 0.158555 17.6666 0.472722 17.9992C0.778549 18.323 1.18212 18.5 1.61306 18.5H1.64618C2.08824 18.4882 2.49982 18.2936 2.80009 17.9492L9.40859 10.3549C9.7728 10.6846 10.1568 10.9933 10.5544 11.2788L11.856 12.2171C11.7331 12.5365 11.7935 12.9168 12.0379 13.1755C12.2005 13.3475 12.4141 13.4337 12.6276 13.4337C12.8414 13.4337 13.0547 13.3478 13.2173 13.1755L16.7561 9.42871C17.0811 9.0846 17.0814 8.52407 16.7561 8.17995C16.5118 7.92121 16.1526 7.85696 15.8509 7.98678L14.9636 6.60751C13.96 5.04737 12.7034 3.71693 11.2299 2.65427L9.9288 1.71656C10.0514 1.39717 9.99045 1.0166 9.74635 0.758145C9.58356 0.585794 9.37022 0.499963 9.15663 0.5Z" fill="white" />
               <path d="M6.51961 34.2742C8.9902 34.2742 10.9804 32.2161 10.9804 29.6613C10.9804 27.1065 8.9902 25.0484 6.51961 25.0484C4.04902 25.0484 2.05882 27.1065 2.05882 29.6613C2.05882 32.2161 4.04902 34.2742 6.51961 34.2742ZM1.02941 43.5C1.61275 43.5 2.05882 43.0387 2.05882 42.4355C2.05882 39.8806 4.04902 43.5 6.51961 43.5C7.10294 43.5 7.54902 37.3613 7.54902 36.7581C7.54902 36.1548 7.10294 35.6935 6.51961 35.6935C2.91667 35.6935 0 38.7097 0 42.4355C0 43.0387 0.446078 43.5 1.02941 43.5ZM32.9412 29.6613C32.9412 27.1065 30.951 25.0484 28.4804 25.0484C26.0098 25.0484 24.0196 27.1065 24.0196 29.6613C24.0196 32.2161 26.0098 34.2742 28.4804 34.2742C30.951 34.2742 32.9412 32.2161 32.9412 29.6613ZM21.9608 26.1129C21.9608 23.5581 19.9706 21.5 17.5 21.5C15.0294 21.5 13.0392 23.5581 13.0392 26.1129C13.0392 28.6677 15.0294 30.7258 17.5 30.7258C19.9706 30.7258 21.9608 28.6677 21.9608 26.1129ZM22.9902 43.5C23.5735 43.5 24.0196 43.0387 24.0196 42.4355V38.8871C24.0196 35.1613 21.1029 32.1452 17.5 32.1452C13.8971 32.1452 10.9804 35.1613 10.9804 38.8871V42.4355C10.9804 43.0387 11.4265 43.5 12.0098 43.5C12.5931 43.5 13.0392 43.5 13.0392 43.5C13.0392 43.5 15.0294 43.5 17.5 43.5C19.9706 43.5 23 43.5 23 43.5C23 43.5 22.4069 43.5 22.9902 43.5ZM28.4804 43.5C30.951 43.5 32.9412 39.8806 32.9412 42.4355C32.9412 43.0387 33.3873 43.5 33.9706 43.5C34.5539 43.5 35 43.0387 35 42.4355C35 38.7097 32.0833 35.6935 28.4804 35.6935C27.8971 35.6935 27.451 36.1548 27.451 36.7581C27.451 37.3613 27.8971 43.5 28.4804 43.5Z" fill="white" />
          </svg>
      )
}

export default Public