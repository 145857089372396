import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { config, url } from '../Components/Helpers/Help'
import BlueButton from '../Components/UI/Buttons/BlueButton'
import Ring from '../Components/UI/Loader/Ring'
import alert from '../Helpers/Modal'

const Country = (props) => {
     const [isLoading, setIsLoading] = useState('')
     const [cities, setCities] = useState([])
     const [isCountry, setIsCountry] = useState([])
     const [isSelected, setIsSelect] = useState()
     const [isCountryID, setIsCountryID] = useState('')
     const [isCityID, setIsCityID] = useState()
     const [errorMessageAlert, setErrorMessageAlert] = useState('')

     // Get Countries from localStorage
     useEffect(() => {
          if (localStorage.__authtk__awasell_secure) {
               const data = localStorage.getItem('__data_aW__a_start')
               const dataArr = JSON.parse(data)
               setIsCountry(dataArr.countries)
          }
     }, [])

     // Get user Information from localStorage
     useEffect(() => {
          if (localStorage.__User_aW__a_Info) {
               const userData = localStorage.getItem('__User_aW__a_Info')
               const userInfo = JSON.parse(userData)
               setIsCountryID(userInfo.country)
               setIsCityID(userInfo.city)
          }
     }, []);

     // send user ountry to server
     const sendCountry = () => {
          axios.post(url + 'users/update',
               //  Data
               {
                    country_id: isSelected,
                    city_id: isCityID
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setIsCountryID()
                    setIsCityID()
                    localStorage.setItem('__User_aW__a_Info', JSON.stringify(response.data.data.user))
                    window.location.href = '/introduction'
                    alert({
                         title: `${t('Alert')}`,
                         text: `${t('Thank you, your location has been updated')}`,
                         icon: 'success',
                         timer: 3500
                    })
               })
               // Check Error
               .catch(error => {
                    setErrorMessageAlert(error.response.data.message)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 3500
                    })
               })

     }

     return (
          <div className="flex items-center justify-center h-screen">
               <div className={`w-8/12 mx-auto shadow-xl top-0  bg-[#F9F9F9] max-h-[90vh] z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
                    {/* <h3 onClick={props.onBack} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'>
                         {t('Country')}
                    </h3> */}
                    <li className='mb-6 cursor-pointer flex items-center justify-between gap-4' >
                         {/* <CountryIcon /> <span className='w-1/2'>{t('Country')}</span> */}
                         {isCountryID && <span className='flex items-center justify-end gap-4 w-1/2'> <img src={isCountryID.flag} alt={isCountryID.name} className='mx-2 w-8' /> {isCountryID.name}</span>}
                    </li>
                    <div className="flex w-full flex-wrap items-start justify-center px-12">
                         {(isCountry && isCountry.length > 0) &&
                              <div className='w-1/2'>
                                   <h3 className=' text-left font-bold mt-4 pb-2'>{t('Your Country')}</h3>
                                   <ul className={`${isSelected && 'bg-gray-100 '} px-3 max-h-[60vh] overflow-y-auto overflow-x-hidden no-scroll flex justify-start items-start flex-wrap cursor-pointer`}>
                                        {
                                             isCountry.map((item, index) => (
                                                  <li key={index} className={`py-2 my-4 relative border w-full flex items-center justify-start
                                              ${isSelected === item.id ? 'text-gray-100 bg-blue rounded-r-full' : 'text-blue border-blue hover:text-white hover:bg-blue rounded-lg'}`}
                                                       onClick={() => { setIsCountryID(item); setCities(item.cities); console.log(item.id) ; setIsSelect(item.id) }}>
                                                       <img src={item.flag} alt={item.name} className='mx-2 w-8' />
                                                       {item.name}
                                                  </li>
                                             ))
                                        }
                                   </ul>
                              </div>
                         }

                         {(cities && cities.length > 0) &&
                              <div className='w-1/2 p-4'>
                                   <h3 className='text-left font-bold mt-4 pb-2'>{t('Your City')}</h3>
                                   <ul className='max-h-[60vh] overflow-y-auto no-scroll overflow-x-hidden px-4 cursor-pointer bg-gray-100'>
                                        {
                                             cities.map((item, index) => (
                                                  <li key={index} className={`p-2 my-4 relative border-b w-full flex items-center justify-start
                                              ${isCityID === item.id ? 'text-gray-200 bg-blue rounded-r-full' : 'text-blue hover:text-white hover:bg-blue '}`}
                                                       onClick={() => { setIsCityID(item.id); console.log(item.id) }}>{item.name}</li>
                                             ))
                                        }
                                   </ul>
                              </div>
                         }
                    </div>
                    {errorMessageAlert && <p className='text-red'>{errorMessageAlert}</p>}

                    <BlueButton onClick={sendCountry} className='mb-6 flex items-center justify-center mx-auto text-[1.5rem] py-4 px-5 mt-8 hover:bg-mint'>
                         {isLoading ? <Ring /> : `${t('Submit')}`}
                    </BlueButton>
               </div >
          </div>
     )
}

export default Country