import axios from "axios";
import { useState, useEffect } from "react";
import { config, url } from "../Components/Helpers/Help";
import Layout from "../Components/Layout";

const AboutUs = () => {
  const [about, setAbout] = useState();
  console.log("🚀 ~ AboutUs ~ about:", about);

  useEffect(() => {
    axios(url + "content ", config).then((response) => {
      setAbout(response.data.data.about_us);
    });
  }, []);

  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-[80vh]">
        <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
          <div className="row flex items-start justify-center flex-wrap ">
            <div className="w-full md:w-8/12 p-4 xl:px-6">
              {about && (
                <div className="bg-white shadow rounded-2xl p-8">
                  <div dangerouslySetInnerHTML={{ __html: about }} />
                </div>
              )}
            </div>
          </div>
        </main>
      </main>
    </Layout>
  );
};

export default AboutUs;
