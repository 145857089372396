import axios from 'axios';
import { t } from 'i18next';
import { useEffect } from 'react';
import { createContext, useState } from 'react';
import { config, url } from '../Components/Helpers/Help';
import alert from '../Helpers/Modal';

const Context = createContext({
     addVideoFavorite: (favoriteMeetup) => { },
     removeVideoFavorite: (id) => { },

     addQuestionFavorite: (favoriteMeetup) => { },
     removeQuestionFavorite: (id) => { },

     isLoad: false,
     favorites: [],
     totalFavorites: 0,

     addFavorite: (favoriteMeetup) => { },
     removeFavorite: (id, slug) => { },
     itemIsFavorite: (id) => { },
});

export const ContextProvider = (props) => {
     const [isLoading, setIsLoading] = useState(false);
     const [userFavorites, setUserFavorites] = useState([])

     useEffect(() => {
          if (localStorage.__User_aW__a_Info == null || !localStorage.__User_aW__a_Info  ) {
               return
          } 
          setIsLoading(true)
          axios(url + 'users/favourites-web', config)
               // Response
               .then(response => {
                    // console.log(response.data.data.section.objects)
                    setUserFavorites(response.data.data.section.objects)
                    setIsLoading(false)
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }, [])

     function addFavoriteHandler(id, slug) {
          if (!localStorage.__authtk__awasell_secure) {
               alert({
                    title: `${t('Note')}`,
                    text: `${t('Please login first ')}`,
                    icon: 'info',
                    timer: 2500
               })
               return
          }
          axios.post(url + `animals/bb/like`,
               {
                    animal_id: id,
                    slug: slug,
               },
               config)
               // Response
               .then(response => {
                    // console.log(response)
                    setUserFavorites((prevUserFavorites) => {
                         return prevUserFavorites.concat(response.data.data);
                    });
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }

     function removeFavoriteHandler(id, slug) {
          axios.post(url + 'animals/bb/like',
               {
                    animal_id: id,
                    slug: slug,
               },
               config)
               // Response
               .then(response => {
                    setUserFavorites((prevUserFavorites) => {
                         return prevUserFavorites.filter(meetup => meetup.id !== id)
                    });
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }


     function addVideoFavoriteHandler(id) {
          if (!localStorage.__authtk__awasell_secure) {
               alert({
                    title: `${t('Note')}`,
                    text: `${t('Please login first ')}`,
                    icon: 'info',
                    timer: 2500
               })
               return
          }
          axios.post(url + `videos/like`,
               {
                    video_id: id,
               },
               config)
               // Response
               .then(response => {
                    // console.log(response)
                    setUserFavorites((prevUserFavorites) => {
                         return prevUserFavorites.concat(response.data.data);
                    });
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }

     function removeVideoFavoriteHandler(id) {
          axios.post(url + 'videos/like',
               {
                    video_id: id,
               },
               config)
               // Response
               .then(response => {
                    setUserFavorites((prevUserFavorites) => {
                         return prevUserFavorites.filter(meetup => meetup.id !== id)
                    });
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }


     function addQuestionFavoriteHandler(id) {
          if (!localStorage.__authtk__awasell_secure) {
               alert({
                    title: `${t('Note')}`,
                    text: `${t('Please login first ')}`,
                    icon: 'info',
                    timer: 2500
               })
               return
          }
          axios.post(url + `questions/like`,
               {
                    question_id: id,
               },
               config)
               // Response
               .then(response => {
                    // console.log(response)
                    setUserFavorites((prevUserFavorites) => {
                         return prevUserFavorites.concat(response.data.data);
                    });
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }

     function removeQuestionFavoriteHandler(id) {
          axios.post(url + 'questions/like',
               {
                    question_id: id,
               },
               config)
               // Response
               .then(response => {
                    setUserFavorites((prevUserFavorites) => {
                         return prevUserFavorites.filter(meetup => meetup.id !== id)
                    });
               })
               // Check Error
               .catch(error => {
                    console.log(error)
               })
     }


     function itemIsFavoriteHandler(id, slug) {
          return userFavorites.some(favItem => favItem.id === id && favItem.slug === slug);
     }

     const context = {
          isLoad: isLoading,
          favorites: userFavorites,
          totalFavorites: userFavorites.length,
          addVideoFavorite: addVideoFavoriteHandler,
          removeVideoFavorite: removeVideoFavoriteHandler,
          addQuestionFavorite: addQuestionFavoriteHandler,
          removeQuestionFavorite: removeQuestionFavoriteHandler,
          addFavorite: addFavoriteHandler,
          removeFavorite: removeFavoriteHandler,
          itemIsFavorite: itemIsFavoriteHandler
     };

     return (
          <Context.Provider value={context}>
               {props.children}
          </Context.Provider>
     );
};


export default Context