import React from 'react'

const Language = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.99935 1.66585C5.40435 1.66585 1.66602 5.40418 1.66602 9.99918C1.66602 11.3271 1.98744 12.6429 2.59619 13.8208L1.70426 17.0117C1.60385 17.3734 1.70617 17.7627 1.97201 18.0282C2.16992 18.2261 2.43681 18.3333 2.70931 18.3333C2.80306 18.3333 2.89594 18.3209 2.98844 18.2951L6.18099 17.404C7.35891 18.0123 8.6731 18.3325 9.99935 18.3325C14.5943 18.3325 18.3327 14.5942 18.3327 9.99918C18.3327 5.40418 14.5943 1.66585 9.99935 1.66585ZM8.54102 5.41666H11.041C11.386 5.41666 11.666 5.69624 11.666 6.04166V7.08333C11.666 7.95666 11.2794 8.77643 10.6056 9.33268C10.4894 9.42893 10.3485 9.47591 10.2085 9.47591C10.0281 9.47591 9.84925 9.39844 9.72591 9.24886C9.50591 8.98261 9.54348 8.58914 9.80973 8.36914C10.1951 8.05122 10.416 7.58249 10.416 7.08333V6.66666H8.54102C8.19602 6.66666 7.91602 6.38708 7.91602 6.04166C7.91602 5.69624 8.19602 5.41666 8.54102 5.41666ZM13.1243 5.41666C13.4693 5.41666 13.7493 5.69624 13.7493 6.04166V7.49999H14.3743C14.7193 7.49999 14.9993 7.77958 14.9993 8.12499C14.9993 8.47041 14.7193 8.74999 14.3743 8.74999H13.7493V11.4583C13.7493 11.8037 13.4693 12.0833 13.1243 12.0833C12.7793 12.0833 12.4993 11.8037 12.4993 11.4583V6.04166C12.4993 5.69624 12.7793 5.41666 13.1243 5.41666ZM7.70768 7.49999C7.96643 7.49999 8.19826 7.65914 8.29118 7.90039L10.3745 13.3171C10.4983 13.6396 10.3373 14.0014 10.0148 14.1252C9.94106 14.1535 9.86602 14.1667 9.79102 14.1667C9.53977 14.1667 9.30294 14.0138 9.20752 13.7655L8.72087 12.5H6.6945L6.20784 13.7663C6.08368 14.0888 5.72097 14.2493 5.40055 14.1252C5.07847 14.0014 4.9171 13.6399 5.04085 13.3179L7.12419 7.9012C7.2171 7.65953 7.44893 7.49999 7.70768 7.49999ZM7.70768 9.86653L7.17546 11.25H8.23991L7.70768 9.86653Z" fill="#0F70B7" />
          </svg>

     )
}

export default Language