import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config, url } from '../Components/Helpers/Help';
import Layout from '../Components/Layout'
const Privacy = () => {
     const [privacy_policy, setPrivacy_policy] = useState();

     useEffect(() => {
          axios(url + "content ", config).then((response) => {
               setPrivacy_policy(response.data.data.privacy_policy)
          });
     }, []); 
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    <div className="row flex items-start justify-center flex-wrap min-h-[70vh]">
                         <div className="w-full md:w-8/12 p-4 xl:px-6">
                              {privacy_policy && <div className="bg-white shadow rounded-2xl p-8">
                                   <div dangerouslySetInnerHTML={{ __html: privacy_policy }} />
                              </div>}

                         </div>
                    </div>
               </main>
          </Layout>

     )
}

export default Privacy