import { t } from "i18next";
import React, { useEffect, useState } from "react";
import BlueButton from "../UI/Buttons/BlueButton";
import Input from "../UI/Input";
import HolesaleMarket from "../../assets/Icons/UserIcons/Colors/HolesaleMarket";
import MarketIcon from "../../assets/Icons/UserIcons/Colors/MarketIcon";
import QuickMarket from "../../assets/Icons/UserIcons/Colors/QuickMarket";
import WhiteHolesaleMarket from "../../assets/Icons/UserIcons/White/WhiteHolesaleMarket";
import WhiteMarket from "../../assets/Icons/UserIcons/White/WhiteMarket";
import WhiteQuickMarket from "../../assets/Icons/UserIcons/White/WhiteQuickMarket";
import Select from "react-select";
import Title from "./Title";
import { config, url } from "../Helpers/Help";
import axios from "axios";
import Map from "../UI/Map";
import alert from "../../Helpers/Modal";
import ButtonLoading from "../UI/ButtonLoading";
import { Link } from "react-router-dom";

const AddAwaLive = () => {
  // const [showIn, setShowIn] = useState("1")
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [color, setColor] = useState("");
  const [ownership, setownership] = useState("");
  const [Ensure, setEnsure] = useState("");
  const [commitmentOwnership, setCommitmentOwnership] = useState("");
  const [check, setCheck] = useState("");
  const [AskingPrice, setAskingPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("1");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  // const [activeTap, setActiveTap] = useState(4);
  const [priceValue, setPriceValue] = useState();
  const [itemsCount, setItemsCount] = useState("");
  const [startingAuctionPrice, setstartingAuctionPrice] = useState("");
  const [passportNO, setPassportNO] = useState("");
  const [animalSpecies, setAnimalSpecies] = useState("");
  const [dateofBirthday, setDateofBirthday] = useState("");
  const [singleImage, setUploadSingleImage] = useState("");
  const [medicalCertificate, setUploadMedicalCertificate] = useState("");
  const [uploadVideo, setUploadVideo] = useState("");
  const [categories, setCategories] = useState([]);
  const [priceType, setPriceType] = useState("");
  const [isError, setIsError] = useState(true);
  const [childs, setChilds] = useState([]);
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [medicalCertificateError, setMedicalCertificateError] = useState(false);
  const [startingAuctionPriceError, setStartingAuctionPrice] = useState(false);
  const [CategoryError, setCategoryError] = useState(false);
  const [TypeError, setTypeError] = useState(false);
  const [animalSpeciesError, setAnimalSpeciesError] = useState(false);
  const [askingPriceError, setAskingPriceError] = useState(false);
  const [dateofBirthdayError, setDateofBirthdayError] = useState(false);
  const [passportNoError, setPassportNoError] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const [weightError, setweightError] = useState(false);
  const [priceTypeError, setpriceTypeError] = useState(false);
  const [priceError, setpriceError] = useState(false);
  const [ImagesError, setImagesError] = useState(false);
  const [videoError, setvideoError] = useState(false);
  const [singleImageError, setsingleImageError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [noteError, setnoteError] = useState(false);
  const [mapError, setmapError] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [ownershipError, setOwnershipError] = useState(false);
  const [EnsureError, setEnsureError] = useState(false);
  const [commitmentOwnershipError, setCommitmentOwnershipError] =
    useState(false);

  // upload malti image
  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    console.log(newImageUrls);
  }, [images]);

  // Storage Images
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  // Get Categories from localStorage
  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem("__data_aW__a_start");
      const dataArr = JSON.parse(data);
      setCategories(dataArr.categories);
    }
  }, []);

  // Get Childes of Categories by ID
  useEffect(() => {
    if (category) {
      const item = categories.find((item) => item.id === category);
      setChilds(item.childs);
    }
  }, [category, categories]);

  //  Submit auction form
  const submitHandler = () => {
    // if (   !phone || !email || !note || !lat || !lng) {
    //      setIsError(true)
    // }
    if (!title) {
      setTitleError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!category) {
      setCategoryError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!type) {
      setTypeError(true);
      window.scrollTo(0, 0);
      return;
    }

    if (!animalSpecies) {
      setAnimalSpeciesError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!dateofBirthday) {
      setDateofBirthdayError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!passportNO) {
      setPassportNoError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!color) {
      setColorError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!location) {
      setlocationError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!weight) {
      setweightError(true);
      window.scrollTo(0, 0);
      return;
    }

    if (!images || images.length < 3) {
      setImagesError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!uploadVideo) {
      setvideoError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!singleImage) {
      setsingleImageError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!phone) {
      setphoneError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!email) {
      setemailError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!note) {
      setnoteError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!lat || !lng) {
      setmapError(true);
      return;
    }
    if (!commitmentOwnership) {
      setCommitmentOwnershipError(true);
      return;
    }
    if (!check) {
      setCheckError(true);
      return;
    }
    if (!ownership) {
      setOwnershipError(true);
      return;
    }
    if (!Ensure) {
      setEnsureError(true);
      return;
    }
    setLoading(true);
    axios
      .post(
        url + "animals",
        //  Data
        {
          slug: "awa-lives",
          show_in: 2,
          // type_id: activeTap,
          category_id: category,
          sub_category_id: type,
          species: animalSpecies,
          address: location,
          gender: gender,
          date_of_birth: dateofBirthday,
          passport_number: passportNO,
          color: color,
          weight: weight,
          items_count: itemsCount,
          medical_certificate: medicalCertificate,
          video: uploadVideo,
          asking_price: AskingPrice,
          starting_auction_price: startingAuctionPrice,
          image: singleImage,
          "images[]": images,
          phone: phone,
          title: title,
          email: email,
          note: note,
          lat: lat,
          lng: lng,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setCategory("");
        setType("");
        setAnimalSpecies("");
        setDateofBirthday("");
        setPassportNO("");
        setColor("");
        setWeight("");
        setLocation("");
        setUploadSingleImage("");
        setUploadVideo("");
        setPriceType("");
        setPrice("");
        setPhone("");
        setEmail("");
        setNote("");
        setLat("");
        setLng("");
        setCheck("");
        setImages([]);
        setLoading(false);
        if (response.data.data.payment_link) {
          window.location.href = `${response.data.data.payment_link}`;
        } else {
          alert({
            title: `${t("Done")}`,
            text: `${t(`Thank you for send`)}`,

            icon: "success",
          });
        }
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        setLoading(false);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    axios(url + `type/awa-lives`, config)
      // Response
      .then((response) => {
        console.log(response.data.data.price);
        setPriceValue(response?.data?.data?.price);
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);
  return (
    <>
      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <Title className="mb-8"> {t("Add AWA Live")} </Title>

        {priceValue && (
          <h3 className="w-full flex items-center justify-center font-bold my-8 ">
            {" "}
            {t("AWA Live value")} : {priceValue}{" "}
          </h3>
        )}

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setTitleError(false)}
            input_className={`border-2 ${
              titleError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder={t("Title")}
            id="title"
            label={t("Title")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Category")}
            </label>
            <select
              onFocus={() => setCategoryError(false)}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${
                CategoryError ? "border-red" : "border-blue"
              }`}
            >
              <option value={""}> {t("chosse")}. . . </option>
              {categories &&
                categories.map((item) => (
                  <option value={item.id} key={item.id}>
                    {" "}
                    {item.title}{" "}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {childs && childs.length > 0 && (
          <div className="w-full md:w-1/2 p-2 my-2">
            <div className="flex flex-col">
              <label htmlFor="" className="font-medium text-[1.5] ">
                {" "}
                {t("Type")}{" "}
              </label>
              <div className="flex">
                <select
                  onFocus={() => setTypeError(false)}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${
                    TypeError ? "border-red" : "border-blue"
                  }`}
                >
                  <option value={""}> {t("chosse")} . . . </option>

                  {childs &&
                    childs.map((item) => (
                      <option value={item.id} key={item.id}>
                        {" "}
                        {item.title}{" "}
                      </option>
                    ))}
                </select>
                <Link
                  to="/add-animal/add-sub-categorey"
                  className="flex items-center justify-center hover:opacity-70 bg-blue text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all px-4 ml-3 rounded-lg"
                >
                  +
                </Link>
              </div>
            </div>
          </div>
        )}

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setAnimalSpeciesError(false)}
            input_className={`border-2  ${
              animalSpeciesError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={animalSpecies}
            onChange={(e) => setAnimalSpecies(e.target.value)}
            type="text"
            placeholder={t("Animal Species")}
            id="AnimalSpecies"
            label={t("Animal Species")}
          />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setAskingPriceError(false)}
            input_className={`border-2  ${
              askingPriceError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={AskingPrice}
            onChange={(e) => setAskingPrice(e.target.value)}
            type="number"
            placeholder={t("Asking Price")}
            id="AskingPrice"
            label={t("Asking Price")}
          />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setStartingAuctionPrice(false)}
            input_className={`border-2  ${
              startingAuctionPriceError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={startingAuctionPrice}
            onChange={(e) => setstartingAuctionPrice(e.target.value)}
            type="number"
            placeholder={t("Starting Auction Price")}
            id="itemsCount"
            label={t("Starting Auction Price")}
          />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setDateofBirthdayError(false)}
            input_className={`border-2 ${
              dateofBirthdayError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={dateofBirthday}
            onChange={(e) => setDateofBirthday(e.target.value)}
            type="date"
            placeholder={t("Date of Birthday")}
            id="DateBirthday"
            label={t("Date of Birthday")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setPassportNoError(false)}
            input_className={`border-2 ${
              passportNoError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={passportNO}
            onChange={(e) => setPassportNO(e.target.value)}
            type="text"
            placeholder={t("Passport NO")}
            id="Passport"
            label={t("Passport NO")}
          />
        </div>
        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setColorError(false)}
            input_className={`border-2 border-blue py-0 h-[4rem] ${
              colorError ? "border-red" : "border-blue"
            }`}
            div_className="w-full "
            value={color}
            onChange={(e) => setColor(e.target.value)}
            type="color"
            placeholder={t("Color")}
            id="Color"
            label={t("Color")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setweightError(false)}
            input_className={`border-2  ${
              weightError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            type="number"
            placeholder={t("Weight")}
            id="Weight"
            label={t("Weight")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2 hidden">
          <Input
            onFocus={() => setlocationError(false)}
            input_className={`border-2 border-blue ${
              locationError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            placeholder={t("location")}
            id="location"
            label={t("location")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Medical Certificate")}{" "}
            </label>
            <div
              onFocus={() => setMedicalCertificateError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                medicalCertificateError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => {
                  setUploadMedicalCertificate(e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                className="pl-5 mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute  rtl:-right-[0.2rem] ltr:-left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white px-3 border-r w-[11.5rem] text-center">
                {" "}
                {t("Upload Image")}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Upload one Image")}{" "}
            </label>
            <div
              onFocus={() => setsingleImageError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                singleImageError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => {
                  setUploadSingleImage(e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                accept="image/jpg, image/jpeg,image/png"
                className="pl-5 mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute  rtl:-right-[0.2rem] ltr:-left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white px-3 border-r w-[12rem] text-center">
                {" "}
                {t("Upload Image")}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Upload three Images")}{" "}
            </label>
            <div
              onFocus={() => setImagesError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                ImagesError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={onImageChange}
                multiple
                accept="image/jpg, image/jpeg,image/png"
                className="mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-4 border-r w-[11.5rem] text-center">
                {" "}
                {t("Upload Image")}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Upload Video")}{" "}
            </label>
            <div
              onFocus={() => setvideoError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16  ${
                videoError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => setUploadVideo(e.target.files[0])}
                accept="video/mp4,video/x-m4v,video/*"
                className="pl-5 mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute top-0 rtl:right-0 ltr:left-0 h-full leading-[2.5] bg-blue text-white px-3 border-r w-[11.5rem] text-center">
                {t("Upload Video")}{" "}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="w-full md:w-1/2 p-2 my-2">
                         <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Posted on')} </label>
                         <div className="flex items-center justify-between flex-wrap w-full mt-2">
                              <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
                                   {t('My page')}
                                   <input name="PostedOn" value='MyPage' checked={showIn === "1"} onChange={() => setShowIn('1')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
                                   <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
                              </label>
                              <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
                                   {t('My page & AWA page')}
                                   <input name="PostedOn" value='AWAPage' checked={showIn === '2'} onChange={() => setShowIn('2')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
                                   <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
                              </label>

                         </div>
                    </div> */}
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2">
            {" "}
            {t("Gender")}{" "}
          </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Male")}
              <input
                name="radio"
                value="1"
                checked={gender === "1"}
                onChange={() => setGender("1")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Female")}
              <input
                name="radio"
                value="2"
                checked={gender === "2"}
                onChange={() => setGender("2")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t(`Don't Now`)}
              <input
                name="radio"
                value="3"
                checked={gender === "3"}
                onChange={() => setGender("3")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
          </div>
        </div>
        <hr className="block my-12 w-full" />
        <h3 className="w-full rtl:text-right ltr:text-left font-bold px-4">
          {t("Contact")}
        </h3>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setphoneError(false)}
            input_className={`border-2 ${
              phoneError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="tel"
            placeholder={t("Phone")}
            id="Phone"
            label={t("Phone")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setemailError(false)}
            input_className={`border-2 ${
              emailError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder={t("Email")}
            id="Email"
            label={t("Email")}
          />
        </div>

        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]">
            {" "}
            {t("Note")}{" "}
          </label>
          <textarea
            onFocus={() => setnoteError(false)}
            className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${
              noteError ? "border-red" : "border-blue"
            }`}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            name=""
            id=""
            cols="30"
            rows="5"
            placeholder={t("Note . . .")}
          ></textarea>
        </div>

        <div
          onFocus={() => setmapError(false)}
          className={`w-full p-2  ${mapError && "border-red border"}`}
        >
          <Map
            address={(e) => setLocation(e)}
            lat={(e) => setLat(e)}
            lng={(e) => setLng(e)}
          />
        </div>
        <label
          onFocus={() => setCommitmentOwnershipError(false)}
          className={`w-full CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3 ${
            commitmentOwnershipError && "text-red"
          }`}
        >
          <span>
            {t("I agree to the AWE Live Streaming Terms and Conditions")}{" "}
          </span>
          <strong>
            {" "}
            <Link to="/commitment-ownership">{t("read")}</Link>
          </strong>
          <input
            name="commitmentOwnership"
            value="1"
            checked={commitmentOwnership === "1"}
            onChange={() => setCommitmentOwnership("1")}
            type="checkbox"
            className="absolute opacity-0 cursor-pointer h-0 w-0"
          />
          <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
        </label>
        <label
          onFocus={() => setOwnershipError(false)}
          className={`w-full CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3 ${
            ownershipError && "text-red"
          }`}
        >
          {t("Ensure that the animal eats and drinks during this period")}
          <input
            name="ownership"
            value="1"
            checked={ownership === "1"}
            onChange={() => setownership("1")}
            type="checkbox"
            className="absolute opacity-0 cursor-pointer h-0 w-0"
          />
          <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
        </label>
        <label
          onFocus={() => setEnsureError(false)}
          className={`w-full CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3 ${
            EnsureError && "text-red"
          }`}
        >
          {t("Pledge of ownership of the animal")}
          <input
            name="Ensure"
            value="1"
            checked={Ensure === "1"}
            onChange={() => setEnsure("1")}
            type="checkbox"
            className="absolute opacity-0 cursor-pointer h-0 w-0"
          />
          <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
        </label>
        <label
          onFocus={() => setCheckError(false)}
          className={`w-full CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3 ${
            checkError && "text-red"
          }`}
        >
          {t(
            "Bringing the animal to the auction site two days before the auction"
          )}
          <input
            name="check"
            value="1"
            checked={check === "1"}
            onChange={() => setCheck("1")}
            type="checkbox"
            className="absolute opacity-0 cursor-pointer h-0 w-0"
          />
          <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
        </label>
        <div className="w-full flex items-center justify-center">
          <BlueButton
            className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${
              loading && "cursor-not-allowed"
            }`}
            disabled={loading}
            onClick={submitHandler}
          >
            {loading ? <ButtonLoading /> : `${t("Send")}`}
          </BlueButton>
        </div>
        <div className="my-8 w-full flex items-end justify-center">
          <p className="font-bold text-red capitalize">
            {CategoryError && `${t("Please enter your animal category")}`}
            {TypeError && `${t("Please enter your animal category type")}`}
            {animalSpeciesError && `${t("Please enter your  animal species")}`}
            {dateofBirthdayError &&
              `${t("Please enter your animal date of birthday")}`}
            {passportNoError && `${t("Please enter your animal passport No")}`}
            {colorError && `${t("Please enter your animal color")}`}
            {weightError && `${t("Please enter your animal weight")}`}
            {locationError && `${t("Please enter your location")}`}
            {singleImageError && `${t("Please enter your Image")}`}
            {ImagesError && `${t("Please enter your single Image")}`}
            {videoError && `${t("Please enter your video")}`}
            {phoneError && `${t("Please enter your phone")}`}
            {emailError && `${t("Please enter your email")}`}
            {mapError && `${t("Please enter your map location")}`}
            {checkError && `${t("Please apply conditions")}`}
            {commitmentOwnershipError && `${t("Please apply conditions")}`}
            {ownershipError && `${t("Please verify ownership of the animal")}`}
            {EnsureError &&
              `${t(
                "Please be sure to provide the animal with food and water"
              )}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default AddAwaLive;
