import React from 'react'

const Gallery = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_90_17620)">
                    <path d="M3.94737 0C1.77053 0 0 1.77053 0 3.94737V11.4176L3.60197 7.93277C4.35671 7.20382 5.64126 7.20435 6.39494 7.93277L9.13035 10.5798L10.2333 9.51172C10.6081 9.14856 11.1031 8.9479 11.6262 8.94737H11.6303C12.1546 8.94737 12.65 9.14801 13.0263 9.51275L17.354 13.6986C17.3609 13.6065 17.3684 13.5147 17.3684 13.4211V3.94737C17.3684 1.77053 15.5979 0 13.4211 0H3.94737ZM11.8421 4.21053C12.5689 4.21053 13.1579 4.79947 13.1579 5.52632C13.1579 6.25316 12.5689 6.84211 11.8421 6.84211C11.1153 6.84211 10.5263 6.25316 10.5263 5.52632C10.5263 4.79947 11.1153 4.21053 11.8421 4.21053ZM4.99794 8.95148C4.889 8.95148 4.78012 8.98975 4.69881 9.06764L0.00925164 13.6051C0.0818832 15.1635 1.05701 16.4894 2.42701 17.0631L7.99445 11.6776L5.29708 9.06764C5.21629 8.98975 5.10689 8.95148 4.99794 8.95148ZM11.6303 10.5263C11.5182 10.5263 11.4117 10.5692 11.3322 10.6466L4.38322 17.3684H13.4211C14.8789 17.3684 16.1512 16.5721 16.8349 15.3937L11.9285 10.6466C11.8479 10.5687 11.7425 10.5263 11.6303 10.5263Z" fill="#0F70B7" />
                    <path d="M18.4215 3.42822V13.9473C18.4215 16.4142 16.4147 18.421 13.9478 18.421H3.42871C4.14976 19.3779 5.29152 20 6.57941 20H13.9478C17.2852 20 20.0005 17.2847 20.0005 13.9473V6.57892C20.0005 5.29155 19.3784 4.14928 18.4215 3.42822Z" fill="#17C3B2" />
               </g>
               <defs>
                    <clipPath id="clip0_90_17620">
                         <rect width="20" height="20" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     )
}

export default Gallery