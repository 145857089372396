import React from 'react'

const Twitter = () => {
  return (
       <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9993 2.66699C8.63535 2.66699 2.66602 8.63633 2.66602 16.0003C2.66602 23.3643 8.63535 29.3337 15.9993 29.3337C23.3633 29.3337 29.3327 23.3643 29.3327 16.0003C29.3327 8.63633 23.3633 2.66699 15.9993 2.66699Z" fill="url(#paint0_linear_56_3782)" />
            <path opacity="0.05" d="M13.3326 23.3338C11.1726 23.3338 9.09864 22.8044 7.64264 21.8818L7.41797 21.7398L7.81264 20.6258L8.07664 20.6564C8.17597 20.6684 8.25397 20.6704 8.3333 20.6704L8.66264 20.6671C9.3273 20.6671 10.2973 20.5604 11.2266 20.2824C10.296 19.9651 9.11597 19.3484 8.74664 18.2058L8.46397 17.3338H9.14864C8.41597 16.6651 7.9993 15.7351 7.9993 14.7051V13.6251L8.4053 13.8124C8.14197 13.3024 8.00264 12.7878 8.00264 12.3044C8.00264 11.6051 8.18797 10.9211 8.53997 10.3271L9.02597 9.50844L9.62997 10.2431C10.9613 11.8638 13.126 13.6404 15.3326 13.9518V13.3338C15.3326 11.0911 16.978 9.33378 19.0773 9.33378C20.1073 9.33378 20.9993 9.62844 21.6693 10.1878C22.362 10.0138 23.208 9.69244 23.662 9.42511L25.1713 8.53711L24.6333 10.2038C24.608 10.2838 24.576 10.3638 24.5373 10.4451L25.83 9.87244L24.3913 12.0278C24.092 12.4831 23.808 12.9158 23.328 13.3204C23.3313 13.4931 23.3326 13.6744 23.3326 13.7538C23.3326 18.3978 19.828 23.3338 13.3326 23.3338Z" fill="black" />
            <path opacity="0.07" d="M13.3343 22.9999C11.2363 22.9999 9.22693 22.4892 7.82227 21.5992L8.03893 20.9866C8.1536 20.9999 8.24293 21.0032 8.3336 21.0032L8.6676 20.9999C9.74293 20.9999 11.2003 20.7539 12.3176 20.2246C10.9669 19.9732 9.44693 19.2812 9.0656 18.1019L8.92427 17.6666H9.38293C9.70027 17.6666 9.95293 17.6499 10.1596 17.6232C9.02427 17.0532 8.33493 15.9872 8.33493 14.7046V14.1459L8.82627 14.3732C8.9696 14.4506 9.16027 14.5392 9.36827 14.6252C8.7816 13.9519 8.33827 13.1219 8.33827 12.3046C8.33827 11.6652 8.50827 11.0399 8.82893 10.4972L9.0716 10.0879L9.3736 10.4552C10.8463 12.2479 13.2429 14.1579 15.6669 14.3219V13.3332C15.6669 11.2772 17.1656 9.66655 19.0783 9.66655C20.0976 9.66655 20.9576 9.97189 21.5789 10.5519C22.3283 10.3839 23.3023 10.0239 23.8316 9.71255L24.5863 9.26855L24.3176 10.1019C24.1903 10.4979 23.8816 10.8966 23.5196 11.2526C23.6356 11.2072 23.7516 11.1592 23.8663 11.1086L24.9176 10.6426L24.1163 11.8432C23.7943 12.3339 23.5123 12.7632 22.9943 13.1639C22.9983 13.3659 23.0009 13.6486 23.0009 13.7526C23.0009 18.2359 19.6129 22.9999 13.3343 22.9999Z" fill="black" />
            <path d="M24 10C23.366 10.3727 22.2193 10.7707 21.4713 10.9147C20.874 10.2813 20.022 10 19.078 10C17.2647 10 16 11.5367 16 13.3333V14.6667C13.3333 14.6667 10.7333 12.6353 9.11533 10.6667C8.83067 11.1473 8.67067 11.71 8.67067 12.3047C8.67067 13.5173 9.78467 14.748 10.6667 15.3333C10.1287 15.3167 9.11 14.906 8.66667 14.6667V14.7047C8.66667 16.2827 9.774 17.354 11.2747 17.6527C11.0007 17.728 10.6667 18 9.38133 18C9.79867 19.29 11.8967 19.972 13.3333 20C12.2093 20.8713 10.2053 21.3333 8.66667 21.3333C8.40067 21.3333 8.25667 21.348 8 21.318C9.452 22.238 11.48 22.6667 13.3333 22.6667C19.3713 22.6667 22.6667 18.0547 22.6667 13.7533C22.6667 13.612 22.662 13.1387 22.6547 13C23.3 12.5453 23.5613 12.0693 24 11.4133C23.412 11.674 22.6673 11.9187 22 12C22.6787 11.5973 23.7553 10.7587 24 10Z" fill="white" />
            <defs>
                 <linearGradient id="paint0_linear_56_3782" x1="5.63601" y1="5.25566" x2="27.2527" y2="27.667" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#2AA4F4" />
                      <stop offset="1" stopColor="#007AD9" />
                 </linearGradient>
            </defs>
       </svg>

  )
}

export default Twitter