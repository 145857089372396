import React from 'react'

const Services = (props) => {
     return (
          <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_139_34003)">
                    <path d="M15.8842 23.3677C15.695 23.9353 15.1704 24.3137 14.5684 24.3137C14.4738 24.3137 14.362 24.2965 14.2416 24.2707L8.6 22.8603L1.9522 17.8723L1.72 17.7003H0V10.8203H3.698C5.7534 10.8203 7.74 11.5599 9.2966 12.8929L11.825 15.0601C12.1088 15.3009 12.2722 15.6363 12.2808 16.0061C12.298 16.3673 12.1604 16.7199 11.9024 16.9779C11.653 17.2359 11.3176 17.3649 10.9822 17.3649C10.75 17.3649 10.5178 17.3047 10.3028 17.1757L6.88 15.1203C6.88 15.1203 8.5398 18.5001 8.8838 18.8527L10.0018 19.9707C10.1996 20.1599 10.4404 20.3061 10.75 20.4007L14.9554 21.6047C15.3252 21.7165 15.6176 21.9487 15.7982 22.2927C15.9788 22.6281 16.0046 22.9979 15.8842 23.3677Z" fill={props.fill} />
                    <path d="M40.7124 27.5122C40.0691 26.4733 39.56 25.6537 39.56 24.6518C39.56 22.2326 41.5836 21.1628 41.6644 21.1215C42.0892 20.9091 42.2612 20.3922 42.0488 19.9674C41.8355 19.5425 41.3204 19.3697 40.8947 19.583C40.7709 19.6449 37.84 21.1507 37.84 24.6518C37.84 26.143 38.5254 27.249 39.2512 28.4186C40.2015 29.952 41.28 31.6909 41.28 34.9718C41.28 38.9966 38.2089 39.8833 36.7435 40.0768C37.8434 38.9338 38.7 37.1794 38.7 34.6192C38.7 25.8042 30.3408 21.8258 30.3408 16.5368C30.3408 9.30424 26.6256 6.5918 26.6256 6.5918L22.9104 10.2081C19.3594 10.2081 19.1952 13.8244 15.48 15.633C15.48 19.2493 21.1904 20.1531 21.1904 20.1531V22.8656C21.1904 30.0982 24.9056 29.1943 23.048 36.4269C21.0863 36.4269 19.3328 38.0962 19.3328 40.0432C19.3328 41.0167 20.2091 41.8518 21.1904 41.8518H32.68H33.1272H36.0314C38.442 41.8518 43 40.4139 43 34.9718C43 31.1998 41.7315 29.1548 40.7124 27.5122Z" fill={props.fill} />
               </g>
               <defs>
                    <clipPath id="clip0_139_34003">
                         <rect width="43" height="43" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
               </defs>
          </svg>

     )
}

export default Services