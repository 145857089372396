import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import LeftArrow from '../../assets/Icons/LeftArrow'
import BlueButton from '../UI/Buttons/BlueButton'
import Ring from '../UI/Loader/Ring'
import CountryIcon from '../../assets/Icons/Country'
import Uae from '../../assets/Icons/Uae'
import axios from 'axios'
import { config, url } from '../Helpers/Help'
import alert from '../../Helpers/Modal'

const Country = (props) => {
     const [isLoading, setIsLoading] = useState('')
     const [cities, setCities] = useState([])
     const [isCountry, setIsCountry] = useState([])
     const [isSelected, setIsSelect] = useState()
     const [isCountryID, setIsCountryID] = useState('')
     const [isCityID, setIsCityID] = useState()
     const [errorMessageAlert, setErrorMessageAlert] = useState('')

     // Get Countries from localStorage
     useEffect(() => {
          if (localStorage.__authtk__awasell_secure) {
               const data = localStorage.getItem('__data_aW__a_start')
               const dataArr = JSON.parse(data)
               setIsCountry(dataArr.countries)
          }
     }, [])

     // Get user Information from localStorage
     useEffect(() => {
          if (localStorage.__User_aW__a_Info) {
               const userData = localStorage.getItem('__User_aW__a_Info')
               const userInfo = JSON.parse(userData)
               setIsCountryID(userInfo.country)
               setIsCityID(userInfo.city)
          }
     }, []);

     // send user ountry to server
     const sendCountry = () => {
          axios.post(url + 'users/update',
               //  Data
               {
                    country_id: isCountryID.id,
                    city_id: isCityID
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setIsCountryID()
                    setIsCityID()
                    localStorage.setItem('__User_aW__a_Info', JSON.stringify(response.data.data.user))
                    props.onCloseSidebar()
                    alert({
                         title: `${t('Alert')}`,
                         text: `${t('Thank you, your location has been updated')}`,
                         icon: 'success',
                         timer: 3500
                    })
               })
               // Check Error
               .catch(error => {
                    setErrorMessageAlert(error.response.data.message)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 3500
                    })
               })

     }

     return (
          <div className={`fixed shadow-xl top-0 min-w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onBack} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> {t('Country')} </h3>
               <li className='mb-6 cursor-pointer flex items-center justify-between gap-4' >
                    <CountryIcon /> <span className='w-1/2'>{t('Country')}</span>
                    {isCountryID && <span className='flex items-center justify-end gap-4 w-1/2'> <img src={isCountryID.flag} alt={isCountryID.name} className='mx-2 w-8' /> {isCountryID.name}</span>}
               </li> 
               <div className="flex w-[50rem] flex-wrap items-start justify-center">
                    {(isCountry && isCountry.length > 0) &&
                         <div className='w-1/2'>
                              <h3 className=' text-left font-bold mt-4 pb-2'>{t('Your Country')}</h3>
                              <ul className={`${isSelected && 'bg-gray-100 '} max-h-[70vh] overflow-y-auto overflow-x-hidden no-scroll flex justify-start items-start flex-wrap cursor-pointer`}>
                                   {
                                        isCountry.map((item, index) => (
                                             <li key={index} className={`py-2 my-4 relative border w-full flex items-center justify-start
                                              ${isSelected === item.id ? 'text-gray-100 bg-blue rounded-r-full' : 'text-blue border-blue hover:text-white hover:bg-blue rounded-lg'}`}
                                                  onClick={() => { setIsCountryID(item); setCities(item.cities); setIsSelect(item.id) }}>
                                                  <img src={item.flag} alt={item.name} className='mx-2 w-8' />
                                                  {item.name}
                                             </li>
                                        ))
                                   }
                              </ul>
                         </div>
                    }

                    {(cities && cities.length > 0) &&
                         <div className='w-1/2'>
                              <h3 className='text-left font-bold mt-4 pb-2'>{t('Your City')}</h3>
                              <ul className='max-h-[70vh] overflow-y-auto no-scroll overflow-x-hidden px-4 cursor-pointer bg-gray-100'>
                                   {
                                        cities.map((item, index) => (
                                             <li key={index} className={`p-2 my-4 relative border-b w-full flex items-center justify-start
                                              ${isCityID === item.id ? 'text-gray-200 bg-blue rounded-r-full' : 'text-blue hover:text-white hover:bg-blue '}`}
                                                  onClick={() => { setIsCityID(item.id); console.log(item.id) }}>{item.name}</li>
                                        ))
                                   }
                              </ul>
                         </div>
                    }
               </div>
               {errorMessageAlert && <p className='text-red'>{errorMessageAlert}</p>}

               <BlueButton onClick={sendCountry} className='w-full text-[1.5rem] py-4 px-5 mt-8 hover:bg-mint'>
                    {isLoading ? <Ring /> : `${t('Submit')}`}
               </BlueButton>
          </div >
     )
}

export default Country