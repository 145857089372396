import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import VideoCard from "../../Components/UI/Cards/Video-card";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { config, url } from "../../Components/Helpers/Help";
import ProfileUser from "../../Components/Seactions/ProfileUser";
import { shortVideosSlider } from "../../Components/Helpers/SlidersOptions";
import Search from "../../Components/Search/Search";
import PublicCategories from "../../Components/Seactions/PublicCategories";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ShortVideoCard from "../../Components/UI/Cards/ShortVideoCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import PreLoader from "../../Components/UI/PreLoader";
import { t } from "i18next";
import NotItems from "../../Components/UI/NotItems";

const Videos = () => {
  const [data, setData] = useState();
  const [allVideos, setVideos] = useState();
  const params = useLocation();
  const [profile, setProfile] = useState(false);
  const [shortVideo, setShortVideos] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  console.log(params)
  useEffect(() => {
    if (params.pathname.match("/profile/videos")) {
      setProfile(true);
      setIsLoading(true)
      axios(url + "videos/my?type=1", config)
        .then((response) => {
          console.log(response.data.data);
          setShortVideos(response.data.data);
        })
      axios(url + `videos/my?type=2`, config)
        .then((response) => {
          console.log(response.data.data);
          setVideos(response.data.data);
          setIsLoading(false)
        })
    } else {
      setIsLoading(true)
      axios(url + "videos?type=1&limit=10&offset=1", config)
        .then((response) => {
          console.log(response.data.data.data);
          setShortVideos(response.data.data.data);
        })
      axios(url + `videos?type=2&limit=10&offset=${page}`, config)
        .then((response) => {
          console.log(response.data.data);
          setVideos(response.data.data.data);
          setIsLoading(false)
        })
    }
  }, [params]);

  const onLoadMore = () => {
    if (params.pathname.match("/profile/videos")) return
    setIsLoading(true)
    axios.get(url + `videos?type=2&limit=10&offset=${page + 1}`, config).then(response => {
      setIsLoading(false)
      if (response.data.data.data.length === 0) {
        setHasMore(false);
      } else {
        setVideos(prevItems => [...prevItems, ...response.data.data.data]);
        setPage(prevPage => prevPage + 1);

      }
    });
  };

  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem("__User_aW__a_Info");
      setData(JSON.parse(data));
    }
  }, []);

  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        {profile &&
          <ProfileUser myProfile={true}
            id={data.id}
            follow={false}
            personal_picture={data.personal_picture}
            f_name={data.f_name}
            nickname={data.nickname}
            l_name={data.l_name}
            posts={data.posts}
            followers={data.followers}
            following={data.following}
          />
        }


        {!profile && (
          <>
            <div className="my-12">
              <PublicCategories />
            </div>
            <section className="mt-12">
              <Search />
            </section>
          </>
        )}
        <div className="my-24">
          {shortVideo && (
            <section className="">
              <Slider {...shortVideosSlider}>
                {shortVideo.map((item, index) => (
                  <ShortVideoCard
                    slug={item.slug}
                    key={item.id}
                    id={item.id}
                    likes_count={item.likes_count}
                    comment_count={item.comment_count}
                    description={item.content}
                    poster={item.thumbnail}
                    userImage={item.user && item.user.personal_picture}
                    userId={item.user && item.user.id}
                    name={item.user && item.user.f_name}
                    video={item.file}
                    location={(item.user && item.user.city) && item.user.city.name} />
                ))}
              </Slider>
            </section>
          )}
        </div>

        {allVideos && (
          <section className="">
            <InfiniteScroll
              dataLength={allVideos.length}
              next={onLoadMore}
              hasMore={hasMore}
              endMessage={<p className='mt-12 flex items-center justify-center'> <b> {t('You have seen it all')}</b> </p>}
              loader={!params.pathname.match("/profile/videos") && <PreLoader />}
            >
              <div className="row flex items-start justify-center flex-wrap">
                {allVideos.map((item, index) => (
                  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3" key={index}>
                    <VideoCard  views_count={item.views_count} 
                      slug={item.slug}
                      key={item.id}
                      id={item.id}
                      likes_count={item.likes_count}
                      comment_count={item.comment_count}
                      description={item.content}
                      poster={item.thumbnail}
                      userImage={item.user && item.user.personal_picture}
                      name={item.user && item.user.f_name}
                      video={item.file}
                      badge={item.user && item.user.badge}
                      userId={item.user && item.user.id}
                      rate={item.user && item.user.avg_rate_int}
                      is_verified={item.user && item.user.is_verified}
                      location={(item.user && item.user.city) && item.user.city.name} />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
            {
              !isLoading && allVideos.length === 0 &&
              <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                <NotItems />
              </div>
            }
          </section>
        )}
      </main>
    </Layout>
  );
};

export default Videos;
