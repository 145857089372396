import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../Components/Layout";
import BlueButton from "../Components/UI/Buttons/BlueButton";
import Input from "../Components/UI/Input";
import Ring from "../Components/UI/Loader/Ring";
import bg from "../assets/images/auth.jpg";
import { t } from "i18next";
import Twitter from "../assets/Icons/Twitter";
import Facebook from "../assets/Icons/Facebook";
import Instagram from "../assets/Icons/Instagram";
import Linkedin from "../assets/Icons/Linkedin";
import axios from "axios";
import { config, url } from "../Components/Helpers/Help";
import ButtonLoading from "../Components/UI/ButtonLoading";
import alert from "../Helpers/Modal";

const ContactUs = () => {
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [links, setLinks] = useState();

  useEffect(() => {
    if (localStorage.websiteContent) {
      const content = localStorage.getItem("websiteContent");
      setLinks(JSON.parse(content));
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!name) {
      setNameError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!email || !email.includes("@")) {
      setemailError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!Phone) {
      setphoneError(true);
      window.scrollTo(0, 0);
      return;
    }
    if (!message) {
      setMessageError(true);
      window.scrollTo(0, 0);
      return;
    }

    setLoading(true);
    axios
      .post(
        url + "contact-us",
        //  Data
        {
          name: name,
          phone: Phone,
          email: email,
          message: message,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setLoading(false);
        alert({
          title: `${t("Thank you for send")}`,
          text: `${t(`Done`)}`,

          icon: "success",
        });
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        setLoading(false);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2500,
        });
      });
  };

  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-[80vh] flex items-center justify-center">
        <div className="flex items-center justify-center flex-wrap">
          <div id="" className="container mx-auto">
            <div className="row flex justify-center items-center flex-wrap mt-28">
              <div className="w-full">
                <h3 className="w-full mb-6 block text-center text-[1.8rem] font-bold">
                  {t("Contact Us")}
                </h3>
              </div>
              <div className="w-full md:w-10/12 lg:w-9/12 xl:w-8/12">
                <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl">
                  <div className="w-full md:w-1/2 flex items-center">
                    <div className="LoginContent flex items-start justify-center flex-wrap  p-4 md:p-8">
                      <form
                        onSubmit={submitHandler}
                        action=""
                        className="mt-4 flex flex-wrap items-center justify-between"
                      >
                        <Input
                          onFocus={() => setNameError(false)}
                          input_className={`${nameError && "border-red"}`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder={t("Your Name")}
                          id="name"
                          label={t("Your Name")}
                        />
                        <Input
                          onFocus={() => setemailError(false)}
                          input_className={`${emailError && "border-red"}`}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder={t("Your Email")}
                          id="Email"
                          label={t("Email")}
                        />
                        <Input
                          onFocus={() => setphoneError(false)}
                          input_className={`${phoneError && "border-red"}`}
                          value={Phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="number"
                          placeholder={t("Your Phone")}
                          id="Phone"
                          label={t("Phone")}
                        />
                        <label className={`font-medium text-[1.5]`}>
                          {" "}
                          {t("Write Your message")}{" "}
                        </label>
                        <textarea
                          onFocus={() => setMessageError(false)}
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                          name=""
                          id=""
                          cols="50"
                          className={`w-full h-48 resize-none text-[1.5rem] rounded-lg border-2 border-gold py-3 px-5 ${
                            messageError && "border-red"
                          }`}
                          placeholder={t("Your message")}
                        ></textarea>
                        <BlueButton
                          className={`w-full text-[1.5rem] p-5 mt-8 hover:bg-mint ${
                            loading && "cursor-not-allowed"
                          }`}
                          disabled={loading}
                          onClick={submitHandler}
                        >
                          {loading ? <ButtonLoading /> : `${t("Send")}`}
                        </BlueButton>
                      </form>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 flex items-center lg:px-8 relative overflow-hidden">
                    <img
                      src={bg}
                      alt=""
                      className="w-full min-h-full object-contain absolute top-0 left-0 max-h-full z-[1] opacity-5"
                    />
                    <div className="flex items-center justify-center flex-col w-full z-[2]">
                      <>
                        {links && (
                          <a
                            href={`tel:` + links.phone}
                            className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "
                          >
                            {t("Phone")}: {links.phone}
                          </a>
                        )}
                        {links && (
                          <a
                            href={`mailto:` + links.email}
                            className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "
                          >
                            {t("Email")}: {links.email}
                          </a>
                        )}
                        {links && (
                          <a
                            href="https://www.google.com/maps/place/Brain+Click+Ads/@25.1859067,55.2580765,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f69d6bebf924f:0xc9891260a62ede50!8m2!3d25.1859019!4d55.2602652"
                            className="w-full mb-6 block text-center mt-5 text-[1.6rem] font-medium "
                          >
                            {links.address}
                          </a>
                        )}
                        <h3 className="w-full block text-center mt-12 text-[1.6rem] font-bold ">
                          {t("Social media")}:
                        </h3>
                        {links && (
                          <ul className="w-full flex items-center justify-center flex-wrap mb-16 lg:mb-0 mt-4 gap-4">
                            <a href={links.twitter}>
                              <Twitter />
                            </a>
                            <a href={links.facebook}>
                              <Facebook />
                            </a>
                            <a href={links.instagram}>
                              <Instagram />
                            </a>
                            <a href={links.linkedin}>
                              <Linkedin />
                            </a>
                          </ul>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default ContactUs;
