import React from 'react'

const Report = (props) => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.33303 19.3333C6.76036 19.3333 4.66636 17.24 4.66636 14.6666C4.66636 12.0933 6.76036 9.99996 9.33303 9.99996C11.9057 9.99996 13.9997 12.0933 13.9997 14.6666C13.9997 17.24 11.9057 19.3333 9.33303 19.3333ZM9.33236 29.334C5.1277 29.334 1.95703 27.4253 1.95703 24.8946V23.506C1.9577 22.308 2.9977 21.3333 4.27703 21.3333H14.3884C15.667 21.3333 16.7077 22.308 16.7077 23.506V24.8946C16.7077 27.4253 13.537 29.334 9.33236 29.334ZM27.6664 2.66663H18.333C17.0464 2.66663 15.9997 3.71329 15.9997 4.99996V17.6666C15.9997 18.0713 16.243 18.436 16.617 18.5906C16.7404 18.642 16.871 18.6666 16.9997 18.6666C17.2597 18.6666 17.515 18.5653 17.707 18.374L20.747 15.3333H27.6664C28.953 15.3333 29.9997 14.2866 29.9997 13V4.99996C29.9997 3.71329 28.953 2.66663 27.6664 2.66663ZM22.9997 13.3333C22.4477 13.3333 21.9997 12.8853 21.9997 12.3333C21.9997 11.7813 22.4477 11.3333 22.9997 11.3333C23.5517 11.3333 23.9997 11.7813 23.9997 12.3333C23.9997 12.8853 23.5517 13.3333 22.9997 13.3333ZM23.9997 8.99996C23.9997 9.55263 23.5517 9.99996 22.9997 9.99996C22.4477 9.99996 21.9997 9.55263 21.9997 8.99996V5.66663C21.9997 5.11396 22.4477 4.66663 22.9997 4.66663C23.5517 4.66663 23.9997 5.11396 23.9997 5.66663V8.99996Z" fill={props.fill} />
          </svg>
     )
}

export default Report