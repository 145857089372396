import React from 'react'

const Linkedin = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M16 2.66675C8.63599 2.66675 2.66666 8.63608 2.66666 16.0001C2.66666 23.3641 8.63599 29.3334 16 29.3334C23.364 29.3334 29.3333 23.3641 29.3333 16.0001C29.3333 8.63608 23.364 2.66675 16 2.66675Z" fill="#0078D4" />
               <path opacity="0.05" d="M20 23.3333V17.3333C20 16.598 19.402 16 18.6667 16C17.9313 16 17.3333 16.598 17.3333 17.3333V23.3333H13.3333V12H17.3333V12.6847C18.0267 12.2393 18.8347 12 19.6667 12C22.056 12 24 13.944 24 16.3333V23.3333H20ZM8.66666 23.3333V12H10.644C9.49799 12 8.66666 11.1587 8.66666 9.99933C8.66666 8.84067 9.51132 8 10.674 8C11.8047 8 12.6427 8.83467 12.6667 9.986C12.6667 11.1553 11.822 12 10.6587 12H12.6667V23.3333H8.66666Z" fill="black" />
               <path opacity="0.07" d="M20.3333 22.9999V17.3333C20.3333 16.4146 19.586 15.6666 18.6667 15.6666C17.7473 15.6666 17 16.4146 17 17.3333V22.9999H13.6667V12.3333H17V13.3559C17.7267 12.7046 18.6747 12.3333 19.6667 12.3333C21.8727 12.3333 23.6667 14.1273 23.6667 16.3333V22.9999H20.3333ZM9 22.9999V12.3333H12.3333V22.9999H9ZM10.644 11.6666C9.69133 11.6666 9 10.9653 9 9.99925C9 9.03392 9.704 8.33325 10.674 8.33325C11.6313 8.33325 12.3133 9.01525 12.3333 9.99259C12.3333 10.9653 11.6287 11.6666 10.6587 11.6666H10.644Z" fill="black" />
               <path d="M9.33334 12.6667H12V22.6667H9.33334V12.6667ZM10.6587 11.3334H10.644C9.84801 11.3334 9.33334 10.7401 9.33334 9.99941C9.33334 9.24275 9.86401 8.66675 10.674 8.66675C11.4853 8.66675 11.9847 9.24275 12 9.99941C12 10.7401 11.4853 11.3334 10.6587 11.3334ZM23.3333 16.3334C23.3333 14.3081 21.692 12.6667 19.6667 12.6667C18.4253 12.6667 17.33 13.2854 16.6667 14.2294V12.6667H14V22.6667H16.6667V17.3334C16.6667 16.2287 17.562 15.3334 18.6667 15.3334C19.7713 15.3334 20.6667 16.2287 20.6667 17.3334V22.6667H23.3333C23.3333 22.6667 23.3333 16.6141 23.3333 16.3334Z" fill="white" />
          </svg>

     )
}

export default Linkedin