import React from 'react'

const PasswordEye = () => {
     return (
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_41_4843)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.749951 14.6062C0.749951 14.6062 7.00073 7.19995 15 7.19995C22.9992 7.19995 29.25 14.6062 29.25 14.6062C29.4468 14.8312 29.4468 15.1687 29.25 15.3937C29.25 15.3937 22.9992 22.8 15 22.8C7.00073 22.8 0.749951 15.3937 0.749951 15.3937C0.553076 15.1687 0.553076 14.8312 0.749951 14.6062ZM19.4812 9.22495C18.0843 8.72573 16.5773 8.39995 15 8.39995C13.4179 8.39995 11.9015 8.72339 10.5 9.22495C9.56245 10.2843 8.99995 11.6695 8.99995 13.2C8.99995 16.521 11.6789 19.2 15 19.2C18.321 19.2 21 16.521 21 13.2C21 11.6695 20.4187 10.2843 19.4812 9.22495ZM7.79995 13.2C7.79995 12.0937 8.06245 11.0437 8.51245 10.1062C5.11636 11.8289 2.66714 14.325 2.0437 15C3.0187 16.0546 8.41636 21.6 15 21.6C21.5835 21.6 26.9812 16.0546 27.9562 15C27.3328 14.3273 24.8953 11.8289 21.5062 10.1062C21.9492 11.0437 22.1999 12.0984 22.1999 13.2C22.1999 17.1703 18.9703 20.4 15 20.4C11.0296 20.4 7.79995 17.1703 7.79995 13.2ZM12 13.2C12 11.5429 13.3429 10.2 15 10.2C16.657 10.2 18 11.5429 18 13.2C18 14.857 16.657 16.2 15 16.2C13.3429 16.2 12 14.857 12 13.2Z" fill="url(#paint0_linear_41_4843)" />
               </g>
               <defs>
                    <linearGradient id="paint0_linear_41_4843" x1="115.895" y1="-39.6549" x2="24.3274" y2="129.365" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#0F70B7" />
                         <stop offset="0.24" stopColor="#0F70B7" />
                         <stop offset="0.65" stopColor="#0F70B7" />
                         <stop offset="1" stopColor="#0F70B7" />
                    </linearGradient>
                    <clipPath id="clip0_41_4843">
                         <rect width="30" height="30" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     )
}

export default PasswordEye