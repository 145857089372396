import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import LeftArrow from '../../assets/Icons/LeftArrow'
import LocationIcon from '../../assets/Icons/Location'
import Input from '../UI/Input'
import {
     LoadScript,
     GoogleMap,
     StandaloneSearchBox,
     Marker
} from '@react-google-maps/api';
import BlueButton from '../UI/Buttons/BlueButton'
import { config, url } from '../Helpers/Help'
import axios from 'axios'
import alert from '../../Helpers/Modal'
const MAP_OPTIONS = {
     scrollwheel: true,
}


const Location = (props) => {
     const [userInfo, setUserInfo] = useState()
     const [mapref, setMapRef] = useState(null);
     const [pickLocation, setPickLocation] = useState('');
     const [defaultLocation, setDefaultLocation] = useState({ lat: 25.270012722064614, lng: 55.258894247149705 });
     const [zoomSize, setZoomSize] = useState(14);
     const key = "AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc";
     const lib = ['places'];

     // Get user Information from localStorage
     useEffect(() => {
          if (localStorage.__User_aW__a_Info) {
               const userData = localStorage.getItem('__User_aW__a_Info')
               const userInfo = JSON.parse(userData)
               setUserInfo(userInfo)
               setLat(userInfo.lat)
               setLng(userInfo.lng)
               setDefaultLocation({ lat: +userInfo.lat, lng: +userInfo.lng })
          }
     }, []);

     const displayLocation = (latitude, longitude) => {
          var request = new XMLHttpRequest();
          var method = 'GET';
          var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true&key=AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc';
          var async = true;

          request.open(method, url, async);
          request.onreadystatechange = function () {
               if (request.readyState == 4 && request.status == 200) {
                    var data = JSON.parse(request.responseText);
                    var address = data.results[0];
                    setPickLocation(address.formatted_address)
               }
          };
          request.send();
     };
     const handleCenterChanged = (e) => {
          if (mapref) {
               const newCenter = mapref.getCenter();
               setLat(newCenter.lat());
               setLng(newCenter.lng());
               displayLocation(newCenter.lat(), newCenter.lng())
               document.getElementById("searchMap").value = '';
               console.log(newCenter)
          }
     };


     const [searchBox, setSearchBox] = useState(null);
     const onPlacesChanged = () => {
          var lk = searchBox.getPlaces();
          setZoomSize(15)
          setDefaultLocation({ lat: lk[0].geometry.location.lat(), lng: lk[0].geometry.location.lng() });
          setPickLocation(lk[0].formatted_address)
          setLat(lk[0].geometry.location.lat());
          setLng(lk[0].geometry.location.lng());
     }
     const onSBLoad = ref => {
          setSearchBox(ref);
     };
     const handleOnLoad = map => {
          setMapRef(map);
     };


     const [lat, setLat] = useState();
     const [lng, setLng] = useState();
     const [errorMessageAlert, setErrorMessageAlert] = useState('')

     // send user ountry to server
     const sendLocation = () => {
          axios.post(url + 'users/update',
               //  Data
               {
                    lat: lat,
                    lng: lng

               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setLat()
                    setLng()
                    localStorage.setItem('__User_aW__a_Info', JSON.stringify(response.data.data.user))
                    props.onCloseSidebar()
                    alert({
                         title: `${t('Alert')}`,
                         text: `${t('Thank you for sharing your location')}`,
                         icon: 'success',
                         timer: 3500
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 3500
                    })
               })

     }



     return (
          <div className={`fixed shadow-xl top-0 min-w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onBack} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> {t('Location')} </h3>
               {(userInfo && userInfo.country) && <li className='mb-6 cursor-pointer flex items-center justify-between gap-4'><LocationIcon /> <span className='w-1/2'>{t('Location')}</span> <span className='flex items-center justify-end gap-4 w-1/2'>{userInfo.country.name}</span></li>}
               {/* <Input div_className='my-8' label_className='mb-4 flex' value={location} onChange={(e) => setLocation(e.target.value)} type='text' placeholder={t('My location')} id='Location' label={t('My location')} /> */}

               <h3 className="mt-12 font-bold mb-4">{t('Select Your Location')}</h3>
               <LoadScript googleMapsApiKey={key} libraries={lib}>
                    <div className='relative '>
                         {/* <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Google_Maps_pin.svg/1170px-Google_Maps_pin.svg.png'} className="absolute top-1/2 left-1/2 -translate-x-1/2 w-12 z-40" alt="" /> */}
                         <GoogleMap center={defaultLocation} zoom={zoomSize} onLoad={handleOnLoad} onDragEnd={handleCenterChanged} options={MAP_OPTIONS} mapContainerStyle={{ width: '100%', height: '40rem' }} >
                              <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onPlacesChanged} >
                                   <input id="searchMap" type="text" placeholder={t('Choose your location')} style={{ boxSizing: `border-box`, border: `1px solid #ccc`, width: `100%`, padding: `20px`, paddingLeft: `5px`, borderRadius: `0`, boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, fontSize: `14px`, outline: `none`, textOverflow: `ellipses`, position: "absolute", left: "0%", top: "0rem", }} />
                              </StandaloneSearchBox>
                              {userInfo && <Marker position={{ lat: +userInfo.lat, lng: +userInfo.lng }} />}
                         </GoogleMap>
                    </div>
               </LoadScript>
               {errorMessageAlert && <p className='text-red'>{errorMessageAlert}</p>}
               <BlueButton onClick={sendLocation} className='w-full p-6 mt-12'> {t('Submit')} </BlueButton>

          </div>
     )
}

export default Location