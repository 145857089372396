import React from 'react'

const AdsIcon = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_1602_8421)">
                    <path d="M19.7109 0.884974C19.5489 0.972781 19.396 1.08003 19.2567 1.20613L10.1946 9.42482L15.8007 22.3925L27.9972 21.4209C28.7455 21.361 29.4232 20.9411 29.8111 20.2977C30.1977 19.6548 30.2516 18.859 29.9535 18.1693L27.5721 12.6607C28.3111 11.9098 28.566 10.7599 28.1222 9.7333C27.6784 8.70672 26.6661 8.10469 25.6128 8.1286L22.9668 2.00808C22.6687 1.31844 22.0526 0.812198 21.3188 0.653706C20.7678 0.534575 20.1968 0.621554 19.7109 0.884974ZM8.54585 10.6511L3.8594 13.1197C2.17394 14.0074 1.44674 16.0703 2.20228 17.8179L4.21116 22.4648C4.9466 24.166 6.86379 25.0483 8.62584 24.5226L11.4619 29.4913C12.0705 30.5581 13.3808 30.9953 14.5086 30.5077C15.7269 29.981 16.2886 28.5613 15.7619 27.343L8.54585 10.6511Z" stroke="#1E1E1E" />
               </g>
               <defs>
                    <clipPath id="clip0_1602_8421">
                         <rect width="32" height="32" fill="white" />
                    </clipPath>
               </defs>
          </svg>

     )
}

export default AdsIcon