import React, { useEffect, useState } from "react";
import BlueButton from "../UI/Buttons/BlueButton";
import SearchIcon from "../../assets/Icons/SearchIcon";
import SearchIcons from "../../assets/Icons/Search";

import Select from "react-select";
import {
  CategoriesData,
  LocationData,
  SortbyData,
  TypeData,
} from "../Helpers/Data";
import PublicCategories from "../Seactions/PublicCategories";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { t } from "i18next";
import axios from "axios";
import { config, url } from "../Helpers/Help";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const [category, setCategory] = useState("");
  // const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([])
  const [childs, setChilds] = useState([])
  // Get Categories from localStorage
  useEffect(() => {
    if (localStorage.__data_aW__a_start) {
      const data = localStorage.getItem('__data_aW__a_start')
      const dataArr = JSON.parse(data)
      console.log(dataArr)
      setCategories(dataArr.categories) 
    }
  }, [])

  // Get Childes of Categories by ID
  useEffect(() => {
    if (category) {
      const item = categories.find(item => item.id === category);
      setChilds(item.childs)
    }

  }, [category, categories])


  const handleSearch = (e) => {
    window.location.href = `/search?search=${search}&category=${category}&type=${type}&sort_by=${sort}`
    
  };

  return (
    <LazyLoadComponent delaytime='500'>
      {
        window.innerWidth > 767 &&
        <section className="searchFilter">
          <div className="row flex items-center justify-center">
            <div className="w-full xl:w-10/12">
              <div className="search row flex items-center flex-wrap text-[1.5rem] font-semibold justify-start border-2 border-blue bg-white rounded-2xl md:px-2 relative" >
                <select onChange={(e) => { setCategory(e.target.value) }} className={`py-6 border-0 rounded-none rtl:pl-2 ltr:pr-2 rtl:border-l-2 ltr:border-r-2 border-gray-200 mb-4 md:mb-0 md:w-1/5  bg-transparent w-full text-[1.5rem]  px-5  `}>
                  <option value={''}> {t('Choose Category')}. . . </option>
                  {
                    categories &&
                    categories.map(item => (
                      <option value={item.id} key={item.id}> {item.title} </option>
                    ))
                  }
                </select>
                <select onChange={(e) => { setType(e.target.value) }} className={`py-6 border-0 rounded-none rtl:pl-2 ltr:pr-2 rtl:border-l-2 ltr:border-r-2 border-gray-200 mb-4 md:mb-0 md:w-1/5  bg-transparent w-full text-[1.5rem]  px-5  `}>
                  <option value={''}> {t('Choose Type')}. . . </option>
                  {
                    childs &&
                    childs.map(item => (
                      <option value={item.id} key={item.id}> {item.title} </option>
                    ))
                  }
                </select>
                <select onChange={(e) => { setSort(e.target.value) }} className={`py-6 border-0 rounded-none rtl:pl-2 ltr:pr-2 rtl:border-l-2 ltr:border-r-2 border-gray-200 mb-4 md:mb-0 md:w-1/5  bg-transparent w-full text-[1.5rem]  px-5  `}>
                  <option value={''}> {t('Sort by')}. . . </option>
                  <option value={'id-desc'}> {t('Latest')}  </option>
                  <option value={'id-asc'}> {t('Oldesr')}  </option>

                </select>
                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-full md:w-1/5 bg-transparent px-4 md:px-8"
                  placeholder={t('Search')}
                />

                {/*               
              <Select
                onChange={(e) => setType(e.value)}
                className="mb-4 bg-transparent w-full md:mb-0 md:w-1/6 md:py-4"
                classNamePrefix="select"
                placeholder={"Type"}
                options={TypeData}
              />
              <Select
                onChange={(e) => setSort(e.value)}
                className="mb-4 bg-transparent w-full md:mb-0 md:w-1/6 md:py-4"
                classNamePrefix="select"
                placeholder={"Sort by"}
                options={SortbyData}
              /> */}

                <BlueButton onClick={handleSearch} className="md:absolute mt-6 -mb-2 md:mt-0 top-0 rtl:-left-1 ltr:-right-1 h-full w-full md:w-auto border border-blue md:z-10 flex items-center justify-center py-4 md:px-8 gap-2">
                  {/* <SearchIcon /> */}
                    <SearchIcons fill="#fff" />
                   {/* {t('Search')} */}
                </BlueButton>
              </div>
            </div>
          </div>
          {/* <div className="mt-12">
          <PublicCategories />
        </div> */}
        </section>
      }
    </LazyLoadComponent>
  );
};

export default Search;
