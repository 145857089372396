import { t } from 'i18next'
import React, { useState } from 'react'
import Input from '../../UI/Input'
import BlueButton from '../../UI/Buttons/BlueButton'

const NewPassword = (props) => {
     const [password, setPassword] = useState('')
     const [rePassword, setRePassword] = useState('')
     const [errorMessageAlert, setErrorMessageAlert] = useState('')

     const submitHandler = (e) => {
          e.preventDefault()
          if (password.length < 8 || rePassword.length < 8) {
               setErrorMessageAlert('Password must be more than 8 characters')
               setTimeout(() => {
                    setErrorMessageAlert('')
               }, 2000);
               return
          }
          if (password !== rePassword) {
               setErrorMessageAlert('The password must be the same characters')
               setTimeout(() => {
                    setErrorMessageAlert('')
               }, 2000);
               return

          }
          props.onChangePassword(password, rePassword)
     }
     return (
          <>
               <h3 className='w-full font-bold text-[3.4rem] Abril tracking-widest'>{t('New password')}</h3>
               <p className='w-full font-light text-[1.5] mb-4'>{t('Create New password.')}</p>
               <h3 className='text-red  font-bold text-left w-full ' > {errorMessageAlert}</h3>
               <form onSubmit={submitHandler} action="" className='mt-4 flex flex-wrap items-center justify-between'>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder={t('Your password')} id='password' label={t('Password')} />
                    <Input value={rePassword} onChange={(e) => setRePassword(e.target.value)} type='password' placeholder={t('Your password')} id='repassword' label={t('Re-New Password')} />
                    <BlueButton className={`w-full text-[1.5rem] py-4 px-5 mt-8 ${props.isLoading && 'cursor-not-allowed'}`} disbled={props.isLoading}> 
                         {t('Submit')}
                    </BlueButton>
               </form>
          </>
     )
}

export default NewPassword