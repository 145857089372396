import { t } from 'i18next'
import React from 'react'
import cat from '../../../assets/images/cat.png'
import BlueButton from '../Buttons/BlueButton'
import RedButton from '../Buttons/RedButton'

const RequestCard = (props) => {
     const { img, created_at, reject_reason, type, age, weight, qrCode, status } = props

     return (
          <div className='relative overflow-hidden p-4 mb-2 shadow-xl bg-white rounded-2xl '>
               <div className="flex justify-between items-start w-full">
                    <div className="img w-2/6 h-40 rounded-xl relative overflow-hidden flex items-center justify-center">
                         <img className='max-w-full max-h-full rounded-2xl relative overflow-hidden border border-blue' src={img} alt="" />
                    </div>
                    <div className="data w-4/6">
                         <ul className='px-4 leading-[2] no-wrap '>
                              <li>{t('Type')} : <b>{type}</b> </li>
                              <li>{t('Request Date')}  : <b>{created_at}</b> </li>
                              <li>{t('Status')}  : <b className=''>{status === '0' ? <span className='text-yellow-500'>{t('pending')}</span> : (status === '-1' ? <span className='text-red-500'>{t('Rejected')}</span> : <span className='text-green-500'>{t('Accepted')}</span>)}</b> </li>

                              {reject_reason && <li>{t('reject reason')}  : <b>{reject_reason}</b> </li>}
                         </ul>
                         {/* <div className="flex gap-4 px-4">
                              <BlueButton className='px-6 lg:px-12 py-4'>{t('Edit')}</BlueButton>
                              <RedButton className='px-6 lg:px-12 py-4 bg-red text-white'>{t('Canel')}</RedButton>
                         </div> */}
                    </div>
               </div>
          </div>
     )
}

export default RequestCard