import React, { useContext, useState } from 'react'
import { Player } from 'video-react';
import Like from '../../../assets/Icons/Like';
import Comment from '../../../assets/Icons/Comment';
import Share from '../../../assets/Icons/Share';
import '../../../Styles/VideoCard.scss'
import { Link } from 'react-router-dom';
import LineHeart from '../../../assets/Icons/LineHeart';
import Context from '../../../Store/Store';
import { t } from 'i18next';

const ShortVideoCard = (props) => {
     const { id, description, userImage, name, location, poster, video, slug, live, userId, liveLink } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);
     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeVideoFavorite(id, slug)
          } else {
               ctx.addVideoFavorite(id, slug);
          }
     }
     return (
          <div className='dir-rtl mx-2 md:mx-4 relative overflow-hidden rounded-2xl bg-white'>
               <Player
                    height={250}
                    width={'100%'}
                    fluid={false}
                    playsInline
                    poster={poster}
                    src={video}
               />
          </div>
     )
}

export default ShortVideoCard