import axios from "axios";
import React, { useEffect, useState } from "react";
import { config, url } from "../Helpers/Help";
import { categoriesSlider } from "../Helpers/SlidersOptions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const PublicCategories = () => {
  const [quickLinks, setQuickLinks] = useState([]);

  useEffect(() => {
    axios(url + "footer ", config).then((response) => {
      const res = response.data.data.animal_types
      console.log(res)
      setQuickLinks(res)

    });
  }, []);


  return (
    <>
      {quickLinks && quickLinks.length > 0 && (
        <Slider {...categoriesSlider}>
          {quickLinks.map((item, index) => (
            <Link
              key={index}
              to={`${item.target_page === "add_question"
                ? `/public/question`
                : `${item.target_page === "add_video"
                  ? `/public/videos`
                  : `${item.target_page === "add_animal"
                    ? `/public/animals-type/${item.slug}`
                    : `${item.target_page === "add_image"
                      ? `/public/animals-type/${item.slug}`
                      : ""
                    }`
                  }`
                }`
                }`}
              title={item.title}
              id={item.id}
              className="cursor-pointer"
            >
              <img
                src={item.icon}
                alt={item.title}
                className="mx-auto h-[3.6rem]"
              />
              <h3
                className={`text-[1.4rem] text-center text-black font-medium no-wrap`}
              >
                {item.title}
                {item.numbers && (
                  <h4 className="text-red text-center text-[1.3rem]">
                    {item.numbers}
                  </h4>
                )}
              </h3>
              
            </Link>
          ))}
        </Slider>
      )}
    </>
  );
};

export default PublicCategories;
