import React from 'react'

const User = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M16 2.6665C12.33 2.6665 9.33334 5.66312 9.33334 9.33317C9.33334 13.0032 12.33 15.9998 16 15.9998C19.6701 15.9998 22.6667 13.0032 22.6667 9.33317C22.6667 5.66312 19.6701 2.6665 16 2.6665ZM8.33334 18.6665C6.68834 18.6665 5.33334 20.0215 5.33334 21.6665V22.466C5.33334 24.4266 6.57649 26.1852 8.47267 27.3944C10.3688 28.6036 12.9632 29.3332 16 29.3332C19.0368 29.3332 21.6312 28.6036 23.5274 27.3944C25.4235 26.1852 26.6667 24.4266 26.6667 22.466V21.6665C26.6667 20.0215 25.3117 18.6665 23.6667 18.6665H8.33334Z" fill="#0F70B7" />
          </svg>
     )
}

export default User