import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import User from '../../assets/Icons/User'
import Lang from '../Lang/Lang'
import AddAnimal from '../Seactions/AddAnimal'
import Menu from '../UserSideBar/Menu'
import AddIcon from '../../assets/Icons/AddIcon'
import HomeIcon from '../../assets/Icons/HomeIcon'
import AdsIcon from '../../assets/Icons/AdsIcon'
import MobileSearchIcon from '../../assets/Icons/Search_Icon'
import AuctionIcon from '../../assets/Icons/AuctionIcon'
import Live from '../../assets/Icons/Live'
import alert from '../../Helpers/Modal'
import { t } from 'i18next'
import BlueAuctionIcon from '../../assets/Icons/BlueAuctionIcon'
import BluseSearchIcon from '../../assets/Icons/BluseSearchIcon'
import BlueAdsIcon from '../../assets/Icons/BlueAdsIcon'
import BlueHomeIcon from '../../assets/Icons/BlueHomeIcon'
import Search from '../../assets/Icons/Search'
import axios from 'axios'
import { config, url } from '../Helpers/Help'

const MobileNav = () => {
     const [pathName, setPathName] = useState()
     const [userData, setData] = useState()
     const [addAnimal, setAddAnimal] = useState(false);
     const [menu, setMenu] = useState(false);
     const [addAnimalCss, setAddAnimalCss] = useState(false);
     const [dimensions, setDimensions] = useState(window.innerWidth)
     const isLoggedIn = (localStorage.getItem('__authtk__awasell_secure') && localStorage.getItem('__User_aW__a_Info'));
     const pathname = window.location;
     
     useEffect(() => {
          setPathName(pathname)
     }, [pathname]);

     useEffect(() => {
          window.scrollTo(0, 0)
     }, []);

     useEffect(() => {
          if (localStorage.__authtk__awasell_secure) {
               const data = localStorage.getItem('__User_aW__a_Info')
               setData(JSON.parse(data))
          }
          function handleResize() {
               setDimensions(window.innerWidth)
          }
          window.addEventListener('resize', handleResize)
          return _ => {
               window.removeEventListener('resize', handleResize)
          }

     }, [dimensions])

     const HandleMenu = () => {
          document.body.style.overflowY = "hidden";
          if (!addAnimal) {
               setMenu((prevState) => !prevState);
               setTimeout(() => {
                    setAddAnimalCss((prevState) => !prevState);
               }, 500);
          }
     }

     const HandleAddAnimal = () => {
          if (!isLoggedIn && !userData) {
               alert({
                    title: `${t('Please Login or sign up')}`,
                    text: `${t(`Alert`)}`,

                    icon: 'info',
               })
               return
          }
          document.body.style.overflowY = "hidden";
          if (!addAnimal) {
               setAddAnimal((prevState) => !prevState);
               setTimeout(() => {
                    setAddAnimalCss((prevState) => !prevState);
               }, 500);
          }
     }

     const closeAddAnimal = () => {
          document.body.style.overflowY = "auto";
          if (addAnimal) {
               setAddAnimalCss((prevState) => !prevState);
               setTimeout(() => {
                    setAddAnimal((prevState) => !prevState);
               }, 500);
          }
     }

     const closeMenu = () => {
          document.body.style.overflowY = "auto";
          if (menu) {
               setAddAnimalCss((prevState) => !prevState);
               setTimeout(() => {
                    setMenu((prevState) => !prevState);
               }, 500);
          }
     }
     return (
          <>
               <div className="fixed top-0 left-0 w-full h-[5rem] z-[99] bg-white shadow-md flex items-center justify-between">
                    <div className="w-1/2 flex items-center justify-start gap-4">
                         <Link to='/'>
                              <img src={logo} alt="" className='w-40 p-2' />
                         </Link>
                         <Link to='/public/animals-type/awa-lives'>
                              <Live />
                         </Link>
                         <Lang />
                    </div>
                    <div className="w-1/2 flex items-center justify-end px-4">
                         <div className="flex items-center justify-end">
                              {/* {(isLoggedIn && userData) &&
                                   <BlueButton className='AddAnimalButton py-4 px-8' onClick={HandleAddAnimal}> Add Animal </BlueButton>
                              }
                              <Lang /> */}
                              {(isLoggedIn && userData) ?
                                   <button onClick={HandleMenu}>
                                        <div className="user flex items-center gap-2">
                                             <div className="userImage w-12 h-12 rounded-full relative overflow-hidden bg-blue text-white flex items-center justify-center font-bold">
                                                  {/* <img src={userImage} alt="" className='h-full w-full object-cover' /> */}
                                                  {userData.personal_picture ?
                                                       <img src={userData.personal_picture} alt={userData.f_name} />
                                                       : userData.f_name.charAt(0)}
                                             </div>
                                        </div>
                                   </button> :
                                   <Link to='/login-signup' className='loginLink flex items-center flex-wrap text-[1.6rem] font-bold gap-4'>
                                        <User /> {t('Log in')}
                                   </Link>
                              }
                         </div>
                    </div>
               </div>

               <div className="fixed bottom-0 left-0 w-full h-auto z-[99] bg-white shadow-md flex items-center justify-center">
                    <div className="w-1/5">
                         <Link to='/' className={`flex items-center justify-center mx-auto border-t-4 border-blue h-[6rem] ${pathName && pathName.pathname.length < 2 ? 'border-blue' : 'border-white'}`}>
                              {pathName && pathName.pathname.length < 2 ? <BlueHomeIcon /> : <HomeIcon />}
                         </Link>
                    </div>
                    <div className="w-1/5">
                         <Link to='/ads' className={`flex items-center justify-center mx-auto border-t-4 h-[6rem] ${pathName && pathName.pathname === '/ads' ? ' border-blue' : 'border-white'}`}>
                              {pathName && pathName.pathname === '/ads' ? <BlueAdsIcon /> : <AdsIcon />}
                         </Link>
                    </div>
                    <div className="w-1/5">
                         <button onClick={HandleAddAnimal} className='flex items-center justify-center mx-auto'>
                              <AddIcon />
                         </button>
                    </div>
                    <div className="w-1/5">
                         <Link to='/app-search' className={`flex items-center justify-center mx-auto border-t-4 h-[6rem] ${pathName && pathName.pathname === '/app-search' ? ' border-blue' : 'border-white'}`}>
                              {/* {pathName && pathName.pathname === '/app-search' ? <BluseSearchIcon /> : <MobileSearchIcon />} */}
                              {pathName && pathName.pathname === '/app-search' ? <Search fill='#0f70b7' /> : <Search fill='#555' />}

                         </Link>
                    </div>
                    <div className="w-1/5">
                         <Link to='/public/animals-type/auctions' className={`flex items-center justify-center mx-auto border-t-4 h-[6rem] ${pathName && pathName.pathname.indexOf('auctions') > -1 ? ' border-blue' : 'border-white'}`}>
                              {pathName && pathName.pathname.indexOf('auctions') > -1 ? <BlueAuctionIcon /> : <AuctionIcon />}
                         </Link>
                    </div>
               </div>
               {
                    addAnimal &&
                    <AddAnimal onClick={closeAddAnimal} className={`${addAnimalCss ? 'right-0 transition-all duration-1000' : '-right-[50rem] transition-all duration-1000'}`} />
               }

               {
                    menu &&
                    <Menu onClick={closeMenu} className={`${addAnimalCss ? 'right-0 transition-all duration-1000' : '-right-[150rem] transition-all duration-1000'}`} />
               }
          </>
     )
}

export default MobileNav