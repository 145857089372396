//  attributes[type_id] === slug
import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../../Components/Layout";
import ProfileUser from "../../Components/Seactions/ProfileUser";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { config, url } from "../../Components/Helpers/Help";
import Search from "../../Components/Search/Search";
import SpecialOffer from "../../Components/UI/AnimalCard/SpecialOffer";
import LookFor from "../../Components/UI/AnimalCard/LookFor";
import Photographer from "../../Components/UI/AnimalCard/Photographer";
import Quick from "../../assets/Icons/UserIcons/White/Quick";
import Hours from "../../assets/Icons/UserIcons/Colors/Hours";
import Public from "../../assets/Icons/UserIcons/White/Public";
import VipWhite from "../../assets/Icons/UserIcons/White/VipWhite";
import Vip from "../../assets/Icons/UserIcons/Colors/Vip";
import PublicIcon from "../../assets/Icons/UserIcons/Colors/Public";
import AdoptionForAnimal from "../../Components/UI/AnimalCard/AdoptionForAnimal";
import WhiteMarket from "../../assets/Icons/UserIcons/White/WhiteMarket";
import MarketIcon from "../../assets/Icons/UserIcons/Colors/MarketIcon";
import WhiteHolesaleMarket from "../../assets/Icons/UserIcons/White/WhiteHolesaleMarket";
import HolesaleMarket from "../../assets/Icons/UserIcons/Colors/HolesaleMarket";
import WhiteQuickMarket from "../../assets/Icons/UserIcons/White/WhiteQuickMarket";
import QuickMarket from "../../assets/Icons/UserIcons/Colors/QuickMarket";
import FridayMarket from "../../Components/UI/AnimalCard/FridayMarket";
import Accessories from "../../assets/Icons/Accessories";
import Services from "../../assets/Icons/UserIcons/White/Services";
import Others from "../../Components/UI/AnimalCard/Others";
import Delivery from "../../Components/UI/AnimalCard/Delivery";
import VeterinaryClinic from "../../Components/UI/AnimalCard/VeterinaryClinic";
import LostAnimal from "../../Components/UI/AnimalCard/LostAnimal";
import Mating from "../../Components/UI/AnimalCard/Mating";
import DailyAnnouncement from "../../Components/UI/AnimalCard/DailyAnnouncement";
import VipAuction from "../../Components/UI/AnimalCard/VipAuction";
import VideoCard from "../../Components/UI/Cards/Video-card";
import { t } from "i18next";
import PublicCategories from "../../Components/Seactions/PublicCategories";
import SalesCard from "../../Components/UI/AnimalCard/SalesCard";
import NotItems from "../../Components/UI/NotItems";
import PreLoader from "../../Components/UI/PreLoader";
import errorImage from '../../assets/images/error.jpg'
const AnimalsType = () => {
     const params = useLocation();
     const [count, setCount] = useState();
     const [serverError, setServerError] = useState(false);
     const [profile, setProfile] = useState(false);
     const [isLoading, setIsLoading] = useState(false);
     const [activeMarketTap, setActiveMarketTap] = useState('wholesale_markets');
     const [activeOthersTap, setActiveOthersTap] = useState('services');
     const [animalType, setAnimalType] = useState([]);
     const [data, setData] = useState();
     const [activeTap, setActiveTap] = useState('public-auctions');
     const pathname = window.location.pathname;
     const appId = pathname.split("/")[3];

     useEffect(() => {
          if (localStorage.__authtk__awasell_secure) {
               const data = localStorage.getItem("infoCounts");
               setCount(JSON.parse(data));
          }
     }, []);

     useEffect(() => {
          if (localStorage.__authtk__awasell_secure) {
               const data = localStorage.getItem("__User_aW__a_Info");
               setData(JSON.parse(data));
          }
     }, []);

     useEffect(() => {
          if (params.pathname.match("/profile")) {
               setProfile(true);
          }
     }, [params, appId]);

     useEffect(() => {
          if (!params.pathname.match("/profile")) return
          console.log('This is Profile Page')
          setIsLoading(true)

          window.scrollTo(0, 0)
          if (appId !== 'auctions' && appId !== 'markets' && appId !== 'others' && appId !== 'sales-pages') {
               axios(url + `animals/my?attributes[type_id]=${appId}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

          if (appId === 'sales-pages') {
               axios(url + `users/my-sale-page?attributes[category_id]=1&attributes[country]=1&attributes[city]=1&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

          if (appId === 'auctions') {
               axios(url + `animals/my?attributes[type_id]=${activeTap}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

          if (appId === 'markets') {
               axios(url + `animals/my?attributes[type_id]=${activeMarketTap}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

          if (appId === 'others') {
               axios(url + `animals/my?attributes[type_id]=${activeOthersTap}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }


     }, [appId, params, activeTap, activeMarketTap, activeOthersTap]);

     useEffect(() => {
          if (params.pathname.match("/profile")) return
          console.log('This is Public Page')
          setIsLoading(true)
          window.scrollTo(0, 0)
          if (appId !== 'auctions' && appId !== 'markets' && appId !== 'others' && appId !== 'sales-pages') {
               axios(url + `animals/all?attributes[type_id]=${appId}&limit=30`, config).then(
                    (response) => {
                         console.log(response.data.data.data);
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error.response);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }

               });
          }
          if (appId === 'sales-pages') {
               axios(url + `sale-page?attributes[category_id]=1&attributes[country]=1&attributes[city]=1&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }
          if (appId === 'auctions') {
               axios(url + `animals/all?attributes[type_id]=${activeTap}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

          if (appId === 'markets') {
               axios(url + `animals/all?attributes[type_id]=${activeMarketTap}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

          if (appId === 'others') {
               axios(url + `animals/all?attributes[type_id]=${activeOthersTap}&limit=30`, config).then(
                    (response) => {
                         setServerError(false)
                         setAnimalType(response.data.data.data);
                         console.log(response.data.data.data);
                         setIsLoading(false)
                    }
               ).catch((error) => {
                    console.log(error);
                    if (error && error.response.status === 500) {
                         setServerError(true)
                    } else {
                         setServerError(false)
                    }
               });
          }

     }, [appId, params, activeTap, activeMarketTap, activeOthersTap]);

     return (
          <Layout>
               <main className="container mx-auto pt-24 md:pt-36 lg:pt-28 px-4 md:px-0 min-h-screen ">
                    {profile &&
                         <ProfileUser myProfile={true}
                              id={data.id}
                              follow={false}
                              personal_picture={data.personal_picture}
                              f_name={data.f_name}
                              nickname={data.nickname}
                              l_name={data.l_name}
                              posts={data.posts}
                              followers={data.followers}
                              following={data.following}
                         />
                    }


                    {!profile && (
                         <>
                              <section className="mt-12">
                                   <PublicCategories />
                              </section>
                              <section className="my-12">
                                   <Search />
                              </section>
                         </>
                    )}
                    {serverError ? <img src={errorImage} alt="server error" className="w-[50rem] h-[50rem] xl:mt-48 block mx-auto mix-blend-multiply object-contain" />
                         :
                         <>
                              {
                                   isLoading ?
                                        <PreLoader />
                                        :
                                        <>
                                             {appId === "sales-pages" && (
                                                  <section className="flex flex-wrap items-start justify-center">
                                                       {animalType.map(item => (
                                                            <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3" key={item.id}>
                                                                 <SalesCard
                                                                      slug={item.slug}
                                                                      key={item.id}
                                                                      id={item.id}
                                                                      price={item.price}
                                                                      max_price={item.max_price}
                                                                      users_count={item.users_count}
                                                                      img={item.image}
                                                                      type={item.category && item.category.title}
                                                                      badg={item.user && item.user.is_vip}
                                                                      isFav={item.isFav}
                                                                      phone={item.user && item.user.phone}
                                                                      age={item.age}
                                                                      weight={item.weight}
                                                                      gender={item.gender}
                                                                      action={item.action}
                                                                      location={item.city && item.city.name}
                                                                      duration={item.duration}
                                                                      inMarket={item.inMarket}
                                                                      whatsapp={item.user && item.user.whatsapp}
                                                                      email={item.user && item.user.email}
                                                                      name={item.user && item.user.f_name}
                                                                      userImage={item.user && item.user.personal_picture}
                                                                      userId={item.user && item.user.id}
                                                                      rate={item.user && item.user.avg_rate_int}
                                                                      is_verified={item.user && item.user.is_verified}
                                                                 />
                                                            </div>
                                                       ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "special-offers" && (
                                                  <section className="flex flex-wrap items-start justify-center">
                                                       {animalType.map((item, index) => (
                                                            <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3" key={item.id}>
                                                                 <SpecialOffer
                                                                      id={item.id}
                                                                      slug={item.slug}
                                                                      img={item.image}
                                                                      type={item.sub_category?.title}
                                                                      badge={item.user?.badge}
                                                                      price={item.offer_price}
                                                                      oldPrice={+item.price > +item.offer_price && item.price}
                                                                      isFav={item.isFav}
                                                                      phone={item.user?.phone}
                                                                      age={item.age}
                                                                      weight={item.weight}
                                                                      gender={item.gender}
                                                                      location={item.city?.name}
                                                                      email={item.user?.email}
                                                                      name={item.user?.f_name}
                                                                      whatsapp={item.user?.whatsapp}
                                                                      userImage={item.user?.personal_picture}
                                                                      userId={item.user?.id}
                                                                      rate={item.user?.avg_rate_int}
                                                                      is_verified={item.user?.is_verified}
                                                                 />
                                                            </div>
                                                       ))
                                                       }
                                                  </section>
                                             )}

                                             {/* Daily Announcement */}
                                             {appId === "daily-announcements" && (
                                                  <section className="flex flex-wrap items-start justify-center">
                                                       {animalType.map((item, index) => (
                                                            <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3" key={item.id}>
                                                                 <DailyAnnouncement
                                                                      id={item.id}
                                                                      slug={item.slug}
                                                                      img={item.image}
                                                                      type={item.category && item.category.title}
                                                                      badg={item.user && item.user.is_vip}
                                                                      price={item.offer_price}
                                                                      oldPrice={+item.price > +item.offer_price && item.price}
                                                                      isFav={item.isFav}
                                                                      phone={item.user && item.user.phone}
                                                                      age={item.age}
                                                                      weight={item.weight}
                                                                      gender={item.gender}
                                                                      location={item.city && item.city.name}
                                                                      email={item.user && item.user.email}
                                                                      name={item.user && item.user.f_name}
                                                                      userImage={item.user && item.user.personal_picture}
                                                                      userId={item.user && item.user.id}
                                                                      rate={item.user && item.user.avg_rate_int}
                                                                      is_verified={item.user && item.user.is_verified}
                                                                 />
                                                            </div>
                                                       ))
                                                       }
                                                  </section>
                                             )}

                                             {/* Mating */}
                                             {appId === "matings" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {/* <Auction vipAuctionData={animalType} /> */}
                                                       {animalType &&
                                                            animalType.map(item => (
                                                                 <div className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5 p-2" key={item.id}>
                                                                      <Mating
                                                                           id={item.id}
                                                                           key={item.id}
                                                                           slug={item.slug}
                                                                           img={item.image ? item.image : 'http://front.awasell.co/static/media/logo%20+%20words@2x.858e5a21f7aa91285f56.png'}
                                                                           type={item.category && item.category.title}
                                                                           badg={item.badg}
                                                                           isFav={item.isFav}
                                                                           phone={item.user && item.user.phone}
                                                                           age={item.age}
                                                                           weight={item.weight}
                                                                           gender={item.gender}
                                                                           location={item.city && item.city.name}
                                                                           email={item.user && item.user.email}
                                                                           name={item.user && item.user.f_name}
                                                                           userImage={item.user && item.user.personal_picture}
                                                                           userId={item.user && item.user.id}
                                                                           rate={item.user && item.user.avg_rate_int}
                                                                           is_verified={item.user && item.user.is_verified}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "lost_animals" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {/* <Auction vipAuctionData={animalType} /> */}
                                                       {animalType &&
                                                            animalType.map(item => (
                                                                 <div className="w-full md:w-1/2 lg:w-1/3  p-2" key={item.id}>
                                                                      <LostAnimal
                                                                           id={item.id}
                                                                           type={item.category && item.category.title}
                                                                           img={item.image}
                                                                           loastDate={item.lost_date}
                                                                           // location={item.user && item.user.city.name}
                                                                           badge={item.user && item.user.badge}
                                                                           name={item.user && item.user.f_name}
                                                                           userImage={item.user && item.user.personal_picture}
                                                                           userId={item.user && item.user.id}
                                                                           rate={item.user && item.user.avg_rate_int}
                                                                           is_verified={item.user && item.user.is_verified}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "veterinaries" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {
                                                            animalType.map(item => (
                                                                 <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                      <VeterinaryClinic
                                                                           id={item.id}
                                                                           slug={item.slug}
                                                                           img={item.image}
                                                                           title={item.title}
                                                                           badg={item.badg}
                                                                           location={item.address}
                                                                           name={item.user && item.user.f_name}
                                                                           phone={item.phone}
                                                                           email={item.email}
                                                                           whatsapp={item.user && item.user.whatsapp}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "deliveries" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {
                                                            animalType.map(item => (
                                                                 <div className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                      <Delivery
                                                                           first_name={item.first_name}
                                                                           last_name={item.last_name}
                                                                           id={item.id}
                                                                           slug={item.slug}
                                                                           rate={item.avg_rate_int}
                                                                           className="h-[36.5rem]"
                                                                           img={item.image}
                                                                           title={item.title}
                                                                           location={item.address}
                                                                           phone={item.user && item.user.phone}
                                                                           email={item.user && item.user.email}
                                                                           whatsapp={item.user && item.user.whatsapp}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "quarterings" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {
                                                            animalType.map(item => (
                                                                 <div className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                      <AdoptionForAnimal
                                                                           id={item.id}
                                                                           slug={item.slug}
                                                                           key={item.id}
                                                                           img={item.image}
                                                                           badg={item.badg}
                                                                           price={item.price}
                                                                           isFav={item.isFav}
                                                                           age={item.age}
                                                                           duration={item.price_unit_text}
                                                                           weight={item.weight}
                                                                           gender={item.gender}
                                                                           location={item.city && item.city.name}
                                                                           locationAnimal={item.address}
                                                                           email={item.user && item.user.email}
                                                                           name={item.user && item.user.f_name}
                                                                           phone={item.phone}
                                                                           userImage={item.user && item.user.personal_picture}
                                                                           userId={item.user && item.user.id}
                                                                           rate={item.user && item.user.avg_rate_int}
                                                                           is_verified={item.user && item.user.is_verified}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }


                                                  </section>
                                             )}

                                             {(appId === "others" || appId === "accessories" || appId === "services") && (
                                                  <>
                                                       <section className="mt-12">
                                                            <div className="row items-center justify-center flex flex-wrap">
                                                                 <div className="w-full flex justify-center my-12">
                                                                      <div onClick={() => setActiveOthersTap("services")} className={`h-28 cursor-pointer w-auto p-4 px-6 mx-2 rounded-xl flex items-center gap-4 ${activeOthersTap === "services" ? 'bg-blue text-white' : 'bg-white border'}`}>
                                                                           {activeOthersTap === "services" ? <Services fill='white' /> : <Services fill='#0F70B7' />}
                                                                           <h3>
                                                                                {t('Services')}
                                                                                <span className="relative w-8 h-8 -mt-2 inline-flex rounded-md items-center mx-4 justify-center bg-blue text-white text-[1.4rem]">
                                                                                     {count?.services}
                                                                                </span>
                                                                           </h3>
                                                                      </div>
                                                                      <div onClick={() => setActiveOthersTap("accessories")} className={`h-28 cursor-pointer w-auto p-4 px-6 mx-2 rounded-xl flex items-center gap-4 ${activeOthersTap === "accessories" ? 'bg-blue text-white' : 'bg-white border'}`}>
                                                                           {activeOthersTap === "accessories" ? <Accessories blue='white' mint='white' /> : <Accessories blue='#0F70B7' mint='#17C3B2' />}
                                                                           <h3>
                                                                                {t('Accessories')}
                                                                                <span className="relative w-8 h-8 -mt-2 inline-flex rounded-md items-center mx-4 justify-center bg-blue text-white text-[1.4rem]">
                                                                                     {count?.accessories}
                                                                                </span>
                                                                           </h3>
                                                                      </div>
                                                                      <div onClick={() => setActiveOthersTap("adoptions")} className={`h-28 cursor-pointer w-auto p-4 px-6 mx-2 rounded-xl flex items-center gap-4 ${activeOthersTap === "adoptions" ? 'bg-blue text-white' : 'bg-white border'}`}>
                                                                           {activeOthersTap === "adoptions" ? <Accessories blue='white' mint='white' /> : <Accessories blue='#0F70B7' mint='#17C3B2' />}
                                                                           <h3>
                                                                                {t('Adoptions')}
                                                                                <span className="relative w-8 h-8 -mt-2 inline-flex rounded-md items-center mx-4 justify-center bg-blue text-white text-[1.4rem]">
                                                                                     {count?.adoptions}
                                                                                </span>
                                                                           </h3>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </section>
                                                       <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                            {/* <Auction vipAuctionData={animalType} /> */}
                                                            {animalType && animalType.length > 0 ?
                                                                 <>
                                                                      {animalType.map(item => (
                                                                           <div className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                                <Others
                                                                                     id={item.id}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.user && item.user.badge}
                                                                                     price={item.price}
                                                                                     name={item.user && item.user.f_name}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                     location={item.user && item.user.city && item.user.city.name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))}
                                                                 </>
                                                                 :
                                                                 <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                                                                      <NotItems />
                                                                 </div>
                                                            }
                                                       </section>
                                                  </>
                                             )}

                                             {appId === "looking-for" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {
                                                            animalType.map((item, index) => (
                                                                 <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5" key={index}>
                                                                      <LookFor
                                                                           id={item.id}
                                                                           key={item.id}
                                                                           img={item.image}
                                                                           type={item.sub_category?.title}
                                                                           badge={item.user && item.user.badge}
                                                                           isFav={item.isFav}
                                                                           phone={item.user && item.user.phone}
                                                                           age_range_from={item.age_range_to}
                                                                           age_range_to={item.age_range_from}
                                                                           price_range_from={item.price_range_from}
                                                                           price_range_to={item.price_range_to}
                                                                           weight={item.weight}
                                                                           gender={item.gender}
                                                                           address={item.address}
                                                                           email={item.user && item.user.email}
                                                                           name={item.user && item.user.f_name}
                                                                           userImage={item.user && item.user.personal_picture}
                                                                           userId={item.user && item.user.id}
                                                                           rate={item.user && item.user.avg_rate_int}
                                                                           is_verified={item.user && item.user.is_verified}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {(appId === "markets" || appId === "today-markets" || appId === "friday-markets" || appId === "wholesale_markets") && (
                                                  <section className="mt-12">
                                                       <section className="mt-12">
                                                            <div className="row items-center justify-center flex flex-wrap">
                                                                 <div onClick={() => setActiveMarketTap('friday-markets')} className={`h-28 cursor-pointer w-auto p-4 px-2 md:px-6 mx-2 rounded-xl flex items-center gap-4 md:flex-row flex-col ${activeMarketTap === 'friday-markets' ? 'bg-blue text-white' : 'bg-white'}`}>
                                                                      {activeMarketTap === 'friday-markets' ? <WhiteMarket /> : <MarketIcon />}
                                                                      <h3 className="text-[1.3rem] md:text-[1.6rem]">{t('Friday market')}</h3>
                                                                 </div>
                                                                 <div onClick={() => setActiveMarketTap('wholesale_markets')} className={`h-28 cursor-pointer w-auto p-4 px-2 md:px-6 mx-2 rounded-xl flex items-center gap-4 md:flex-row flex-col ${activeMarketTap === 'wholesale_markets' ? 'bg-blue text-white' : 'bg-white'}`}>
                                                                      {activeMarketTap === 'wholesale_markets' ? <WhiteHolesaleMarket /> : <HolesaleMarket />}
                                                                      <h3 className="text-[1.3rem] md:text-[1.6rem]">{t('Wholesale market')}</h3>
                                                                 </div>
                                                                 <div onClick={() => setActiveMarketTap('today-markets')} className={`h-28 cursor-pointer w-auto p-4 px-2 md:px-6 mx-2 rounded-xl flex items-center gap-4 md:flex-row flex-col ${activeMarketTap === 'today-markets' ? 'bg-blue text-white' : 'bg-white'}`}>
                                                                      {activeMarketTap === 'today-markets' ? <WhiteQuickMarket /> : <QuickMarket />}
                                                                      <h3 className="text-[1.3rem] md:text-[1.6rem]">{t('Today market')}</h3>
                                                                 </div>
                                                            </div>
                                                       </section>
                                                       <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                            {/* <Auction vipAuctionData={animalType} /> */}
                                                            {animalType &&
                                                                 animalType.map(item => (
                                                                      <div className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                           <FridayMarket
                                                                                slug={item.slug}
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                img={item.image ? item.image : 'http://front.awasell.co/static/media/logo%20+%20words@2x.858e5a21f7aa91285f56.png'}
                                                                                type={activeMarketTap !== 'wholesale_markets' && item.category?.title}
                                                                                badge={item.user && item.user.badge}
                                                                                isFav={item.isFav}
                                                                                phone={item.user && item.user.phone}
                                                                                age={item.age}
                                                                                weight={item.weight}
                                                                                gender={item.gender}
                                                                                action={item.action}
                                                                                price={activeMarketTap !== 'today-markets' && item.price}
                                                                                location={item.city && item.city.name}
                                                                                duration={item.duration}
                                                                                inMarket={item.inMarket}
                                                                                whatsapp={item.user?.whatsapp}
                                                                                email={item.user && item.user.email}
                                                                                name={item.user && item.user.f_name}
                                                                                userImage={item.user && item.user.personal_picture}
                                                                                userId={item.user && item.user.id}
                                                                                rate={item.user && item.user.avg_rate_int}
                                                                                is_verified={item.user && item.user.is_verified}
                                                                           />
                                                                      </div>
                                                                 ))
                                                            }
                                                       </section>
                                                  </section>
                                             )}

                                             {appId === 'auctions' &&
                                                  (
                                                       <div className="w-full flex items-start justify-center flex-wrap my-6">
                                                            {/* Public Auction */}
                                                            <div onClick={() => setActiveTap('public-auctions')} className={`cursor-pointer w-auto p-4 md:px-6 mx-1 sm:mx-2 rounded-xl flex items-center gap-4 flex-col md:flex-row ${activeTap === 'public-auctions' ? "bg-blue text-white" : "bg-white border"}`} >
                                                                 {activeTap === 'public-auctions' ? <Public /> : <PublicIcon />}
                                                                 <h3 className="text-[1.3rem] md:text-[1.6rem]">{t('Public Auction')}</h3>
                                                            </div>

                                                            {/* Vip Auction */}
                                                            <div onClick={() => setActiveTap('vip-auctions')} className={`cursor-pointer w-auto p-4 md:px-6 mx-1 sm:mx-2 rounded-xl flex items-center gap-4 flex-col md:flex-row ${activeTap === 'vip-auctions' ? "bg-blue text-white" : "bg-white border"}`}>
                                                                 {activeTap === 'vip-auctions' ? <VipWhite /> : <Vip />}
                                                                 <h3 className="text-[1.3rem] md:text-[1.6rem]">{t('VIP Auction')}</h3>
                                                            </div>

                                                            {/* Quick Auction */}
                                                            <div onClick={() => setActiveTap('quick-auctions')} className={`cursor-pointer w-auto p-4 md:px-6 mx-1 sm:mx-2 rounded-xl flex items-center gap-4 flex-col md:flex-row ${activeTap === 'quick-auctions' ? "bg-blue text-white" : "bg-white border"}`} >
                                                                 {activeTap === 'quick-auctions' ? <Quick /> : <Hours />}
                                                                 <h3 className="text-[1.3rem] md:text-[1.6rem]">{t('Quick Auction')}</h3>
                                                            </div>


                                                       </div>
                                                  )
                                             }

                                             {(appId === "auctions" || appId === "quick-auctions" || appId === "public-auctions" || appId === "vip-auctions") && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {/* <Auction vipAuctionData={animalType} /> */}
                                                       {animalType &&
                                                            animalType.map(item => (
                                                                 <div className="w-1/2 md:w-1/2 xl:w-1/3 p-2" key={item.id}>
                                                                      <VipAuction
                                                                           is_notify={item.is_notify}
                                                                           key={item.id}
                                                                           vip={activeTap}
                                                                           id={item.id}
                                                                           isMine={item.is_mine}
                                                                           slug={item.slug}
                                                                           img={item.image}
                                                                           end_minutes={item.end_minutes}
                                                                           start={item.start_time}
                                                                           type={item.category && item.category.title}
                                                                           max_price={item.max_price}
                                                                           gender={item.gender}
                                                                           age={item.age}
                                                                           users_count={item.users_count}
                                                                           weight={item.weight}
                                                                           isFav={item.isFav}
                                                                           inMarket={item.in_market && item.in_market_till !== 0}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "photographers" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {animalType &&
                                                            animalType.map((item, index) => (
                                                                 <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5" key={index}>
                                                                      <Photographer
                                                                           key={item.id}
                                                                           id={item.id}
                                                                           img={item.images ? item.images[0] : item.image}
                                                                           slug={item.slug}
                                                                           type={item.category && item.category.title}
                                                                           badg={item.user && item.user.badge}
                                                                           isFav={item.isFav}
                                                                           phone={item.user && item.user.phone}
                                                                           age={item.age}
                                                                           weight={item.weight}
                                                                           gender={item.gender}
                                                                           location={item.address}
                                                                           email={item.user && item.user.email}
                                                                           name={item.user && item.user.f_name}
                                                                           whatsapp={item.user && item.user.whatsapp}
                                                                           userImage={item.user && item.user.personal_picture}
                                                                           userId={item.user && item.user.id}
                                                                           rate={item.user && item.user.avg_rate_int}
                                                                           is_verified={item.user && item.user.is_verified}
                                                                      />
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}

                                             {appId === "awa-lives" && (
                                                  <section className="mt-12 row flex items-start justify-center flex-wrap">
                                                       {animalType &&
                                                            animalType.map((item, index) => (
                                                                 <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-8" key={index}>
                                                                      <a href={`/live-streaming/${item.id}`} key={item.id}>
                                                                           <VideoCard  views_count={item.views_count} 
                                                                                liveLink={`/live-streaming/${item.id}`}
                                                                                live='true'
                                                                                id={item.id}
                                                                                title={item.title}
                                                                                is_live={item.is_started}
                                                                                start_time={item.start_time}
                                                                                likes_count={item.likes_count}
                                                                                comment_count={item.comment_count}
                                                                                description={item.content}
                                                                                max_price={item.max_price}
                                                                                poster={item.image}
                                                                                end_minutes={item.end_minutes}
                                                                                userImage={item.user && item.user.personal_picture}
                                                                                name={(item.user && item.user.f_name) && item.user.f_name}
                                                                                userId={item.user?.id}
                                                                                rate={'5'}
                                                                                is_verified={item.user && item.user.is_verified}
                                                                                location={(item.user && item.user.city) && item.user.city.name} />
                                                                      </a>
                                                                 </div>
                                                            ))
                                                       }
                                                  </section>
                                             )}
                                        </>
                              }
                         </>
                    }

               </main>
          </Layout>
     );
};

export default AnimalsType;
