import React from 'react'

const AwaReels = () => {
  return (
       <svg className="w-16" version="1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
             xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 36">
            <path className="st0" d="M8.2,1.9l5,8.5H1.5V9.7C1.5,5.8,4.4,2.5,8.2,1.9z M22.6,10.5h-7.7l-5-8.6h7.7L22.6,10.5z M31.8,9.7v0.7h-7.5
	l-5-8.6h4.6C28.2,1.8,31.8,5.4,31.8,9.7z" fill="#17C3B2" />
            <path className="st1" d="M31.8,20.8v-8.9H1.5v12.2C1.5,28.5,5,32,9.4,32h11.1c1.4,2.3,4,3.8,6.9,3.8c4.5,0,8.1-3.6,8.1-8.1
	C35.4,24.8,34,22.3,31.8,20.8z M13,24.2v-6.5c0-1.1,1.2-1.8,2.2-1.2l5.8,3.3c0.9,0.4,0.9,1.8,0,2.3l-5.8,3.3
	C14.2,25.9,13,25.3,13,24.2z M27.3,34.4c-2.1,0-4-0.9-5.2-2.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.2-1.4-2.6-1.4-4.1
	c0-3.8,3-6.8,6.8-6.8c1.6,0,3.1,0.5,4.2,1.5c0.1,0.1,0.2,0.1,0.2,0.2c1.4,1.2,2.3,3.1,2.3,5.1C34.1,31.4,31.1,34.4,27.3,34.4z" fill="#0F70B7" />
            <g>
                 <path className="st1" d="M24.3,29.2h-2l1.7-1.9c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.1-0.5
		c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.3-0.3-0.5-0.6-0.6c-0.2-0.1-0.5-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.6
		c-0.2,0.3-0.2,0.6-0.2,0.9c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3
		c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4
		c0.1,0.2,0.1,0.3,0.1,0.6c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.3,0.5l-2.1,2.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3
		c0.1,0.1,0.2,0.1,0.3,0.1h2.7c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3C24.5,29.3,24.4,29.2,24.3,29.2z" fill="#0F70B7" />
                 <path className="st1" d="M28.8,28h-0.4v-3.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1
		l-2.8,3.7c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1h2.4v0.9c0,0.1,0,0.2,0.1,0.3
		c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-0.9h0.4c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.1-0.2
		c0-0.1,0-0.2-0.1-0.3C29,28,28.9,28,28.8,28z M25.8,28l1.8-2.4V28H25.8z" fill="#0F70B7" />
                 <path className="st1" d="M33.3,26.8c-0.2-0.3-0.4-0.5-0.6-0.6c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2
		c-0.1,0.1-0.3,0.2-0.4,0.3v-0.1v-1.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1s-0.1,0.2-0.1,0.3v1.7v1.8
		v1.5c0,0.1,0,0.2,0.1,0.3S30,30,30.1,30c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-1.5v-0.6c0-0.2,0-0.3,0.1-0.5
		c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.4,0.2,0.7v1.8
		c0,0.1,0,0.2,0.1,0.3C32.9,30,33,30,33.1,30c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-1.8C33.5,27.4,33.4,27.1,33.3,26.8z" fill="#0F70B7" />
            </g>
       </svg>
  )
}

export default AwaReels