import { t } from "i18next";
import { config, url } from "../Helpers/Help";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Hours from "../../assets/Icons/UserIcons/Colors/Hours";
import Public from "../../assets/Icons/UserIcons/White/Public";
import Quick from "../../assets/Icons/UserIcons/White/Quick";
import BlueButton from "../UI/Buttons/BlueButton";
import Input from "../UI/Input";
import PublicIcon from "../../assets/Icons/UserIcons/Colors/Public";
import VipWhite from "../../assets/Icons/UserIcons/White/VipWhite";
import Vip from "../../assets/Icons/Vip";
import Title from "./Title";
import alert from "../../Helpers/Modal";
import axios from "axios";
import Map from "../UI/Map";
import ButtonLoading from "../UI/ButtonLoading";
import { Link } from "react-router-dom";

const pricesType = [
  {
    value: "1",
    label: localStorage.awaLang === "en" ? "Open Price" : "سعر الفتح",
  },
  {
    value: "2",
    label: localStorage.awaLang === "en" ? "Start Price" : "السعر المبدئي",
  },
  {
    value: "3",
    label: localStorage.awaLang === "en" ? "End Price" : "السعر النهائي",
  },
];
const ErrorText = ({ text }) => <p className="text-red mr-4 mt-2">{text}</p>;
const AddAuction = (props) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [color, setColor] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setheight] = useState("");
  const [gender, setGender] = useState("1");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [activeTap, setActiveTap] = useState("public-auctions");
  const [typeId, setTypeId] = useState(1);
  const [passportNO, setPassportNO] = useState("");
  const [animalSpecies, setAnimalSpecies] = useState("");
  const [dateofBirthday, setDateofBirthday] = useState("");
  const [singleImage, setUploadSingleImage] = useState("");
  const [uploadVideo, setUploadVideo] = useState("");
  const [categories, setCategories] = useState([]);
  const [priceType, setPriceType] = useState("");
  const [childs, setChilds] = useState([]);
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [priceValue, setPriceValue] = useState();

  const [CategoryError, setCategoryError] = useState(false);
  const [TypeError, setTypeError] = useState(false);
  const [animalSpeciesError, setAnimalSpeciesError] = useState(false);

  const [dateofBirthdayError, setDateofBirthdayError] = useState(false);
  const [passportNoError, setPassportNoError] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const [weightError, setweightError] = useState(false);
  const [priceTypeError, setpriceTypeError] = useState(false);
  const [priceError, setpriceError] = useState(false);
  const [ImagesError, setImagesError] = useState(false);
  const [videoError, setvideoError] = useState(false);
  const [singleImageError, setsingleImageError] = useState(false);
  const [phoneError, setphoneError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [noteError, setnoteError] = useState(false);
  const [mapError, setmapError] = useState(false);

  const [check, setCheck] = useState("");
  const [checkError, setCheckError] = useState(false);
  // upload malti image
  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    console.log(newImageUrls);
  }, [images]);

  // Storage Images
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  // Get Categories from localStorage
  useEffect(() => {
    axios(url + "categories", config)
      // Response
      .then((response) => {
        setCategories(response.data.data);
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  }, []);

  // Get Childes of Categories by ID
  useEffect(() => {
    if (category) {
      const item = categories.find((item) => item.id === category);
      setChilds(item.childs);
    }
  }, [category, categories]);
  const scrollToTop = (top = 0) => {
    window.scrollTo({ top: top, behavior: "smooth" });
  };
  //  Submit auction form
  const submitHandler = () => {
    // if (   !phone || !email || !note || !lat || !lng) {
    //      setIsError(true)
    // }
    if (!category) {
      setCategoryError(true);
      scrollToTop(0);
      return;
    }
    if (!type) {
      setTypeError(true);
      scrollToTop(0);

      return;
    }
    if (!animalSpecies) {
      setAnimalSpeciesError(true);
      scrollToTop(0);

      return;
    }
    if (!dateofBirthday) {
      setDateofBirthdayError(true);
      scrollToTop(0);

      return;
    }
    if (!passportNO) {
      setPassportNoError(true);
      scrollToTop(0);

      return;
    }
    if (!color) {
      setColorError(true);
      scrollToTop(0);

      return;
    }

    if (!weight) {
      setweightError(true);
      scrollToTop(0);

      return;
    }
    // if (!priceType) {
    //      setpriceTypeError(true)
    //      return
    // }
    if (!price) {
      setpriceError(true);
      scrollToTop(0);

      return;
    }
    if (!images || images.length < 3) {
      setImagesError(true);
      scrollToTop(100);

      return;
    }
    if (!uploadVideo) {
      setvideoError(true);
      scrollToTop(150);

      return;
    }
    if (!singleImage) {
      setsingleImageError(true);
      scrollToTop(150);

      return;
    }
    if (!phone) {
      setphoneError(true);
      scrollToTop(460);

      return;
    }
    if (!email) {
      setemailError(true);
      scrollToTop(460);

      return;
    }
    if (!note) {
      setnoteError(true);
      scrollToTop(820);

      return;
    }
    if (!lat || !lng) {
      setmapError(true);
      return;
    }
    if (!check) {
      setCheckError(true);
      return;
    }
    if (!location) {
      setlocationError(true);
      scrollToTop(940);

      return;
    }
    setLoading(true);
    axios
      .post(
        url + "animals",
        //  Data
        {
          slug: "auctions",
          show_in: 2,
          type_id: typeId,
          category_id: category,
          sub_category_id: type,
          species: animalSpecies,
          address: location,
          gender: gender,
          date_of_birth: dateofBirthday,
          passport_number: passportNO,
          color: color,
          weight: weight,
          // price_type: priceType,
          price: price,
          video: uploadVideo,
          height: height,
          image: singleImage,
          "images[]": images,
          phone: phone,
          email: email,
          note: note,
          lat: lat,
          lng: lng,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setCategory("");
        setType("");
        setAnimalSpecies("");
        setDateofBirthday("");
        setPassportNO("");
        setColor("");
        setWeight("");
        setLocation("");
        setUploadSingleImage("");
        setUploadVideo("");
        // setPriceType('')
        setPrice("");
        setPhone("");
        setEmail("");
        setNote("");
        setLat("");
        setLng("");
        setImages([]);
        setLoading(false);
        if (response.data.data.payment_link) {
          window.location.href = `${response.data.data.payment_link}`;
        } else {
          alert({
            title: `${t("Done")}`,
            text: `${t(`Thank you for send`)}`,

            icon: "success",
          });
        }
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    if (!activeTap) return;
    axios(url + `type/${activeTap}`, config)
      // Response
      .then((response) => {
        console.log(response.data.data.price);
        setPriceValue(response?.data?.data?.price);
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [activeTap]);

  return (
    <>
      <div className="row flex items-start justify-start flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
        <Title className="md:mb-8"> {t("Add Auction")}</Title>

        {/* Tabs */}

        <div className="w-full flex items-start justify-center flex-wrap my-6">
          {/* Vip Auction */}
          {/* <div
            onClick={() => {
              setTypeId(3);
              setActiveTap("vip-auctions");
            }}
            className={`cursor-pointer w-auto p-4 px-2 sm:px-6 mx-1 sm:mx-2 rounded-xl flex flex-col md:flex-row items-center gap-4 ${
              activeTap === "vip-auctions"
                ? "bg-blue text-white"
                : "bg-white border h-24"
            }`}
          >
            {activeTap === "vip-auctions" ? <VipWhite /> : <Vip />}
            <h3 className="text-[1.3rem] md:text-[1.6rem]">
              {t("VIP Auction")}
            </h3>
          </div> */}

          {/* Public Auction */}
          {/* <div
            onClick={() => {
              setTypeId(2);
              setActiveTap("quick-auctions");
            }}
            className={`cursor-pointer w-auto p-4 px-2 sm:px-6 mx-1 sm:mx-2 rounded-xl flex flex-col md:flex-row items-center gap-4 ${
              activeTap === "quick-auctions"
                ? "bg-blue text-white"
                : "bg-white border"
            }`}
          >
            {activeTap === "quick-auctions" ? <Public /> : <PublicIcon />}
            <h3 className="text-[1.3rem] md:text-[1.6rem]">
              {t("Quick Auction")}
            </h3>
          </div> */}

          {/* Quick Auction */}
          <div
            onClick={() => {
              setTypeId(1);
              setActiveTap("public-auctions");
            }}
            className={`cursor-pointer w-auto p-4 px-2 sm:px-6 mx-1 sm:mx-2 rounded-xl flex flex-col md:flex-row items-center gap-4 ${
              activeTap === "public-auctions"
                ? "bg-blue text-white"
                : "bg-white border"
            }`}
          >
            {activeTap === "public-auctions" ? <Quick /> : <Hours />}
            <h3 className="text-[1.3rem] md:text-[1.6rem]">
              {t("Public Auction")}
            </h3>
          </div>
        </div>
        {priceValue && (
          <h3 className="w-full flex items-center justify-center font-bold my-8 ">
            {" "}
            {t("auction value")} : {priceValue}{" "}
          </h3>
        )}

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Category")}{" "}
            </label>
            <select
              onFocus={() => setCategoryError(false)}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${
                CategoryError ? "border-red" : "border-blue"
              }`}
            >
              <option value={""}> {t("chosse")}. . . </option>
              {categories &&
                categories.map((item) => (
                  <option value={item.id} key={item.id}>
                    {" "}
                    {item.title}{" "}
                  </option>
                ))}
            </select>
            {CategoryError && (
              <ErrorText text={`${t("Please enter your animal category")}`} />
            )}
          </div>
        </div>

        {childs && childs.length > 0 && (
          <div className="w-full md:w-1/2 p-2 my-2">
            <div className="flex flex-col">
              <label htmlFor="" className="font-medium text-[1.5] ">
                {" "}
                {t("Type")}{" "}
              </label>
              <div className="flex">
                <select
                  onFocus={() => setTypeError(false)}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${
                    TypeError ? "border-red" : "border-blue"
                  }`}
                >
                  <option value={""}> {t("chosse")} . . . </option>

                  {childs &&
                    childs.map((item) => (
                      <option value={item.id} key={item.id}>
                        {" "}
                        {item.title}{" "}
                      </option>
                    ))}
                </select>
                <Link
                  to="/add-animal/add-sub-categorey"
                  className="flex items-center justify-center hover:opacity-70 bg-blue text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all px-4 rtl:mr-3 ltr:ml-3 rounded-lg"
                >
                  +
                </Link>
              </div>
              {TypeError && (
                <ErrorText
                  text={`${t("Please enter your animal category type")}`}
                />
              )}
            </div>
          </div>
        )}

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setAnimalSpeciesError(false)}
            input_className={`border-2  ${
              animalSpeciesError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={animalSpecies}
            onChange={(e) => setAnimalSpecies(e.target.value)}
            type="text"
            placeholder={t("Animal Species")}
            id="AnimalSpecies"
            label={t("Animal Species")}
          />
          {animalSpeciesError && (
            <ErrorText text={`${t("Please enter your animal species")}`} />
          )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            input_className="border-2 border-blue"
            div_className="w-full"
            value={height}
            onChange={(e) => setheight(e.target.value)}
            type="number"
            placeholder={t("height")}
            id="height"
            label={t("height")}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setDateofBirthdayError(false)}
            input_className={`border-2 ${
              dateofBirthdayError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={dateofBirthday}
            onChange={(e) => setDateofBirthday(e.target.value)}
            type="date"
            placeholder={t("Date of Birthday")}
            id="DateBirthday"
            label={t("Date of Birthday")}
          />
          {dateofBirthdayError && (
            <ErrorText
              text={`${t("Please enter your animal date of birthday")}`}
            />
          )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setPassportNoError(false)}
            input_className={`border-2 ${
              passportNoError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={passportNO}
            onChange={(e) => setPassportNO(e.target.value)}
            type="text"
            placeholder={t("Passport NO")}
            id="Passport"
            label={t("Passport NO")}
          />
          {passportNoError && (
            <ErrorText text={`${t("Please enter your animal passport No")}`} />
          )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setColorError(false)}
            input_className={`border-2 border-blue py-0 h-[4rem] ${
              colorError ? "border-red" : "border-blue"
            }`}
            div_className="w-full "
            value={color}
            onChange={(e) => setColor(e.target.value)}
            type="color"
            placeholder={t("Color")}
            id="Color"
            label={t("Color")}
          />
          {colorError && (
            <ErrorText text={`${t("Please enter your animal color")}`} />
          )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setweightError(false)}
            input_className={`border-2  ${
              weightError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            type="number"
            placeholder={t("Weight")}
            id="Weight"
            label={t("Weight")}
          />
          {weightError && (
            <ErrorText text={`${t("Please enter your animal weight")}`} />
          )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2 hidden">
          <Input
            onFocus={() => setlocationError(false)}
            input_className={`border-2 border-blue ${
              locationError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            type="text"
            placeholder={t("location")}
            id="location"
            label={t("location")}
          />
        </div>

        {/* <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="font-medium text-[1.5] rtl:text-right block w-full"
            >
              {" "}
              {t("Price Type")}{" "}
            </label>
            <Select
              onFocus={() => setpriceTypeError(false)}
              className={`${priceTypeError && "error"}`}
              options={pricesType}
              onChange={(e) => setPriceType(e.value)}
            />
          </div>
        </div> */}

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setpriceError(false)}
            input_className={`border-2 border-blue ${
              priceError ? "border-red" : "border-blue"
            }`}
            div_className="w-full"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            type="number"
            placeholder={t("price")}
            id="price"
            label={t("Price")}
          />
          {priceError && (
            <ErrorText text={`${t("Please enter your animal price")}`} />
          )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload one Image")}{" "}
            </label>
            <div
              onFocus={() => setsingleImageError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                singleImageError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => {
                  setUploadSingleImage(e.target.files[0]);
                  console.log(e.target.files[0]);
                }}
                accept="image/jpg, image/jpeg,image/png"
                className=" mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                {t("Upload Image")}
              </span>
            </div>
          </div>
            {singleImageError && (
              <ErrorText text={`${t("Please enter your Image")}`} />
            )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload three Images")}{" "}
            </label>
            <div
              onFocus={() => setImagesError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16 ${
                ImagesError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={onImageChange}
                multiple
                accept="image/jpg, image/jpeg,image/png"
                className=" mt-2 opacity-60 cursor-pointer"
                type="file"
              />
              <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[11.5rem]">
                {t("Upload Image")}
              </span>
            </div>
          </div>
          {ImagesError && (
              <ErrorText text={`${t("Please enter your single Image")}`} />
            )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <div className="flex flex-col dir-ltr-ar">
            <label
              htmlFor=""
              className="ltr:text-left rtl:text-right font-medium text-[1.5]"
            >
              {" "}
              {t("Upload Video")}{" "}
            </label>
            <div
              onFocus={() => setvideoError(false)}
              className={`border-2 rounded-lg relative overflow-hidden h-16  ${
                videoError ? "border-red" : "border-blue"
              }`}
            >
              <input
                onChange={(e) => setUploadVideo(e.target.files[0])}
                className=" mt-2 opacity-60 cursor-pointer"
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
              />
              <span className="absolute -left-[0.2rem] top-0 h-full leading-[2.5] bg-blue text-white text-center px-3 border-r w-[10.5rem]">
                {t("Upload Video")}
              </span>
            </div>
          </div>
            {videoError && (
              <ErrorText text={`${t("Please enter your video")}`} />
            )}
        </div>
        {/* <div className="w-full md:w-1/2 p-2 my-2">
                         <label htmlFor="" className="font-medium text-[1.5] mb-2"> {t('Posted on')} </label>
                         <div className="flex items-center justify-between flex-wrap w-full mt-2">
                              <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
                                   {t('My page')}
                                   <input name="PostedOn" value='MyPage' checked={showIn === "1"} onChange={() => setShowIn('1')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
                                   <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
                              </label>
                              <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
                                   {t('My page & AWA page')}
                                   <input name="PostedOn" value='AWAPage' checked={showIn === '2'} onChange={() => setShowIn('2')} type="radio" className='absolute opacity-0 cursor-pointer h-0 w-0' />
                                   <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
                              </label>

                         </div>
                    </div> */}
        <div className="w-full md:w-1/2 p-2 my-2">
          <label htmlFor="" className="font-medium text-[1.5] mb-2">
            {" "}
            {t("Gender")}{" "}
          </label>
          <div className="flex items-center justify-between flex-wrap w-full mt-2">
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Male")}
              <input
                name="radio"
                value="1"
                checked={gender === "1"}
                onChange={() => setGender("1")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t("Female")}
              <input
                name="radio"
                value="2"
                checked={gender === "2"}
                onChange={() => setGender("2")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>

            <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
              {t(`Don't Now`)}
              <input
                name="radio"
                value="3"
                checked={gender === "3"}
                onChange={() => setGender("3")}
                type="radio"
                className="absolute opacity-0 cursor-pointer h-0 w-0"
              />
              <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
            </label>
          </div>
        </div>

        <hr className="block my-12 w-full " />
        <h3 className="w-full rtl:text-right ltr:text-left font-bold px-4">
          {t("Contact")}
        </h3>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setphoneError(false)}
            input_className={`border-2 ${
              phoneError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="tel"
            placeholder={t("Phone")}
            id="Phone"
            label={t("Phone")}
          />
           {phoneError && (
              <ErrorText text={`${t("Please enter your phone")}`} />
            )}
        </div>

        <div className="w-full md:w-1/2 p-2 my-2">
          <Input
            onFocus={() => setemailError(false)}
            input_className={`border-2 ${
              emailError ? "border-red" : "border-blue"
            } `}
            div_className="w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder={t("Email")}
            id="Email"
            label={t("Email")}
          />
           {emailError && (
              <ErrorText text={`${t("Please enter your email")}`} />
            )}
        </div>

        <div className="w-full p-2">
          <label htmlFor="" className="w-full font-medium text-[1.5]">
            {" "}
            {t("Note")}{" "}
          </label>
          <textarea
            onFocus={() => setnoteError(false)}
            className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${
              noteError ? "border-red" : "border-blue"
            }`}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            name=""
            id=""
            cols="30"
            rows="5"
            placeholder={t("Note . . .")}
          ></textarea>
        </div>

        <div
          onFocus={() => setmapError(false)}
          className={`w-full p-2  ${mapError && "border-red border"}`}
        >
          <Map
            address={(e) => setLocation(e)}
            lat={(e) => setLat(e)}
            lng={(e) => setLng(e)}
          />
        </div>
        <label
          onFocus={() => setCheckError(false)}
          className={`w-full CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3 ${
            checkError && "text-red"
          }`}
        >
          {t("I agree to the")}{" "}
          <Link to="/term-condition" className="text-cyan-500">
            {" "}
            {t("Terms and Use Policy")}{" "}
          </Link>
          <input
            name="check"
            value="1"
            checked={check === "1"}
            onChange={() => setCheck("1")}
            type="checkbox"
            className="absolute opacity-0 cursor-pointer h-0 w-0"
          />
          <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
        </label>
        <div className="w-full flex items-center justify-center">
          <BlueButton
            disabled={loading}
            className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${
              loading && "cursor-not-allowed"
            }`}
            onClick={submitHandler}
          >
            {loading ? <ButtonLoading /> : `${t("Send")}`}
          </BlueButton>
        </div>
        <div className="my-8 w-full flex items-end justify-center">
          <p className="font-bold text-red capitalize">
            {locationError && (
              <ErrorText text={`${t("Please enter your location")}`} />
            )}

            {priceTypeError && (
              <ErrorText text={`${t("Please enter your type")}`} />
            )}

           
           
           
          
            {mapError && (
              <ErrorText text={`${t("Please enter your map location")}`} />
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default AddAuction;
