import React from 'react'

const HomeIcon = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g opacity="0.5">
                    <path d="M15.0719 2.48745L15.0712 2.48796L5.59658 9.95393C5.59657 9.95394 5.59655 9.95395 5.59654 9.95396C4.27286 10.9968 3.5 12.5895 3.5 14.2754V27C3.5 28.1968 4.46986 29.1667 5.66667 29.1667H11.6667C12.8635 29.1667 13.8333 28.1968 13.8333 27V21C13.8333 20.5395 14.2061 20.1667 14.6667 20.1667H17.3333C17.7939 20.1667 18.1667 20.5395 18.1667 21V27C18.1667 28.1968 19.1365 29.1667 20.3333 29.1667H26.3333C27.5301 29.1667 28.5 28.1968 28.5 27V14.274C28.5 12.5889 27.7278 10.9968 26.4041 9.95394C26.4041 9.95394 26.4041 9.95393 26.4041 9.95393L16.9281 2.48794L16.9278 2.48773C16.3836 2.05954 15.6163 2.0599 15.0719 2.48745Z" stroke="#1E1E1E" />
               </g>
          </svg>  
     )
}

export default HomeIcon