import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import User from "../../assets/Icons/User";
import logo from "../../assets/images/logo/logo + words@2x.png";
import { userImage } from "../Helpers/Data";
import { config, url } from "../Helpers/Help";
import Lang from "../Lang/Lang";
import AddAnimal from "../Seactions/AddAnimal";
import BlueButton from "../UI/Buttons/BlueButton";
import Verified from "../../assets/Icons/Verified.jsx";
import Menu from "../UserSideBar/Menu";
import Badge from "../../assets/Icons/Badge";
import BadgeGray from "../../assets/Icons/BadgeGray";
const Nav = () => {
  const isLoggedIn =
    localStorage.getItem("__authtk__awasell_secure") &&
    localStorage.getItem("__User_aW__a_Info");
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [scroll, setScroll] = useState(false);
  const [addAnimal, setAddAnimal] = useState(false);
  const [addAnimalCss, setAddAnimalCss] = useState(false);
  const [menu, setMenu] = useState(false);
  const [userData, setData] = useState();

  useEffect(() => {
    axios(url + "users/info ", config).then((response) => {
      localStorage.setItem(
        "__User_aW__a_Info",
        JSON.stringify(response.data.data.user)
      );
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem("__User_aW__a_Info");
      setData(JSON.parse(data));
    }
    function handleResize() {
      setDimensions(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimensions]);

  const HandleAddAnimal = () => {
    document.body.style.overflowY = "hidden";
    if (!addAnimal) {
      setAddAnimal((prevState) => !prevState);
      setTimeout(() => {
        setAddAnimalCss((prevState) => !prevState);
      }, 500);
    }
  };
  const closeAddAnimal = () => {
    document.body.style.overflowY = "auto";
    if (addAnimal) {
      setAddAnimalCss((prevState) => !prevState);
      setTimeout(() => {
        setAddAnimal((prevState) => !prevState);
      }, 500);
    }
  };

  const HandleMenu = () => {
    document.body.style.overflowY = "hidden";
    if (!addAnimal) {
      setMenu((prevState) => !prevState);
      setTimeout(() => {
        setAddAnimalCss((prevState) => !prevState);
      }, 500);
    }
  };
  const closeMenu = () => {
    document.body.style.overflowY = "auto";
    if (menu) {
      setAddAnimalCss((prevState) => !prevState);
      setTimeout(() => {
        setMenu((prevState) => !prevState);
      }, 500);
    }
  };

  return (
    <>
      <nav
        className={`bg-white py-4 shadow-sm top-0 w-full z-40 ${
          scroll ? "fixed" : "absolute"
        }`}
      >
        <div className="container mx-auto">
          <div className="w-full flex items-center flex-wrap">
            <div className="w-full md:w-2/3 lg:w-1/2">
              <div className="flex items-center justify-center md:justify-start flex-wrap">
                <Link
                  to="/"
                  className="rtl:ml-4 rtl:lg:ml-12 ltr:mr-4 ltr:lg:mr-12"
                >
                  <img src={logo} alt="" className="h-16 block mx-auto " />
                </Link>
                <div className="linksNav flex items-center justify-start flex-wrap gap-8 capitalize md:text-[1.3rem] lg:text-[1.4rem] xl:text-[1.6rem] font-semibold my-4 md:my-0">
                  <Link to="/" className="">
                    {t("Home")}
                  </Link>
                  {/* <Link to='#!' className="">{t('Blogs')}</Link> */}
                  <Link to="/ads" className="">
                    {t("Ads")}
                  </Link>
                  <Link to="/buy" className="">
                    {t("Buy")}
                  </Link>
                  <Link to="/sell" className="">
                    {t("Sell")}
                  </Link>
                  <Link to="/about-us" className="">
                    {t("About us")}
                  </Link>
                  <Link to="/contact-us" className="">
                    {t("Contact Us")}
                  </Link>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 lg:w-1/2 hidden md:block">
              <div className="flex items-center justify-center md:justify-end flex-wrap gap-8">
                {isLoggedIn && userData && (
                  <BlueButton
                    className={`AddAnimalButton  ${
                      window.innerWidth > 1024
                        ? "py-4 px-8"
                        : "py-2 px-4 w-20 h-20 rounded-full"
                    }`}
                    onClick={HandleAddAnimal}
                  >
                    {window.innerWidth > 1024 ? (
                      ` ${t("Add Animal")} `
                    ) : (
                      <span className="text-[3rem]">+</span>
                    )}
                  </BlueButton>
                )}
                {/* <Lang /> */}
                <Lang />
                {isLoggedIn && userData ? (
                  <button onClick={HandleMenu}>
                    <div className="user flex items-center gap-2">
                      <div className="userImage w-12 h-12 rounded-full relative overflow-hidden bg-blue text-white flex items-center justify-center font-bold">
                        {/* <img src={userImage} alt="" className='h-full w-full object-cover' /> */}
                        {userData.personal_picture ? (
                          <img
                            src={userData.personal_picture}
                            alt={userData.f_name}
                          />
                        ) : (
                          userData.f_name.charAt(0)
                        )}
                      </div>
                      {window.innerWidth > 1024 && (
                        <div className="name flex items-center justify-start">
                          {userData?.show_name ? userData.show_name : "user"}
                          {userData?.is_verified === 1 && <Verified />}
                          {userData?.badge === "gold" ? (
                            <Badge />
                          ) : (
                            <BadgeGray />
                          )}
                          {userData?.access_data && (
                            <img
                              src={userData?.access_data?.back}
                              alt={userData?.show_name}
                              className="w-6 mx-2"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </button>
                ) : (
                  <Link
                    to="/login-signup"
                    className="loginLink flex items-center flex-wrap text-[1.6rem] font-bold gap-4"
                  >
                    <User /> {t("Log in")}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {addAnimal && (
        <AddAnimal
          onClick={closeAddAnimal}
          className={`${
            addAnimalCss
              ? "right-0 transition-all duration-1000"
              : "-right-[50rem] transition-all duration-1000"
          }`}
        />
      )}

      {menu && (
        <Menu
          onClick={closeMenu}
          className={`${
            addAnimalCss
              ? "right-0 transition-all duration-1000"
              : "-right-[150rem] transition-all duration-1000"
          }`}
        />
      )}
    </>
  );
};

export default Nav;
