import React from 'react'

const LeftArrow = () => {
  return (
       <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4852 0.491522C10.2904 0.497199 10.1055 0.578454 9.96958 0.718084L0.219583 10.4681C0.0789832 10.6087 0 10.7995 0 10.9984C0 11.1972 0.0789832 11.388 0.219583 11.5286L9.96958 21.2786C10.0387 21.3506 10.1215 21.4081 10.2131 21.4477C10.3047 21.4872 10.4032 21.5082 10.503 21.5092C10.6028 21.5102 10.7018 21.4913 10.7942 21.4536C10.8865 21.4159 10.9705 21.3601 11.041 21.2895C11.1116 21.219 11.1674 21.135 11.2051 21.0427C11.2428 20.9503 11.2617 20.8513 11.2607 20.7515C11.2597 20.6517 11.2387 20.5532 11.1992 20.4616C11.1596 20.37 11.1021 20.2872 11.0301 20.2181L1.8104 10.9984L11.0301 1.77863C11.1383 1.67325 11.2122 1.53767 11.2421 1.38961C11.2719 1.24156 11.2564 1.08794 11.1975 0.948856C11.1386 0.809772 11.0391 0.691704 10.912 0.610104C10.7849 0.528504 10.6362 0.487174 10.4852 0.491522Z" fill="black" />
       </svg>

  )
}

export default LeftArrow