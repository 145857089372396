import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import VideoCard from "../../Components/UI/Cards/Video-card";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { config, url } from "../../Components/Helpers/Help";
import ProfileUser from "../../Components/Seactions/ProfileUser";
import { shortVideosSlider } from "../../Components/Helpers/SlidersOptions";
import Search from "../../Components/Search/Search";
import PublicCategories from "../../Components/Seactions/PublicCategories";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ShortVideoCard from "../../Components/UI/Cards/ShortVideoCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import PreLoader from "../../Components/UI/PreLoader";
import { t } from "i18next";
import NotItems from "../../Components/UI/NotItems";
import { Player } from "video-react";

const Video = () => {
  const params = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [mainVideos, setMainVideos] = useState()
  const videoID = params.pathname.split("/").at(-1)


  useEffect(() => {
    if (videoID) {
      axios(url + `animals/details/videos/${videoID}`, config)
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
        .then((response) => {
          console.log("xxx", response?.data?.data);
          setMainVideos(response?.data?.data)
          setIsLoading(false)
        })

    }
  }, [params]);


  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        <div className="my-12">
          <PublicCategories />
        </div>
        {/* <section className="mt-12">
              <Search />
            </section> */}
        {
          mainVideos
            ?
            <section className="">
              <div className="w-full my-3" key={videoID}>
                <Player
                  height={650}
                  width={'100%'}
                  fluid={false}
                  playsInline
                  poster={mainVideos.thumbnail}
                  src={mainVideos.file}
                  autoPlay={true}
                />
              </div>
            </section>
            :
            null
        }
      </main>
    </Layout>
  );
};

export default Video;
