import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { config, url } from "../../Components/Helpers/Help";
import { t } from "i18next";
import VipAuction from "../../Components/UI/AnimalCard/VipAuction";
import SectionTitle from "../../Components/UI/Titles/SectionTitle";
import Vip from "../../assets/Icons/Vip";
import Hour24 from "../../assets/Icons/Hour24";
import FridayMarket from "../../Components/UI/AnimalCard/FridayMarket";
import WholesaleMarket from "../../Components/UI/AnimalCard/WholesaleMarket";
import TodayMarket from "../../Components/UI/AnimalCard/TodayMarket";
import SpecialOffer from "../../Components/UI/AnimalCard/SpecialOffer";
import AdoptionForAnimal from "../../Components/UI/AnimalCard/AdoptionForAnimal";
import Mating from "../../Components/UI/AnimalCard/Mating";
import Photographer from "../../Components/UI/AnimalCard/Photographer";
import LostAnimal from "../../Components/UI/AnimalCard/LostAnimal";
import VeterinaryClinic from "../../Components/UI/AnimalCard/VeterinaryClinic";
import LookFor from "../../Components/UI/AnimalCard/LookFor";
import Delivery from "../../Components/UI/AnimalCard/Delivery";
import Questions from "../../Components/Seactions/Questions";
import Others from "../../Components/UI/AnimalCard/Others";
import Videos from "../../Components/Seactions/Videos";
import ProfileUser from "../../Components/Seactions/ProfileUser";
import VideoCard from "../../Components/UI/Cards/Video-card";
import QuestionsCard from "../../Components/UI/Cards/Questions-card";
import alert from "../../Helpers/Modal";
import BlueButton from "../../Components/UI/Buttons/BlueButton";
import SalesCard from "../../Components/UI/AnimalCard/SalesCard";
import PreLoader from "../../Components/UI/PreLoader";
import errorImage from "../../assets/images/error.jpg";
import NotItems from "../../Components/UI/NotItems";

const UserPage = () => {
  const params = useLocation();
  const slug = +params.pathname.split("/")[2];
  const [userInfo, setUserInfo] = useState();
  const [data, setData] = useState();
  const [rateValue, setRateValue] = useState();
  const [myId, setMyId] = useState();
  const [rateMeModal, setRateMeModal] = useState(false);
  const [id, setId] = useState();
  const [modal, setModal] = useState();
  const [answaerMessage, setAnswaerMessage] = useState();
  const [infoCounts, setInfoCounts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    axios(url + `users/info-counts?user_id=${slug}`, config)
      .then((response) => {
        console.log(response.data.data.section);
        const res = response.data.data;
        setInfoCounts(res.section);
      })
      .catch((error) => {
        console.log(error);
        alert({
          title: `${t("Alert")}`,
          text: error.response.data?.message,
          icon: "error",
          timer: 1000,
        });
      });
  }, [slug]);

  useEffect(() => {
    setIsLoading(true);
    axios(url + `users/info?user_id=${slug}`, config)
      .then((response) => {
        setData(response.data.data.section);
        setUserInfo(response.data.data.user);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error && error.response.status === 500) {
          setServerError(true);
        } else {
          setServerError(false);
        }
        alert({
          title: `${t("Alert")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 1000,
        });
      });
  }, [slug]);

  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem("__User_aW__a_Info");
      setMyId(JSON.parse(data));
    }
  }, []);

  const rateMe = (rate) => {
    axios
      .post(
        url + "users/rate",
        //  Data
        {
          rated_id: slug,
          rate: rateValue,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        setRateMeModal(false);
        console.log(response);
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };

  // Submit my answer
  const submitAnswer = (e) => {
    e.preventDefault();
    axios
      .post(
        url + "questions/answer",
        //  Data
        {
          // I mean with modal => id
          question_id: id,
          content: answaerMessage,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        setModal(false);
        setAnswaerMessage();
        alert({
          title: `${t("Done")}`,
          text: `${t("Thanks for sharing your answer")}`,
          icon: "success",
          timer: 2000,
        });
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };
  const profileEmpty =
    (!data?.friday_market || data.friday_market.length == 0) &&
    (!data?.auction_vip || data?.auction_vip.length == 0) &&
    (!data?.auction_quick || data?.auction_quick.length == 0) &&
    (!data?.wholesale || data?.wholesale.length == 0) &&
    (!data?.today_market || data?.today_market.length == 0) &&
    (!data?.videos || data?.videos.length == 0) &&
    (!data?.offers || data?.offers.length == 0) &&
    (!data?.adoption || data?.adoption.length == 0) &&
    (!data?.mating || data?.mating.length == 0) &&
    (!data?.photographer || data?.photographer.length == 0) &&
    (!data?.lost_animal || data?.lost_animal.length == 0) &&
    (!data?.clinic || data?.clinic.length == 0) &&
    (!data?.looking_for || data?.looking_for.length == 0) &&
    (!data?.clinic_delivery || data?.clinic_delivery.length == 0) &&
    (!data?.questions || data?.questions.length == 0) &&
    (!data?.other || data?.other.length == 0) &&
    (!data?.sales_pages || data?.sales_pages.length == 0);
  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-32 px-4 md:px-0 min-h-screen">
        {modal && (
          <>
            <div
              className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40"
              onClick={() => setModal(false)}
            />
            <div
              className={`fixed shadow-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[50rem] bg-[#F9F9F9] rounded-2xl z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}
            >
              <button
                className="w-12 h-12 border-2 font-bold text-[2rem] flex items-center justify-center rounded-full absolute right-8 top-4 border-gray-600"
                onClick={() => setModal(false)}
              >
                x
              </button>
              <div className="flex flex-col justify-center items-center">
                <h3 className="font-bold text-[1.8rem] mb-8">
                  {t("Your Answer")}
                </h3>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="7"
                  className="w-full mb-8 rounded-xl resize-none p-4"
                  onChange={(e) => setAnswaerMessage(e.target.value)}
                ></textarea>
                <BlueButton
                  onClick={submitAnswer}
                  className="py-6 px-8 xl:px-28"
                >
                  {t("Submit")}
                </BlueButton>
              </div>
            </div>
          </>
        )}

        {rateMeModal && (
          <>
            <div
              className="overlay fixed right-0 top-0 bg-black/90 h-screen w-screen z-40"
              onClick={() => setRateMeModal(false)}
            />
            <div
              className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pb-[6rem] md:pb-0 shadow-xl w-8/12 md:w-[60rem] bg-[#F9F9F9]  z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}
            >
              <h3 className="border-b-2 border-blue pb-4 font-semibold text-[1.6rem]">
                {" "}
                {t("Rate Me")}{" "}
              </h3>
              <div className="mt-4 flex flex-wrap items-center justify-between">
                <div className="rate w-full flex justify-center h-[5.6rem] ltr:float-right rtl:float-left mt-12 px-[1rem]">
                  <input
                    type="radio"
                    id="star5"
                    name="rate"
                    value="5"
                    onClick={(e) => setRateValue(e.target.value)}
                  />
                  <label for="star5" title="text">
                    5 stars
                  </label>
                  <input
                    type="radio"
                    id="star4"
                    name="rate"
                    value="4"
                    onClick={(e) => setRateValue(e.target.value)}
                  />
                  <label for="star4" title="text">
                    4 stars
                  </label>
                  <input
                    type="radio"
                    id="star3"
                    name="rate"
                    value="3"
                    onClick={(e) => setRateValue(e.target.value)}
                  />
                  <label for="star3" title="text">
                    3 stars
                  </label>
                  <input
                    type="radio"
                    id="star2"
                    name="rate"
                    value="2"
                    onClick={(e) => setRateValue(e.target.value)}
                  />
                  <label for="star2" title="text">
                    2 stars
                  </label>
                  <input
                    type="radio"
                    id="star1"
                    name="rate"
                    value="1"
                    onClick={(e) => setRateValue(e.target.value)}
                  />
                  <label for="star1" title="text">
                    1 star
                  </label>
                </div>
                <div className="w-full flex items-center justify-center my-8">
                  <BlueButton
                    onClick={rateMe}
                    className="flex p-4 px-8 rounded-none"
                  >
                    {t("Send")}
                  </BlueButton>
                </div>
              </div>
            </div>
          </>
        )}

        {userInfo && (
          <ProfileUser
            id={userInfo.id}
            bio={userInfo.bio}
            rate={userInfo.avg_rate_int}
            rateMe={() => setRateMeModal(true)}
            isMine={myId && myId.id !== userInfo.id}
            follow={myId && myId.id !== userInfo.id}
            personal_picture={userInfo.personal_picture}
            f_name={userInfo.f_name}
            nickname={userInfo.nickname}
            is_rated={userInfo.is_rated}
            l_name={userInfo.l_name}
            posts={userInfo.posts}
            followers={+userInfo.followers}
            following={userInfo.following}
            is_followed={userInfo.is_followed}
          />
        )}
        {isLoading ? (
          <PreLoader />
        ) : (
          <>
            {serverError ? (
              <img
                src={errorImage}
                alt="server error"
                className="w-[50rem] h-[50rem] xl:mt-48 block mx-auto mix-blend-multiply object-contain"
              />
            ) : (
              <>
                {data && (
                  <>
                    {slug !== userInfo?.id &&
                      data.auction_vip &&
                      data.auction_vip.length > 0 && (
                        <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                          <SectionTitle
                            number={infoCounts?.auction_vip}
                            className="w-full"
                          >
                            <Vip /> {t("Upcoming VIP Auction")}
                          </SectionTitle>
                          {data.auction_vip.map((item, index) => (
                            <div
                              className="w-1/2 md:w-1/2 xl:w-1/3 my-3"
                              key={item.id}
                            >
                              <VipAuction
                                is_notify={item.is_notify}
                                key={item.id}
                                id={item.id}
                                isMine={item.is_mine}
                                slug={item.slug}
                                img={item.image}
                                end_minutes={item.end_minutes}
                                start={item.start_time}
                                type={item.category && item.category.title}
                                max_price={item.max_price}
                                gender={item.gender}
                                age={item.age}
                                users_count={item.users_count}
                                weight={item.weight}
                                isFav={item.isFav}
                                inMarket={
                                  item.in_market && item.in_market_till !== 0
                                }
                              />
                            </div>
                          ))}
                        </section>
                      )}
                    {slug !== userInfo.id &&
                      data.auction_quick &&
                      data.auction_quick.length > 0 && (
                        <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                          <SectionTitle
                            number={infoCounts?.auction_quick}
                            className="w-full"
                          >
                            <Hour24 /> {t("Upcoming Quick Auction")}
                          </SectionTitle>
                          {data.auction_quick.map((item, index) => (
                            <div
                              className="w-1/2 md:w-1/2 xl:w-1/3 my-3"
                              key={item.id}
                            >
                              <VipAuction
                                is_notify={item.is_notify}
                                key={item.id}
                                id={item.id}
                                isMine={item.is_mine}
                                slug={item.slug}
                                img={item.image}
                                end_minutes={item.end_minutes}
                                start={item.start_time}
                                type={item.category && item.category.title}
                                max_price={item.max_price}
                                gender={item.gender}
                                age={item.age}
                                users_count={item.users_count}
                                weight={item.weight}
                                isFav={item.isFav}
                                inMarket={
                                  item.in_market && item.in_market_till !== 0
                                }
                              />
                            </div>
                          ))}
                        </section>
                      )}
                    {data.friday_market && data.friday_market.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.friday_markets}
                          className="w-full"
                        >
                          {t("Friday Market")}{" "}
                        </SectionTitle>
                        {data.friday_market.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 2xl:w-1/4 my-3"
                            key={item.id}
                          >
                            <FridayMarket
                              slug={item.slug}
                              key={item.id}
                              id={item.id}
                              img={item.image}
                              type={item.category && item.category.title}
                              badge={item.user && item.user.badge}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              action={item.action}
                              price={item.price}
                              location={item.city && item.city.name}
                              duration={item.duration}
                              inMarket={item.inMarket}
                              whatsapp={
                                item.user && item.whatsapp && item.user.whatsapp
                              }
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.wholesale && data.wholesale.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.wholesale}
                          className="w-full"
                        >
                          {t("Wholesale Market")}{" "}
                        </SectionTitle>
                        {data.wholesale.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
                            key={item.id}
                          >
                            <WholesaleMarket
                              slug={item.slug}
                              id={item.id}
                              key={item.id}
                              img={item.image}
                              type={item.category && item.category.title}
                              badge={item.user && item.user.badge}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              action={item.action}
                              price={item.price}
                              location={item.city && item.city.name}
                              description={item.note}
                              inMarket={item.inMarket}
                              whatsapp={
                                item.user && item.whatsapp && item.user.whatsapp
                              }
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.today_market && data.today_market.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.today_market}
                          className="w-full"
                        >
                          {t("Today Market")}
                        </SectionTitle>
                        {data.today_market.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
                            key={item.id}
                          >
                            <TodayMarket
                              key={item.id}
                              slug={item.slug}
                              id={item.id}
                              img={item.image}
                              type={item.category && item.category.title}
                              badge={item.user && item.user.badge}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              location={item.city && item.city.name}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.videos && data.videos.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.videos}
                          className="w-full"
                        >
                          {t("Videos")}{" "}
                        </SectionTitle>
                        {data.videos.map((item, index) => (
                          <div
                            className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 my-3"
                            key={index}
                          >
                            <VideoCard
                              views_count={item.views_count}
                              slug={item.slug}
                              key={item.id}
                              id={item.id}
                              likes_count={item.likes_count}
                              comment_count={item.comment_count}
                              description={item.content}
                              poster={item.thumbnail}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              name={
                                item.user &&
                                item.user.f_name &&
                                item.user.f_name
                              }
                              video={item.file}
                              badge={item.user && item.user.badge}
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                              location={
                                item.user &&
                                item.user.city &&
                                item.user.city.name
                              }
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.offers && data.offers.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.offers}
                          className="w-full"
                        >
                          {t("Special Offers")}{" "}
                        </SectionTitle>
                        {data.offers.map((item, index) => (
                          <div
                            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/5 my-3"
                            key={item.id}
                          >
                            <SpecialOffer
                              key={item.id}
                              id={item.id}
                              slug={item.slug}
                              img={item.image}
                              type={item.category && item.category.title}
                              badg={item.badg}
                              price={item.offer_price}
                              oldPrice={
                                +item.price > +item.offer_price && item.price
                              }
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              location={item.city && item.city.name}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.adoption && data.adoption.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.adoptions}
                          className="w-full"
                        >
                          {t("Adoption for animal")}{" "}
                        </SectionTitle>
                        {data.adoption.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <AdoptionForAnimal
                              key={item.id}
                              id={item.id}
                              img={item.image}
                              slug={item.slug}
                              badg={item.badg}
                              price={item.price}
                              isFav={item.isFav}
                              age={item.age}
                              duration={item.price_unit_text}
                              weight={item.weight}
                              gender={item.gender}
                              location={item.city && item.city.name}
                              locationAnimal={item.city && item.city.name}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              phone={item.phone}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.mating && data.mating.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.mating}
                          className="w-full"
                        >
                          {t("Mating")}{" "}
                        </SectionTitle>
                        {data.mating.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <Mating
                              id={item.id}
                              key={item.id}
                              slug={item.slug}
                              img={item.image}
                              type={item.category && item.category.title}
                              badg={item.badg}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              location={item.city && item.city.name}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.photographer && data.photographer.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.photographer}
                          className="w-full"
                        >
                          {t("Photographer")}{" "}
                        </SectionTitle>
                        {data.photographer.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <Photographer
                              key={item.id}
                              id={item.id}
                              img={item.image}
                              slug={item.slug}
                              type={item.category && item.category.title}
                              badg={item.user && item.user.badge}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              // location={item.user && item.user.city.name}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              whatsapp={item.user && item.user.whatsapp}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.lost_animal && data.lost_animal.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.lost_animal}
                          className="w-full"
                        >
                          {t("Lost Animal")}{" "}
                        </SectionTitle>
                        {data.lost_animal.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 my-3"
                            key={item.id}
                          >
                            <LostAnimal
                              id={item.id}
                              type={item.category && item.category.title}
                              img={item.image}
                              loastDate={item.lost_date}
                              // location={item.user && item.user.city.name}
                              badge={item.user && item.user.badge}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.clinic && data.clinic.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.veterinaries}
                          className="w-full"
                        >
                          {t("Veterinary Clinic")}{" "}
                        </SectionTitle>

                        {data.clinic.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <VeterinaryClinic
                              id={item.id}
                              slug={item.slug}
                              img={item.image}
                              title={item.title}
                              badge={item.user && item.user.badge}
                              location={item.address}
                              name={item.user && item.user.f_name}
                              phone={item.user && item.user.phone}
                              email={item.user && item.user.email}
                              whatsapp={item.user && item.user.whatsapp}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.looking_for && data.looking_for.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.looking_for}
                          className="w-full"
                        >
                          {t("Look for")}{" "}
                        </SectionTitle>
                        {data.looking_for.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <LookFor
                              id={item.id}
                              key={item.id}
                              img={item.image}
                              type={item.category && item.category.title}
                              badge={item.user && item.user.badge}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age_range_from={item.age_range_to}
                              age_range_to={item.age_range_from}
                              price_range_from={item.price_range_from}
                              price_range_to={item.price_range_to}
                              weight={item.weight}
                              gender={item.gender}
                              address={item.address}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.clinic_delivery &&
                      data.clinic_delivery.length > 0 && (
                        <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                          <SectionTitle
                            number={infoCounts?.deliveries}
                            className="w-full"
                          >
                            {t("Delivery")}{" "}
                          </SectionTitle>
                          {data.clinic_delivery.map((item, index) => (
                            <div
                              className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                              key={item.id}
                            >
                              <Delivery
                                id={item.id}
                                slug={item.slug}
                                rate={item.avg_rate_int}
                                className="h-[36.5rem]"
                                img={item.image}
                                location={item.address}
                                phone={item.user && item.user.phone}
                                email={item.user && item.user.email}
                                whatsapp={item.user && item.user.whatsapp}
                              />
                            </div>
                          ))}
                        </section>
                      )}
                    {data.questions && data.questions.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.questions}
                          className="w-full"
                        >
                          {t("Questions")}{" "}
                        </SectionTitle>
                        {data.questions.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <QuestionsCard
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              location={item.user && item.user.city.name}
                              badge={item.user && item.user.badge}
                              tags={item.tags}
                              description={item.content}
                              hashtags={item.hashtags}
                              id={item.id}
                              slug={item.slug}
                              openModal={() => {
                                setId(item.id);
                                setModal(true);
                              }}
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                    {data.other && data.other.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.other}
                          className="w-full"
                        >
                          {t("Others")}{" "}
                        </SectionTitle>
                        {data.other.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <Others
                              id={item.id}
                              img={item.image}
                              type={item.category && item.category.title}
                              badg={item.user && item.user.badge}
                              price={item.price}
                              name={item.user && item.user.f_name}
                              whatsapp={item.user && item.user.whatsapp}
                              location={
                                item.user &&
                                item.user.city &&
                                item.user.city.name
                              }
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}

                    {data.sales_pages && data.sales_pages.length > 0 && (
                      <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                        <SectionTitle
                          number={infoCounts?.sales_pages}
                          className="w-full"
                        >
                          {t("sales pages")}{" "}
                        </SectionTitle>
                        {data.sales_pages.map((item, index) => (
                          <div
                            className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2"
                            key={item.id}
                          >
                            <SalesCard
                              slug={item.slug}
                              key={item.id}
                              id={item.id}
                              price={item.price}
                              max_price={item.max_price}
                              users_count={item.users_count}
                              img={item.image}
                              type={item.category && item.category.title}
                              badg={item.user && item.user.is_vip}
                              isFav={item.isFav}
                              phone={item.user && item.user.phone}
                              age={item.age}
                              weight={item.weight}
                              gender={item.gender}
                              action={item.action}
                              location={item.city && item.city.name}
                              duration={item.duration}
                              inMarket={item.inMarket}
                              whatsapp={item.user && item.user.whatsapp}
                              email={item.user && item.user.email}
                              name={item.user && item.user.f_name}
                              userImage={
                                item.user && item.user.personal_picture
                              }
                              userId={item.user && item.user.id}
                              rate={item.user && item.user.avg_rate_int}
                              is_verified={item.user && item.user.is_verified}
                            />
                          </div>
                        ))}
                      </section>
                    )}
                  </>
                )}
                {}
              </>
            )}
            {profileEmpty && <h1 className=" text-[3.2rem] text-center mt-48 text-gray-600">{t("that's all")}</h1>}
          </>
        )}

        {/* {
          (!isLoading && infoCounts.accessories === 0 && infoCounts.adoptions === 0 && infoCounts.auction_quick === 0 && infoCounts.auction_vip === 0 && infoCounts.deliveries === 0 && infoCounts.favourits === 0 && infoCounts.friday_market === 0 && infoCounts.gallery === 0 && infoCounts.inbox === 0 && infoCounts.looking_for === 0 && infoCounts.lost_animal === 0 && infoCounts.mating === 0 && infoCounts.my_ads === 0 && infoCounts.my_bids === 0 && infoCounts.my_offer_price === 0 && infoCounts.offers === 0 && infoCounts.other === 0 && infoCounts.photographer === 0 && infoCounts.public_auctions === 0 && infoCounts.quarterings === 0 && infoCounts.questions === 0 && infoCounts.registered_animal === 0 && infoCounts.sales_pages === 0 && infoCounts.services === 0 && infoCounts.today_market === 0 && infoCounts.veterinaries === 0 && infoCounts.videos === 0 && infoCounts.wholesale === 0) &&
          <div div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
            <NotItems />
          </div>
        } */}
      </main>
    </Layout>
  );
};

export default UserPage;
