import React, { useEffect, useState } from 'react'
import City from '../Components/introduction/City'
import Country from '../Components/introduction/Country'
import Nav from '../Components/Layout/Nav'
import { useNavigate } from 'react-router-dom'
const UserLocation = (props) => {
     const [country, setCountry] = useState()
     const [city, setCity] = useState()
     const navigate = useNavigate()

     useEffect(() => {
          if (!city || !country) {
               return
          } else {
               console.log(city, country)
               navigate('/introduction')
          }
     }, [city, country, navigate])

     return (
          <main id="Introduction" className="min-h-screen flex items-center justify-center pt-8">
               <Nav />
               <div className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    <div id='' className='container mx-auto'>
                         <div className="row flex justify-center items-start flex-wrap">
                              <div className="w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12">
                                   {!country ?
                                        <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl p-2 md:p-8 xl:p-12">
                                             {/* city false & country false */}
                                             <Country onSkip={() => navigate('/introduction')} onCountryHandler={(country) => setCountry(country)} />
                                        </div>
                                        :
                                        <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl p-2 md:p-8 xl:p-12">
                                             {/* I have a country */}
                                             <City onSkip={() => navigate('/introduction')} onCityHandler={(isCity) => setCity(isCity)} />
                                        </div>
                                   }
                              </div>


                         </div>
                    </div>
               </div>
          </main>
     )
}

export default UserLocation