import React from 'react'

const AuctionIcon = () => {
     return (
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fillRule="evenodd" clipRule="evenodd" d="M28.07 11.7C27.61 11.24 27.01 11.02 26.41 11.03L25.37 9.51C23.72 7.09 21.66 5.02 19.23 3.37L17.72 2.34C17.73 1.74 17.5 1.13 17.04 0.67C16.59 0.22 16 0 15.41 0C14.82 0 14.23 0.22 13.77 0.67L8.19998 6.24C7.29998 7.14 7.29998 8.61 8.19998 9.51C8.65998 9.96 9.24998 10.19 9.83998 10.19H9.86998L10.91 11.7C11.17 12.09 11.45 12.47 11.74 12.85L2.48998 21.36L1.13998 22.61C0.429977 23.26 0.019977 24.16 -2.30421e-05 25.14C-0.020023 26.11 0.349977 27.02 1.03998 27.71C1.70998 28.38 2.58998 28.75 3.53998 28.75H3.61998C4.57998 28.72 5.47998 28.32 6.13998 27.61L15.9 17.01C16.28 17.3 16.66 17.58 17.05 17.84L18.56 18.87C18.56 19.48 18.78 20.08 19.24 20.54C19.69 20.99 20.28 21.22 20.87 21.22C21.46 21.22 22.05 20.99 22.51 20.54L28.08 14.97C28.98 14.07 28.98 12.61 28.07 11.7ZM27.37 14.27L21.8 19.84C21.54 20.09 21.21 20.22 20.87 20.22C20.53 20.22 20.2 20.09 19.94 19.84C19.56 19.45 19.46 18.89 19.66 18.41L17.61 17.02C16.98 16.59 16.38 16.13 15.81 15.64L5.40998 26.93C4.92998 27.44 4.28998 27.73 3.58998 27.75H3.53998C2.85998 27.75 2.22998 27.49 1.73998 27C1.24998 26.51 0.989977 25.85 0.999977 25.16C1.00998 24.46 1.30998 23.82 1.81998 23.34L13.1 12.95C12.62 12.37 12.16 11.77 11.73 11.14L10.33 9.09C10.18 9.16 10.01 9.19 9.83998 9.19C9.49998 9.19 9.16998 9.06 8.90998 8.8C8.39998 8.29 8.39998 7.46 8.90998 6.95L14.48 1.38C14.74 1.12 15.07 1 15.41 1C15.75 1 16.08 1.12 16.34 1.38C16.72 1.76 16.82 2.33 16.62 2.8L18.67 4.2C20.99 5.78 22.97 7.75 24.55 10.07L25.94 12.12C26.42 11.93 26.98 12.03 27.37 12.41C27.88 12.92 27.88 13.76 27.37 14.27Z" fill="#1E1E1E" />
          </svg>
     )
}

export default AuctionIcon