import React, { useContext, useEffect, useState } from "react";
import Countdown from "react-countdown";
import LineHeart from "../../assets/Icons/LineHeart";
import Report from "../../assets/Icons/Report";
import Context from "../../Store/Store";
import BlueButton from "../UI/Buttons/BlueButton";
import RedButton from "../UI/Buttons/RedButton";
import { t } from "i18next";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import InMarket from "../../Components/Lottie/InMarket.json";
import Delete from "../../assets/Icons/Delete";
import WhiteDelete from "../../assets/Icons/WhiteDelete";
import audio from "../../assets/audio/notification.wav";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import Share from "../../assets/Icons/Share";
import axios from "axios";
import { config, url } from "../Helpers/Help";
import DeleteIcon from "../../assets/Icons/DeleteIcon";
import Pusher from "pusher-js";
import { useRef } from "react";
const Completionist = () => <span>You are good to go!</span>;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: InMarket,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ConditionsProduct = (props) => {
  const ctx = useContext(Context);
  const {
    id,
    deleteItem,
    is_mine,
    is_started,
    slug,
    max_price,
    onBindingNow,
    in_market_till,
    end_minutes,
    onShowDetails,
    isLive,
    in_market,
    market,
    isMine,
    onMakeOfferPrice,
    onGoToAuction,
    todayMarketDetails,
    onMarketDetails,
    type,
    onMarket,
  } = props;
  const itemIsFavorite = ctx.itemIsFavorite(id, slug);
  // const [isShare, setIsShare] = useState(false);
  const [condtion, setCondtion] = useState();
  const [lastPrice, setLastPrice] = useState();
  const [auctionPrice, setAuctionPrice] = useState();
  const [inMarketTime, setInMarketTime] = useState();

  const audioRef = useRef(null);
  // const openShare = () => {
  //      setIsShare(true)
  // }
  function toggleFavoriteStatusHandler() {
    console.log(id, slug);
    if (itemIsFavorite) {
      ctx.removeFavorite(id, slug);
    } else {
      ctx.addFavorite(id, slug);
    }
  }

  useEffect(() => {
    axios(url + "content ", config).then((response) => {
      setCondtion(response.data.data.animal_conditions);
      console.log(response.data.data.animal_conditions);
    });
  }, []);

  useEffect(() => {
    const pusher = new Pusher("4cd59efd7f1d45a34882", {
      cluster: "ap2",
      encrypted: true,
    });

    // Subscribe to the private channel for the current user
    const channel = pusher.subscribe(`awa-lives-${id}`);

    // Bind to the event for new notifications
    channel.bind("live-bid-added", (data) => {
      setLastPrice(data?.data.last_price);
      console.log(data?.data.last_price);
      // Handle the new notification here, e.g., show a toast, update notification count, etc.
    });

    // Clean up the Pusher instance when the component unmounts
    return () => {
      pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    const pusher = new Pusher("4cd59efd7f1d45a34882", {
      cluster: "ap2",
      encrypted: true,
    });

    // Subscribe to the private channel for the current user
    const channel = pusher.subscribe(`auctions_${id}`);

    // Bind to the event for new notifications
    channel.bind("auction-bid-added", (data) => {
      setAuctionPrice(data?.data.max_price);
      audioRef.current.play();
      // Handle the new notification here, e.g., show a toast, update notification count, etc.
    });

    // Clean up the Pusher instance when the component unmounts
    return () => {
      pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    const pusher = new Pusher("4cd59efd7f1d45a34882", {
      cluster: "ap2",
      encrypted: true,
    });

    // Subscribe to the private channel for the current user
    const channel = pusher.subscribe(`auctions_${id}_set_in_market`);

    // Bind to the event for new notifications
    channel.bind("auction-set-in-market", (data) => {
      console.log(data.data.in_market_till);
      // Handle the new notification here, e.g., show a toast, update notification count, etc.
    });

    // Clean up the Pusher instance when the component unmounts
    return () => {
      pusher.disconnect();
    };
  }, [id]);

  useEffect(() => {
    const currentDate = new Date();
    const startTime = new Date("2023-08-04 14:45:20");
    const timeDiffInMilliseconds = startTime - currentDate;
    const timeDiffInMinutes = timeDiffInMilliseconds / (1000 * 60);
    setInMarketTime(timeDiffInMinutes); // الفرق بالدقائق بين التوقيتين
    console.log(timeDiffInMinutes);
  }, []);
  return (
    <>
      <audio ref={audioRef} controls>
        <source src={audio} type="audio/ogg" />
        <source src={audio} type="audio/mpeg" />
      </audio>
      {/*  in_market === show in in_market_till */}
      {/*  !in_market === show in end_minutes */}

      {/* <p>{+formattedDate - new Date('2023-08-04 09:41:21')}</p> */}

      {slug === "live" && (
        <ul className="flex items-center justify-center mt-4 gap-4 border-b border-gray-300 pb-8 mb-8">
          <>
            {is_started === -1 || is_started == 2 ? (
              <>
                {is_started == 2 ? (
                  <BlueButton
                    disabled
                    className="border-2 border-gray-600 flex items-center justify-center p-4 hover:bg-gray-500 hover:border-gray-500 w-full bg-gray-600 cursor-not-allowed"
                  >
                    {" "}
                    {t("This auction is finished")}{" "}
                  </BlueButton>
                ) : (
                  <BlueButton
                    disabled
                    className="border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full"
                  >
                    {" "}
                    {t("Upcoming")}{" "}
                  </BlueButton>
                )}
              </>
            ) : (
              <div className="w-full flex flex-wrap">
                <BlueButton
                  onClick={onBindingNow}
                  className={`${
                    end_minutes === 0 && "cursor-not-allowed"
                  } border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full`}
                >
                  {" "}
                  {t("Binding Now")}{" "}
                </BlueButton>
                <p className="w-full flex justify-center items-center mt-12">
                  {t("Last Price")} :
                  <span className="text-[2rem] text-blue">
                    {lastPrice ? (
                      <b className="mx-2">{lastPrice} </b>
                    ) : (
                      <b className="mx-2">{max_price}</b>
                    )}{" "}
                    {t("AED")}
                  </span>
                </p>
              </div>
            )}
          </>
        </ul>
      )}
      {slug === "auctions" && (
        <ul className="flex items-center justify-center flex-wrap mt-4 gap-4 border-b border-gray-300 pb-8 mb-8">
          {is_mine && !in_market && (
            <RedButton
              onClick={onMarket}
              className="w-full flex items-center justify-center hover:opacity-70 bg-white border-red border rounded-2xl text-[1.4rem] font-bold text-red ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8"
            >
              <span className="w-16">
                {" "}
                <Lottie options={defaultOptions} height={20} width={20} />
              </span>{" "}
              {t("Set in market")}
            </RedButton>
          )}

          <>
            {is_started === -1 || is_started == 2 ? (
              <>
                {is_started == 2 ? (
                  <BlueButton
                    disabled
                    className="border-2 border-gray-600 flex items-center justify-center p-4 hover:bg-gray-500 hover:border-gray-500 w-full bg-gray-600 cursor-not-allowed"
                  >
                    {" "}
                    {t("This auction is finished")}{" "}
                  </BlueButton>
                ) : (
                  <BlueButton
                    disabled
                    className="border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full"
                  >
                    {" "}
                    {t("Upcoming")}{" "}
                  </BlueButton>
                )}
              </>
            ) : (
              <div className="w-full flex flex-wrap">
                <BlueButton
                  disabled={
                    (in_market === 1 && in_market_till === 0) ||
                    (end_minutes === 0 && in_market === 0)
                  }
                  onClick={onBindingNow}
                  className={`${
                    (in_market === 1 && in_market_till === 0) ||
                    (end_minutes === 0 && in_market === 0)
                      ? "bg-gray-500 hover:bg-gray-500 border-gray-500 hover:border-gray-500 cursor-not-allowed"
                      : "border-bluehover:bg-mint hover:border-mint"
                  } border-2  flex items-center justify-center p-4  w-full`}
                >
                  {" "}
                  {t("Binding Now")}{" "}
                </BlueButton>
                <p className="w-full flex justify-center items-center mt-12">
                  {t("Last Price")} :
                  <span className="text-[2rem] text-blue">
                    {auctionPrice ? (
                      <b className="mx-2">{auctionPrice} </b>
                    ) : (
                      <b className="mx-2">{max_price}</b>
                    )}{" "}
                    {t("AED")}
                  </span>
                </p>
              </div>
            )}
          </>

          <div className="digital w-full text-[3rem] flex justify-center items-center">
            {in_market ? (
              <Countdown
                date={
                  Date.now() +
                  60000 * `${inMarketTime ? inMarketTime : in_market_till}`
                }
              >
                <Completionist />
              </Countdown>
            ) : (
              <Countdown date={Date.now() + 60000 * end_minutes}>
                <Completionist />
              </Countdown>
            )}
          </div>
        </ul>
      )}

      {slug === "markets" &&
        type === "Today's market" &&
        localStorage.__User_aW__a_Info &&
        localStorage.__User_aW__a_Info != "null" && (
          <>
            {isMine ? (
              <ul className="flex items-center justify-center mt-4 gap-4 border-b border-gray-300 pb-8 mb-8">
                <BlueButton
                  onClick={onMarketDetails}
                  className=" w-1/2 hover:opacity-70 bg-blue rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8"
                >
                  {" "}
                  {!todayMarketDetails
                    ? `${t("Show Details")}`
                    : `${t("Hide Details")}`}{" "}
                </BlueButton>
              </ul>
            ) : (
              <ul className="flex items-center justify-center mt-4 gap-4 border-b border-gray-300 pb-8 mb-8">
                <BlueButton
                  onClick={onMakeOfferPrice}
                  className=" w-1/2 hover:opacity-70 bg-blue rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8"
                >
                  {" "}
                  {t("Make Offer")}{" "}
                </BlueButton>
                <BlueButton
                  onClick={onGoToAuction}
                  className=" w-1/2 hover:opacity-70 bg-mint rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8"
                >
                  {" "}
                  {t("Go To Auction")}{" "}
                </BlueButton>
              </ul>
            )}
          </>
        )}

      {slug === "markets" &&
        (type === "Friday Market" || type === "Wholesale market") &&
        localStorage.__User_aW__a_Info &&
        localStorage.__User_aW__a_Info != "null" && (
          <>
            {isMine ? (
              <ul className="flex items-center justify-center mt-4 gap-4 border-b border-gray-300 pb-8 mb-8">
                <BlueButton
                  onClick={onMarketDetails}
                  className=" w-1/2 hover:opacity-70 bg-blue rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8"
                >
                  {" "}
                  {!todayMarketDetails
                    ? `${t("Show Details")}`
                    : `${t("Hide Details")}`}{" "}
                </BlueButton>
              </ul>
            ) : (
              <BlueButton
                onClick={onMakeOfferPrice}
                className=" w-full mt-8 hover:opacity-70 bg-blue rounded-2xl text-[1.4rem] font-bold text-white ltr:tracking-wider duration-700 transition-all AddAnimalButton py-4 px-8"
              >
                {" "}
                {t("Make Offer")}{" "}
              </BlueButton>
            )}
          </>
        )}
      <div className="relative flex items-center justify-end">
        <div className="ShareGallery">
          <button className="bg-white mx-4 rounded-full">
            <Share className={"w-8 h-10 pt-2"} />
          </button>
          <div
            className={`shareItems absolute top-0 rtl:left-28 ltr:right-28 z-[10] flex gap-2 transition-all duration-1000 overflow-hidden items-center justify-end w-0`}
          >
            <FacebookShareButton
              url={`https://front.awasell.co/details/${slug}/${id}`}
            >
              <FacebookIcon className="w-12 h-12 rounded-full " />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://front.awasell.co/details/${slug}/${id}`}
            >
              <TwitterIcon className="w-12 h-12 rounded-full " />
            </TwitterShareButton>
            <WhatsappShareButton
              url={`https://front.awasell.co/details/${slug}/${id}`}
            >
              <WhatsappIcon className="w-12 h-12 rounded-full " />
            </WhatsappShareButton>
            <LinkedinShareButton
              url={`https://front.awasell.co/details/${slug}/${id}`}
            >
              <LinkedinIcon className="w-12 h-12 rounded-full " />
            </LinkedinShareButton>
            <EmailShareButton
              url={`https://front.awasell.co/details/${slug}/${id}`}
            >
              <EmailIcon className="w-12 h-12 rounded-full " />
            </EmailShareButton>
          </div>
        </div>
        {!isMine &&
          localStorage.__User_aW__a_Info &&
          localStorage.__User_aW__a_Info != "null" && (
            <li className="flex items-center justify-end ">
              {!isLive && (
                <button className="" onClick={toggleFavoriteStatusHandler}>
                  {" "}
                  <LineHeart
                    className={` hover:fill-blue ${
                      itemIsFavorite ? "fill-blue" : "fill-[#d9d9d9]"
                    }`}
                  />{" "}
                </button>
              )}
            </li>
          )}
      </div>
      <div className="">
        {condtion && <div dangerouslySetInnerHTML={{ __html: condtion }} />}
      </div>
      <Link
        to={`/reports?animalId=${id}`}
        className="bg-yellow-700 text-white p-4 px-12 flex items-center justify-center text-center gap-4 min-w-[50%] my-6"
      >
        {" "}
        <Report fill="#fff" /> {t("Report")}{" "}
      </Link>
      {is_mine && (
        <button
          onClick={deleteItem}
          className="w-full bg-red text-white p-4 px-12 flex items-center justify-center text-center gap-4 my-6"
        >
          {" "}
          <DeleteIcon fill="#fff" /> {t("Delete")}{" "}
        </button>
      )}
    </>
  );
};

export default ConditionsProduct;
