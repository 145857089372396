import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import Fire from '../assets/Icons/Fire'
import Pomp from '../assets/Icons/Pomp'
import Rocket from '../assets/Icons/Rocket'
import { config, url } from '../Components/Helpers/Help'
import Layout from '../Components/Layout'
import AdvirtisingsForm from '../Components/Seactions/AdvirtisingsForm'
import ButtonLoading from '../Components/UI/ButtonLoading'
import BlueButton from '../Components/UI/Buttons/BlueButton'
import Input from '../Components/UI/Input'
import { useSearchParams } from "react-router-dom";
import alert from '../Helpers/Modal';

const complain = [
     { value: '1', label: 'unclear' },
     { value: '2', label: 'fake account' },
     { value: '3', label: 'liar' }
];
const Reports = () => {
     const query = new URLSearchParams(window.location.search)
     const token = query.get('animalId')

     const [loading, setLoading] = useState(false);
     const [codeNumber, setCodeNumber] = useState('')
     const [complaint, setComplaint] = useState('')
     const [note, setNote] = useState('')
     const [animalTypeValue, setAnimalTypeValue] = useState()
     const [animalId, setAnimalId] = useState()
     const [animalTypes, setAnimalTypes] = useState([])


     const [animalTypeError, setAnimalTypeError] = useState(false);
     const [codeNumberError, setcodeNumberError] = useState(false);
     const [ComplaintError, setComplaintError] = useState(false);
     const [noteError, setnoteError] = useState(false);
     useEffect(() => {
          setAnimalId(token)
     }, [token]);

     useEffect(() => {
          if (localStorage.__data_aW__a_start) {
               const aboutus = localStorage.getItem('__data_aW__a_start')
               setAnimalTypes(JSON.parse(aboutus).animal_types)
               console.log(JSON.parse(aboutus).animal_types)
          }
     }, [])

     const submitHandler = (e) => {
          e.preventDefault()
          if (!animalTypeValue) {
               setAnimalTypeError(true)
               return
          }
          if (!complaint) {
               setComplaintError(true)
               return
          }
          if (!codeNumber) {
               setcodeNumberError(true)
               return
          }
          if (!note) {
               setnoteError(true)
               return
          }
          setLoading(true)
          axios.post(url + 'report-animal',
               //  Data
               {
                    animal_id: animalId,
                    animal_type: animalTypeValue,
                    complaint: complaint,
                    code_number: codeNumber,
                    note: note,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setCodeNumber('')
                    setLoading(false)
                    setCodeNumber('')
                    setComplaint('')
                    setNote('')
                    setAnimalTypeValue('')
                    alert({
                         title: `${t('Alert')}`,
                         text: `${t('Thanks for sent')}`,
                         icon: 'success',
                         timer: 3500
                    })
               })
               // Check Error
               .catch(error => {
                    setLoading(false)
                    console.log(error)
                    alert({
                         title: `${t('Alert')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 3500
                    })
               })
     }

     return (
          <Layout>
               <main className="container mx-auto pt-12 md:pt-36 lg:pt-28 px-4 md:px-0">
                    <div className="w-full lg:w-8/12 mx-auto">
                         <div className="row flex items-start justify-center flex-wrap">
                              <div className="banner relative overflow-hidden w-full rounded-3xl min-h-[40rem] mt-24">
                                   <div className="overlay top-0 left-0 w-full h-full absolute bg-black/60 -z-[1]" />
                                   <img src={'https://images.unsplash.com/photo-1534644107580-3a4dbd494a95?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'} width={500} height='500' className='absolute top-0 left-0 w-full h-full object-cover z-10' alt={'Report'} />

                              </div>

                              <div className="data text-center relative overflow-hidden w-full rounded-3xl mt-24 mb-12">
                                   <h3 className='text-[3rem] font-bold'>{t('Report')}</h3>
                                   <p className='text-[1.5rem]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet ac nullam sed commodo nibh turpis lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet ac nullam sed commodo nibh turpis lectus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet ac nullam sed commodo nibh turpis lectus.</p>
                              </div>

                              <form onSubmit={submitHandler} action="" className='mt-4 flex flex-wrap items-start justify-start bg-white p-6 rounded-lg shadow-md'>
                                   {
                                        animalTypes && animalTypes.length > 0 &&
                                        <div className="flex flex-col w-full">
                                             <label htmlFor="" className="font-medium text-[1.5] "> {t('Type')} </label>
                                             <select onFocus={() => setAnimalTypeError(false)} onChange={(e) => { setAnimalTypeValue(e.target.value) }} className={`bg-transparent w-full text-[1.5rem] rounded-lg py-3 px-5 border-2  ${animalTypeError ? 'border-red' : 'border-blue'}`}>
                                                  <option value={''}> {t('chosse')} . . . </option>

                                                  {
                                                       animalTypes &&
                                                       animalTypes.map(item => (
                                                            <option value={item.id} key={item.id}> {item.title} </option>
                                                       ))
                                                  }
                                             </select>
                                        </div>
                                   }
                                   <div className="w-full pt-2 mt-2">
                                        <Input onFocus={() => setComplaintError(false)} input_className={`border-2  ${ComplaintError ? 'border-red' : 'border-blue'}`} div_className="w-full" value={complaint} onChange={(e) => setComplaint(e.target.value)} type="text" placeholder={t("Complaint About")} id="ComplaintAbout" label={t("Complaint About")} />
                                   </div>
                                   <Input onFocus={() => setcodeNumberError(false)} input_className={`border ${codeNumberError ? 'border-red' : 'border-blue'}`} div_className='mb-8 text-left' value={codeNumber} onChange={(e) => setCodeNumber(e.target.value)} type='text' placeholder={t('Code Number')} id='f_name' label={t('Code Number')} />

                                   <div className="w-full p-2">
                                        <label htmlFor="" className="w-full font-medium text-[1.5]"> {t('Note')} </label>
                                        <textarea onFocus={() => setnoteError(false)} className={`w-full rounded-lg relative overflow-hidden p-4 border-2 ${noteError ? 'border-red' : 'border-blue'}`} value={note} onChange={(e) => setNote(e.target.value)} name="" id="" cols="30" rows="5" placeholder={t('Note . . .')}></textarea>
                                   </div>


                                   <BlueButton className={`w-full text-[1.5rem] p-5 mt-8 ${loading && 'cursor-not-allowed'}`} disabled={loading}>
                                        {loading ? <ButtonLoading /> : `${t("Submit")}`}
                                   </BlueButton>
                              </form>
                         </div>
                    </div>
               </main>
          </Layout >
     )
}

export default Reports