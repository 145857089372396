import React from 'react'

const BlueAdsIcon = () => {
  return (
       <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7109 0.884791C18.5489 0.972598 18.396 1.07984 18.2567 1.20594L9.1946 9.42463L14.8007 22.3923L26.9972 21.4207C27.7455 21.3608 28.4232 20.9409 28.8111 20.2975C29.1977 19.6546 29.2516 18.8588 28.9535 18.1691L26.5721 12.6605C27.3111 11.9096 27.566 10.7597 27.1222 9.73312C26.6784 8.70654 25.6661 8.1045 24.6128 8.12842L21.9668 2.0079C21.6687 1.31825 21.0526 0.812015 20.3188 0.653522C19.7678 0.534392 19.1968 0.621371 18.7109 0.884791ZM7.54585 10.6509L2.8594 13.1195C1.17394 14.0072 0.446741 16.0701 1.20228 17.8178L3.21116 22.4646C3.9466 24.1658 5.86379 25.0481 7.62584 24.5224L10.4619 29.4911C11.0705 30.5579 12.3808 30.9951 13.5086 30.5075C14.7269 29.9808 15.2886 28.5612 14.7619 27.3428L7.54585 10.6509Z" fill="#0F70B7" />
       </svg>

  )
}

export default BlueAdsIcon