import React from 'react'

const Share = (props) => {
  return (
       <svg className={props.className} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.11769 11.6644C1.13402 11.666 1.15069 11.6667 1.16702 11.6667C1.40302 11.6667 1.60969 11.5004 1.65702 11.2647C2.35635 7.76604 6.00269 7.51704 7.66669 7.59437V10.1667C7.66669 10.3687 7.78835 10.551 7.97535 10.6287C8.16202 10.7057 8.37702 10.6634 8.52035 10.5204L13.187 5.85371C13.3824 5.65871 13.3824 5.34171 13.187 5.14671L8.52035 0.480041C8.37735 0.337041 8.16235 0.294041 7.97535 0.371707C7.78835 0.448707 7.66669 0.631041 7.66669 0.833374V3.39837C5.78902 3.64371 0.666687 4.88904 0.666687 11.1667C0.666687 11.4237 0.861687 11.639 1.11769 11.6644Z" fill="#0F70B7" />
       </svg>

  )
}

export default Share