import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import alert from "../../../Helpers/Modal";
import { config, url } from "../../Helpers/Help";
import SocialMedia from "../../SocialMedia/SocialMedia";
import BlueButton from "../../UI/Buttons/BlueButton";
import Input from "../../UI/Input";
import Ring from "../../UI/Loader/Ring";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";

const Signup = (props) => {
  const [f_name, setFname] = useState("");
  const [l_name, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [accept, setAccept] = useState(false);
  const [otp, setOtp] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [nickname, setNickname] = useState("");
  const [terms_and_conditions, setTerms_and_conditions] = useState();
  const [otpNumber, setOtpNumber] = useState("");

  useEffect(() => {
    if (localStorage.websiteContent) {
      const aboutus = localStorage.getItem("websiteContent");
      setTerms_and_conditions(JSON.parse(aboutus).terms_and_conditions);
    }
  }, []);

  useEffect(() => {
    console.log(otpNumber);
  }, [otpNumber]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!nickname) {
      alert({
        title: `${t("Alert")}`,
        text: `${t(`please enter your Nickname`)}`,
        icon: "error",
        timer: 2500,
      });
      return;
    }
    if (phone.length < 6 || phone.trim() === "") {
      alert({
        title: `${t("Alert")}`,
        text: `${t(`please enter your Phone`)}`,
        icon: "error",
      });
      return;
    }
    if (!email.includes("@") || email.trim() === "") {
      alert({
        title: `${t("Alert")}`,
        text: `${t(`please enter your email`)}`,
        icon: "error",
        timer: 2500,
      });
      return;
    }
    if (
      password !== rePassword ||
      password.trim() === "" ||
      password.length < 6
    ) {
      alert({
        title: `${t("Alert")}`,
        text: `${t(
          `Enter the correct password and it must be the same and more than 6 characters long`
        )}`,
        icon: "error",
        timer: 2500,
      });
      return;
    }
    if (!accept) {
      alert({
        title: `${t("Alert")}`,
        text: `${t(`You must agree to the terms and conditions`)}`,
        icon: "error",
      });
      return;
    }
    //  set loadin true
    setIsLoading(true);
    axios
      .post(
        url + "users/register",
        //  Data
        {
          f_name: f_name,
          l_name: l_name,
          email: email,
          phone: phone,
          password: password,
          password_confirmation: rePassword,
          nickname: nickname,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setOtp(true);
        setEmail("");
        setPhone("");
        setPassword("");
        setRePassword("");
        setFname("");
        setLname("");
        setNickname("");
        setIsLoading(false);
        localStorage.setItem(
          "__authtk__awasell_secure",
          response.data.data.token
        );
        localStorage.setItem(
          "__User_aW__a_Info",
          JSON.stringify(response.data.data.user)
        );
      })
      // Check Error
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        alert({
          title: `${t("Alert")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2500,
        });
      });
  };
  return (
    <>
      {isReading && (
        <>
          <div className="max-h-[90vh] overflow-y-auto no-scroll shadow-lg modal fixed w-full md:w-8/12 p-8 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white z-[99999999] rounded-2xl">
            <span
              onClick={() => setIsReading(false)}
              className="w-12 h-12 cursor-pointer flex items-center justify-center rounded-full absolute right-4 top-4 border font-bold text-gray-500"
            >
              x
            </span>
            <div className="warranty-policy px-4 lg:px-8 xl:px-12py-24">
              <h3 className="Abril text-[2rem] md:text-[3.4rem] font-normal w-full text-center lg:my-12">
                {t("AWA Terms Condition")}
              </h3>
              {terms_and_conditions && (
                <div className="bg-white shadow rounded-2xl p-8">
                  <div
                    dangerouslySetInnerHTML={{ __html: terms_and_conditions }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {otp ? (
        <>
          <h3 className="w-full font-bold text-[3.4rem] Abril ltr:tracking-widest">
            {t("OTP")}
          </h3>
          <p className="w-full font-light text-[1.5] mb-8">
            {t("Please enter your contact details to sign up")}
          </p>
          <form
            action=""
            className="otpForm mt-4 flex flex-wrap items-center justify-between"
          >
            <OtpInput
              inputStyle={{
                width: "40px",
                height: "40px",
                margin: "0 10px",
                fontSize: "20px",
                borderRadius: "4px",
                border: "1px solid rgba(0, 0, 0, .3)",
              }}
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              value={otp}
              onChange={(otp) => setOtpNumber({ otp })}
              numInputs={6}
              separator={<span>-</span>}
            />
            <BlueButton
              disabled={isLoading}
              className={`w-full text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${
                isLoading && "cursor-not-allowed"
              }`}
              onClick={submitHandler}
            >
              {isLoading ? <Ring /> : `${t("Send")}`}
            </BlueButton>
            <p className="text-[1.5rem] font-light flex items-center justify-center gap-4 mt-8 text-center w-full">
              {t("have account?")}
              <button
                onClick={() => setOtp(false)}
                className="font-medium text-blue"
              >
                {" "}
                {t("Back")}{" "}
              </button>
            </p>
          </form>
        </>
      ) : (
        <>
          <h3 className="w-full font-bold text-[3.4rem] Abril ltr:tracking-widest">
            {t("Welcome")}
          </h3>
          <p className="w-full font-light text-[1.5] mb-8">
            {t("Please enter your contact details to sign up")}
          </p>
          <form
            action=""
            className="mt-4 flex flex-wrap items-center justify-between"
          >
            <div className="flex gap-2">
              <Input
                value={f_name}
                onChange={(e) => setFname(e.target.value)}
                type="text"
                placeholder={t("First name")}
                id="Email"
                label={t("First name")}
              />
              <Input
                value={l_name}
                onChange={(e) => setLname(e.target.value)}
                type="text"
                placeholder={t("Last name")}
                id="Email"
                label={t("Last name")}
              />
            </div>
            <div className="flex gap-2">
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder={t("Your Email")}
                id="Email"
                label={t("Email address")}
              />
              <Input
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                type="text"
                placeholder={t("Your Nickname")}
                id="text"
                label={t("Your Nickname")}
              />
            </div>
            <label className="font-medium text-[1.5] ltr:text-left rtl:text-right w-full flex mt-">
              {t("Your  Phone")}
            </label>
            <div dir="ltr" className=" w-full">
              <PhoneInput
                placeholder={t("Your  Phone")}
                country={"ae"} // Set the default country (optional)
                value={phone}
                onChange={(e) => setPhone(`+${e}`)}
                className="rtl:text-left bg-transparent w-full text-[1.5rem] rounded-lg border-2 border-gold px-5 mb-4"
              />
            </div>
            {/* <Input value={phone} onChange={(e) => setPhone(e.target.value)} type='tel' placeholder={t('Your  Phone')} id='Phone' label={t('Phone')} /> */}
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder={t("Your password")}
              id="password"
              label={t("Password")}
            />
            <Input
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
              type="password"
              placeholder={t("Your password")}
              id="password"
              label={t("Re-Password")}
            />

            <div className="flex items-center justify-start flex-wrap w-full mb-8">
              <label className="CheckBox block relative rtl:pr-10 ltr:pl-10 mb-4 cursor-pointer text-[1.5rem] select-none mt-3">
                {t("Accept term & condition")}
                <input
                  onChange={() => setAccept((prev) => !prev)}
                  type="checkbox"
                  className="absolute opacity-0 cursor-pointer h-0 w-0"
                />
                <span className="checkmark rounded-full absolute top-1 rtl:right-0 ltr:left-0 h-6 w-6 bg-slate-300"></span>
              </label>
              <span
                className="mx-2 text-dark-gold font-medium cursor-pointer"
                onClick={() => setIsReading(true)}
              >
                {" "}
                {t("Read")}
              </span>
            </div>
            <BlueButton
              disabled={isLoading}
              className={`w-full text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${
                isLoading && "cursor-not-allowed"
              }`}
              onClick={submitHandler}
            >
              {isLoading ? <Ring /> : `${t("Sign up")}`}
            </BlueButton>
            <div className="loginByGoogle">
              <SocialMedia />
            </div>
            <p className="text-[1.5rem] font-light flex items-center justify-center gap-4 mt-8 text-center w-full">
              {t("have account?")}
              <button
                onClick={() => props.onLogin()}
                className="font-medium text-blue"
              >
                {" "}
                {t("Log in")}{" "}
              </button>
            </p>
          </form>
        </>
      )}
    </>
  );
};

export default Signup;
