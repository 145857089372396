import React, { useState } from "react";
// import logo from '../assets/images/logoHr.png'
import bg from "../assets/images/auth.jpg";
import { Link } from "react-router-dom";
import Signup from "../Components/Authentication/Signup/Signup";
import Login from "../Components/Authentication/Login/Login";
import "../Styles/_Auth.scss";
import Nav from "../Components/Layout/Nav";
const Auth = () => {
  const [signup, setSignup] = useState(false);

  const switchAuthHandler = () => {
    setSignup((prevState) => !prevState);
  };

  return (
    <main id="Auth" className="min-h-screen flex items-center justify-center">
      <Nav />
      <div id="" className="container mx-auto">
        <div className="row flex justify-center items-center flex-wrap">
          <div className="w-full md:w-10/12 lg:w-8/12 xl:w-7/12">
            <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl">
              <div className="w-full md:w-1/2 flex items-center">
                <div className="LoginContent flex items-start justify-center flex-wrap  p-4 md:p-8 pt-32 md:pt-0">
                  <Link to="/">
                    {/* <img src={logo} alt="" className='mx-auto block mb-6' /> */}
                  </Link>
                  {signup ? <Signup onLogin={switchAuthHandler} /> : <Login onSignup={switchAuthHandler} onForgotPassword={() => setSignup(false)} /> }
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="LoginContent p-4 md:p-8 h-full">
                  <img
                    src={bg}
                    alt=""
                    className="w-full min-h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </main>
  );
};

export default Auth;
