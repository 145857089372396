import axios from "axios";
import { t } from "i18next";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Hour24 from "../assets/Icons/Hour24";
import Vip from "../assets/Icons/Vip";
import { config, url } from "../Components/Helpers/Help";
import Layout from "../Components/Layout";
import Search from "../Components/Search/Search";
import AdoptionForAnimal from "../Components/UI/AnimalCard/AdoptionForAnimal";
import Delivery from "../Components/UI/AnimalCard/Delivery";
import FridayMarket from "../Components/UI/AnimalCard/FridayMarket";
import LookFor from "../Components/UI/AnimalCard/LookFor";
import LostAnimal from "../Components/UI/AnimalCard/LostAnimal";
import Mating from "../Components/UI/AnimalCard/Mating";
import Others from "../Components/UI/AnimalCard/Others";
import Photographer from "../Components/UI/AnimalCard/Photographer";
import SpecialOffer from "../Components/UI/AnimalCard/SpecialOffer";
import VeterinaryClinic from "../Components/UI/AnimalCard/VeterinaryClinic";
import VipAuction from "../Components/UI/AnimalCard/VipAuction";
import WholesaleMarket from "../Components/UI/AnimalCard/WholesaleMarket";
import QuestionsCard from "../Components/UI/Cards/Questions-card";
import VideoCard from "../Components/UI/Cards/Video-card";
import SectionTitle from "../Components/UI/Titles/SectionTitle";
import BlueButton from "../Components/UI/Buttons/BlueButton";
import alert from "../Helpers/Modal";
import TodayMarket from "../Components/UI/AnimalCard/TodayMarket";
import errorImage from "../assets/images/error.jpg";
import PreLoader from "../Components/UI/PreLoader";

const WebSearch = () => {
  const [id, setId] = useState();
  const [modal, setModal] = useState();
  const [answaerMessage, setAnswaerMessage] = useState();
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  var pathname = window.location;
  var appSlug = pathname.search;
  // console.log(appSlug)
  //  can you create useState()?
  useEffect(() => {
    setIsLoading(true);

    if (!appSlug) {
      return;
    }
    axios(url + `search${appSlug}`, config)
      // Response
      .then((response) => {
        setSearch(response.data.data);
        setIsLoading(false);
      })
      // Check Error
      .catch((error) => {
        console.log(error?.response?.data);
        setIsLoading(false);
        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
        }
      });
  }, [appSlug]);

  // Submit my answer
  const submitAnswer = (e) => {
    e.preventDefault();
    axios
      .post(
        url + "questions/answer",
        //  Data
        {
          // I mean with modal => id
          question_id: id,
          content: answaerMessage,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        setModal(false);
        setAnswaerMessage();
        alert({
          title: `${t("Done")}`,
          text: `${t("Thanks for sharing your answer")}`,
          icon: "success",
          timer: 2000,
        });
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };
  return (
    <Layout>
      {modal && (
        <>
          <div
            className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40"
            onClick={() => setModal(false)}
          />
          <div
            className={`fixed shadow-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[50rem] bg-[#F9F9F9] rounded-2xl z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}
          >
            <button
              className="w-12 h-12 border-2 font-bold text-[2rem] flex items-center justify-center rounded-full absolute right-8 top-4 border-gray-600"
              onClick={() => setModal(false)}
            >
              x
            </button>
            <div className="flex flex-col justify-center items-center">
              <h3 className="font-bold text-[1.8rem] mb-8">
                {t("Your Answer")}
              </h3>
              <textarea
                name=""
                id=""
                cols="30"
                rows="7"
                className="w-full mb-8 rounded-xl resize-none p-4"
                onChange={(e) => setAnswaerMessage(e.target.value)}
              ></textarea>
              <BlueButton onClick={submitAnswer} className="py-6 px-8 xl:px-28">
                {t("Submit")}
              </BlueButton>
            </div>
          </div>
        </>
      )}
      <main className="container mx-auto pt-28 md:pt-36 lg:pt-28 px-4 md:px-0">
        <h3 className="text-blue font-bold mb-4">{t("Search")}</h3>

        <section className="mt-12 w-full ">
          <Search />
        </section>
        {isLoading ? (
          <PreLoader />
        ) : (
          <>
            {serverError ? (
              <img
                src={errorImage}
                alt="server error"
                className="w-[50rem] h-[50rem] xl:mt-48 block mx-auto mix-blend-multiply object-contain"
              />
            ) : (
              <div className="row w-full flex items-start justify-start flex-wrap min-h-[40vh]">
                {search &&
                  search.auction_vip &&
                  search.auction_vip.length === 0 &&
                  search.public_auctions &&
                  search.public_auctions.length === 0 &&
                  search.auction_quick &&
                  search.auction_quick.length === 0 &&
                  search.wholesale &&
                  search.wholesale.length === 0 &&
                  search.friday_market &&
                  search.friday_market.length === 0 &&
                  search.today_market &&
                  search.today_market.length === 0 &&
                  search.deliveries &&
                  search.deliveries.length === 0 &&
                  search.looking_for &&
                  search.looking_for.length === 0 &&
                  search.lost_animal &&
                  search.lost_animal.length === 0 &&
                  search.mating &&
                  search.mating.length === 0 &&
                  search.offers &&
                  search.offers.length === 0 &&
                  search.adoptions &&
                  search.adoptions.length === 0 &&
                  search.other &&
                  search.other.length === 0 &&
                  search.photographer &&
                  search.photographer.length === 0 &&
                  search.questions &&
                  search.questions.length === 0 &&
                  search.veterinaries &&
                  search.veterinaries.length === 0 &&
                  search.videos &&
                  search.videos.length === 0 && (
                    <h3 className="w-full text-center text-[2rem] font-bold mt-24">
                      {" "}
                      {t("No Items")}
                    </h3>
                  )}

                {search &&
                  search.auction_vip &&
                  search.auction_vip.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        <Vip /> {t("Upcoming VIP Auction")}
                      </SectionTitle>
                      {search.auction_vip.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 my-2"
                          key={item.id}
                        >
                          <VipAuction
                            is_notify={item.is_notify}
                            key={item.id}
                            id={item.id}
                            isMine={item.is_mine}
                            slug={item.slug}
                            img={item.image}
                            end_minutes={item.end_minutes}
                            start={item.start_time}
                            type={item.category && item.category.title}
                            max_price={item.max_price}
                            gender={item.gender}
                            age={item.age}
                            users_count={item.users_count}
                            weight={item.weight}
                            isFav={item.isFav}
                            inMarket={
                              item.in_market && item.in_market_till !== 0
                            }
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search &&
                  search.public_auctions &&
                  search.public_auctions.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        <Vip /> {t("Upcoming VIP Auction")}
                      </SectionTitle>
                      {search.public_auctions.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 my-2"
                          key={item.id}
                        >
                          <VipAuction
                            is_notify={item.is_notify}
                            key={item.id}
                            id={item.id}
                            isMine={item.is_mine}
                            slug={item.slug}
                            img={item.image}
                            end_minutes={item.end_minutes}
                            start={item.start_time}
                            type={item.category && item.category.title}
                            max_price={item.max_price}
                            gender={item.gender}
                            age={item.age}
                            users_count={item.users_count}
                            weight={item.weight}
                            isFav={item.isFav}
                            inMarket={
                              item.in_market && item.in_market_till !== 0
                            }
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search &&
                  search.auction_quick &&
                  search.auction_quick.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        <Hour24 /> {t("Upcoming Quick Auction")}
                      </SectionTitle>
                      {search.auction_quick.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 my-2"
                          key={item.id}
                        >
                          <VipAuction
                            is_notify={item.is_notify}
                            key={item.id}
                            id={item.id}
                            isMine={item.is_mine}
                            slug={item.slug}
                            img={item.image}
                            end_minutes={item.end_minutes}
                            start={item.start_time}
                            type={item.category && item.category.title}
                            max_price={item.max_price}
                            gender={item.gender}
                            age={item.age}
                            users_count={item.users_count}
                            weight={item.weight}
                            isFav={item.isFav}
                            inMarket={
                              item.in_market && item.in_market_till !== 0
                            }
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search && search.wholesale && search.wholesale.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      {t("Wholesale Market")}
                    </SectionTitle>
                    {search.wholesale.map((item) => (
                      <div
                        className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                        id={item.id}
                        key={item.id}
                      >
                        <WholesaleMarket
                          slug={item.slug}
                          id={item.id}
                          key={item.id}
                          img={item.image}
                          type={item.category && item.category.title}
                          badge={item.user && item.user.badge}
                          isFav={item.isFav}
                          phone={item.user && item.user.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          action={item.action}
                          price={item.price}
                          location={item.city && item.city.name}
                          description={item.note}
                          inMarket={item.inMarket}
                          whatsapp={
                            item.user && item.whatsapp && item.user.whatsapp
                          }
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      </div>
                    ))}
                  </section>
                )}

                {search &&
                  search.friday_market &&
                  search.friday_market.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        {t("Friday Market")}
                      </SectionTitle>
                      {search.friday_market.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                          id={item.id}
                          key={item.id}
                        >
                          <FridayMarket
                            slug={item.slug}
                            key={item.id}
                            id={item.id}
                            img={item.image}
                            type={item.category && item.category.title}
                            badge={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age={item.age}
                            weight={item.weight}
                            gender={item.gender}
                            action={item.action}
                            price={item.price}
                            location={item.city && item.city.name}
                            duration={item.duration}
                            inMarket={item.inMarket}
                            whatsapp={
                              item.user && item.whatsapp && item.user.whatsapp
                            }
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search &&
                  search.today_market &&
                  search.today_market.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        {t("Today Market")}
                      </SectionTitle>
                      {search.today_market.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                          id={item.id}
                          key={item.id}
                        >
                          <TodayMarket
                            key={item.id}
                            slug={item.slug}
                            id={item.id}
                            img={item.image}
                            type={item.category && item.category.title}
                            badge={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age={item.age}
                            weight={item.weight}
                            gender={item.gender}
                            location={item.city && item.city.name}
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search &&
                  search.deliveries &&
                  search.deliveries.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        {t("Delivery")}
                      </SectionTitle>
                      {search.deliveries.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                          id={item.id}
                          key={item.id}
                        >
                          <Delivery
                            id={item.id}
                            slug={item.slug}
                            rate={item.avg_rate_int}
                            className="h-[36.5rem]"
                            img={item.image}
                            location={item.address}
                            phone={item.user && item.user.phone}
                            email={item.user && item.user.email}
                            whatsapp={item.user && item.user.whatsapp}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search &&
                  search.looking_for &&
                  search.looking_for.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        <Vip /> {t("Look for")}
                      </SectionTitle>
                      {search.looking_for.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 my-2"
                          key={item.id}
                        >
                          <LookFor
                            id={item.id}
                            key={item.id}
                            img={item.image}
                            type={item.category && item.category.title}
                            badge={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age_range_from={item.age_range_to}
                            age_range_to={item.age_range_from}
                            price_range_from={item.price_range_from}
                            price_range_to={item.price_range_to}
                            weight={item.weight}
                            gender={item.gender}
                            address={item.address}
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search &&
                  search.lost_animal &&
                  search.lost_animal.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        <Vip /> {t("Upcoming VIP Auction")}
                      </SectionTitle>
                      {search.lost_animal.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                          id={item.id}
                          key={item.id}
                        >
                          <LostAnimal
                            id={item.id}
                            type={item.category && item.category.title}
                            img={item.image}
                            loastDate={item.lost_date}
                            // location={item.user && item.user.city.name}
                            badge={item.user && item.user.badge}
                            name={item.user && item.user.f_name}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search && search.mating && search.mating.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      <Vip /> {t("Mating")}
                    </SectionTitle>
                    {search.mating.map((item) => (
                      <div
                        className="w-1/2 md:w-1/2 lg:w-1/3 my-2"
                        key={item.id}
                      >
                        <Mating
                          id={item.id}
                          key={item.id}
                          slug={item.slug}
                          img={item.image}
                          type={item.category && item.category.title}
                          badg={item.badg}
                          isFav={item.isFav}
                          phone={item.user && item.user.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city && item.city.name}
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      </div>
                    ))}
                  </section>
                )}

                {search && search.offers && search.offers.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      {t("Special Offers")}
                    </SectionTitle>
                    {search.offers.map((item) => (
                      <div
                        className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                        id={item.id}
                        key={item.id}
                      >
                        <SpecialOffer
                          key={item.id}
                          id={item.id}
                          slug={item.slug}
                          img={item.image}
                          type={item.category && item.category.title}
                          badg={item.badg}
                          price={item.offer_price}
                          oldPrice={
                            +item.price > +item.offer_price && item.price
                          }
                          isFav={item.isFav}
                          phone={item.user && item.user.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city && item.city.name}
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      </div>
                    ))}
                  </section>
                )}

                {search && search.adoptions && search.adoptions.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      {" "}
                      {t("Adoption for animal")}{" "}
                    </SectionTitle>
                    {search.adoptions.map((item) => (
                      <div
                        className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                        id={item.id}
                        key={item.id}
                      >
                        <AdoptionForAnimal
                          key={item.id}
                          id={item.id}
                          img={item.image}
                          slug={item.slug}
                          badg={item.badg}
                          price={item.price}
                          isFav={item.isFav}
                          age={item.age}
                          duration={item.price_unit_text}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city && item.city.name}
                          locationAnimal={item.city && item.city.name}
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          phone={item.phone}
                          userImage={item.user && item.user.personal_picture}
                        />
                      </div>
                    ))}
                  </section>
                )}

                {search && search.other && search.other.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      {t("Others")}
                    </SectionTitle>
                    {search.other.map((item) => (
                      <div
                        className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                        id={item.id}
                        key={item.id}
                      >
                        <Others
                          id={item.id}
                          img={item.image}
                          type={item.category && item.category.title}
                          badg={item.user && item.user.badge}
                          price={item.price}
                          name={item.user && item.user.f_name}
                          whatsapp={item.user && item.user.whatsapp}
                          location={
                            item.user && item.user.city && item.user.city.name
                          }
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      </div>
                    ))}
                  </section>
                )}

                {search &&
                  search.photographer &&
                  search.photographer.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        {t("Photographer and artist")}
                      </SectionTitle>
                      {search.photographer.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                          id={item.id}
                          key={item.id}
                        >
                          <Photographer
                            key={item.id}
                            id={item.id}
                            img={item.image}
                            slug={item.slug}
                            type={item.category && item.category.title}
                            badg={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age={item.age}
                            weight={item.weight}
                            gender={item.gender}
                            // location={item.user && item.user.city.name}
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            whatsapp={item.user && item.user.whatsapp}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search && search.questions && search.questions.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      {t("Questions")}
                    </SectionTitle>
                    {search.questions.map((item) => (
                      <div
                        className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                        id={item.id}
                        key={item.id}
                      >
                        <QuestionsCard
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          badge={item.user && item.user.badge}
                          tags={item.tags}
                          description={item.content}
                          hashtags={item.hashtags}
                          id={item.id}
                          slug={item.slug}
                          openModal={() => {
                            setId(item.id);
                            setModal(true);
                          }}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      </div>
                    ))}
                  </section>
                )}

                {search &&
                  search.veterinaries &&
                  search.veterinaries.length > 0 && (
                    <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                      <SectionTitle className="w-full">
                        {t("Veterinary Clinic")}
                      </SectionTitle>
                      {search.veterinaries.map((item) => (
                        <div
                          className="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
                          id={item.id}
                          key={item.id}
                        >
                          <VeterinaryClinic
                            id={item.id}
                            slug={item.slug}
                            img={item.image}
                            title={item.title}
                            badge={item.user && item.user.badge}
                            location={item.address}
                            name={item.user && item.user.f_name}
                            phone={item.user && item.user.phone}
                            email={item.user && item.user.email}
                            whatsapp={item.user && item.user.whatsapp}
                          />
                        </div>
                      ))}
                    </section>
                  )}

                {search && search.videos && search.videos.length > 0 && (
                  <section className="mt-12 row w-full flex items-start justify-start flex-wrap">
                    <SectionTitle className="w-full">
                      {t("Videos")}
                    </SectionTitle>
                    {search.videos.map((item) => (
                      <div
                        className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-2"
                        key={item.id}
                      >
                        <VideoCard
                          views_count={item.views_count}
                          slug={item.slug}
                          key={item.id}
                          id={item.id}
                          likes_count={item.likes_count}
                          comment_count={item.comment_count}
                          description={item.content}
                          poster={item.thumbnail}
                          userImage={item.user && item.user.personal_picture}
                          name={
                            item.user && item.user.f_name && item.user.f_name
                          }
                          video={item.file}
                          badge={item.user && item.user.badge}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                          location={
                            item.user && item.user.city && item.user.city.name
                          }
                        />
                      </div>
                    ))}
                  </section>
                )}
              </div>
            )}
          </>
        )}
      </main>
    </Layout>
  );
};

export default WebSearch;
