import React from 'react'

const Settings = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M26.0923 17.5214C25.617 17.1728 25.333 16.6128 25.333 16.0228C25.333 15.4328 25.617 14.8728 26.0917 14.5248L28.2877 12.9148C28.6377 12.6574 28.7823 12.2048 28.6457 11.7921C28.0803 10.0934 27.1863 8.54409 25.9877 7.18609C25.699 6.86075 25.2337 6.75809 24.8357 6.93275L22.363 8.02008C21.8243 8.25742 21.197 8.22342 20.6857 7.92808C20.175 7.63342 19.8323 7.10809 19.7677 6.52208L19.4703 3.80742C19.423 3.37542 19.1017 3.02342 18.6763 2.93608C16.945 2.58208 15.1257 2.57609 13.3637 2.92942C12.9357 3.01542 12.6137 3.36742 12.5663 3.80142L12.2717 6.49875C12.207 7.08542 11.8643 7.61075 11.3523 7.90542C10.8417 8.20008 10.2163 8.23542 9.67567 7.99742L7.18967 6.90408C6.79501 6.72942 6.32834 6.83075 6.03967 7.15542C4.83701 8.50808 3.93901 10.0554 3.36767 11.7528C3.22901 12.1648 3.37301 12.6201 3.72434 12.8781L5.90634 14.4781C6.38234 14.8274 6.66634 15.3874 6.66634 15.9774C6.66634 16.5674 6.38234 17.1274 5.90767 17.4754L3.71167 19.0854C3.36167 19.3428 3.21701 19.7954 3.35367 20.2081C3.91901 21.9068 4.81301 23.4561 6.01167 24.8141C6.30034 25.1401 6.76701 25.2434 7.16367 25.0674L9.63634 23.9801C10.175 23.7428 10.8017 23.7768 11.3137 24.0721C11.8243 24.3668 12.167 24.8921 12.2317 25.4781L12.529 28.1928C12.5763 28.6248 12.8977 28.9767 13.323 29.0641C14.199 29.2428 15.099 29.3334 15.9997 29.3334C16.8783 29.3334 17.765 29.2447 18.635 29.0701C19.063 28.9841 19.385 28.6321 19.4323 28.1981L19.7277 25.5007C19.7923 24.9141 20.135 24.3887 20.647 24.0941C21.1577 23.8001 21.7837 23.7654 22.3237 24.0021L24.8097 25.0954C25.2057 25.2708 25.671 25.1694 25.9597 24.8441C27.1623 23.4914 28.0603 21.9441 28.6317 20.2468C28.7703 19.8348 28.6263 19.3794 28.275 19.1214L26.0923 17.5214ZM15.9997 20.6667C13.4223 20.6667 11.333 18.5774 11.333 16.0001C11.333 13.4228 13.4223 11.3334 15.9997 11.3334C18.577 11.3334 20.6663 13.4228 20.6663 16.0001C20.6663 18.5774 18.577 20.6667 15.9997 20.6667Z" fill="#227C9D" />
          </svg>
     )
}

export default Settings