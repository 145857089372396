import React from "react";

const RedButton = (props) => {
  return (
    <button
      className={`border-red border-2 rounded-2xl text-[1.4rem] font-bold ltr:tracking-wider duration-700 transition-all ${props.className}`}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default RedButton;
