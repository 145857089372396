import React from 'react'

const Bids = () => {
     return (
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M15.6092 6.69943C15.4695 6.68954 15.326 6.73289 15.2121 6.83214L12.7327 8.99094C12.505 9.18942 12.4805 9.537 12.6781 9.76566C12.777 9.88 12.9129 9.9432 13.0526 9.95311C13.1225 9.95807 13.1934 9.94976 13.2612 9.92778L13.7822 10.8236C13.9421 11.0973 14.1137 11.3628 14.2986 11.618L7.13933 17.2525C6.9126 17.4345 6.77197 17.6943 6.74594 17.986C6.72003 18.2758 6.80976 18.5571 7.00078 18.778C7.18674 18.993 7.44306 19.1215 7.72502 19.1415L7.74669 19.143C8.03644 19.1562 8.31424 19.0546 8.52575 18.855L15.3435 12.8223C15.5674 13.0436 15.8052 13.2529 16.0528 13.4484L16.8635 14.0906C16.7691 14.283 16.792 14.5216 16.9406 14.6934C17.0395 14.8076 17.1755 14.8709 17.3152 14.8809C17.4551 14.8908 17.5984 14.8474 17.7123 14.7482L20.1915 12.589C20.4191 12.3907 20.4438 12.0431 20.246 11.8146C20.0974 11.6428 19.8652 11.5863 19.6622 11.6528L19.1419 10.7563C18.5534 9.74226 17.7893 8.85892 16.8716 8.13155L16.0613 7.48967C16.1555 7.29731 16.1322 7.05848 15.9838 6.88688C15.8848 6.77245 15.749 6.70932 15.6092 6.69943Z" fill="#17C3B2" />
               <path fillRule="evenodd" clipRule="evenodd" d="M3.72185 1.2251C4.50356 0.44068 5.56378 0 6.66928 0C7.77478 0 8.83501 0.44068 9.61671 1.2251C10.3984 2.00951 10.8376 3.07341 10.8376 4.18274C10.8376 5.29207 10.3984 6.35596 9.61671 7.14038C8.83501 7.92479 7.77478 8.36547 6.66928 8.36547C5.56378 8.36547 4.50356 7.92479 3.72185 7.14038C2.94014 6.35596 2.50098 5.29207 2.50098 4.18274C2.50098 3.07341 2.94014 2.00951 3.72185 1.2251ZM0 11.7029C0 10.7852 0.744042 10.0386 1.65857 10.0386H11.68C12.4656 10.0386 13.1254 10.5895 13.2958 11.3266L6.46249 16.7291C4.85293 16.7008 3.35023 16.3422 2.21212 15.7116C0.785725 14.9223 0 13.7244 0 12.3391V11.7029Z" fill="#0F70B7" />
          </svg>

     )
}

export default Bids