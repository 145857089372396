
import React from 'react'
import Layout from '../../Components/Layout'
import { lostDataPage } from '../../Components/Helpers/Data'
import Search from '../../Components/Search/Search'
import AuctionCard from '../../Components/UI/Cards/Auction-Card'
const LostAnimal = () => {
  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        <section className="mt-12">
          <Search />
        </section>
        <section className="mt-12">
          <div className="row flex items-start justify-center flex-wrap">
            {
              lostDataPage.map((item, index) => (
                <div className='w-full md:w-1/2 lg:w-1/3 my-3' key={index}>
                  <AuctionCard
                    key={index}
                    img={item.img}
                    start={item.start}
                    title={item.title}
                    type={item.type}
                    price={item.price}
                    gender={item.gender}
                    isFav={item.isFav}
                    sectionType={'lostAnimal'} 
                    location={item.location}
                    loastDate={item.loastDate}
                    tags={item.tags} />

                </div>
              ))
            }
          </div>
        </section>

      </main>
    </Layout>
  )
}

export default LostAnimal

