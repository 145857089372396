import React from 'react'

const Male = (props) => {
     return (
          <svg className={`flex mx-auto ${props.className}`} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fillRule="evenodd" clipRule="evenodd" d="M11.5181 1.88328H9.87952C9.46988 1.88328 9.15663 1.57003 9.15663 1.16039C9.15663 0.750753 9.46988 0.4375 9.87952 0.4375H13.253C13.2775 0.4375 13.3017 0.438621 13.3255 0.440818C13.3969 0.446405 13.4695 0.461492 13.5422 0.485698C13.8072 0.60618 14 0.87124 14 1.1604V4.53389C14 4.94353 13.6867 5.25678 13.2771 5.25678C12.8675 5.25678 12.5542 4.94353 12.5542 4.53389V2.89534L9.93526 5.49247C10.6558 6.42781 11.0843 7.59959 11.0843 8.87123C11.0843 11.9315 8.60241 14.4134 5.54217 14.4134C2.48193 14.4134 0 11.9315 0 8.87123C0 5.81099 2.48193 3.32907 5.54217 3.32907C6.81439 3.32907 7.98667 3.75802 8.92222 4.47914L11.5181 1.88328ZM5.54217 4.77485C3.27711 4.77485 1.44578 6.60617 1.44578 8.87123C1.44578 11.1363 3.27711 12.9676 5.54217 12.9676C7.80723 12.9676 9.63855 11.1363 9.63855 8.87123C9.63855 6.60617 7.80723 4.77485 5.54217 4.77485Z" fill="white" />
          </svg>
     )
}

export default Male