import React from 'react'

const Border = (props) => {
     return (
          <svg className={`borderIcon ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.36 43.81">
               <defs>
                    <linearGradient id="Nueva_muestra_de_degradado_11" data-name="Nueva muestra de degradado 11" x1="32.03" y1="-2.73" x2="17.34" y2="39.02" gradientUnits="userSpaceOnUse">
                         <stop offset="0" stopColor="#dcc59e" />
                         <stop offset=".07" stopColor="#d3bb94" />
                         <stop offset=".32" stopColor="#b99b76" />
                         <stop offset=".57" stopColor="#a68461" />
                         <stop offset=".8" stopColor="#9a7654" />
                         <stop offset="1" stopColor="#977250" />
                    </linearGradient>
               </defs>
               <g className="cls-3">
                    <g id="Layer_2" data-name="Layer 2">
                         <g id="Layer_1-2" data-name="Layer 1">
                              <g>
                                   <path className="cls-2" d="m47.13,20.76c-.14-.52-.36-1.04-.64-1.52l-8.5-14.74c-1.18-2.03-3.34-3.28-5.69-3.28H15.29c-2.34,0-4.51,1.25-5.68,3.28L1.1,19.24c-.68,1.18-.96,2.52-.86,3.83.09.95.37,1.88.86,2.73l8.51,14.73c1.17,2.03,3.34,3.28,5.68,3.28h17.01c2.35,0,4.51-1.25,5.69-3.28l8.5-14.73c.89-1.54,1.1-3.36.64-5.04Zm-42.99-2.74l6.88-11.91c1.17-2.03,3.33-3.28,5.68-3.28h13.75c2.34,0,4.51,1.25,5.68,3.28l6.88,11.91c1.17,2.03,1.17,4.53,0,6.56l-6.88,11.91c-1.17,2.03-3.34,3.28-5.68,3.28h-13.75c-2.35,0-4.51-1.25-5.68-3.28l-6.88-11.91c-1.17-2.03-1.17-4.53,0-6.56Z" />
                                   <path className="cls-1" d="m47.13,20.76c-.08-.95-.36-1.89-.86-2.74l-8.51-14.73c-1.17-2.03-3.33-3.29-5.68-3.29H15.07c-2.35,0-4.51,1.26-5.68,3.29L.88,18.02C-.01,19.57-.22,21.39.24,23.07c.15.52.36,1.03.64,1.51l8.51,14.74c1.17,2.03,3.33,3.28,5.68,3.28h17.01c2.35,0,4.51-1.25,5.68-3.28l8.51-14.74c.68-1.18.96-2.51.86-3.82Zm-4.12,3.82l-6.88,11.91c-1.17,2.03-3.34,3.28-5.68,3.28h-13.75c-2.35,0-4.51-1.25-5.68-3.28l-6.88-11.91c-1.17-2.03-1.17-4.53,0-6.56l6.88-11.91c1.17-2.03,3.33-3.28,5.68-3.28h13.75c2.34,0,4.51,1.25,5.68,3.28l6.88,11.91c1.17,2.03,1.17,4.53,0,6.56Z" />
                              </g>
                         </g>
                    </g>
               </g>
          </svg>
     )
}

export default Border