import axios from 'axios'
import { t } from 'i18next'
import React, { Fragment, useState } from 'react'
import alert from '../../Helpers/Modal'
import { config, url } from '../Helpers/Help'
import BlueButton from '../UI/Buttons/BlueButton'

const InterestedType = (props) => {
  const [query, setQuery] = useState("")
  const [interestedArray, setInterestedArray] = useState([])

  const handleChecked = (id) => {
    let prev = interestedArray;
    let itemIndex = prev.indexOf(id);
    if (itemIndex !== -1) { prev.splice(itemIndex, 1) }
    else { prev.push(id); }
    setInterestedArray([...prev]);
  };

  const categories = interestedArray.toString()

  const handleSearch = (e) => {
    e.preventDefault()
    axios.post(url + 'users/update-animal-categories',
      //  Data
      {
        'categories': categories,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        setTimeout(() => {
          window.location.href = '/'
        }, 1100);
        alert({
          title: `${t('Alert')}`,
          text: `${t('Thanks for sharing your Interesting')}`,
          icon: 'success',
          timer: 1000
        })
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        alert({
          title: `${t('Alert')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 1000
        })
      })
  }






  return (
    <>
      <h3 className='w-full font-bold text-[3.4rem]'>{t('Interested in')}</h3>
      <p className='w-full font-light text-[1.5] mb-8'>{t('Lorem ipsum dolor sit amet, consectetur adipiscing')}</p>
      <form className="search w-full flex items-center flex-wrap text-[1.5rem] font-semibold justify-start border-2 border-blue bg-white rounded-2xl py-4 relative mb-8">
        <input type="text" onChange={event => setQuery(event.target.value)} className='w-full md:w-full bg-transparent px-4 md:px-8' placeholder='Search' />
      </form>
      {
        (props.items && props.items.length > 0) &&
        <div className='flex items-start justify-center flex-wrap w-full text-center'>
          {
            props.items.map((item, index) => (
              <Fragment key={index}>
                {item.childs &&
                  item.childs.filter((inneritem) => {
                    if (query === '') {
                      return inneritem;
                    } else if (inneritem.title.toLowerCase().includes(query.toLowerCase())) {
                      return inneritem;
                    }
                  }).map((inneritem, innerIndex) => (
                    <div className="w-1/2 p-2 my-2 md:w-1/4 lg:w-1/5 xl:w-1/6 relative overflow-hidden" key={innerIndex}>
                      <input value={inneritem.id} type="checkbox" onChange={() => handleChecked(inneritem.id)} className='absolute top-0 left-0 w-full h-full z-10 opacity-0 cursor-pointer' />
                      <div className={`h-[12rem] flex items-center justify-start flex-col rounded-3xl px-2 ${interestedArray.includes(inneritem.id) && `InterestedIteemChecked`}`} style={{ 'background': inneritem.background }} >
                        <div className="h-[9rem] flex items-center justify-center relative overflow-hidden rounded-3xl mt-2 bg-white w-full">
                          <img className='bg-white max-h-full' src={inneritem.icon} alt="" />
                        </div>
                        <h3 className='h-[3rem] text-[1.6rem] text-white font-semibold no-wrap w-full text-center'>{inneritem.title}</h3>
                      </div>
                    </div>
                  ))
                }
              </Fragment>
            ))
          }
        </div>
      }

      <BlueButton onClick={handleSearch} className='w-full text-[1.5rem] py-4 px-5 mt-8 hover:bg-mint'> {t('Next')} </BlueButton>
      <button onClick={props.onSkip} className='bg-dark-gold w-full mt-4 text-[1.5rem] rounded-xl py-4 px-5 font-bold text-gray-300 hover:text-blue text-center'> {t('Skip')}</button>

    </>
  )
}

export default InterestedType