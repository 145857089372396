import React, { useEffect, useState } from 'react'
import InterestedIn from '../Components/introduction/InterestedIn'
import InterestedType from '../Components/introduction/InterestedType'
import Nav from '../Components/Layout/Nav'
import { useNavigate } from 'react-router-dom'
import alert from '../Helpers/Modal'
import { t } from 'i18next'
const Introduction = (props) => {
     const [mainInterested, setMainInterested] = useState()
     const [subInterested, setSubInterested] = useState()

     const navigate = useNavigate()
 
     useEffect(() => {
          if (!mainInterested || !subInterested) {
               return
          }
     }, [mainInterested, subInterested, navigate])

 


 
     const handleSendInterested = () => {
          console.log(subInterested)
          alert({
               title: `${t('Alert')}`,
               text: `${t(`Thanks for sharing your interests`)}`,
               icon: 'success',
               timer: 1000
          })
          setTimeout(() => {
               // window.location.href = '/'
          }, 1000);
     }
     return (
          <main id="Introduction" className="min-h-screen flex items-center justify-center pt-8">
               <Nav />
               <div className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    <div id='' className='container mx-auto'>
                         <div className="row flex justify-center items-start flex-wrap">
                              {mainInterested ?
                                   <div className="w-full md:w-10/12 xl:w-8/12">
                                        {subInterested &&
                                             <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl p-2 md:p-8 xl:p-12">
                                                  <InterestedType items={subInterested} onSelectInterested={(interestedArray) => { handleSendInterested(); setSubInterested(interestedArray) }} onSkip={() => navigate('/')} />
                                             </div>
                                        }
                                   </div>
                                   :
                                   <div className="w-full sm:w-10/12 md:w-8/12 lg:w-8/12 xl:w-8/12">
                                        <div className="row flex items-stretch justify-center flex-wrap bg-white shadow-xl rounded-2xl p-2 md:p-8 xl:p-12">
                                             <InterestedIn onSelectInterested={(isInterested, childs) => { setSubInterested(childs); setMainInterested(isInterested) }} onSkip={() => navigate('/')} />
                                        </div>
                                   </div>
                              }
                         </div>
                    </div>
               </div>
          </main>
     )
}

export default Introduction