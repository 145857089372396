import React, { useEffect, useState } from 'react'
import Vip2 from '../../assets/Icons/Vip2'
import Hour from '../../assets/Icons/24Hour'
import PublicAuction from '../../assets/Icons/PublicAuction'
import Holesalemarket from '../../assets/Icons/Holesalemarket'
import Fridaymarket from '../../assets/Icons/Fridaymarket'
import DailyAnnounce from '../../assets/Icons/DailyAnnounce'
import Specialoffer from '../../assets/Icons/Specialoffer'
import Quickmarket from '../../assets/Icons/Quickmarket'
import Videos from '../../assets/Icons/Videos'
import Other from '../../assets/Icons/Other'
import Mating from '../../assets/Icons/Mating'
import Lookfor from '../../assets/Icons/Lookfor'
import { Link } from 'react-router-dom'
import { config, url } from '../Helpers/Help'
import axios from 'axios'
import { t } from 'i18next'
import Advirtisings from '../../assets/Icons/Settings/Advirtisings'
const AddAnimal = (props) => {
     const [start, setStart] = useState([]);
     useEffect(() => {
          if (localStorage.__data_aW__a_start) {
               setStart(localStorage.__data_aW__a_start.animal_types)
          }
          axios(url + "start ", config).then((response) => {
               setStart(response.data.data.animal_types);
               localStorage.setItem(
                    "__data_aW__a_start",
                    JSON.stringify(response.data.data)
               );
          });
     }, []);
     return (
          <>
               <div className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40" onClick={props.onClick} />
               <div className={`fixed pb-[6rem] md:pb-0 shadow-xl top-0 w-8/12 md:w-[30rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
                    <h3 className='border-b-2 border-blue pb-4 font-semibold text-[1.6rem]'> {t('Add Animal')} </h3>
                    <ul className='text-[1.4rem] font-semibold mt-12'>

                         {
                              start &&
                              <>
                                   {start.map(item => (
                                        <Link to={`/add-animal/${item.slug}`} key={item.id} onClick={props.onClick} className={` rounded-xl shadow-md px-4 py-6 xl:py-8 my-4 bg-white flex items-center justify-start gap-4`}>
                                             <img src={item.icon} alt={item.title} className='w-12' /> {item.title}
                                        </Link>
                                   ))}
                                   <Link onClick={props.onClick} to="/advirtisings" className="rounded-xl shadow-md px-4 py-6 xl:py-8 my-4 bg-white flex items-center justify-start gap-4" >
                                        <Advirtisings fill='#0F70B7' /> {t('Add Advirtisings')}
                                   </Link>
                              </>
                         }


                    </ul>
               </div>
          </>
     )
}

export default AddAnimal