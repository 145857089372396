import React from 'react'

const Hour24 = () => {
     return (
          <svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g filter="url(#filter0_d_56_3617)">
                    <path d="M1 0H37V20C37 29.9411 28.9411 38 19 38C9.05888 38 1 29.9411 1 20V0Z" fill="#00EC89" shapeRendering="crispEdges" />
                    <path d="M6.90608 24V23.09V22.621C6.90608 22.103 7.00174 21.6573 7.19308 21.284C7.38441 20.906 7.63874 20.577 7.95608 20.297C8.27341 20.017 8.62108 19.7697 8.99908 19.555C9.38174 19.3357 9.75974 19.1303 10.1331 18.939C10.5111 18.743 10.8587 18.5423 11.1761 18.337C11.4934 18.127 11.7477 17.8937 11.9391 17.637C12.1351 17.3803 12.2331 17.0747 12.2331 16.72C12.2331 16.2673 12.0721 15.8987 11.7501 15.614C11.4327 15.3247 11.0011 15.18 10.4551 15.18C9.87641 15.18 9.39808 15.3457 9.02008 15.677C8.64674 16.0083 8.42508 16.475 8.35508 17.077H6.70308C6.71241 16.461 6.85941 15.901 7.14408 15.397C7.42874 14.893 7.85108 14.4917 8.41108 14.193C8.97108 13.8897 9.66874 13.738 10.5041 13.738C11.2274 13.738 11.8504 13.864 12.3731 14.116C12.9004 14.3633 13.3064 14.711 13.5911 15.159C13.8804 15.6023 14.0251 16.1203 14.0251 16.713C14.0251 17.231 13.9294 17.6743 13.7381 18.043C13.5514 18.4117 13.3017 18.7313 12.9891 19.002C12.6811 19.268 12.3404 19.5037 11.9671 19.709C11.5984 19.9143 11.2274 20.108 10.8541 20.29C10.4854 20.4673 10.1447 20.654 9.83208 20.85C9.52408 21.0413 9.27441 21.2607 9.08308 21.508C8.89641 21.7553 8.80308 22.0517 8.80308 22.397V22.544H14.0461V24H6.90608ZM20.0057 13.878H21.6997V20.15H23.3167V21.599H21.6997V24H20.0477L20.0547 21.599H15.3437V20.15L20.0057 13.878ZM16.9397 20.15H20.0407V15.992L16.9397 20.15ZM24.6965 24V13.598H26.3765V17.812C26.4931 17.63 26.6471 17.455 26.8385 17.287C27.0298 17.1143 27.2631 16.9743 27.5385 16.867C27.8185 16.755 28.1521 16.699 28.5395 16.699C29.0015 16.699 29.4238 16.7877 29.8065 16.965C30.1891 17.1423 30.4948 17.399 30.7235 17.735C30.9521 18.0663 31.0665 18.463 31.0665 18.925V24H29.3585V19.212C29.3585 18.8387 29.2255 18.5493 28.9595 18.344C28.6981 18.1387 28.3668 18.036 27.9655 18.036C27.6995 18.036 27.4451 18.0827 27.2025 18.176C26.9598 18.2693 26.7638 18.4093 26.6145 18.596C26.4698 18.778 26.3975 19.0067 26.3975 19.282V24H24.6965Z" fill="black" />
               </g>
               <defs>
                    <filter id="filter0_d_56_3617" x="0" y="0" width="38" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                         <feFlood floodOpacity="0" result="BackgroundImageFix" />
                         <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                         <feOffset dy="1" />
                         <feGaussianBlur stdDeviation="0.5" />
                         <feComposite in2="hardAlpha" operator="out" />
                         <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.180392 0 0 0 0 0.301961 0 0 0 0.1 0" />
                         <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_56_3617" />
                         <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_56_3617" result="shape" />
                    </filter>
               </defs>
          </svg>

     )
}

export default Hour24