import { t } from 'i18next'
import React, { useContext } from 'react'
import Countdown from 'react-countdown'
import { Link } from 'react-router-dom'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import Male from '../../../assets/Icons/Male'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Context from '../../../Store/Store'
import Vip from '../../../assets/Icons/Vip'
import Hour24 from '../../../assets/Icons/Hour24'
import BlueButton from '../Buttons/BlueButton'
import Remind from '../../../assets/Icons/Remind'
import { config, url } from '../../Helpers/Help'
import axios from 'axios'
import alert from '../../../Helpers/Modal'
import Reminded from '../../../assets/Icons/Reminded'
import { useState } from 'react'
// import InMarket from '../../../assets/Icons/InMarket'
import Lottie from 'react-lottie';
import InMarket from '../../../Components/Lottie/InMarket.json'

const defaultOptions = {
     loop: true,
     autoplay: true,
     animationData: InMarket,
     rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
     }
};
const Completionist = () => <span>Auction Expired</span>;
// ""
const VipAuction = (props) => {
     const [auctionId, setAuctionId] = useState()
     const { id, vip, slug, img, max_price, type, users_count, end_minutes, age, weight, gender, start, is_notify, inMarket } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }


     const notify = () => {
          axios.post(url + 'animals/bb/notify',
               //  Data
               {
                    slug: 'auctions',
                    animal_id: id,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response.data)
                    if (response.data.data.is_notify) {
                         setAuctionId(+response.data.data.id)
                    } else {
                         setAuctionId()
                    }
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you`)}`,
                         icon: 'success',
                         timer: 2000
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error.response)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                    })
               })
     }

     return (
          <div className={`AuctionCard  dir-rtl relative mx-2 flex items-start justify-center bg-white rounded-2xl flex-wrap h-[34rem] md:h-[20rem]`}>
               {vip === "vip-auction" &&
                    <div className="absolute top-0 left-4 z-10">
                         <Vip />
                    </div> 
               }
               {vip === "quick-auction" &&
                    <div className="absolute top-0 left-4 z-10">
                         <Hour24 />
                    </div>
               }
               <div className="w-full md:w-1/2 h-[16rem] md:h-[20rem] relative overflow-hidden">
                    <Link to={`/details/auctions/${id}`} >
                         {end_minutes === 0 && <img src={img} alt="" className='h-full w-full object-cover rounded-2xl' /> }
                         <img src={img} alt="" className='h-full w-full object-cover rounded-2xl' />
                    </Link>
               </div>

               <div className="w-full md:w-1/2">
                    <ul className='p-2 md:p-4'>
                         <Link to={`/details/auctions/${id}`} >
                              {start && <li className='mt-2 no-wrap'> <b><span className="digital text-3xl">
                                   <Countdown date={Date.now() + 60000 * `${end_minutes}`}>
                                        <span className='PublicSans'><Completionist /></span>
                                   </Countdown>
                              </span> </b> </li>}
                              {type && <li className='mt-4 no-wrap'>{t('Type')}: <b>{type}</b></li>}
                              <li className='mt-4 no-wrap'>{t('Last Bid')}: <b>{max_price ? `${max_price}` : 0} {t('AED')}</b></li>
                              <div className="flex justify-start items-center gap-2 mt-2">

                                   <li> {weight && <span className='bg-[#EBEBEB] px-4 py-2 rounded-2xl text-[1.4rem]'>{weight} </span>}</li>
                                   <li>{age && <span className='bg-[#EBEBEB] px-4 py-2 rounded-2xl text-[1.4rem]'>{age} </span>}</li>
                              </div>
                         </Link>

                         <li className='mt-4 no-wrap flex items-center justify-start gap-2 flex-wrap'>
                              {gender && <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>}
                              <li className='no-wrap flex items-center justify-start gap-4'>
                                   <svg width="26" height="26" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.00004 0.666504C5.89497 0.666504 4.83516 1.10549 4.05376 1.88689C3.27236 2.66829 2.83337 3.7281 2.83337 4.83317C2.83337 5.93824 3.27236 6.99805 4.05376 7.77945C4.83516 8.56085 5.89497 8.99984 7.00004 8.99984C8.10511 8.99984 9.16492 8.56085 9.94632 7.77945C10.7277 6.99805 11.1667 5.93824 11.1667 4.83317C11.1667 3.7281 10.7277 2.66829 9.94632 1.88689C9.16492 1.10549 8.10511 0.666504 7.00004 0.666504ZM12.0088 10.6665H1.99129C1.07712 10.6665 0.333374 11.4103 0.333374 12.3244V12.9582C0.333374 14.3382 1.11879 15.5315 2.54462 16.3178C3.73087 16.9728 5.31337 17.3332 7.00004 17.3332C10.2109 17.3332 13.6667 15.964 13.6667 12.9582V12.3244C13.6667 11.4103 12.923 10.6665 12.0088 10.6665Z" fill="#0F70B7" />
                                   </svg>
                                   <b>{users_count}</b>
                              </li>


                              {window.innerWidth < 767 &&
                                   <button onClick={notify} >
                                        {auctionId === +id || is_notify ?
                                             <Reminded fill={'#0f70b7'} />
                                             :
                                             <Remind strok={'#0f70b7'} />
                                        }
                                   </button>
                              }
                              {inMarket &&
                                   <div>
                                        <Lottie options={defaultOptions} height={20} width={20} />
                                   </div>
                              }
                         </li>


                    </ul>
                    <li className='flex items-center justify-between gap-4'>
                         {/* <BlueButton
                              onClick={notify}
                              disabled={auctionId === +id || is_notify}
                              className={`px-4 mx-3 w-full py-4 flex items-center justify-center gap-4 ${(auctionId === +id || is_notify) && 'cursor-not-allowed'}`}>
                              {auctionId === +id || is_notify ? <> {t('Reminded')} <Reminded /></> : <>{t('Remind Me')} <Remind /> </>}
                         </BlueButton> */}
                         {/* {auctionId === +id || is_notify ?
                              <BlueButton onClick={notify} className={`px-4 mx-3 w-full py-4 flex items-center justify-center gap-4 `}> {t('Reminded')} <Reminded /> </BlueButton>
                              :
                              <BlueButton onClick={notify} className={`px-4 mx-3 w-full py-4 flex items-center justify-center gap-4`}> {t('Remind Me')} <Remind />  </BlueButton>
                         } */}
                         {window.innerWidth > 767 &&
                              <button onClick={notify} className={`absolute top-4 rtl:left-8 ltr:right-20 z-10 dir-ltr`}>
                                   {auctionId === +id || is_notify ?
                                        <Reminded fill={'#0f70b7'} />
                                        :
                                        <Remind strok={'#0f70b7'} />
                                   }
                              </button>
                         }

                         <button className='absolute top-4 rtl:left-20 ltr:right-4 z-10 dir-ltr' onClick={toggleFavoriteStatusHandler}>
                              <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} />
                         </button>
                    </li>
               </div>
          </div>
     )
}

export default VipAuction