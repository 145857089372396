import React from 'react'

const Facebook = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M16 2.66675C8.63601 2.66675 2.66667 8.63608 2.66667 16.0001C2.66667 22.6854 7.586 28.2207 14.0027 29.1847C14.654 29.2827 15.3213 27.3334 16 27.3334C16.6127 27.3334 17.216 29.2921 17.8073 29.2121C24.3167 28.3301 29.3333 22.7514 29.3333 16.0001C29.3333 8.63608 23.364 2.66675 16 2.66675Z" fill="url(#paint0_linear_302_670)" />
               <path opacity="0.05" d="M18.4713 14.1126C18.4713 13.1633 18.6747 12.0319 19.6427 12.0319H22.498L22.4973 8.28728L22.464 7.71928L21.9 7.64328C21.4947 7.58861 20.6513 7.47461 19.096 7.47461C15.3833 7.47461 13.3387 9.68461 13.3387 13.6973V15.3626H10.04V20.2013H13.3387V29.0666C14.1987 29.2406 15.0887 29.3333 16 29.3333C16.8453 29.3333 17.6693 29.2459 18.4713 29.0953V20.2006H21.8267L22.5747 15.3619H18.4713V14.1126Z" fill="black" />
               <path opacity="0.05" d="M18.138 14.1128C18.138 13.2108 18.3333 11.6988 19.6427 11.6988H22.1647V8.30677L22.1473 8.01344L21.8587 7.97411C21.4607 7.92011 20.6333 7.80811 19.0967 7.80811C15.548 7.80811 13.6727 9.84477 13.6727 13.6974V15.6961H10.3733V19.8681H13.672V29.1174C14.4293 29.2508 15.204 29.3334 16 29.3334C16.7293 29.3334 17.44 29.2588 18.138 29.1461V19.8674H21.5407L22.1853 15.6954H18.1373V14.1128H18.138Z" fill="black" />
               <path d="M17.8047 19.534H21.2553L21.7973 16.0287H17.8047V14.1127C17.8047 12.6567 18.2807 11.3654 19.6427 11.3654H21.8313V8.30669C21.4467 8.25469 20.6333 8.14136 19.0967 8.14136C15.8873 8.14136 14.006 9.83602 14.006 13.6974V16.0294H10.7067V19.5347H14.0053V29.1847C14.6587 29.282 15.3207 29.3334 16 29.3334C16.614 29.3334 17.2133 29.292 17.8047 29.212V19.534Z" fill="white" />
               <defs>
                    <linearGradient id="paint0_linear_302_670" x1="6.662" y1="6.66208" x2="27.0767" y2="27.0767" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#2AA4F4" />
                         <stop offset="1" stopColor="#007AD9" />
                    </linearGradient>
               </defs>
          </svg>

     )
}

export default Facebook