import React from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import Male from '../../../assets/Icons/Male'
import Phone from '../../../assets/Icons/Phone'
import Whatsapp from '../../../assets/Icons/Whatsapp'
import BlueButton from '../Buttons/BlueButton'
import RedButton from '../Buttons/RedButton'
import Lottie from 'react-lottie';
import InMarket from '../../../Components/Lottie/InMarket.json'
import { t } from 'i18next'
import { Veterinary } from '../../../Pages'
import { Link } from 'react-router-dom'

const defaultOptions = {
     loop: true,
     autoplay: true,
     animationData: InMarket,
     rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
     }
};

const ProductCard = (props) => {
     const { img, userImage, noImage, id, slug, description, show_scan, offerPrice, name, openModal, veterinary, isMyBids, NO_email_Phone, badg, age, weight, location, type, typeCard, myBid, myPrice, lastBid, price, gender, startBid, isFav, inMarket, offer, oldPrice, action, phone, email, contact, question, averagePrice, title, locationAnimal, duration, whatsapp, photographer, qr } = props
     return (
          <div className={`AuctionCard mx-2 flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${photographer ? 'flex-col-reverse pb-0' : 'flex-col'}`}>
               {img && !noImage &&
                    <Link to={`/Details/${slug}/${id}`} className='w-full'>
                         <img src={img} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                    </Link>}
               <div className="w-full min-h-[10rem] max-h-[27rem]">
                    <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap">
                         {
                              title && !veterinary && <h3 className='font-semibold '>{title}</h3>
                         }
                         {(userImage || name || badg) &&
                              <>
                                   <div className="user flex items-center gap-2">
                                        {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                             <img src={userImage} alt="" className='h-full w-full object-cover' />
                                        </div>
                                        }
                                        {name && <div className="name">
                                             {name}
                                        </div>
                                        }
                                        <div className="">
                                             {badg === 'verify' ?
                                                  <Badge />
                                                  :
                                                  <BadgeGray />
                                             }
                                        </div>
                                   </div>

                                   <div className="location">
                                        {location}
                                   </div>
                              </>
                         }
                         {
                              veterinary && <h3 className='font-semibold mt-2'>{title}</h3>
                         }
                    </div>
                    <ul className='px-2 md:px-4'>
                         <Link to={`/Details/${slug}/${id}`}>
                              {description && <p className='text-[1.6rem]'>{description}</p>}
                              {type && <li className='mt-2 no-wrap'>{t('Type')}: <b>{type}</b></li>}
                              <li className='mt-2 no-wrap'>{t('My Bid')}: {myBid ? <b> {myBid}</b> : `${t('No Bids')}`}</li>
                              {myPrice && <li className='mt-2 no-wrap'>{t('My Price')}: <b>{myPrice}</b></li>}
                              {lastBid && <li className='mt-2 no-wrap'>{t('Last Bid')}: <b>{lastBid}</b></li>}
                              {averagePrice && <li className='mt-2 no-wrap'>{t('Average Price')}: <b>{averagePrice}</b></li>}
                              {locationAnimal && <li className='mt-2 no-wrap'>{t('Location')}: <b>{locationAnimal}</b> </li>}
                              {price && <li className='mt-2 no-wrap'>{t('Price')} {!offer && 'start'}: <b>{price} {t('AED')} {duration && `/ ${duration}`}</b> {oldPrice && <span className='text-[1.2rem] text-red line-through'>{oldPrice}</span>} </li>}
                              {whatsapp && <a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} className='mt-2 w-full no-wrap flex flex-wrap items-center justify-start gap-2'>{t('Contact')}: <b className='flex flex-wrap items-center justify-start gap-2'> <Whatsapp /> {whatsapp}</b></a>}
                         </Link>
                         <li className='w-full mt-2 flex items-center justify-start flex-wrap'>
                              <p className='w-4/6 flex items-center justify-start flex-wrap gap-2'>
                                   {gender && <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>}
                                   {age && <span className='bg-[#ececec] px-4 py-2 rounded-2xl text-[1.4rem]'>{age}</span>}
                                   {weight && <span className='bg-[#ececec] px-4 py-2 rounded-2xl text-[1.4rem]'>{weight}</span>}
                              </p>

                              {(phone || email) && NO_email_Phone &&
                                   <p className='flex items-center flex-wrap gap-1 w-2/6 justify-end'>
                                        {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                        {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                   </p>
                              }

                              {/* {(tags || gender) &&
                                   <p className='w-4/6 flex items-center justify-start flex-wrap gap-2'>
                                        {gender &&
                                             <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>
                                        }
                                        {tags && <>{tags && <>{tags.slice(0, 2).map((item, index) => (
                                             <span className='bg-[#ececec] px-4 py-2 rounded-2xl text-[1.4rem]' key={index}>{item}</span>
                                        ))}</>}</>}
                                   </p>
                              } */}
                              {/* {(phone || email) &&
                                   <p className={` flex items-center  flex-wrap gap-1 ${!tags ? 'w-full justify-start' : 'w-2/6 justify-end'}`}>
                                        {phone && <a href={`tel:${phone}`}> <Phone /> </a>}
                                        {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                   </p>
                              } */}
                         </li>
                         {!action &&
                              <li className='flex items-center justify-center mt-4 gap-4'>
                                   {inMarket ?
                                        <RedButton className='flex items-center justify-center p-4 w-full hover:bg-red hover:text-white'>
                                             <div className="w-12 mx-4 bg-white py-1 rounded-md">
                                                  <Lottie options={defaultOptions} height={20} width={20} />
                                             </div> {t('In Market')}
                                        </RedButton>
                                        :
                                        <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full'> {!offer ? `${t('Your Offer Price')}` : `${t('Contact Owner')}`} </BlueButton>
                                   }
                                   <button className=''> <LineHeart className={` hover:fill-blue ${isFav ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                              </li>
                         }
                         {typeCard === 'live' &&
                              <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full mt-6'>{t('Start Bid')}</BlueButton>

                         }
                         {contact &&
                              <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full mt-4'> {t('Contact')} </BlueButton>
                         }
                         {question &&
                              <li className='flex items-center justify-center mt-4 gap-4'>
                                   <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full mt-4'> {t('Answer')} </BlueButton>
                                   <button className=''> <LineHeart className={` hover:fill-blue ${isFav ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                              </li>
                         }
                         {isMyBids &&
                              <>
                                   {!show_scan ?
                                        <Link to={`/Details/${slug}/${id}`}>
                                             <BlueButton className='border-2 border-blue flex items-center justify-center p-4 hover:bg-mint hover:border-mint w-full mt-6'>{t('Start Bid')}</BlueButton>
                                        </Link>
                                        :
                                        <BlueButton onClick={() => openModal(id, qr)} className='border-2 border-green flex items-center justify-center p-4 bg-green hover:border-mint w-full mt-6'>{t('Scan QR Code')}</BlueButton>
                                   }
                              </>}
                         {offerPrice &&
                              <Link to={`/Details/${slug}/${id}`}>
                                   <BlueButton className='border-2 border-blue flex items-center justify-center p-4 bg-green-500 hover:border-mint w-full mt-6'>{t('Your Offer Price')}</BlueButton>
                              </Link>
                         }
                    </ul>
               </div>
          </div >
     )
}

export default ProductCard