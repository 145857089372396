import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { config, url } from '../Components/Helpers/Help';
import Layout from '../Components/Layout'
import { Player } from 'video-react';
import { t } from 'i18next';

const Ads = () => {
  const [ads, setAds] = useState()
  const [advertises, setAdvertises] = useState(true)
  useEffect(() => {
    axios(url + 'advertise', config).then(
      response => {
        setAds(response.data.data)
        console.log(response.data.data)
      }
    )
  }, []);
  return (
    <Layout>
      <main className="container mx-auto pt-16 md:pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        {ads &&
          <section className="mt-12">
            <div className="flex items-center justify-center">
              <button className={` capitalize bg-white p-4 border mx-4 rounded-lg shadow-lg hover:bg-blue/90 hover:text-white ${advertises && 'bg-blue text-white'}`} onClick={() => setAdvertises(true)}>{t('advertises')}</button>
              <button className={` capitalize bg-white p-4 border mx-4 rounded-lg shadow-lg hover:bg-blue/90 hover:text-white ${!advertises && 'bg-blue text-white'}`} onClick={() => setAdvertises(false)}>{t('Banner')}</button>

            </div>
            {
              advertises ?
                <div className="row flex items-center justify-center flex-wrap">
                  {
                    ads.advertises.map(item => (
                      <div className='w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5' key={item.id}>
                        <div className="card h-[25rem] relative overflow-hidden text-white mb-2 shadow-xl p-4 rounded-xl">
                          {
                            item.type === "image" ?
                              <img src={item.banner} alt={item.title} className='mx-auto w-full h-full object-cover rounded-xl' />
                              :
                              <Player height={'100%'} width={'100%'} fluid={false} playsInline poster={item.thumbnail} src={item.banner}  />
                          }
                        </div>
                      </div>
                    ))
                  }

                </div>
                :
                <div className="row flex items-center justify-center flex-wrap">
                  {
                    ads &&
                    ads.banners.map(item => (
                      <div className='w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5' key={item.id}>
                        <div className="card h-[25rem] relative overflow-hidden text-white mb-2 shadow-xl p-4 rounded-xl">
                          {
                            item.type === "image" ?
                              <img src={item.web_file} alt={item.title} className='mx-auto w-full h-full object-cover rounded-xl' />
                              :
                              <Player height={'100%'} width={'100%'} fluid={false} playsInline poster={item.thumbnail} src={item.banner} />
                          }
                        </div>
                      </div>
                    ))
                  }

                </div>

            }



          </section>
        }
      </main>
    </Layout >
  )
}

export default Ads