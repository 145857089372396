import { t } from 'i18next'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const alert = (props) => {
     return (
          Swal.fire({
               position: 'center',
               title: props.title,
               text: props.text,
               icon: props.icon,
               confirmButtonText: `${t('ok')}`,
               timer: props.timer,
               showCloseButton: true,
          })
     )
}

export default alert