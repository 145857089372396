import React, { useContext, useState } from "react";
import { Player } from "video-react";
import Like from "../../../assets/Icons/Like";
import Comment from "../../../assets/Icons/Comment";
import eye from "../../../assets/Icons/view.png";
import Share from "../../../assets/Icons/Share";
import "../../../Styles/VideoCard.scss";
import { Link } from "react-router-dom";
import LineHeart from "../../../assets/Icons/LineHeart";
import Context from "../../../Store/Store";
import { t } from "i18next";
import Badge from "../../../assets/Icons/Badge";
import BadgeGray from "../../../assets/Icons/BadgeGray";
import Verified from "../../../assets/Icons/Verified";
import Live from "../../../assets/Icons/Live";
import Countdown from "react-countdown";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VideoCard = (props) => {
  const {
    id,
    is_live,
    views_count,
    description,
    userImage,
    title,
    name,
    badge,
    start_time,
    max_price,
    end_minutes,
    poster,
    video,
    slug,
    live,
    userId,
    liveLink,
  } = props;
  const { t } = useTranslation("translation");
  const Completionist = () => <span> {t("Live Expired")}</span>;

  const params = useLocation();
  const ctx = useContext(Context);
  const itemIsFavorite = ctx.itemIsFavorite(id, slug);
  function toggleFavoriteStatusHandler() {
    // console.log(id, slug)
    if (itemIsFavorite) {
      ctx.removeVideoFavorite(id, slug);
    } else {
      ctx.addVideoFavorite(id, slug);
    }
  }
  return (
    <div className="dir-rtl mx-2 md:mx-4 relative overflow-hidden rounded-2xl bg-white">
      <Player
        height={360}
        width={"100%"}
        fluid={false}
        playsInline
        poster={poster}
        src={video}
      />
      {is_live === 0 && (
        <div className="absolute top-8 left-8 bg-white flex items-center justify-center rounded-lg p-2 w-16">
          <Live className="w-10" />
        </div>
      )}

      <div className="mt-2 flex flex-col p-4">
        <p className="w-full">{title}</p>
        {start_time && is_live === -1 && (
          <>
            <p>
              Live start :
              <span className="text-2xl">
                {new Date(start_time).getFullYear()}/
                {new Date(start_time).getDate()}/{new Date(start_time).getDay()}
              </span>
            </p>
            <p>
              At Clock :
              <span className="text-2xl">
                {" "}
                {new Date(start_time).getHours()} :{" "}
                {new Date(start_time).getMinutes()}
              </span>
            </p>
          </>
        )}
        {is_live !== -1 && (
          <p>
            {t("live end")} :
            <span className="digital text-3xl">
              <Countdown
                date={Date.now() + 60000 * `${end_minutes ? end_minutes : 0}`}
              >
                <span className="PublicSans">
                  <Completionist />
                </span>
              </Countdown>
            </span>
          </p>
        )}
        {is_live !== -1 && (
          <p>
            {t("Last Bid")} :
            <span className="text-3xl">
              {" "}
              {max_price ? max_price : 0} {t("AED")}
            </span>
          </p>
        )}
      </div>

      <div className="p-4 flex items-center justify-between flex-wrap  ">
        {live ? (
          <Link
            to={liveLink}
            className={` w-full py-4 border-2 mb-4 flex rounded-lg items-center justify-center gap-3 ${
              is_live === 0
                ? "border-red bg-white text-red-500"
                : "border-blue bg-blue text-white"
            }  `}
          >
            {is_live === 0 || is_live === 1 ? (
              <>
                {t("Live Now")}
                <Live className="w-10" />
              </>
            ) : (
              <>
                {is_live === -1 ? (
                  <> {t("Upcoming")} </>
                ) : (
                  <> {t("Finished")} </>
                )}
              </>
            )}
          </Link>
        ) : (
          <div className="w-full flex items-center justify-start flex-wrap">
            <div className="w-full flex items-center justify-between flex-wrap gap-4 font-bold text-blue">
              {/* <Like />  */}
              <button
                className="w-8 cursor-pointer"
                onClick={toggleFavoriteStatusHandler}
              >
                <LineHeart
                  className={`w-full hover:fill-blue ${
                    itemIsFavorite ? "fill-blue" : "fill-[#d9d9d9]"
                  }`}
                />
              </button>
              <span className="flex items-center justify-end flex-wprap gap-4">
                <img
                  src={eye}
                  alt={"AWA"}
                  width="20"
                  height="20"
                  className="opacity-50"
                />
                {views_count}
              </span>
              {/* {likes_count} */}
              {/* <Comment /> */}
              {/* {comment_count} */}
            </div>
            {/* <div className="w-1/2 flex items-center justify-end flex-wrap gap-4 font-bold text-blue">
                                        <Share /> Share
                                   </div> */}
          </div>
        )}
        <p className="text-[1.6rem] mb-3 w-full lines one">
          {description && description}
        </p>

        {false && userId && (
          <div className="w-full  flex items-center flex-wrap justify-start">
            <div className="user w-2/3 flex items-center gap-2 md:gap-1 xl:gap-2 font-semibold">
              <Link
                to={`/user-page/${userId}`}
                className="flex gap-2 items-center"
              >
                <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                  <img
                    src={userImage}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="name lines one">{name}</div>
                <div className="flex items-center">
                  {badge === "gold" ? <Badge /> : <BadgeGray />}
                  {props.is_verified === 1 && (
                    <div className="">
                      <Verified />
                    </div>
                  )}
                </div>
              </Link>
            </div>

            <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
              {props.rate && (
                <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                  {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                  {props.rate === "1" && "★☆☆☆☆"}
                  {props.rate === "2" && "★★☆☆☆"}
                  {props.rate === "3" && "★★★☆☆"}
                  {props.rate === "4" && "★★★★☆"}
                  {props.rate === "5" && "★★★★★"}
                  {(props.rate === "0" || !props.rate) && "☆☆☆☆☆"}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
