import { t } from 'i18next'
import React, { useState } from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import Ring from '../UI/Loader/Ring'

const Country = (props) => {
  const [isLoading, setIsLoading] = useState('')
  const [isCountry, setIsCountry] = useState('')

  const sendCountry = (e) => {
    setIsLoading(true)
    e.preventDefault()
    setTimeout(() => {
      setIsLoading(false)
      props.onCountryHandler(isCountry)
    }, 500);
  }
  return (
    <>
      <h3 className='w-full font-bold text-[3.4rem]'>{t('Your Country')}</h3>
      <p className='w-full font-light text-[1.5] mb-8'>{t('Lorem ipsum dolor sit amet, consectetur adipiscing')}</p>
      <ul className='w-full flex justify-center items-start flex-wrap cursor-pointer'>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'Egypt' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('Egypt')}>Egypt</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'UAE' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('UAE')}>UAE</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'Iraq' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('Iraq')}>Iraq</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'USA' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('USA')}>USA</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'Canada' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('Canada')}>Canada</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'Brazil' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('Brazil')}>Brazil</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCountry === 'Algerie' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCountry('Algerie')}>Algerie</li>
      </ul>
      <BlueButton onClick={sendCountry} className='w-full text-[1.5rem] py-4 px-5 mt-8 hover:bg-mint'>
        {isLoading ? <Ring /> : `${t('Next')}`}
      </BlueButton>
      <button onClick={props.onSkip} className='bg-dark-gold w-full mt-4 text-[1.5rem] rounded-xl py-4 px-5 font-bold text-gray-300 hover:text-blue text-center'> {t('Skip')}</button>
    </>
  )
}

export default Country