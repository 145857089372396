import React from 'react'

const Notification = () => {
  return (
       <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6654 24H6.33476C5.53143 24 4.79476 23.5953 4.36476 22.9167C3.93476 22.238 3.88143 21.4 4.22343 20.6733L6.0001 17.0987V12.9207C6.0001 7.428 10.1808 2.92933 15.5181 2.678C18.2854 2.55266 20.9048 3.52533 22.9014 5.43C24.9001 7.336 26.0001 9.906 26.0001 12.6667V17.0987L27.7668 20.654C28.1181 21.4 28.0654 22.2387 27.6354 22.9173C27.2054 23.596 26.4688 24 25.6654 24ZM15.5654 3.67733H15.5721H15.5654ZM12.0601 25.3333C12.3788 27.222 14.0214 28.6667 16.0001 28.6667C17.9788 28.6667 19.6208 27.222 19.9401 25.3333H12.0601Z" fill="#FFCB77" />
       </svg>

  )
}

export default Notification