import React from "react";
import { videosData } from "../Components/Helpers/Data";
import Layout from "../Components/Layout";
import VideoCard from "../Components/UI/Cards/Video-card";
import Galleries from "../assets/Icons/Activities/Gallery";
import Videos from "../assets/Icons/Videos";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { config, url } from "../Components/Helpers/Help";
import { Player } from "video-react";
import alert from "../Helpers/Modal";
import { t } from "i18next";
import Delete from "../assets/Icons/Delete";
import DeleteIcon from "../assets/Icons/DeleteIcon";
import Share from "../assets/Icons/Share";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import RedButton from "../Components/UI/Buttons/RedButton";
import ButtonLoading from "../Components/UI/ButtonLoading";
import Remove from "../assets/Icons/Remove";
const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const getData = () => {
    axios(url + "users/gallery?limit=100", config).then((response) => {
      setGallery(response.data.data.data);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const deleteITem = (id) => {
    console.log(id);
    axios
      .post(
        url + "users/gallery/delete",
        //  Data
        {
          id: id,
        },
        // Configrations
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setGallery((gallery) => gallery.filter((item) => item.id !== id));
      })
      // Check Error
      .catch((error) => {
        console.log(error.response.data.message);
        alert({
          title: `${t("Error")}`,
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
        });
      });
  };
  const openShare = () => {
    setIsShare(true);
  };
  const closeShare = () => {
    setIsShare(false);
  };
  const handleDelete = () => {
    setLoadingDelete(true);
    axios.post(url + "clear_my_gallery", {}, config).finally(() => {
      setLoadingDelete(false);
      getData();
    });
  };
  return (
    <Layout>
      <main className="container mx-auto pt-12 md:pt-36 lg:pt-28 px-4 md:px-0">
        <section className="mt-12">
          <div className="flex content-between w-full">
            <h3 className="flex items-center justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem] flex-1">
              <Galleries /> {t("Gallery")}{" "}
            </h3>
            {/* <button
              onClick={handleDelete}
              disabled={loadingDelete || gallery.length === 0}
              className="delete-all text-red bg-white mt-1 rounded shadow-md"
            >
              {loadingDelete ? <ButtonLoading /> : <p>{t("delete all")}</p>}
            </button> */}
            <button
              className="mx-10"
              disabled={loadingDelete || gallery.length === 0}
              onClick={handleDelete}
            >
              {loadingDelete ? (
                <ButtonLoading />
              ) : (
                <div className="flex gap-2 items-center text-red">
                  {t("delete all")}
                  <Remove color="rgb(208 0 0 / var(--tw-text-opacity))" />
                </div>
              )}
            </button>
          </div>
          <div className="flex items-start justify-start flex-wrap">
            {gallery.map((item, index) => (
              <>
                {item.type === "image" && (
                  // <div key={index} alt="" className={`w-1/2 p-2 rounded-3xl h-[35rem] relative overflow-hidden  ${(index === 3 || index === 4 || index === 2 || index === 7 || index === 8 || index === 9) && 'w-1/3 h-[30rem]'}`}>
                  <div
                    key={index}
                    alt=""
                    className={`w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2 rounded-3xl h-[35rem] relative overflow-hidden  `}
                  >
                    <div className="ShareGallery">
                      <button
                        onMouseOver={openShare}
                        className="bg-white  h-12 w-12 flex items-center justify-center rounded-full absolute top-8 right-28 z-[20]"
                      >
                        <Share />
                      </button>
                      <div
                        className={`shareItems absolute top-8 ltr:right-44 rtl:left-44 z-[10] flex gap-2 transition-all duration-1000 overflow-hidden items-center justify-end w-0`}
                      >
                        <FacebookShareButton
                          url={`https://front.awasell.co/gallery/`}
                        >
                          <FacebookIcon className="w-12 h-12 rounded-full " />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={`https://front.awasell.co/gallery/`}
                        >
                          <TwitterIcon className="w-12 h-12 rounded-full " />
                        </TwitterShareButton>
                        <WhatsappShareButton
                          url={`https://front.awasell.co/gallery/`}
                        >
                          <WhatsappIcon className="w-12 h-12 rounded-full " />
                        </WhatsappShareButton>
                      </div>
                    </div>
                    <button
                      onClick={() => deleteITem(item.id)}
                      className="bg-white h-12 w-12 flex items-center justify-center rounded-full absolute top-8 right-8 z-[10] "
                    >
                      <DeleteIcon fill="#bd0101" />
                    </button>

                    <img
                      src={item.file}
                      alt=""
                      className={`p-2 rounded-3xl w-full relative overflow-hidden h-full object-cover`}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </section>
        <section className="mt-8 xl:mt-36">
          <h3 className="flex items-center justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem]">
            {" "}
            <Videos /> videos{" "}
          </h3>
          <div className="flex items-start justify-start flex-wrap">
            {gallery.map((item, index) => (
              <>
                {item.type === "video" && (
                  <div
                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 my-3"
                    key={index}
                  >
                    <div className="dir-rtl mx-2 md:mx-4 relative overflow-hidden rounded-2xl bg-white">
                      <button
                        onClick={() => deleteITem(item.id)}
                        className="bg-white h-12 w-12 flex items-center justify-center rounded-full absolute top-8 right-8 z-[10] "
                      >
                        <DeleteIcon />
                      </button>
                      <Player
                        id={item.id}
                        height={360}
                        width={"100%"}
                        fluid={false}
                        playsInline
                        poster={item.thumbnail}
                        src={item.file}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Gallery;
