import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { config, url } from "../Helpers/Help";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { categoriesSlider } from "../Helpers/SlidersOptions";
import { Link } from "react-router-dom";
import { t } from "i18next";
import alert from "../../Helpers/Modal";

const ProfileUser = (props) => {
  const [start, setStart] = useState();
  const [loading, setLoading] = useState(false);
  const [follow, setFollow] = useState();

  const isLoggedIn =
    localStorage.getItem("__authtk__awasell_secure") &&
    localStorage.getItem("__User_aW__a_Info");

  useEffect(() => {
    axios(url + "start ", config).then((response) => {
      setStart(response.data.data);
      localStorage.setItem(
        "__data_aW__a_start",
        JSON.stringify(response.data.data)
      );
    });
  }, []);

  ;

  const followUser = () => {
    setLoading(true)
    axios.post(url + 'users/follow',
      //  Data
      {
        followed_id: props.id,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        const data = response.data.data.user
        if (data.is_followed) {
          setFollow(1)
        } else {
          setFollow(0)
        }
        console.log(data)
        alert({
          title: `${t('Done')}`,
          text: `${t(`Thank you for send`)}`,

          icon: 'success',
        })
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        setLoading(false)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })

  }

  return (
    <>
      {isLoggedIn && (
        <section className="profileUser w-full md:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto md:mt-24 bg-white rounded-[4rem] shadow-sm mb-16">
          <div className="row flex items-center justify-center flex-wrap">
            <div className="userImage w-3/6 flex items-center justify-start flex-wrap">
              <div className="w-20 md:w-36 h-20 md:h-36 rounded-full relative overflow-hidden shadow-md">
                <img
                  className="h-full w-full object-cover"
                  src={props.personal_picture}
                  alt={props.f_name}
                />
              </div>
              <div className="flex flex-col mx-2 w-2/3">
                <h3 className="font-bold text-3xl"> {props.nickname ? props.nickname : props.f_name + ' ' + props.l_name}
                  <span className="text-[2rem] text-yellow-500 ltr:ml-4 rtl:mr-4">
                    {props.rate === '1' && '★☆☆☆☆'}
                    {props.rate === '2' && '★★☆☆☆'}
                    {props.rate === '3' && '★★★☆☆'}
                    {props.rate === '4' && '★★★★☆'}
                    {props.rate === '5' && '★★★★★'}
                    {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}  </span>
                  {!props.is_rated && <>{(props.isMine) && <button onClick={props.rateMe} className="text-blue" title="rate me" alt='rate me'>✎</button>}</>}
                </h3>
                <div className="w-full">
                  {props.bio && <p className="w-full overflow-hidden lines one">{props.bio} </p>}
                </div>
                {props.isMine &&
                  <button onClick={followUser} className="ltr:text-left rtl:text-right mt-3 text-[1.2rem] font-bold text-mint cursor-pointer">
                    {(follow !== 1 && follow !== 0) && <>  {(props.is_followed) ? 'Unfollow' : 'Follow'}</>}
                    {(follow === 1) && 'Unfollow'}
                    {(follow === 0) && 'Follow'}
                  </button>
                }
              </div>
            </div>
            <div className="userData w-3/6 flex items-center justify-center flex-wrap py-8">
              <div className="text-center px-2 md:px-8">
                <h3 className="font-bold  text-mint">{props.posts}</h3>
                <p className="text-[1.2rem] md:text-[1.4rem]">{t('Posts')}</p>
              </div>
              <Link to={`/followers/${props.id}`} className="text-center border-l border-r px-2 md:px-8">
                <h3 className="font-bold  text-[#FFCB77]">
                  {props.followers}
                </h3>
                <p className="text-[1.2rem] md:text-[1.4rem]">{t('Followers')}</p>
              </Link>
              <Link to={`/following/${props.id}`} className="text-center px-2 md:px-8">
                <h3 className="font-bold  text-[#FE6D73]">
                  {props.following}
                </h3>
                <p className="text-[1.2rem] md:text-[1.4rem]">{t('Following')}</p>
              </Link>
            </div>

          </div>
        </section>
      )}
      {start && start.animal_types && props.myProfile && (
        <Slider {...categoriesSlider}>
          {start.animal_types.map(item => (
            <Link key={item.id}
              to={`${item.target_page === "add_question"
                ? `/profile/question`
                : `${item.target_page === "add_video"
                  ? `/profile/videos`
                  : `${item.target_page === "add_animal"
                    ? `/profile/animals-type/${item.slug}`
                    : `${item.target_page === "add_image"
                      ? `/profile/animals-type/${item.slug}`
                      : ""
                    }`
                  }`
                }`
                }`}

              title={item.title} id={item.id} className="cursor-pointer">
              <img src={item.icon} alt={item.title} className="mx-auto h-[3.6rem]" />
              <h3 className={`text-[1.4rem] text-center text-black font-medium no-wrap`} > {item.title} </h3>
              {item.time && <h4 className="text-red text-center text-[1.3rem]"> {item.time} </h4>}
            </Link>
          ))
          }
        </Slider>
      )
      }
    </>
  );
};

export default ProfileUser;
