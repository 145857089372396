import axios from 'axios';
import { t } from 'i18next'
import React from 'react'
import { useState, useEffect } from 'react';
import { config, url } from '../Components/Helpers/Help';
import Layout from '../Components/Layout'
import { useLocation } from 'react-router-dom';
import UserCard from '../Components/UI/AnimalCard/UserCard';
import SectionTitle from '../Components/UI/Titles/SectionTitle';
import NotItems from '../Components/UI/NotItems';
const Followers = () => {
     const [followers, setFollowers] = useState();
     const params = useLocation();
     const slug = +params.pathname.split("/")[2]

     useEffect(() => {
          axios(url + `users/followers?user_id=${slug}`, config).then((response) => {
               console.log(response.data.data)
               setFollowers(response.data.data)
          });
     }, [slug]); 
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    <div className="row flex items-start justify-center flex-wrap min-h-[40vh]">
                         <SectionTitle className='w-full mt-12 justify-center capitalize flex'>
                              {t('followers')}
                         </SectionTitle>
                              {followers?.length > 0 ?
                                   <>
                                        {followers?.map(item=>(
                                             <div  key={item.id} className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-4 xl:px-6">
                                             <UserCard id={item.id} badge={item.badge} is_verified={item.is_verified} rate={item.avg_rate_int} img={item.personal_picture} name={item.show_name} whatsapp={item.whatsapp} email={item.email}/>
                                             </div>

                                        ))}
                                   </>
                                   :
                                   <NotItems/>
                              }
                    </div>
               </main>
          </Layout>

     )
}


export default Followers