import React from 'react'

const WhatsappFlat = () => {
     return (
          <svg width="26" height="26" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490">
               <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                         <circle fill='#0fb734' className="cls-2" cx="245" cy="245" r="245" />
                         <path fill='#fff' className="cls-1" d="m254,79.5c-86.84,0-157.5,70.66-157.5,157.5,0,27.12,7.01,53.78,20.29,77.31l-20.04,71.49c-.66,2.35-.02,4.87,1.68,6.61,1.31,1.35,3.09,2.08,4.92,2.08.55,0,1.1-.07,1.64-.2l74.61-18.48c22.78,12.23,48.44,18.68,74.39,18.68,86.84,0,157.5-70.66,157.5-157.5s-70.66-157.5-157.5-157.5Zm79.23,213.08c-3.37,9.33-19.53,17.84-27.3,18.98-6.97,1.02-15.79,1.46-25.47-1.58-5.87-1.85-13.4-4.3-23.05-8.42-40.56-17.3-67.05-57.62-69.07-60.29-2.02-2.66-16.51-21.65-16.51-41.29s10.44-29.31,14.15-33.31,8.09-5,10.79-5,5.39.03,7.75.14c2.49.12,5.82-.94,9.1,6.85,3.37,8,11.46,27.64,12.46,29.65,1.01,2,1.68,4.33.34,7-1.34,2.66-2.01,4.33-4.04,6.66s-4.25,5.2-6.07,7c-2.03,1.99-4.13,4.15-1.77,8.15s10.47,17.07,22.49,27.66c15.44,13.6,28.47,17.82,32.51,19.82,4.04,2,6.4,1.66,8.76-1,2.36-2.67,10.11-11.66,12.8-15.65,2.69-3.99,5.39-3.33,9.1-2,3.71,1.33,23.59,10.98,27.63,12.98,4.04,2,6.74,3,7.75,4.66,1.01,1.66,1.01,9.66-2.36,18.98Z" />
                    </g>
               </g>
          </svg>

     )
}

export default WhatsappFlat