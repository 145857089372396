import React from 'react'

const Access = (props) => {
     return (
          <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M10.125 0.421875C4.77562 0.421875 0.421875 4.77562 0.421875 10.125V35.4375C0.421875 40.7869 4.77562 45.1406 10.125 45.1406H18.1406C18.8409 45.1406 19.4062 44.5753 19.4062 43.875C19.4062 43.1747 18.8409 42.6094 18.1406 42.6094H10.125C6.17203 42.6094 2.95312 39.3905 2.95312 35.4375V19.8281H51.0469V22.7812C51.0469 23.4816 51.6122 24.0469 52.3125 24.0469C53.0128 24.0469 53.5781 23.4816 53.5781 22.7812V10.125C53.5781 4.77562 49.2244 0.421875 43.875 0.421875H10.125ZM10.125 2.95312H43.875C47.3977 2.95312 50.33 5.50547 50.929 8.85938H3.07095C3.67002 5.50547 6.60234 2.95312 10.125 2.95312ZM8.01562 34.1719C7.31531 34.1719 6.75 34.7372 6.75 35.4375C6.75 36.1378 7.31531 36.7031 8.01562 36.7031H20.6719C21.3722 36.7031 21.9375 36.1378 21.9375 35.4375C21.9375 34.7372 21.3722 34.1719 20.6719 34.1719H8.01562Z" fill="#0F70B7" />
               <path d="M39.2344 24.8906C31.5562 24.8906 25.3125 31.1344 25.3125 38.8125C25.3125 46.4906 31.5562 52.7344 39.2344 52.7344C46.9125 52.7344 53.1562 46.4906 53.1562 38.8125C53.1562 31.1344 46.9125 24.8906 39.2344 24.8906ZM39.2344 27.4219C45.5161 27.4219 50.625 32.5308 50.625 38.8125C50.625 45.0942 45.5161 50.2031 39.2344 50.2031C32.9527 50.2031 27.8438 45.0942 27.8438 38.8125C27.8438 32.5308 32.9527 27.4219 39.2344 27.4219ZM45.9844 34.1727C45.6606 34.1727 45.3372 34.2967 45.0904 34.5435L38.3906 41.2424L35.4878 38.3404C34.9942 37.8468 34.1933 37.8468 33.6997 38.3404C33.2061 38.834 33.2061 39.6348 33.6997 40.1284L37.4966 43.9253C37.7455 44.1742 38.0658 44.2969 38.3906 44.2969C38.7155 44.2969 39.04 44.1742 39.2846 43.9253L46.8784 36.3315C47.372 35.8379 47.372 35.0371 46.8784 34.5435C46.6316 34.2967 46.3082 34.1727 45.9844 34.1727Z" fill="#17C3B2" />
          </svg>

     )
}

export default Access