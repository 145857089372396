import React, { useState } from 'react'
import Slider from "react-slick";
import { ProductsliderNav, ProductsliderNav_small, ProductSliderOptions } from '../Helpers/SlidersOptions';
import '../../Styles/_Slider.scss'
const ProductSlider = (props) => {
     const [nav1, setNav1] = useState();
     const [nav2, setNav2] = useState();
     return (
          <>
               {
                    window.innerWidth > 767 ?
                         <div className='flex gap-2 mb-24'>
                              <div id="navSlide" className='scroll-none md:h-[50rem] lg:h-[60rem] 2xl:h-[70rem]'>
                                   <Slider {...ProductsliderNav} ref={(slider2) => setNav2(slider2)} asNavFor={nav1}>

                                        {
                                             props.images.map((item, index) => (
                                                  <div key={index} className='rounded-2xl mb-4 h-[10rem] relative overflow-hidden block'>
                                                       <img className='rounded-2xl relative overflow-hidden object-contain' src={item} alt="" />
                                                  </div>
                                             ))
                                        }
                                   </Slider>
                              </div>
                              <div id="mainSlider" className='md:h-[50rem] lg:h-[60rem] 2xl:h-[70rem]'>
                                   <Slider {...ProductSliderOptions} asNavFor={nav2} ref={(slider1) => setNav1(slider1)} >
                                        {
                                             props.images.map((item, index) => (
                                                  <img key={index} className='object-contain rounded-2xl h-[50rem] lg:h-[60rem] 2xl:h-[70rem] object-top' src={item} alt="" />
                                             ))
                                        }
                                   </Slider>
                              </div>
                         </div>
                         :
                         <>

                              <div id="" className='w-full'>
                                   <Slider {...ProductSliderOptions} asNavFor={nav2} ref={(slider1) => setNav1(slider1)} >
                                        {
                                             props.images.map((item, index) => (
                                                  <img key={index} className='object-contain rounded-2xl h-[40rem]' src={item} alt="" />
                                             ))
                                        }
                                   </Slider>
                              </div>
                              <div id="" className='w-full mt-6'>
                                   <Slider {...ProductsliderNav_small} ref={(slider2) => setNav2(slider2)} asNavFor={nav1}>

                                        {
                                             props.images.map((item, index) => (
                                                  <div key={index} className='rounded-2xl w-[6rem] h-[5rem] px-1'>
                                                       <img className='rounded-2xl relative overflow-hidden object-contain w-[6rem]  h-[5rem]' src={item} alt="" />
                                                  </div>
                                             ))
                                        }
                                   </Slider>
                              </div>
                         </>
               }
          </>
     )
}

export default ProductSlider