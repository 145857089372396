import React from 'react'

const Email = () => {
     return (
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect x="0.399902" y="0.5" width="25.6" height="25" rx="12.5" fill="#0FB734" />
               <path d="M7.7399 6.5C6.5153 6.5 5.50892 7.48313 5.40752 8.73438L13.1999 13.1174L20.9923 8.73438C20.8909 7.48313 19.8845 6.5 18.6599 6.5H7.7399ZM5.3999 10.1118V17.0625C5.3999 18.4072 6.449 19.5 7.7399 19.5H18.6599C19.9508 19.5 20.9999 18.4072 20.9999 17.0625V10.1118L13.4772 14.3489C13.3914 14.3976 13.2974 14.4219 13.1999 14.4219C13.1024 14.4219 13.0084 14.3976 12.9226 14.3489L5.3999 10.1118Z" fill="white" />
          </svg>
     )
}

export default Email