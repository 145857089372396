import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import Bids from '../assets/Icons/Activities/Bids'
import { config, url } from '../Components/Helpers/Help'
import Layout from '../Components/Layout'
import ProductCard from '../Components/UI/Cards/Product-card'
import alert from '../Helpers/Modal'
import PreLoader from '../Components/UI/PreLoader'
import NotItems from '../Components/UI/NotItems'
import InfiniteScroll from 'react-infinite-scroll-component';

const MyBids = () => {
     const [bids, setBids] = useState([]);
     const [page, setPage] = useState(1);
     const [hasMore, setHasMore] = useState(true);
     const [isLoading, setIsLoading] = useState(false);

     useEffect(() => {
          setIsLoading(true)
          axios.get(url + `users/my-bids?limit=10&slug=auctions&offset=${page}`, config).then(response => {
               setIsLoading(false)
               const data = response.data.data.data
               console.log(data)
               setBids(data);
          });
     }, []);

     const onLoadMore = () => {
          setIsLoading(true)
          axios.get(url + `users/my-bids?limit=10&slug=auctions&offset=${page + 1}`, config).then(response => {
               setIsLoading(false)
               if (response.data.data.data.length === 0) {
                    setHasMore(false);
               } else {
                    setBids(prevItems => [...prevItems, ...response.data.data.data]);
                    setPage(prevPage => prevPage + 1);

               }
          });
     };

     const modalHandler = (id, qr) => {
          axios.post(url + 'scan-qr',
               //  Data
               {
                    qr: qr,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    bids.map((item) => {
                         if (id === item.id) {
                              item['accepted'] = "0";
                              console.log(item)
                              setBids([...bids, item]);
                         }
                    });
                    alert({
                         title: `${t('Thanks for scan QR number')}`,
                         text: `${t('Done')}`,
                         icon: 'success',
                         timer: 2000
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }




     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
                    <section className="mt-12">
                         <h3 className='flex items-center justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem]'>  <Bids /> {t('My Bids')} </h3>
                         <InfiniteScroll
                              dataLength={bids.length}
                              next={onLoadMore}
                              hasMore={hasMore}
                              endMessage={<p className='mt-12 flex items-center justify-center'> <b> {t('You have seen it all')}</b> </p>}
                              loader={<PreLoader />}
                         >
                              <div className="row flex items-start justify-center flex-wrap">
                                   {bids && bids.length > 0 &&
                                        bids.map((item, index) => (
                                             <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3' key={index}>
                                                  <ProductCard
                                                       id={item.object && item.object.id}
                                                       slug={item.object && item.object.slug}
                                                       key={index}
                                                       img={item.object && item.object.image}
                                                       type={item.object && item.object.type.title}
                                                       myBid={item.object && item.object.price}
                                                       lastBid={item.object && item.object.max_price}
                                                       show_scan={item.object && item.object.show_scan}
                                                       startBid={item.accepted}
                                                       isScanned={item.scanned}
                                                       action={true}
                                                       gender={item.object && item.object.gender}
                                                       age={item.object && item.object.age}
                                                       weight={item.object && item.object.weight}
                                                       isMyBids={true}
                                                       qr={item.qr_number}
                                                       openModal={() => modalHandler(item.id, item.qr_number)}
                                                  />

                                             </div>
                                        ))
                                   }
                              </div>
                         </InfiniteScroll>
                         {
                              !isLoading && bids.length === 0 &&
                              <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                                   <NotItems />
                              </div>
                         }
                    </section>
               </main>
          </Layout>
     )
}

export default MyBids