import React from "react";

const Title = (props) => {
  return (
    <div className={`w-full ${props.className}`}>
      <h3 className="font-bold text-center text-blue text-[2rem] mt-6">
        {props.children}
      </h3>
    </div>
  );
};

export default Title;
