import { t } from 'i18next'
import React, { useState } from 'react'
import ButtonLoading from '../UI/ButtonLoading';
import BlueButton from '../UI/Buttons/BlueButton';
import Input from '../UI/Input'
import alert from "../../Helpers/Modal";
import axios from 'axios';
import { config, url } from '../Helpers/Help';

const AddQuestion = () => {
     const [Question, setQuestion] = useState("");
     const [Hashtag, setHashtag] = useState("");
     const [loading, setLoading] = useState(false);
     const [questionError, setQuestionError] = useState(false);

     //  Submit auction form 
     const submitHandler = () => {
          if (!Question) {
               setQuestionError(true)
               return
          }
          setLoading(true)
          axios.post(url + 'questions',
               //  Data
               {
                    content: Question,
                    hashtags: Hashtag,

               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setQuestion('')
                    setHashtag('')
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,
                        
                         icon: 'success',
                    })
                    setLoading(false)

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    setLoading(false)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }

     return (
          <>

               <div className="row flex items-start justify-center flex-wrap p-4 w-full md:w-10/12 xl:w-8/12 mx-auto bg-white rounded-xl shadow-lg mt-8">
                    <div className="w-full">
                         <h3 className='font-bold text-center text-blue text-[2rem] mt-6'>Add Question</h3>
                    </div>
                    <div className="w-full md:w-1/2 p-2 my-2">
                         <Input onFocus={() => setQuestionError(false)} input_className={`border py-0 h-[4rem] ${questionError ? 'border-red' : 'border-blue'}`} div_className="w-full " value={Question} onChange={(e) => setQuestion(e.target.value)} type="text" placeholder={t("Question")} id="Question" label={t("Question")} />
                    </div>

                    <div className="w-full md:w-1/2 p-2 my-2">
                         <Input input_className="border border-blue" div_className="w-full" value={Hashtag} onChange={(e) => setHashtag(e.target.value)} type="text" placeholder={t("write here")} id="Hashtag" label={t("Hashtag")} />
                    </div>

                    <div className="w-full flex items-center justify-center">
                         <BlueButton disabled={loading} className={`text-[1.5rem] p-5 px-8 mt-8 hover:bg-mint ${loading && 'cursor-not-allowed'}`} onClick={submitHandler}>
                              {loading ?
                                   <ButtonLoading />
                                   :
                                   `${t("Send")}`
                              }
                         </BlueButton>
                    </div>
                    <div className="my-8 w-full flex items-end justify-center">
                         <p className='font-bold text-red capitalize'>
                              {questionError && 'Please enter your Question'}
                         </p>
                    </div>
               </div>
          </>
     )
}

export default AddQuestion