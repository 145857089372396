
import React, { useState } from 'react'
import Layout from '../../Components/Layout'
import { othersDataPage, othersDataPageTwo } from '../../Components/Helpers/Data'
import ProductCard from '../../Components/UI/Cards/Product-card'
import Services from '../../assets/Icons/UserIcons/White/Services'
import Accessories from '../../assets/Icons/Accessories'
import ProfileUser from '../../Components/Seactions/ProfileUser'
const UserOther = () => {
  const [activeTap, setActiveTap] = useState('Accessories')

  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        <ProfileUser />
        <section className="mt-12">
          <div className="row items-center justify-center flex flex-wrap">
            <div onClick={() => setActiveTap('Accessories')} className={`h-28 cursor-pointer w-auto p-4 px-6 mx-2 rounded-xl flex items-center gap-4 ${activeTap === 'Accessories' ? 'bg-blue text-white' : 'bg-white'}`}>
              {activeTap === 'Accessories' ? <Accessories blue='white' mint='white' /> : <Accessories blue='#0F70B7' mint='#17C3B2' />}
              <h3>Accessories </h3>
            </div>
            <div onClick={() => setActiveTap('Services')} className={`h-28 cursor-pointer w-auto p-4 px-6 mx-2 rounded-xl flex items-center gap-4 ${activeTap === 'Services' ? 'bg-blue text-white' : 'bg-white'}`}>
              {activeTap === 'Services' ? <Services fill='white' /> : <Services fill='#0F70B7' />}
              <h3>Services </h3>
            </div>
          </div>
        </section>

        {activeTap === 'Accessories' ?
          <section className="mt-12">
            <div className="row flex items-start justify-center flex-wrap">
              {
                othersDataPage.map((item, index) => (
                  <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 my-3' key={index}>
                    <ProductCard
                      key={index}
                      img={item.img}
                      name={item.name}
                      type={item.type}
                      badg={item.badg}
                      tags={item.tags}
                      title={item.title}
                      start={item.start}
                      price={item.price}
                      email={item.email}
                      isFav={item.isFav}
                      phone={item.phone}
                      offer={true}
                      gender={item.gender}
                      action={true}
                      location={item.location}
                      duration={item.duration}
                      oldPrice={item.oldPrice}
                      inMarket={item.inMarket}
                      whatsapp={item.whatsapp}
                      userImage={item.userImage}
                      description={item.description}
                      averagePrice={item.averagePrice}
                      locationAnimal={item.locationAnimal}
                    />

                  </div>
                ))
              }
            </div>
          </section>
          :
          <section className="mt-12">
            <div className="row flex items-start justify-center flex-wrap">
              {
                othersDataPageTwo.map((item, index) => (
                  <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 my-3' key={index}>
                    <ProductCard
                      key={index}
                      img={item.img}
                      name={item.name}
                      type={item.type}
                      badg={item.badg}
                      tags={item.tags}
                      title={item.title}
                      start={item.start}
                      price={item.price}
                      email={item.email}
                      isFav={item.isFav}
                      phone={item.phone}
                      offer={true}
                      gender={item.gender}
                      action={true}
                      location={item.location}
                      duration={item.duration}
                      oldPrice={item.oldPrice}
                      inMarket={item.inMarket}
                      whatsapp={item.whatsapp}
                      userImage={item.userImage}
                      description={item.description}
                      averagePrice={item.averagePrice}
                      locationAnimal={item.locationAnimal}
                    />

                  </div>
                ))
              }
            </div>
          </section>
        }
      </main>
    </Layout>
  )
}



export default UserOther
