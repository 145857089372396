import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import Male from '../assets/Icons/Male'
import Female from '../assets/Icons/Female'
import ProductSlider from '../Components/ProductDetails/ProductSlider';
import ProductMap from '../Components/ProductDetails/ProductMap'
import Seller from '../Components/ProductDetails/Seller'
import Description from '../Components/ProductDetails/Description';
import ProductTitle from '../Components/ProductDetails/ProductTitle';
import ConditionsProduct from '../Components/ProductDetails/ConditionsProduct';
import axios from 'axios';
import { config, url } from '../Components/Helpers/Help';
import { useNavigate, useParams } from 'react-router-dom';
import { Player } from 'video-react';
import { t } from 'i18next';
import alert from '../Helpers/Modal';
import BlueButton from '../Components/UI/Buttons/BlueButton';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { productsSlider } from '../Components/Helpers/SlidersOptions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Pusher from 'pusher-js';

// function randomID(len) {
//      let result = '';
//      if (result) return result;
//      var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
//           maxPos = chars.length,
//           i;
//      len = len || 5;
//      for (i = 0; i < len; i++) {
//           result += chars.charAt(Math.floor(Math.random() * maxPos));
//      }
//      return result;
// }

// export function getUrlParams(
//      url = window.location.href
// ) {
//      let urlStr = url.split('?')[1];
//      return new URLSearchParams(urlStr);
// }



const LiveStreaming = () => {
     const [isDetails, setIsDetails] = useState();
     const param = useParams()
     const id = param.slug
     const type = param.type
     const [price, setPrice] = useState();
     const [binding, setIsBinding] = useState(false);
     const [details, setDetails] = useState(false);
     const [todayMarketDetails, setTodayMarketDetails] = useState(false);
     const [market, setMarket] = useState(true);
     const [lastPrice, setLastPrice] = useState();
 
     useEffect(() => {
          axios(url + `animals/details/awa-lives/${id}`, config).then((response) => {
               setIsDetails(response.data.data);
               // console.log(response.data.data)
          });
          window.scrollTo(0, 0)
     }, [id]);
 

     const bindingNowHandler = () => {
          if (localStorage.__User_aW__a_Info === null || !localStorage.__User_aW__a_Info || !localStorage.__authtk__awasell_secure) {
               alert({
                    title: `${t(`Please Login `)}`,
                    text: `${t('Alert')}`,
                    icon: 'info',
               })
               return
          }
          setIsBinding(true)
          if (!binding) {
               return
          }
          if (!price) {
               alert({
                    title: `${t('Alert')}`,
                    text: `${t(`Select your price`)}`,
                    icon: 'error',
               })
               return
          }
          axios.post(url + 'animals/bb/add-live-bid',
               //  Data
               {
                    animal_id: id,
                    price: price,
                    slug: type,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    // setIsBinding(false)
                    setPrice('')
                    alert({
                         title: `${t('Done')}`,
                         text: `${t(`Thank you for send`)}`,
                         icon: 'success',
                    })
               })
               // Check Error
               .catch(error => {
                    console.log(error)
                    alert({
                         title: error?.response?.data?.message,
                         text: `${t('Error')}`,
                         icon: 'info',
                    })
               })

     } 

     useEffect(() => {
          const pusher = new Pusher('4cd59efd7f1d45a34882', {
               cluster: 'ap2',
               encrypted: true,
          });

          // Subscribe to the private channel for the current user
          const channel = pusher.subscribe(`awa-lives-${id}`);

          // Bind to the event for new notifications
          channel.bind('live-bid-added', (data) => {
               setLastPrice(data?.data.last_price);
               console.log(data?.data.last_price)
               // Handle the new notification here, e.g., show a toast, update notification count, etc.
          });

          // Clean up the Pusher instance when the component unmounts
          return () => {
               pusher.disconnect();
          };
     }, []);


     return (
          <Layout>
               <main className="container mx-auto pt-24 md:pt-36 lg:pt-28 px-4 md:px-0 mt-6">
                    <div className="row flex items-start justify-center flex-wrap min-h-screen">
                         <div className="w-full md:w-7/12 xl:w-8/12">
                              {isDetails &&
                                   <div className="bg-white shadow-xl rounded-3xl px-4 xl:px-6 pt-2">
                                        {(isDetails && (isDetails.weight || isDetails.gender || isDetails.age)) &&
                                             <ProductTitle gender={isDetails.gender} age={isDetails.age} weight={isDetails.weight} title={isDetails.title} />
                                        }
                                        {/* <div id="facebook-live-embed" className=' mb-24'>
                                             <iframe title='myVideo' src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100063949577161%2Fvideos%2F211320988570155%2F&show_text=false&width=560&t=0" width="100%" height="514" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                        </div> */}

                                        {isDetails?.is_started === 0 ?
                                             <iframe id='myVideo' width="100%" height="650" src={`${isDetails?.live_link}?autoplay=1&mute=1&cc_load_policy=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                             :
                                             <div className='h-[20rem] md:h-[60rem] mb-12 rounded-[2rem] bg-gray-100 w-full flex items-center justify-center'>
                                                  <h2 className='text-[1.5rem] md:text-[3rem] font-bold'>{t('No live Now')}</h2>
                                             </div>
                                        }
                                        {(isDetails && isDetails.video && isDetails.video.length > 0) &&
                                             <div className="w-full mx-auto pb-12">
                                                  <h3 className='text-[1.8rem] font-bold my-2 flex items-center mt-8'>{t(' ')} :</h3>
                                                  <Slider {...productsSlider}>
                                                       {isDetails.video.map((item, index) => (
                                                            <Player
                                                                 key={index}
                                                                 height={260}
                                                                 width={'100%'}
                                                                 fluid={false}
                                                                 playsInline
                                                                 src={item}
                                                            />
                                                       ))
                                                       }
                                                  </Slider>

                                             </div>
                                        }

                                        <div className="row flex items-start justify-start flex-wrap">
                                             {/* {isDetails && isDetails.slug !== 'auctions' && isDetails.user &&
                                                  <div className="w-full lg:w-1/2">
                                                       <Seller
                                                            name={isDetails.user.f_name + ' ' + isDetails.user.l_name}
                                                            email={isDetails.user.email}
                                                            phone={isDetails.user.phone}
                                                            badge={isDetails.user && isDetails.user.badge}
                                                            avg_rate={isDetails.user && isDetails.user.avg_rate_int}
                                                            userImage={isDetails.user.personal_picture}
                                                            id={isDetails.user.id}
                                                            location={isDetails.user.city && isDetails.user.city.name}
                                                       />
                                                  </div>} */}
                                             <div className="w-full">
                                                  {isDetails && isDetails.lng && <ProductMap lng={+isDetails.lng} lat={+isDetails.lat} />}
                                             </div>
                                        </div>
                                        {isDetails &&
                                             <Description
                                                  slug={isDetails.slug}
                                                  passport_number={isDetails.passport_number}
                                                  note={isDetails.note}
                                                  color={isDetails.color}
                                                  address={isDetails.address}
                                                  name={isDetails.name}
                                                  date_of_birth={isDetails.date_of_birth}
                                                  lost_date={isDetails.lost_date}
                                                  lost_time={isDetails.lost_time}
                                                  post_on={isDetails.post_on}
                                                  post_date={isDetails.post_date}
                                                  open_at={isDetails.open_at}
                                                  close_at={isDetails.close_at}
                                                  type={isDetails.category && isDetails.category.title}
                                                  number={isDetails.number}
                                                  size={isDetails.size}
                                                  species={isDetails.species}
                                                  video={isDetails.video}
                                                  phone={isDetails.phone}
                                                  email={isDetails.email}
                                                  id_front={isDetails.id_front}
                                                  id_back={isDetails.id_back}
                                                  car_registration_font={isDetails.car_registration_font}
                                                  car_registration_back={isDetails.car_registration_back}
                                                  driving_license_front={isDetails.driving_license_front}
                                                  driving_license_back={isDetails.driving_license_back}
                                                  sub_category={isDetails.category && isDetails.sub_category.title}
                                                  certificates={isDetails.certificates}
                                             />
                                        }
                                   </div>
                              }

                         </div>
                         {isDetails &&
                              <div className="w-full md:w-5/12 xl:w-4/12 px-0 md:px-4 xl:px-8 mt-8 md:mt-0">
                                   <div className="bg-white shadow-xl rounded-3xl p-4 xl:px-6">
                                        {binding && <div className="card">
                                             <div className={`price flex items-center justify-start flex-wrap gap-4 transition-all overflow-hidden ${binding ? 'h-auto' : 'h-0'}`}>
                                                  <h3 className='w-full border-b mb-6 pb-6 text-center'>{t('Min offer')} = {(lastPrice ? 100 + +lastPrice : 100 + +isDetails.max_price)} {t('AED')}</h3>
                                                  <span onClick={() => setPrice(lastPrice ? 100 + +lastPrice : 100 + +isDetails.max_price)} className={`${(lastPrice ? 100 + +lastPrice === price : 100 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 100 + +lastPrice : 100 + +isDetails.max_price}</span>
                                                  <span onClick={() => setPrice(lastPrice ? 200 + +lastPrice : 200 + +isDetails.max_price)} className={`${(lastPrice ? 200 + +lastPrice === price : 200 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 200 + +lastPrice : 200 + +isDetails.max_price}</span>
                                                  <span onClick={() => setPrice(lastPrice ? 300 + +lastPrice : 300 + +isDetails.max_price)} className={`${(lastPrice ? 300 + +lastPrice === price : 300 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 300 + +lastPrice : 300 + +isDetails.max_price}</span>
                                                  <span onClick={() => setPrice(lastPrice ? 400 + +lastPrice : 400 + +isDetails.max_price)} className={`${(lastPrice ? 400 + +lastPrice === price : 400 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 400 + +lastPrice : 400 + +isDetails.max_price}</span>
                                                  <span onClick={() => setPrice(lastPrice ? 500 + +lastPrice : 500 + +isDetails.max_price)} className={`${(lastPrice ? 500 + +lastPrice === price : 500 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 500 + +lastPrice : 500 + +isDetails.max_price}</span>
                                                  <span onClick={() => setPrice(lastPrice ? 600 + +lastPrice : 600 + +isDetails.max_price)} className={`${(lastPrice ? 600 + +lastPrice === price : 600 + +isDetails.max_price === price) && 'bg-blue text-white'} py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg`}>{lastPrice ? 600 + +lastPrice : 600 + +isDetails.max_price}</span>
                                                  <div className="w-full border border-blue py-4 px-2 rounded-lg">
                                                       <input type='number' d onChange={(e) => setPrice(e.target.value)} value={price || ''} placeholder='Enter your bid' />
                                                  </div>
                                             </div>
                                        </div>}
                                        {details &&
                                             <div className={`price flex items-center justify-start flex-wrap gap-4 transition-all overflow-hidden ${details ? 'h-auto' : 'h-0'}`}>
                                                  {
                                                       (isDetails.offered_prices && isDetails.offered_prices.length > 0) ?
                                                            isDetails.offered_prices.map(item => (
                                                                 <div key={item.id} onClick={() => setPrice('100')} className='flex items-center justify-between flex-wrap py-3 px-6 bg-white border-blue border cursor-pointer rounded-lg w-full'>
                                                                      <span>{item.user && item.user.f_name}  </span>
                                                                      <span>{item.price} {t('AED')}</span>
                                                                 </div>
                                                            ))
                                                            :
                                                            <p className='p-4 border w-full rounded-xl text-center'>{t(` `)}</p>
                                                  }
                                             </div>
                                        } 
                                        <ConditionsProduct
                                             isLive={true}
                                             isMine={isDetails.is_mine}
                                             in_market={isDetails.in_market}
                                             max_price={isDetails.max_price}
                                             is_started={isDetails.is_started}
                                             market={market}
                                             onShowDetails={() => setDetails(!details)}
                                             // onMarket={marketHandler}
                                             onBindingNow={bindingNowHandler}
                                             // onMakeOfferPrice={makeOfferPriceHandler}
                                             // onGoToAuction={goToAuctionHandler}
                                             onMarketDetails={() => setTodayMarketDetails(!todayMarketDetails)}
                                             is_mine={isDetails.is_mine}
                                             slug={'live'}
                                             todayMarketDetails={todayMarketDetails}
                                             type={isDetails.type && isDetails.type.title}
                                             id={isDetails.id} />
                                   </div>
                              </div>
                         }
                    </div>
               </main>
          </Layout >
     )
}

export default LiveStreaming