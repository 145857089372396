import React from 'react'
import Jiuist from '../../../assets/Icons/Jiuist'
import BlueButton from '../Buttons/BlueButton'
import Report from '../../../assets/Icons/Report'
import Remove from '../../../assets/Icons/Remove'
import TurnOff from '../../../assets/Icons/TurnOff'
 
const InboxCard = (props) => {
     const { img, title, user, price } = props
     return (
          <li className='w-full flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative overflow-hidden flex items-start justify-start gap-4'>
               <div className="img w-48 h-48 relative overflow-hidden rounded-2xl">
                    <img className='h-full w-full object-cover' src={img} alt="" />
               </div>
               <div className="data">
                    <h3 className='font-semibold text-[1.7rem]'>{title}</h3>
                    <p className='my-2'> {user}<span className="text-mint"> Give your price</span></p>
                    <h3 className="text-blue font-bold">{price}</h3>
                    <div className="flex mt-2 gap-4">
                         <BlueButton className='py-2 px-8 hover:bg-mint'>Accept</BlueButton>
                         <BlueButton className='py-2 px-8 bg-transparent border-2 border-blue text-blue hover:bg-blue hover:text-white'>Accept</BlueButton>
                    </div>
               </div>
               <div className="icon absolute h-full right-4 top-4 flex flex-col gap-4 bg-white">
                    <button className='mb-3'>
                         <Remove />
                    </button>
                    <button className='mb-3'>
                         <TurnOff />
                    </button>
                    <button className='mb-3'>
                         <Report fill='red' />
                    </button>
               </div>
          </li>
     )
}

export default InboxCard