import React from 'react'

const Bill = () => {
     return (
          <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M2.32284 13.752L2.37509 13.6469V13.5295V9.61259C2.37509 4.7182 6.09644 0.732294 10.8213 0.509556C13.2806 0.398287 15.6028 1.26139 17.375 2.95188L17.375 2.95193C19.1498 4.64443 20.1251 6.92304 20.1251 9.37446V13.5295V13.6468L20.1773 13.752L21.8311 17.0802C22.0862 17.6257 22.0453 18.2285 21.7359 18.7169C21.4247 19.2079 20.8937 19.4995 20.3113 19.4995H2.18884C1.60674 19.4995 1.07562 19.2075 0.764326 18.7162C0.453377 18.2255 0.414516 17.6231 0.659741 17.0982L2.32284 13.752ZM11.2501 23.8745C9.85487 23.8745 8.66327 22.9888 8.20492 21.7495H14.2951C13.8364 22.989 12.6452 23.8745 11.2501 23.8745Z" stroke="white" />
          </svg>

     )
}

export default Bill