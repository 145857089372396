import { Link } from 'react-router-dom'
import Facebook from '../../assets/Icons/Facebook'
import Twitter from '../../assets/Icons/Twitter'
import Instagram from '../../assets/Icons/Instagram'
import Linkedin from '../../assets/Icons/Linkedin'
import TopFooter from '../Seactions/TopFooter'
import Copyright from '../Seactions/Copyright'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { config, url } from '../Helpers/Help'
const Footer = () => {


     const [links, setLinks] = useState([]);
     const [quickLinks, setQuickLinks] = useState();
     useEffect(() => {
          axios(url + "footer ", config).then((response) => {
               const res = response.data.data.animal_types
               setQuickLinks(res)

          });
     }, []);
     useEffect(() => {
          if (localStorage.websiteContent) {
               const content = localStorage.getItem('websiteContent')
               setLinks(JSON.parse(content))
          }
     }, [])
     
     return (
          <footer>
               <TopFooter />
               <div className="container mx-auto">
                    <div className="flex items-start justify-center xl:justify-between flex-wrap py-16">
                         <div className='w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'>
                              <h3 className="block text-center ltr:md:text-left text-[1.8rem] font-bold">{t('Company')}</h3>
                              <Link to='/about-us' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "> {t('About us')}</Link>
                              <Link to='/contact-us' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "> {t('Contact Us')}</Link>
                              <Link to='/term-condition' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "> {t('Term & condition')} </Link>
                              <Link to='/privacy-policy' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "> {t('Privacy Policy')} </Link>
                              <Link to='/refund-policy' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium "> {t('Refund Policy')} </Link>
                         </div>

                         <div className='w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'>
                              <h3 className="block text-center ltr:md:text-left text-[1.8rem] font-bold">{t('Pages')}</h3>
                              {
                                   quickLinks && quickLinks.length > 0 &&
                                   quickLinks.slice(0, 5).map(item => (
                                        <Link key={item.id} to={`${item.target_page === "add_question"
                                             ? `/public/question`
                                             : `${item.target_page === "add_video"
                                                  ? `/public/videos`
                                                  : `${item.target_page === "add_animal"
                                                       ? `/public/animals-type/${item.slug}`
                                                       : `${item.target_page === "add_image"
                                                            ? `/public/animals-type/${item.slug}`
                                                            : ""
                                                       }`
                                                  }`
                                             }`
                                             }`} className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{item.title}</Link>
                                   ))
                              }
                         </div>

                         <div className='w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'>
                              <h3 className="block text-center ltr:md:text-left text-[1.8rem] font-bold opacity-0"> .</h3>
                              {
                                   quickLinks && quickLinks.length > 4 &&
                                   quickLinks.slice(5, 10).map(item => (
                                        <Link key={item.id} to={`${item.target_page === "add_question"
                                             ? `/public/question`
                                             : `${item.target_page === "add_video"
                                                  ? `/public/videos`
                                                  : `${item.target_page === "add_animal"
                                                       ? `/public/animals-type/${item.slug}`
                                                       : `${item.target_page === "add_image"
                                                            ? `/public/animals-type/${item.slug}`
                                                            : ""
                                                       }`
                                                  }`
                                             }`
                                             }`}
                                             className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{item.title}</Link>
                                   ))
                              }
                         </div>

                         <div className='w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'>
                              <h3 className="block text-center ltr:md:text-left text-[1.8rem] font-bold opacity-0">.</h3>
                              {
                                   quickLinks && quickLinks.length > 8 &&
                                   quickLinks.slice(10, 15).map(item => (
                                        <Link key={item.id} to={`${item.target_page === "add_question"
                                             ? `/public/question`
                                             : `${item.target_page === "add_video"
                                                  ? `/public/videos`
                                                  : `${item.target_page === "add_animal"
                                                       ? `/public/animals-type/${item.slug}`
                                                       : `${item.target_page === "add_image"
                                                            ? `/public/animals-type/${item.slug}`
                                                            : ""
                                                       }`
                                                  }`
                                             }`
                                             }`} className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{item.title}</Link>
                                   ))
                              }
                              {/* <Link to='/public/animals-type/deliveries' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Delivery')}</Link>
                              <Link to='/public/animals-type/looking-for' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Look for')}</Link>
                              <Link to='/public/question' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Question')}</Link>
                              <Link to='/public/animals-type/others' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Other')}</Link> */}
                         </div>

                         <div className='w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mt-12 xl:mt-0'>
                              <h3 className="block text-center ltr:md:text-left text-[1.8rem] font-bold">{t('Contact Us')}</h3>
                              {links && <a href={`tel:` + links.phone} className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Phone')}: {links.phone}</a>}
                              {links && <a href={`mailto:` + links.email} className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Email')}: {links.email}</a>}
                              {links && <a href='https://www.google.com/maps/place/Brain+Click+Ads/@25.1859067,55.2580765,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f69d6bebf924f:0xc9891260a62ede50!8m2!3d25.1859019!4d55.2602652' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Location')}: {links.address}</a>}
                              <Link to='/' className="block text-center ltr:md:text-left mt-5 text-[1.6rem] font-medium ">{t('Social media')}:</Link>
                              {
                                   links &&
                                   <ul className='w-full flex items-center justify-center flex-wrap mt-4 gap-4'>
                                        <a href={links.twitter}>
                                             <Twitter />
                                        </a>
                                        <a href={links.facebook}>
                                             <Facebook />
                                        </a>
                                        <a href={links.instagram}>
                                             <Instagram />
                                        </a>
                                        <a href={links.linkedin}>
                                             <Linkedin />
                                        </a>
                                   </ul>
                              }
                         </div>
                    </div>
               </div>
               <Copyright />
          </footer>
     )
}

export default Footer