import React from 'react'

const Vip = () => {
     return (
          <div className='bg-[#FBE200] text-xl text-center pt-2 w-[3rem] h-[3rem] font-bold rounded-b-full'>
               VIP
          </div>

     )
}

export default Vip