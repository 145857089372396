import React from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import Male from '../../../assets/Icons/Male'
import Phone from '../../../assets/Icons/Phone'
import Whatsapp from '../../../assets/Icons/Whatsapp'
import BlueButton from '../Buttons/BlueButton'
import RedButton from '../Buttons/RedButton'
import Lottie from 'react-lottie';
import InMarket from '../../Lottie/InMarket.json'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Verified from '../../../assets/Icons/Verified'

const Others = (props) => {
     const { img, userImage, id, userId, name, veterinary, NO_email_Phone, badge, age, weight, location, type, typeCard, myBid, myPrice, lastBid, price, gender, tags, isFav, inMarket, offer, oldPrice, action, phone, email, contact, question, averagePrice, title, locationAnimal, duration, whatsapp, photographer } = props
     // const ctx = useContext(Context);
     // const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     // function toggleFavoriteStatusHandler() {
     //      console.log(id, slug)
     //      if (itemIsFavorite) {
     //           ctx.removeFavorite(id, slug);
     //      } else {
     //           ctx.addFavorite(id, slug)
     //      }
     // }
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${photographer ? 'flex-col-reverse pb-0' : 'flex-col'}`}>
                    {img &&
                         <Link to={`/details/others/${id}`} className="w-full">
                              <img src={img} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                         </Link>
                    }
                    <div className="w-full min-h-[10rem] max-h-[27rem]">
                         <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap">
                              {
                                   title && !veterinary && <h3 className='font-semibold '>{title}</h3>
                              }
                              {(userImage || name || badge) &&
                                   <>
                                        <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                                             {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                                  <img src={userImage} alt="" className='h-full w-full object-cover' />
                                             </div>
                                             }
                                             {name && <div className="name">
                                                  {name}
                                             </div>
                                             }
                                             <div className="flex items-center">
                                                  {badge === 'gold' ?
                                                       <Badge />
                                                       :
                                                       <BadgeGray />
                                                  }
                                                  {props.is_verified === 1 && <div className=""><Verified /></div>}
                                             </div>
                                        </Link>

                                        <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
                                             {props.rate &&
                                                  <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                                                       {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                       {props.rate === '1' && '★☆☆☆☆'}
                                                       {props.rate === '2' && '★★☆☆☆'}
                                                       {props.rate === '3' && '★★★☆☆'}
                                                       {props.rate === '4' && '★★★★☆'}
                                                       {props.rate === '5' && '★★★★★'}
                                                       {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                                  </span>}
                                        </div>
                                   </>
                              }

                         </div>
                         <ul className='px-2 md:px-4'>
                              {type && <li className='mt-2 no-wrap'>{t('Type')}: <b>{type}</b> </li>}
                              <li className='mt-2 no-wrap'>{t('Price')}: <b>{price ? `${price}  ${t('AED')}` : 'Free'}  </b> </li>
                              {whatsapp ?
                                   <a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} className='mt-2 w-full no-wrap flex flex-wrap items-center justify-start gap-2 text-xl md:text-[1.6rem]'>
                                        {window.innerWidth > 767 && `${t('Contact')}:`}
                                        {window.innerWidth > 767 && <Whatsapp />}
                                        <b className='flex flex-wrap items-center justify-start gap-2'> {whatsapp}</b>
                                   </a>
                                   :
                                   <b className='flex flex-wrap items-center justify-start gap-2'> {t('no contact')}</b>
                              }
                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default Others