import React from 'react'

const PasswordIcon = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M10.021 0.833333C9.85984 0.828304 9.70297 0.885744 9.58317 0.993652C9.58317 0.993652 6.45489 3.75 2.70898 3.75C2.54323 3.75002 2.38427 3.81587 2.26706 3.93308C2.14985 4.05028 2.084 4.20924 2.08398 4.375V9.48324C2.08398 12.2658 3.37124 16.8443 9.75814 19.5345C9.83491 19.5668 9.91736 19.5835 10.0007 19.5835C10.0839 19.5835 10.1664 19.5668 10.2432 19.5345C16.6301 16.8443 17.9173 12.2658 17.9173 9.48324V4.375C17.9173 4.20924 17.8514 4.05028 17.7342 3.93308C17.617 3.81587 17.4581 3.75002 17.2923 3.75C13.5464 3.75 10.4181 0.993652 10.4181 0.993652C10.3088 0.895114 10.1681 0.83834 10.021 0.833333V0.833333ZM10.0007 5C11.3798 5 12.5007 6.12083 12.5007 7.5V8.33333C13.1882 8.33333 13.7507 8.89583 13.7507 9.58333V12.9167C13.7507 13.6042 13.1882 14.1667 12.5007 14.1667H7.50065C6.81315 14.1667 6.25065 13.6042 6.25065 12.9167V9.58333C6.25065 8.89583 6.81315 8.33333 7.50065 8.33333V7.5C7.50065 6.12083 8.62148 5 10.0007 5ZM10.0007 6.25C9.31315 6.25 8.75065 6.8125 8.75065 7.5V8.33333H11.2507V7.5C11.2507 6.8125 10.6882 6.25 10.0007 6.25ZM10.0007 10C9.66913 10 9.35119 10.1317 9.11677 10.3661C8.88235 10.6005 8.75065 10.9185 8.75065 11.25C8.75065 11.5815 8.88235 11.8995 9.11677 12.1339C9.35119 12.3683 9.66913 12.5 10.0007 12.5C10.3322 12.5 10.6501 12.3683 10.8845 12.1339C11.119 11.8995 11.2507 11.5815 11.2507 11.25C11.2507 10.9185 11.119 10.6005 10.8845 10.3661C10.6501 10.1317 10.3322 10 10.0007 10Z" fill="#0F70B7" />
          </svg>

     )
}

export default PasswordIcon