import { t } from 'i18next'
import React from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { config, url } from '../Helpers/Help';
import alert from '../../Helpers/Modal';
import NotItems from '../UI/NotItems';
import PreLoader from '../UI/PreLoader';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

const DashboardMarket = () => {
     const [market, setMarket] = useState([]);
     const [page, setPage] = useState(1);
     const [hasMore, setHasMore] = useState(true);
     const [isLoading, setIsLoading] = useState(false);

     useEffect(() => {
          setIsLoading(true)
          axios.get(url + `users/my-offer-prices?limit=10&offset=${page}&slug=markets`, config).then(response => {
               setIsLoading(false)
               const data = response.data.data.data
               setMarket(data);
          });
     }, []);

     const onLoadMore = () => {
          setIsLoading(true)
          axios.get(url + `users/my-offer-prices?limit=10&offset=${page + 1}&slug=markets`, config).then(response => {
               setIsLoading(false)
               if (response.data.data.data.length === 0) {
                    setHasMore(false);
               } else {
                    setMarket(prevItems => [...prevItems, ...response.data.data.data]);
                    setPage(prevPage => prevPage + 1);

               }
          });
     };

     const acceptHandler = (id, status) => {
          axios.post(url + 'users/my-offer-prices/status',
               //  Data
               {
                    id: id,
                    status: status
               },
               // Configrations 
               config)
               // Response
               .then(response => {
                    market.map((item) => {
                         if (id === item.id) {
                              console.log(item)
                              if (status === '1') {
                                   item['accepted'] = "-1";
                                   console.log(id, item.id)
                                   setMarket([...market, item]);
                              }
                         }

                    });
                    alert({
                         title: `${t('Thanks You')}`,
                         text: `${t('Done')}`,
                         icon: 'success',
                         timer: 2000
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })

     }


     return (
          <>
               {window.innerWidth > 767 &&
                    <div className="TableHead text-center row flex items-center justify-center flex-wrap w-full bg-white p-4 rounded-lg shadow-sm">
                         <div className="photo capitalize md:w-2/12">
                              {t('photo')}
                         </div>
                         <div className="type capitalize md:w-2/12">
                              {t('type')}
                         </div>
                         <div className="date-time capitalize md:w-2/12">
                              {t('date & time')}
                         </div>
                         <div className="name capitalize md:w-2/12">
                              {t('name')}
                         </div>
                         <div className="price capitalize md:w-2/12">
                              {t('price')}
                         </div>
                         <div className="auction capitalize md:w-2/12">
                              {t('auction')}
                         </div>
                    </div>
               }
               {
                    !isLoading && market.length > 0 ?
                         <div className="TableBody flex flex-wrap justify-center ">
                              <InfiniteScroll
                                   dataLength={market.length}
                                   next={onLoadMore}
                                   hasMore={hasMore}
                                   endMessage={<p className='mt-12 flex items-center justify-center'> <b> {t('You have seen it all')}</b> </p>}
                                   loader={<PreLoader />}
                              >
                                   {
                                        market && market.length > 0 &&
                                        market.map(item => (
                                             item.object &&
                                             <div key={item.object && item.object.id} className='text-left md:text-center mt-12 row flex items-center justify-start md:justify-center flex-wrap w-full sm:w-1/2 lg:w-full bg-white p-4 rounded-lg shadow-sm'>
                                                  <Link to={`/details/${item.object.slug}/${item.object.id}`} className="photo capitalize w-full md:w-2/12 p-2">
                                                       <img className='rounded-lg w-full' width='250' height='250' src={item.object && item.object.image} alt="" />
                                                  </Link>
                                                  <Link to={`/details/${item.object.slug}/${item.object.id}`} className="type capitalize w-1/2 md:w-2/12">
                                                       {item.object && item.object.species}
                                                  </Link>
                                                  <Link to={`/details/${item.object.slug}/${item.object.id}`} className="date-time capitalize w-1/2 md:w-2/12">
                                                       {item.object && item.object.end_date} <br /> {item.object && item.object.end_time}
                                                  </Link>
                                                  <Link to={`/details/${item.object.slug}/${item.object.id}`} className="name capitalize w-1/2 md:w-2/12">
                                                       {(item.object && item.object.category) && item.object.category.title}
                                                  </Link>
                                                  <Link to={`/details/${item.object.slug}/${item.object.id}`} className="price capitalize w-1/2 md:w-2/12">
                                                       {item.object && item.object.max_price} {t('AED')}
                                                  </Link>
                                                  <div className="auction capitalize w-full md:w-2/12">
                                                       <div className="flex flex-col gap-4">
                                                            {item.accepted === '0' &&
                                                                 <>
                                                                      <BlueButton className={`AddAnimalButton py-4 px-8 bg-green ${item.accepted === '1' ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}`} onClick={() => acceptHandler(item.id, 1)}>
                                                                           ✔ {item.accepted === '1' ? `${t('Accepted')}` : `${t('Accept')}`}
                                                                      </BlueButton>
                                                                      <BlueButton className={`AddAnimalButton py-4 px-8 bg-red ${item.accepted === '-1' ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}`} onClick={() => acceptHandler(item.id, -1)}>
                                                                           ✗    {item.accepted === '-1' ? `${t('Rejected')}` : `${t('Reject')}`}
                                                                      </BlueButton>
                                                                 </>
                                                            }
                                                            {item.accepted === '1' && <>{t('Accepted')}</>}
                                                            {item.accepted === '-1' && <>{t('Rejected')}</>}
                                                       </div>

                                                  </div>
                                             </div>
                                        ))
                                   }
                              </InfiniteScroll>
                              {
                                   !isLoading && market.length === 0 &&
                                   <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                                        <NotItems />
                                   </div>
                              }
                         </div>
                         :
                         <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                              <NotItems />
                         </div>
               }
          </>
     )
}

export default DashboardMarket