
import React from 'react'
import ProductCard from '../../Components/UI/Cards/Product-card'
import Layout from '../../Components/Layout'
import { MarketDataApi } from '../../Components/Helpers/Data'
import Search from '../../Components/Search/Search'

const Mating = () => {
  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        <section className="mt-12">
          <Search />
        </section>
        <section className="mt-12">
          <div className="row flex items-start justify-center flex-wrap">
            {
              MarketDataApi.reverse().map((item, index) => (
                <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-3' key={index}>
                  <ProductCard
                    key={index}
                    offer={true}
                    img={item.img}
                    name={item.name}
                    type={item.type}
                    badg={item.badg}
                    tags={item.tags}
                    title={item.title}
                    start={item.start}
                    email={item.email}
                    isFav={item.isFav}
                    phone={item.phone}
                    gender={item.gender}
                    location={item.location}
                    duration={item.duration}
                    oldPrice={item.oldPrice}
                    whatsapp={item.whatsapp}
                    userImage={item.userImage}
                    averagePrice={item.averagePrice}
                    locationAnimal={item.locationAnimal}
                  />

                </div>
              ))
            }
          </div>
        </section>

      </main>
    </Layout>
  )
}

export default Mating

