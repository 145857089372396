import React from 'react'

const Ads = () => {
     return (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M18.1123 15.9068L4.01975 13.8631L2.95421 8.97792L14.9157 1.25134L18.1123 15.9068Z" fill="#0F70B7" />
               <path d="M4.74437 13.2788C4.67582 12.9645 3.92497 9.52208 3.85642 9.2078C3.70937 8.53365 3.0429 8.10586 2.36875 8.2529C1.6946 8.39995 1.2668 9.06642 1.41385 9.74057C1.4824 10.0549 2.23325 13.4972 2.3018 13.8115C2.44884 14.4857 3.11532 14.9135 3.78947 14.7664C4.46362 14.6194 4.89141 13.9529 4.74437 13.2788Z" fill="#0F70B7" />
               <path d="M19.6518 15.1445C19.5833 14.8302 16.7013 1.61754 16.6328 1.30326C16.4857 0.629113 15.8193 0.201317 15.1451 0.348361C14.471 0.495406 14.0432 1.16188 14.1902 1.83603C14.2588 2.15031 17.1407 15.363 17.2092 15.6773C17.3563 16.3514 18.0228 16.7792 18.6969 16.6322C19.3711 16.4851 19.7989 15.8187 19.6518 15.1445Z" fill="#0F70B7" />
               <path d="M7.29046 15.7085L7.27847 15.7904C7.14538 16.7014 7.77597 17.5473 8.68692 17.6804L10.3361 17.9211C11.2471 18.0542 12.0929 17.4236 12.226 16.5127L12.2386 16.4255L7.29046 15.7085Z" fill="#17C3B2" />
          </svg>

     )
}

export default Ads