import React from 'react'
import BannerCard from '../UI/Cards/Banner-Card'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { adsBannerSlider } from '../Helpers/SlidersOptions';

const AdsBanner = (props) => {

     const options = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: props.cardBannerData.length < 2 ? 1 : 2,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false
     };

     return (
          <LazyLoadComponent delaytime='500'>
               <Slider {...options}>
                    {
                         props.cardBannerData.map((item, index) => (
                              <div key={index} className={`w-1/2 px-2 ${index === 0 && 'ltr:md:pr-4 rtl:md:pl-4'}`}>
                                   <BannerCard img={window.innerWidth > 767 ? item.web_file : item.mobile_file} description={item.content} type={item.type} poster={item.thumbnail} />
                              </div>
                         ))
                    }
               </Slider>
          </LazyLoadComponent >

     )
}

export default AdsBanner