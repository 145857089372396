import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config, url } from '../Components/Helpers/Help';
const TermsApp = () => {
     const [terms_and_conditions, setTerms_and_conditions] = useState();

     useEffect(() => {
          axios(url + "content ", config).then((response) => {
               setTerms_and_conditions(response.data.data.terms_and_conditions)
          });
     }, []);

     console.log(terms_and_conditions)
     return (
          <main className="container mx-auto px-4 md:px-0">
               <div className="row flex items-start justify-center flex-wrap min-h-[70vh]">
                    <div className="w-full md:w-8/12 p-4 xl:px-6">
                         {terms_and_conditions && <div className="bg-white shadow rounded-2xl p-8">
                              <div dangerouslySetInnerHTML={{ __html: terms_and_conditions }} />
                         </div>}

                    </div>
               </div>
          </main>

     )
}



export default TermsApp