import React from 'react'
import './ButtonLoading.scss'
import { t } from 'i18next'
const ButtonLoading = () => {
     return (
          <>
               <span className="loader"></span> {t('Processing...')}
          </>
     )
}

export default ButtonLoading