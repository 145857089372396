import React from 'react'

const Hours = () => {
     return (
          <svg width="37" height="42" viewBox="0 0 37 42" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9.69526 0.5C9.4691 0.500039 9.24292 0.590716 9.07085 0.772486L5.32328 4.72688C4.97915 5.09042 4.97915 5.68207 5.32328 6.04561C5.49549 6.22738 5.7216 6.3181 5.94768 6.3181C6.06072 6.3181 6.17402 6.29549 6.28058 6.25013L7.22121 7.7048C7.5097 8.14913 7.81611 8.57819 8.14287 8.98834L0.550551 16.371C0.206128 16.7065 0.00914754 17.1634 0.000316191 17.6606C-0.00851516 18.1546 0.167882 18.6203 0.50053 18.9714C0.824346 19.3132 1.25165 19.5 1.70794 19.5H1.74301C2.21108 19.4876 2.64687 19.2822 2.9648 18.9186L9.96204 10.9024C10.3477 11.2504 10.7542 11.5763 11.1752 11.8777L12.5534 12.8681C12.4233 13.2052 12.4872 13.6066 12.746 13.8797C12.9182 14.0612 13.1443 14.1522 13.3704 14.1522C13.5968 14.1522 13.8226 14.0615 13.9948 13.8797L17.7418 9.92475C18.0859 9.56152 18.0862 8.96985 17.7418 8.60662C17.483 8.3335 17.1027 8.26568 16.7833 8.40271L15.8439 6.94682C14.7812 5.30001 13.4507 3.89565 11.8904 2.77396L10.5128 1.78414C10.6427 1.44701 10.5781 1.0453 10.3197 0.772486C10.1473 0.59056 9.92141 0.499961 9.69526 0.5Z" fill="#17C3B2" />
               <path d="M9.69716 39.3635C10.0148 39.3635 10.2769 39.4707 10.4834 39.6851C10.7058 39.8842 10.817 40.1369 10.817 40.4432C10.817 40.7496 10.7058 41.0023 10.4834 41.2014C10.2769 41.4004 10.0148 41.5 9.69716 41.5H1.33425C0.984805 41.5 0.706836 41.4004 0.500345 41.2014C0.293853 41.0023 0.190608 40.7342 0.190608 40.3973C0.190608 40.091 0.301795 39.8153 0.524171 39.5703L5.83735 34.0338C6.52036 33.3293 7.04454 32.6324 7.40987 31.9432C7.79108 31.2541 7.98169 30.6491 7.98169 30.1284C7.98169 29.286 7.7196 28.6045 7.19543 28.0838C6.67126 27.5631 5.98031 27.3027 5.12258 27.3027C4.75725 27.3027 4.38397 27.3869 4.00276 27.5554C3.63743 27.7086 3.28798 27.923 2.95442 28.1986C2.63674 28.459 2.35877 28.7653 2.12051 29.1176C1.96167 29.332 1.80283 29.4698 1.64399 29.5311C1.48515 29.5923 1.33425 29.623 1.1913 29.623C0.889502 29.623 0.611532 29.5234 0.357389 29.3243C0.11913 29.1099 0 28.8649 0 28.5892C0 28.3748 0.0714779 28.168 0.214434 27.9689C0.357389 27.7698 0.540055 27.5631 0.76243 27.3486C1.11188 26.9351 1.5328 26.5676 2.0252 26.2459C2.53349 25.909 3.0656 25.6486 3.62154 25.4649C4.17748 25.2811 4.72548 25.1892 5.26553 25.1892C6.31387 25.1892 7.21926 25.3883 7.98169 25.7865C8.76 26.1847 9.35565 26.7437 9.76863 27.4635C10.1975 28.1833 10.4119 29.0333 10.4119 30.0135C10.4119 30.8405 10.1498 31.7595 9.62568 32.7703C9.11739 33.7658 8.40261 34.7459 7.48134 35.7108L3.78832 39.5932L3.52624 39.3635H9.69716Z" fill="#0F70B7" />
               <path d="M20.7422 41.5C20.4086 41.5 20.1306 41.3928 19.9082 41.1784C19.6859 40.9486 19.5747 40.6653 19.5747 40.3284V28.6351L20.0035 28.773L14.8571 35.1135L14.738 34.8149H23.4107C23.7283 34.8149 23.9904 34.9221 24.1969 35.1365C24.4193 35.3356 24.5305 35.5883 24.5305 35.8946C24.5305 36.1856 24.4193 36.4306 24.1969 36.6297C23.9904 36.8288 23.7283 36.9284 23.4107 36.9284H13.094C12.8081 36.9284 12.5381 36.8212 12.2839 36.6068C12.0298 36.377 11.9027 36.109 11.9027 35.8027C11.9027 35.5117 12.006 35.2514 12.2125 35.0216L19.7891 25.8784C19.9003 25.7252 20.0433 25.6104 20.218 25.5338C20.3927 25.4572 20.5754 25.4189 20.766 25.4189C21.1154 25.4189 21.4013 25.5338 21.6237 25.7635C21.8461 25.9779 21.9573 26.2536 21.9573 26.5905V40.3284C21.9573 40.6653 21.8381 40.9486 21.5999 41.1784C21.3616 41.3928 21.0757 41.5 20.7422 41.5Z" fill="#0F70B7" />
               <path d="M32.8066 29.2095C33.9026 29.2095 34.7524 29.4392 35.356 29.8986C35.9596 30.3428 36.3805 30.9477 36.6188 31.7135C36.8729 32.4793 37 33.3216 37 34.2405V40.3284C37 40.6653 36.8809 40.9486 36.6426 41.1784C36.4202 41.3928 36.1423 41.5 35.8087 41.5C35.4593 41.5 35.1733 41.3928 34.951 41.1784C34.7286 40.9486 34.6174 40.6653 34.6174 40.3284V34.2405C34.6174 33.7045 34.538 33.2144 34.3791 32.7703C34.2362 32.3261 33.9741 31.9739 33.5929 31.7135C33.2276 31.4532 32.7113 31.323 32.0442 31.323C31.4247 31.323 30.8688 31.4532 30.3764 31.7135C29.8999 31.9739 29.5266 32.3261 29.2566 32.7703C29.0024 33.2144 28.8754 33.7045 28.8754 34.2405V40.3284C28.8754 40.6653 28.7562 40.9486 28.518 41.1784C28.2956 41.3928 28.0176 41.5 27.6841 41.5C27.3346 41.5 27.0487 41.3928 26.8263 41.1784C26.6039 40.9486 26.4928 40.6653 26.4928 40.3284V25.6716C26.4928 25.3347 26.6039 25.059 26.8263 24.8446C27.0487 24.6149 27.3346 24.5 27.6841 24.5C28.0176 24.5 28.2956 24.6149 28.518 24.8446C28.7562 25.059 28.8754 25.3347 28.8754 25.6716V31.9432L28.4465 32.173C28.5577 31.836 28.7403 31.4914 28.9945 31.1392C29.2645 30.7869 29.5901 30.4653 29.9714 30.1743C30.3684 29.8833 30.8053 29.6536 31.2818 29.4851C31.7583 29.3014 32.2666 29.2095 32.8066 29.2095Z" fill="#0F70B7" />
          </svg>
     )
}

export default Hours