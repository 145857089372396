import React from 'react'

const Rocket = (props) => {
     return (
          <svg className={props.className} width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M42.6396 39.6917C39.3229 40.4771 35.8833 39.975 32.3292 38.2188C26.0604 41.9521 20.7875 46.4771 16.1875 51.5396L32.3271 58.8417L42.6396 39.6917Z" fill="url(#paint0_linear_269_41445)" />
               <path d="M60.317 57.3667C59.5316 60.6834 60.0337 64.123 61.7899 67.6771C58.0566 73.9459 53.5316 79.2188 48.4691 83.8188L41.167 67.6792L60.317 57.3667Z" fill="url(#paint1_linear_269_41445)" />
               <path d="M85.5546 16.2124C85.4504 15.2874 84.7191 14.5562 83.7941 14.452C73.6941 13.3083 60.0858 13.9729 51.6671 25.1458L28.0254 57.4854L35.2733 64.7333L42.5212 71.9812L74.8608 48.3416C86.0337 39.9229 86.6983 26.3145 85.5546 16.2124Z" fill="url(#paint2_radial_269_41445)" />
               <path d="M39.6941 75.0457L24.9629 60.3145L29.3816 58.8416L41.1671 70.6249L39.6941 75.0457Z" fill="url(#paint3_linear_269_41445)" />
               <path d="M35.9564 71.2978L32.3335 67.6749L28.7106 64.052C28.7106 64.052 20.6023 64.0291 16.771 70.9124C18.7648 71.0145 18.3669 72.502 16.3252 74.252C15.1314 75.277 14.3919 77.1749 14.3877 78.8687C16.371 78.2916 15.8731 82.1624 14.5856 85.4207C17.8439 84.1332 21.7169 83.6353 21.1377 85.6187C22.8335 85.6124 24.7231 84.8687 25.7481 83.6749C27.4981 81.6353 28.9919 81.2416 29.0939 83.2374C35.9794 79.4062 35.9564 71.2978 35.9564 71.2978Z" fill="url(#paint4_linear_269_41445)" />
               <path d="M35.3999 70.7417L32.3332 67.6751L29.2665 64.6084C27.3165 64.7917 21.879 66.6272 18.4228 70.798C19.4999 71.4563 19.1832 73.1292 18.3645 74.123C17.704 74.923 15.804 76.1334 15.0645 78.4646C16.6645 78.1501 17.2686 80.2501 15.827 84.1834C19.7603 82.7417 21.8603 83.3459 21.5457 84.9459C23.877 84.2063 25.0874 82.3063 25.8874 81.6459C26.8811 80.8251 28.5561 80.5105 29.2124 81.5876C33.3832 78.1292 35.2165 72.6917 35.3999 70.7417Z" fill="url(#paint5_radial_269_41445)" />
               <path d="M29.9995 69.9397C26.4329 74.673 21.7287 74.398 17.585 82.3834C22.5995 76.5501 27.9745 76.8314 29.9995 69.9397Z" fill="url(#paint6_linear_269_41445)" />
               <path d="M22.6455 83.225C25.6913 76.35 29.4226 78.3208 33.0809 73.2771C30.7601 80.3188 26.5934 79.6979 22.6455 83.225Z" fill="url(#paint7_linear_269_41445)" />
               <path d="M22.9102 74.3458C24.6393 69.8979 27.1643 70.8937 29.3852 67.6917C28.1477 72.2771 25.3935 72.1625 22.9102 74.3458Z" fill="url(#paint8_linear_269_41445)" />
               <path d="M17.25 83.8647C19.4104 80.2813 21.2812 81.5834 23.6271 79.1709C21.8375 82.9605 19.6313 82.3855 17.25 83.8647Z" fill="url(#paint9_linear_269_41445)" />
               <path d="M16.0059 80.0876C19.5975 79.2647 18.8642 76.9897 21.4746 75.573C17.7829 75.9605 17.8079 78.3042 16.0059 80.0876Z" fill="url(#paint10_linear_269_41445)" />
               <path d="M28.0898 76.2249C32.1107 74.1999 30.9107 72.0145 33.7065 69.6729C29.5003 71.2687 29.894 73.752 28.0898 76.2249Z" fill="url(#paint11_linear_269_41445)" />
               <path d="M16.9463 76.7228C23.7692 73.7103 22.8046 70.1457 27.9088 66.5562C20.9755 68.8707 20.6109 72.8666 16.9463 76.7228Z" fill="url(#paint12_linear_269_41445)" />
               <path d="M32.3666 68.9146L30.8916 67.4417L45.6249 52.7104L47.0979 54.1834L32.3666 68.9146Z" fill="url(#paint13_linear_269_41445)" />
               <path d="M50.5522 50.7259L49.0791 49.2527L45.6231 52.7086L47.0962 54.1818L50.5522 50.7259Z" fill="#288BD7" />
               <path d="M84.4206 34.3313C86.2456 28.1834 86.1727 21.6855 85.554 16.2126C85.4498 15.2876 84.7186 14.5563 83.7936 14.4522C78.3227 13.8334 71.8227 13.7605 65.6748 15.5855L84.4206 34.3313Z" fill="url(#paint14_linear_269_41445)" />
               <path d="M82.9941 38.1666L61.8399 17.0125C61.6045 17.1187 61.367 17.2145 61.1357 17.3291L82.6795 38.8729C82.792 38.6395 82.8878 38.402 82.9941 38.1666Z" fill="url(#paint15_linear_269_41445)" />
               <path d="M83.2898 37.5082L62.4981 16.7166C62.2606 16.8166 62.0273 16.9249 61.7939 17.0332L82.9731 38.2124C83.0794 37.979 83.1898 37.7457 83.2898 37.5082Z" fill="url(#paint16_linear_269_41445)" />
               <path d="M62.0523 57.7042L42.3023 37.9563L41.8711 38.5459L61.4628 58.1355L62.0523 57.7042Z" fill="url(#paint17_linear_269_41445)" />
               <path d="M62.6016 57.302L42.7037 37.4041L42.2725 37.9936L62.012 57.7332L62.6016 57.302Z" fill="url(#paint18_linear_269_41445)" />
               <path d="M62.6441 52.8333C69.7421 52.8333 75.4962 47.0792 75.4962 39.9812C75.4962 32.8832 69.7421 27.1292 62.6441 27.1292C55.5461 27.1292 49.792 32.8832 49.792 39.9812C49.792 47.0792 55.5461 52.8333 62.6441 52.8333Z" fill="url(#paint19_radial_269_41445)" />
               <path d="M61.7894 49.5812C68.0659 49.5812 73.154 44.4931 73.154 38.2166C73.154 31.9401 68.0659 26.8521 61.7894 26.8521C55.5129 26.8521 50.4248 31.9401 50.4248 38.2166C50.4248 44.4931 55.5129 49.5812 61.7894 49.5812Z" fill="url(#paint20_linear_269_41445)" />
               <path d="M61.7893 47.502C66.9175 47.502 71.0747 43.3448 71.0747 38.2166C71.0747 33.0884 66.9175 28.9312 61.7893 28.9312C56.6611 28.9312 52.5039 33.0884 52.5039 38.2166C52.5039 43.3448 56.6611 47.502 61.7893 47.502Z" fill="url(#paint21_linear_269_41445)" />
               <path d="M61.7894 46.55C66.3918 46.55 70.1227 42.819 70.1227 38.2166C70.1227 33.6143 66.3918 29.8833 61.7894 29.8833C57.187 29.8833 53.4561 33.6143 53.4561 38.2166C53.4561 42.819 57.187 46.55 61.7894 46.55Z" fill="url(#paint22_linear_269_41445)" />
               <path d="M63.3916 31.0126C63.8854 31.5063 60.4708 32.1313 58.0666 34.5355C55.6625 36.9397 55.1791 40.2126 54.6854 39.7189C54.1916 39.2251 53.3458 35.7564 56.3437 32.7584C59.0145 30.0876 62.9 30.5188 63.3916 31.0126Z" fill="url(#paint23_radial_269_41445)" />
               <path d="M70.0419 19.952C73.4919 18.3999 77.5315 17.1311 82.2815 16.452C82.2815 16.452 75.3565 14.6478 67.1211 17.0332L70.0419 19.952Z" fill="url(#paint24_linear_269_41445)" />
               <defs>
                    <linearGradient id="paint0_linear_269_41445" x1="29.774" y1="46.8838" x2="34.9959" y2="65.2642" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#0B71D9" />
                         <stop offset="0.628" stopColor="#264774" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_269_41445" x1="59.6095" y1="83.3706" x2="52.1319" y2="65.7872" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#0B71D9" />
                         <stop offset="1" stopColor="#264774" />
                    </linearGradient>
                    <radialGradient id="paint2_radial_269_41445" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(63.6308 50.7754) rotate(47.1274) scale(33.3181 119.354)">
                         <stop offset="0.17" stopColor="#999999" />
                         <stop offset="0.481" stopColor="#EEEEEE" />
                         <stop offset="0.707" stopColor="#ECECEC" />
                         <stop offset="0.788" stopColor="#E5E5E5" />
                         <stop offset="0.846" stopColor="#DADADA" />
                         <stop offset="0.893" stopColor="#C9C9C9" />
                         <stop offset="0.933" stopColor="#B3B3B3" />
                         <stop offset="0.968" stopColor="#989898" />
                         <stop offset="0.999" stopColor="#797979" />
                         <stop offset="1" stopColor="#787878" />
                    </radialGradient>
                    <linearGradient id="paint3_linear_269_41445" x1="28.7665" y1="61.1024" x2="39.0355" y2="71.5398" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#0B71D9" />
                         <stop offset="1" stopColor="#264774" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_269_41445" x1="30.2894" y1="69.7851" x2="15.9452" y2="83.9285" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#FF7900" />
                         <stop offset="1" stopColor="#E51E25" />
                    </linearGradient>
                    <radialGradient id="paint5_radial_269_41445" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.4108 67.79) rotate(45.001) scale(18.95 22.8764)">
                         <stop stopColor="white" />
                         <stop offset="0.254" stopColor="#FFDA4E" />
                         <stop offset="0.699" stopColor="#FF7900" />
                         <stop offset="0.975" stopColor="#E51F25" />
                    </radialGradient>
                    <linearGradient id="paint6_linear_269_41445" x1="40.3952" y1="62.7852" x2="14.6043" y2="83.758" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.404" stopColor="#FFDA4E" />
                         <stop offset="0.699" stopColor="#FF7900" />
                         <stop offset="0.975" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_269_41445" x1="38.6213" y1="68.8543" x2="17.9487" y2="86.8173" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.254" stopColor="#FFDA4E" />
                         <stop offset="0.594" stopColor="#FF7900" />
                         <stop offset="0.975" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_269_41445" x1="34.289" y1="64.0079" x2="16.8146" y2="79.5693" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.28" stopColor="#FFDA4E" />
                         <stop offset="0.834" stopColor="#FF7900" />
                         <stop offset="0.943" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_269_41445" x1="33.5606" y1="74.0032" x2="16.3902" y2="84.0495" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.28" stopColor="#FFDA4E" />
                         <stop offset="0.536" stopColor="#FF7900" />
                         <stop offset="0.943" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint10_linear_269_41445" x1="27.6348" y1="69.2422" x2="14.3555" y2="81.2431" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.28" stopColor="#FFDA4E" />
                         <stop offset="0.491" stopColor="#FF7900" />
                         <stop offset="0.943" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint11_linear_269_41445" x1="36.5319" y1="64.7358" x2="23.9421" y2="82.4301" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.37" stopColor="#FFDA4E" />
                         <stop offset="0.834" stopColor="#FF7900" />
                         <stop offset="0.943" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint12_linear_269_41445" x1="32.5531" y1="60.8663" x2="12.7461" y2="82.601" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" />
                         <stop offset="0.254" stopColor="#FFDA4E" />
                         <stop offset="0.602" stopColor="#FF7900" />
                         <stop offset="0.975" stopColor="#E51F25" />
                    </linearGradient>
                    <linearGradient id="paint13_linear_269_41445" x1="38.2575" y1="60.0759" x2="39.7306" y2="61.5491" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#264774" />
                         <stop offset="1" stopColor="#264774" />
                    </linearGradient>
                    <linearGradient id="paint14_linear_269_41445" x1="74.4325" y1="14.4093" x2="85.5914" y2="25.5685" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#F68381" />
                         <stop offset="0.547" stopColor="#E62228" />
                         <stop offset="0.903" stopColor="#8E2809" />
                    </linearGradient>
                    <linearGradient id="paint15_linear_269_41445" x1="61.3913" y1="17.0738" x2="82.9341" y2="38.6174" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" stopOpacity="0.8" />
                         <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint16_linear_269_41445" x1="62.0482" y1="16.7792" x2="83.2272" y2="37.9589" gradientUnits="userSpaceOnUse">
                         <stop offset="0.293" stopColor="#CBCBCB" />
                         <stop offset="1" stopColor="#666666" />
                    </linearGradient>
                    <linearGradient id="paint17_linear_269_41445" x1="42.0456" y1="38.2102" x2="61.7957" y2="57.9609" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" stopOpacity="0.8" />
                         <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint18_linear_269_41445" x1="42.4486" y1="37.6591" x2="62.3459" y2="57.5571" gradientUnits="userSpaceOnUse">
                         <stop offset="0.293" stopColor="#CBCBCB" />
                         <stop offset="1" stopColor="#666666" />
                    </linearGradient>
                    <radialGradient id="paint19_radial_269_41445" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.7167 39.9076) rotate(45.001) scale(12.2646)">
                         <stop stopOpacity="0.8" />
                         <stop offset="0.384" stopColor="#020202" stopOpacity="0.491" />
                         <stop offset="0.53" stopColor="#090909" stopOpacity="0.373" />
                         <stop offset="0.636" stopColor="#151515" stopOpacity="0.288" />
                         <stop offset="0.722" stopColor="#262626" stopOpacity="0.218" />
                         <stop offset="0.797" stopColor="#3C3C3C" stopOpacity="0.158" />
                         <stop offset="0.863" stopColor="#585858" stopOpacity="0.105" />
                         <stop offset="0.923" stopColor="#797979" stopOpacity="0.057" />
                         <stop offset="0.976" stopColor="#9E9E9E" stopOpacity="0.014" />
                         <stop offset="0.993" stopColor="#ABABAB" stopOpacity="0" />
                    </radialGradient>
                    <linearGradient id="paint20_linear_269_41445" x1="54.8307" y1="31.2586" x2="70.2674" y2="46.6959" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#E7E7E7" />
                         <stop offset="1" stopColor="#999999" />
                    </linearGradient>
                    <linearGradient id="paint21_linear_269_41445" x1="68.6911" y1="44.984" x2="55.9546" y2="32.4945" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#C8C8C8" />
                         <stop offset="1" stopColor="#AAAAAA" />
                    </linearGradient>
                    <linearGradient id="paint22_linear_269_41445" x1="55.8972" y1="32.3252" x2="67.6821" y2="44.1105" gradientUnits="userSpaceOnUse">
                         <stop stopColor="#78D9F4" />
                         <stop offset="0.846" stopColor="#0B71D9" />
                    </linearGradient>
                    <radialGradient id="paint23_radial_269_41445" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(56.5049 33.6592) rotate(-44.998) scale(13.1731 2.51398)">
                         <stop stopColor="white" stopOpacity="0.5" />
                         <stop offset="0.141" stopColor="white" stopOpacity="0.429" />
                         <stop offset="0.999" stopColor="white" stopOpacity="0" />
                    </radialGradient>
                    <linearGradient id="paint24_linear_269_41445" x1="53.1857" y1="1.25405" x2="93.8419" y2="31.2436" gradientUnits="userSpaceOnUse">
                         <stop stopColor="white" stopOpacity="0.8" />
                         <stop offset="0.794" stopColor="white" stopOpacity="0" />
                    </linearGradient>
               </defs>
          </svg>

     )
}

export default Rocket