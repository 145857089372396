
import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LineHeart from '../../assets/Icons/LineHeart'
import { userImage } from '../../Components/Helpers/Data'
import { config, url } from '../../Components/Helpers/Help'
import Layout from '../../Components/Layout'
import Categories from '../../Components/Seactions/Categories'
import ProfileUser from '../../Components/Seactions/ProfileUser'
import Search from '../../Components/Search/Search'
import BlueButton from '../../Components/UI/Buttons/BlueButton'
import alert from '../../Helpers/Modal'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { categoriesSlider } from '../../Components/Helpers/SlidersOptions'
import PublicCategories from '../../Components/Seactions/PublicCategories'

const Question = () => {
  const [content, setContent] = useState()
  const [data, setData] = useState()
  const [modal, setModal] = useState()
  const [question, setQuestion] = useState()
  const [answaerMessage, setAnswaerMessage] = useState()
  const params = useLocation()
  const [profile, setProfile] = useState(false)

  useEffect(() => {
    if (params.pathname.match('/profile/question')) {
      setProfile(true)
      axios(url + 'questions/my?limit=50&offset=1', config).then(
        response => {
          console.log(response.data.data.data)
          setQuestion(response.data.data.data)
        }
      ).catch(error => {
        console.log(error)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })
    } else {
      axios(url + 'questions?limit=50&offset=1', config).then(
        response => {
          setQuestion(response.data.data.data)
          console.log(response.data.data.data)
        }
      ).catch(error => {
        console.log(error)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })
    }
  }, [params]);


  useEffect(() => {
    if (localStorage.__authtk__awasell_secure) {
      const data = localStorage.getItem('__User_aW__a_Info')
      setData(JSON.parse(data))
    }
  }, [])


  // submit question
  const submitQuestion = (e) => {
    e.preventDefault()
    axios.post(url + 'questions',
      //  Data
      {
        content: content,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        setContent('')
        console.log(response)
        question.push(response.data.data)
        alert({
          title: `${t('Done')}`,
          text: `${t('Thanks for sharing your question')}`,
          icon: 'success',
          timer: 2000
        })
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })
  }

  // Submit my answer
  const submitAnswer = (e) => {
    e.preventDefault()
    axios.post(url + 'questions/answer',
      //  Data
      {
        // I mean with modal => id
        question_id: modal,
        content: answaerMessage,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        const item = question.find(item => {
          return item.id === modal;
        })
        // item.liked = !item.liked
        console.log(item.answers.push(response.data.data))
        setModal()
        alert({
          title: `${t('Done')}`,
          text: `${t('Thanks for sharing your answer')}`,
          icon: 'success',
          timer: 2000
        })
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })
  }

  // Add to wishlist
  const handleWishlistItem = (id) => {
    axios.post(url + 'questions/like',
      //  Data
      {
        question_id: id,
      },
      // Configrations
      config)
      // Response
      .then(response => {
        const item = question.find(item => {
          return item.id === id;
        })
        item.liked = !item.liked
        setQuestion([...question]);
        alert({
          title: `${t('Done')}`,
          text: `${t('Your question addes to wishlist')}`,
          icon: 'success',
          timer: 2000
        })
      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })


  }

  // Delete answer
  const deleteAnswer = (id, QID) => {
    axios.delete(url + `questions/answer?answer_id=${id}`, config)
      // Response
      .then(response => {
        console.log(response)
        const item = question.find(item => {
          return item.id === QID;
        })

        // Find the index of the object to be removed
        const secondIndex = item.answers.findIndex((item) => item.id === id);

        // Mutating the array like this will not update the view
        item.answers.splice(secondIndex, 1);
        setQuestion([...question]);

        alert({
          title: `${t('Done')}`,
          text: `${t('Your question addes to wishlist')}`,
          icon: 'success',
          timer: 2000
        })


      })
      // Check Error
      .catch(error => {
        console.log(error.response.data.message)
        alert({
          title: `${t('Error')}`,
          text: error.response.data.message,
          icon: 'error',
          timer: 2000
        })
      })


  }


  return (
    <Layout>

      {modal &&
        <>
          <div className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40" onClick={() => setModal(false)} />
          <div className={`fixed shadow-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[50rem] bg-[#F9F9F9] rounded-2xl z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}>
            <button className='w-12 h-12 border-2 font-bold text-[2rem] flex items-center justify-center rounded-full absolute right-8 top-4 border-gray-600' onClick={() => setModal(false)}>x</button>
            <div className="flex flex-col justify-center items-center">
              <h3 className='font-bold text-[1.8rem] mb-8'>{t('Your Answer')}</h3>
              <textarea name="" id="" cols="30" rows="7" className='w-full mb-8 rounded-xl resize-none p-4' onChange={(e) => setAnswaerMessage(e.target.value)}></textarea>
              <BlueButton onClick={submitAnswer} className='py-6 xl:px-28'>{t('Submit')}</BlueButton>
            </div>
          </div>
        </>

      }


      <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-screen">
        {profile &&
          <ProfileUser myProfile={true}
            id={data.id}
            follow={false}
            personal_picture={data.personal_picture}
            f_name={data.f_name}
            nickname={data.nickname}
            l_name={data.l_name}
            posts={data.posts}
            followers={data.followers}
            following={data.following}
          />
        }
        {!profile && (
          <section className="mt-12">
            <Search /> 
          </section>
        )}

        {data &&
          <section className="w-full mt-12 bg-white shadow-xl items-center justify-between flex flex-wrap p-8 rounded-3xl">
            <div className="flex flex-col justify-start relative w-8/12">
              <div className="user flex items-center flex-wrap gap-6">
                <Link to={`/user-page/${data.id}`} className="userImage w-24 h-24 rounded-full relative overflow-hidden">
                  <img src={data.personal_picture} alt="" className='h-full w-full object-cover' />
                </Link>
                <Link to={`/user-page/${data.id}`} className="data">
                  {data.f_name && <h3 className="text-[1.7rem] font-bold"> {data.f_name} </h3>}
                  {data.city && <p className='font-semibold text-gray-600'> {data.city.name}</p>}
                </Link>
              </div>
              <div className="p-8 w-full">
                <input type="text" placeholder={t('Write your question ...')} className='w-full' value={content} onChange={(e) => setContent(e.target.value)} />
              </div>
            </div>
            <div className="action">
              {/* <BlueButton onClick={() => setModal(true)} className='px-8 py-4'> Post a question </BlueButton> */}
              <BlueButton onClick={submitQuestion} className='px-8 py-4'> {t('Post a question')} </BlueButton>
            </div>
          </section>
        }
        <hr className='border border-gray-400 w-full my-24' />
        {(question && question.length > 0) &&
          <>
            {
              question.map((item, index) => (

                <section key={index} className="w-full mt-12 bg-white shadow-xl items-center justify-between flex flex-wrap p-8 rounded-3xl">
                  <div className="flex flex-col justify-start relativ">
                    {item.user &&
                      <Link to={`/user-page/${item.user && item.user.id}`} className="user flex items-center flex-wrap gap-6">
                        <div className="userImage w-24 h-24 rounded-full relative overflow-hidden">
                          <img src={item.user.personal_picture} alt="" className='h-full w-full object-cover' />
                        </div>
                        <div className="data">
                          <div className="flex items-center gap-3">
                            {item.user.f_name && <h3 className="text-[1.7rem] font-bold"> {item.user.f_name} {item.user.l_name} </h3>}
                            {item.created_at && <span className="text-[1rem]"> {item.created_at}  </span>}
                          </div>
                          {item.user.city && <p className='font-semibold text-gray-600'>{item.user.city.name}</p>}
                        </div>
                      </Link>
                    }
                    <div className="p-8 w-full">
                      <p>{item.content}</p>
                      <b className='text-blue'>{item.hashtags}</b>
                      <div className="flex mt-8 gap-8">
                        <BlueButton onClick={() => setModal(item.id)} className='px-16 xl:px-32 py-4'> {t('Answer')} </BlueButton>
                        <button className='' onClick={() => handleWishlistItem(item.id)}>

                          <LineHeart className={`${item.liked && 'fill-blue'} hover:fill-blue`} />
                        </button>
                      </div>
                    </div>
                  </div>
                  {item.answers &&
                    item.answers.map((innerItem, innerIndex) => (
                      <div className="w-full flex flex-col justify-start relativ bg-gray-100 p-2 my-2 px-8 border rounded-xl ltr:ml-auto " key={innerIndex}>
                        {innerItem.user &&
                          <Link to={`/user-page/${innerItem.user.id}`} className="user flex items-center flex-wrap gap-6">
                            <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                              <img src={innerItem.user.personal_picture} alt="" className='h-full w-full object-cover' />
                            </div>
                            <div className="data">
                              {innerItem.user.f_name && <h3 className="text-[1.7rem] font-bold"> {innerItem.user.f_name} {innerItem.user.l_name} </h3>}
                              {innerItem.user.city && <p className='font-semibold text-gray-600 text-[1.2rem]' >{innerItem.user.city.name}</p>}
                            </div>
                          </Link>
                        }
                        <div className="w-full mt-4 pl-16">
                          {/* <textarea value={innerItem.content} name="text" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'></textarea> */}
                          <p>{innerItem.content}</p>
                          {innerItem.is_mine && <span onClick={() => deleteAnswer(innerItem.id, item.id)} className='text-[1rem] text-red-2000 hover:text-red cursor-pointer'>Delete</span>}
                        </div>
                      </div>

                    ))
                  }
                </section>
              ))
            }
          </>
        }
      </main>
    </Layout>
  )
}




export default Question
