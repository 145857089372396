import React, { useContext } from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import Female from '../../../assets/Icons/Female'
import LineHeart from '../../../assets/Icons/LineHeart'
import Male from '../../../assets/Icons/Male'
import Phone from '../../../assets/Icons/Phone'
import Whatsapp from '../../../assets/Icons/Whatsapp'
import BlueButton from '../Buttons/BlueButton'
import RedButton from '../Buttons/RedButton'
import Lottie from 'react-lottie';
import InMarket from '../../../Components/Lottie/InMarket.json'
import { t } from 'i18next'
import { Veterinary } from '../../../Pages'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Context from '../../../Store/Store'
import Verified from '../../../assets/Icons/Verified'


const Photographer = (props) => {
     const { img, userImage, name, id, badge, location, slug, userId, whatsapp } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }

     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-0 flex-col`}>
                    <div className="w-full mt-2">
                         {(userImage || name) &&
                              <div className='flex items-center justify-start flex-wrap px-2'>
                                   <Link to={`/user-page/${userId}`} className="w-4/5 user flex items-center gap-2">
                                        {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                             <img src={userImage} alt="" className='h-full w-full object-cover' />
                                        </div>
                                        }
                                        {name && <div className="name">
                                             {name}
                                        </div>
                                        }
                                        <div className="flex items-center">
                                             {badge === 'gold' ?
                                                  <Badge />
                                                  :
                                                  <BadgeGray />
                                             }
                                             {props.is_verified === 1 && <div className=""><Verified /></div>}
                                        </div>
                                   </Link>

                                   <div className="fav w-1/5 ltr:text-right flex items-center justify-end">
                                        {props.rate &&
                                             <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 ">
                                                  {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                  {props.rate === '1' && '★☆☆☆☆'}
                                                  {props.rate === '2' && '★★☆☆☆'}
                                                  {props.rate === '3' && '★★★☆☆'}
                                                  {props.rate === '4' && '★★★★☆'}
                                                  {props.rate === '5' && '★★★★★'}
                                                  {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                             </span>
                                        }
                                        <button className='scale-[0.8]' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>
                                   </div>
                              </div>
                         }
                    </div>
                    <ul className='px-2 md:px-4 mb-3'>
                         {location && <li className='mt-2 no-wrap'>{t('Location')}: <b>{location}</b> </li>}
                         {whatsapp ?
                              <a href={`https://api.whatsapp.com/send?phone=${whatsapp}`} className='mt-2 w-full no-wrap flex flex-wrap items-center justify-start gap-2 text-xl md:text-[1.6rem]'>
                                   {window.innerWidth > 767 && `${t('Contact')}:`}
                                   {window.innerWidth > 767 && <Whatsapp />}                                   <b className='flex flex-wrap items-center justify-start gap-2'> {whatsapp}</b>
                              </a>
                              :
                              <b className='flex flex-wrap items-center justify-start gap-2'> {t('no contact')}</b>
                         }
                    </ul>
                    <Link to={`/details/photographers/${id}`} className="w-full flex h-[25rem]">
                         <img src={img} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                    </Link>
               </div>
          </LazyLoadComponent>
     )
}

export default Photographer