import { t } from 'i18next'
import React, { useState } from 'react'
import BlueButton from '../UI/Buttons/BlueButton'
import Ring from '../UI/Loader/Ring'

const City = (props) => {
  const [isLoading, setIsLoading] = useState('')
  const [isCity, setIsCity] = useState('')

  const sendCity = (e) => {
    setIsLoading(true)
    e.preventDefault()
    setTimeout(() => {
      setIsLoading(false)
      props.onCityHandler(isCity)
    }, 500);
  }
  return (
    <>
      <h3 className='w-full font-bold text-[3.4rem]'>{t('Your City')}</h3>
      <p className='w-full font-light text-[1.5] mb-8'>{t('Lorem ipsum dolor sit amet, consectetur adipiscing')}</p>
      <ul className='w-full flex justify-center items-start flex-wrap cursor-pointer'>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Abu Dhabi' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Abu Dhabi')}>Abu Dhabi</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Ajman' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Ajman')}>Ajman</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Dubai' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Dubai')}>Dubai</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Fujairah' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Fujairah')}>Fujairah</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Ras Al Khaimah' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Ras Al Khaimah')}>Ras Al Khaimah</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Sharjah' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Sharjah')}>Sharjah</li>
        <li className={`py-4 my-4 border-blue hover:bg-blue hover:text-white duration-500 transition-all border-2 w-full flex items-center rounded-2xl font-bold justify-center ${isCity === 'Umm Al Quwain' ? 'bg-blue text-white' : 'text-blue'}`} onClick={() => setIsCity('Umm Al Quwain')}>Umm Al Quwain</li>
      </ul>
      <BlueButton onClick={sendCity} className='w-full text-[1.5rem] py-4 px-5 mt-8 hover:bg-mint'>
        {isLoading ? <Ring /> : `${t('Next')}`}
      </BlueButton>
      <button onClick={props.onSkip} className='bg-dark-gold w-full mt-4 text-[1.5rem] rounded-xl py-4 px-5 font-bold text-gray-300 hover:text-blue text-center'> {t('Skip')}</button>
    </>
  )
}

export default City