import React, { useEffect, useState } from 'react'
import Camal from '../../assets/Icons/Animals/Camal'
import BlueButton from '../UI/Buttons/BlueButton'
import { t } from 'i18next'
import axios from 'axios'
import { config, url } from '../Helpers/Help'

const InterestedIn = (props) => {
     const [interestedArray, setInterestedArray] = useState([])
     const [childs, setChilds] = useState([])
     const [categories, setCategories] = useState([])
     const [mine, setMine] = useState()



     useEffect(() => {
          axios(url + "start ", config).then((response) => {
               localStorage.setItem(
                    "__data_aW__a_start",
                    JSON.stringify(response.data.data)
               );
          });
     }, []);
     // Get Countries from localStorage
     useEffect(() => {
          if (localStorage.__authtk__awasell_secure && localStorage.__data_aW__a_start) {
               const data = localStorage.getItem('__data_aW__a_start')
               const dataArr = JSON.parse(data)
               setCategories(dataArr.categories)
          }
     }, [])

     useEffect(() => {
          if (localStorage.__authtk__awasell_secure && localStorage.__User_aW__a_Info) {
               const data = localStorage.getItem('__User_aW__a_Info')
               const interstedID = JSON.parse(data)
               setMine(interstedID.categories_id)
               console.log(interstedID.categories_id.join().split(','))
          }
     }, [])


     const sendIntersted = (e) => {
          e.preventDefault()
          props.onSelectInterested(interestedArray, childs, mine)
     }


     const handleChecked = (item, id) => {
          let prev = interestedArray;
          let itemIndex = prev.indexOf(id);
          if (itemIndex !== -1) {
               prev.splice(itemIndex, 1);
          } else {
               prev.push(id);
          }

          let child = childs;
          let itemChild = child.indexOf(item);
          if (itemChild !== -1) {
               child.splice(itemChild, 1);
          } else {
               child.push(item);
          }

          setChilds([...childs]);
          setInterestedArray([...prev]);
     };
    
     return (
          <>
               <div className='flex items-start justify-center flex-wrap'>

                    {categories && categories.length > 0 && mine && categories.map((item, index) => {
                         return (

                              <div className="w-1/2 p-2 my-2 md:w-1/3 relative overflow-hidden" key={index}>
                                   <input value={item} type="checkbox" onChange={() => handleChecked(item, item.id, mine)} className='absolute top-0 left-0 w-full h-full z-10 opacity-0 cursor-pointer' />
                                   <div className={`h-[17.5rem] flex items-center justify-center flex-col bg-blue rounded-3xl  ${mine.includes(Number(item.id)) ? 'bg-mint' : ''}  ${interestedArray.includes(item.id) && 'bg-mint'}`}>
                                        <div className="h-[10rem] flex items-center justify-center ">
                                             <img src={item.icon} alt="" />
                                        </div>
                                        <h3 className='text-[1.6rem] text-white font-semibold'>{item.title} </h3>
                                   </div>
                              </div>
                         );
                    })}


               </div >
               <div className="flex w-full flex-col justify-center items-center">
                    <BlueButton onClick={sendIntersted} className='w-full max-w-[30rem] text-[1.5rem] py-4 px-5 mt-8 hover:bg-mint'> {t('Next')} </BlueButton>
                    <button onClick={props.onSkip} className='bg-dark-gold w-full max-w-[30rem] mt-4 text-[1.5rem] rounded-xl py-4 px-5 font-bold text-gray-300 hover:text-blue text-center'> {t('Skip')}</button>
               </div>
          </>
     )
}

export default InterestedIn