import React from 'react'
import Female from '../../assets/Icons/Female'
import Male from '../../assets/Icons/Male'
import BlueButton from '../UI/Buttons/BlueButton'

const ProductTitle = (props) => {
     const { weight, gender, age, title, title_ad } = props
     return (
          <div className="my-8">
               <li className='mt-2 flex items-center justify-start flex-wrap'>
                    {(weight || gender || age || title_ad) &&
                         <>
                              <p className='w-4/6 flex items-center justify-start flex-wrap gap-2'>
                                   {gender && <span className='bg-blue w-12 h-12 rounded-full flex items-center justify-center'>{gender === 'male' ? <Male /> : <Female />}</span>}
                                   {weight && <span className='bg-[#ececec] px-4 py-2 rounded-2xl text-[1.4rem]'>{weight}</span>}
                                   {age && <span className='bg-[#ececec] px-4 py-2 rounded-2xl text-[1.4rem]'>{age}</span>}
                              </p>
                              {title_ad && <h3 className='w-full ltr:text-left rtl:text-right mt-8 font-bold'>{title_ad}</h3>}
                         </>
                    }

               </li>
               {title && <h3 className='mt-6 font-bold capitalize'>{title}</h3>}
          </div>
     )
}

export default ProductTitle