import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdCard from '../UI/Cards/Ad-Card';

const Ads = (props) => {
     const adsSlider = {
          dots: false,
          arrows: false,
          infinite: props.adsData?.length > 5 ? true : false,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          responsive: [

               {
                    breakpoint: 1536,
                    settings: {
                         slidesToShow: 4,
                         slidesToScroll: 4
                    }
               }, {
                    breakpoint: 1024,
                    settings: {
                         slidesToShow: 3,
                         slidesToScroll: 3
                    }
               },
               {
                    breakpoint: 768,
                    settings: {
                         slidesToShow: 3,
                         slidesToScroll: 3
                    }
               },
               {
                    breakpoint: 640,
                    settings: {
                         slidesToShow: 2,
                         slidesToScroll: 2
                    }
               }
          ]
     };
     return (
          <Slider {...adsSlider}>
               {
                    props.adsData.map(item => (
                         <AdCard key={item.id} img={item.web_file} vip={item.is_vip} content={item.content} type={item.type} />
                    ))
               }
          </Slider>
     )
}

export default Ads