import React from 'react'
import QuestionsCard from '../UI/Cards/Questions-card'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { productsSlider } from '../Helpers/SlidersOptions';
import SectionTitle from '../UI/Titles/SectionTitle';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import axios from 'axios';
import { config, url } from '../Helpers/Help';
import { useState } from 'react';
import { t } from 'i18next';
import BlueButton from '../UI/Buttons/BlueButton';
import alert from '../../Helpers/Modal';
const Questions = (props) => {
     const [id, setId] = useState()
     const [modal, setModal] = useState()
     const [answaerMessage, setAnswaerMessage] = useState()

     // Submit my answer
     const submitAnswer = (e) => {
          e.preventDefault()
          axios.post(url + 'questions/answer',
               //  Data
               {
                    // I mean with modal => id
                    question_id: id,
                    content: answaerMessage,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setModal(false)
                    setAnswaerMessage()
                    alert({
                         title: `${t('Done')}`,
                         text: `${t('Thanks for sharing your answer')}`,
                         icon: 'success',
                         timer: 2000
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response.data.message)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })
     }

     return (
          <>

               {modal &&
                    <>
                         <div className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40" onClick={() => setModal(false)} />
                         <div className={`fixed shadow-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[50rem] bg-[#F9F9F9] rounded-2xl z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}>
                              <button className='w-12 h-12 border-2 font-bold text-[2rem] flex items-center justify-center rounded-full absolute right-8 top-4 border-gray-600' onClick={() => setModal(false)}>x</button>
                              <div className="flex flex-col justify-center items-center">
                                   <h3 className='font-bold text-[1.8rem] mb-8'>Your Answer</h3>
                                   <textarea name="" id="" cols="30" rows="7" className='w-full mb-8 rounded-xl resize-none p-4' onChange={(e) => setAnswaerMessage(e.target.value)}></textarea>
                                   <BlueButton onClick={submitAnswer} className='py-6 px-8 xl:px-28'>Submit</BlueButton>
                              </div>
                         </div>
                    </>

               }
               <LazyLoadComponent delaytime='500'>
                    <SectionTitle to={props.to} number={props.number}>
                         {props.title}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                         {
                              props.items.map((item, index) => (
                                   <div key={index}>
                                        <QuestionsCard
                                             key={index}
                                             name={item.user && item.user.f_name}
                                             userImage={item.user && item.user.personal_picture}
                                             badge={item.user && item.user.badge}
                                             tags={item.tags}
                                             description={item.content}
                                             hashtags={item.hashtags}
                                             id={item.id} 
                                             slug={item.slug}
                                             openModal={() => { setId(item.id); setModal(true) }}
                                             userId={item.user && item.user.id}
                                             rate={item.user && item.user.avg_rate_int}
                                             is_verified={item.user && item.user.is_verified}
                                        />
                                   </div>
                              ))
                         }
                    </Slider>
               </LazyLoadComponent>
          </>
     )
}

export default Questions