import React from 'react'
import Layout from '../Components/Layout'
import ErrorPages from '../assets/Icons/ErrorPage'
import PaymentSuccessIcon from '../assets/Icons/PaymentSuccessIcon'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

const PaymentSuccess = () => {
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-[80vh] flex items-center justify-center">
                    <div className="flex items-center justify-center flex-wrap">
                         <PaymentSuccessIcon />
                         <div className="w-full flex items-center justify-center flex-wrap">
                              <h3 className='w-full font-bold text-blue text-center text-[2.5rem] mt-8'>{t('for being with us at AWA')}</h3>
                              <Link to='/' className='mt-12 bg-blue py-4 px-6 text-white rounded-lg'> {t('Back to home page')}</Link>
                         </div>
                    </div>
               </main>
          </Layout>
     )
}

export default PaymentSuccess