import React from 'react'

const ManageOffer = (props) => { 
     return (
          <svg className={props.className} width="56" height="54" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clipPath="url(#clip0_823_7966)">
                    <path d="M15.9615 22.2115C13.7793 22.2115 11.9712 24.0158 11.9712 26.1934V30.1753H2.99279C1.34052 30.1753 0 31.513 0 33.1618V38.1391H5.98558C5.98558 37.0426 6.88186 36.1482 7.98077 36.1482H9.97596C11.0749 36.1482 11.9712 37.0426 11.9712 38.1391H31.9231C31.9231 37.0426 32.8194 36.1482 33.9183 36.1482H35.9135C37.0124 36.1482 37.9087 37.0426 37.9087 38.1391H43.8942V33.1618C43.8942 31.513 42.5537 30.1753 40.9014 30.1753H31.9231V26.1934C31.9231 24.0158 30.1149 22.2115 27.9327 22.2115H15.9615ZM15.9615 26.1934H27.9327V30.1753H15.9615V26.1934ZM0 40.1301V53.0713C0 54.72 1.34052 56.0577 2.99279 56.0577H40.9014C42.5537 56.0577 43.8942 54.72 43.8942 53.0713V40.1301H37.9087V42.121C37.9087 43.2176 37.0124 44.112 35.9135 44.112H33.9183C32.8194 44.112 31.9231 43.2176 31.9231 42.121V40.1301H11.9712V42.121C11.9712 43.2176 11.0749 44.112 9.97596 44.112H7.98077C6.88186 44.112 5.98558 43.2176 5.98558 42.121V40.1301H0Z" fill="#0F70B7" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M45.4806 44.306C51.6769 43.2713 54.9998 38.9668 54.9998 34.0859C54.9998 28.9769 52.1809 25.8751 45.8725 23.4634C41.084 21.5119 39.054 20.1897 39.054 17.8362C39.054 15.8846 40.4634 13.8167 44.4078 13.8167C47.949 13.8167 50.3332 14.9462 51.7433 15.6142C51.8589 15.669 51.9679 15.7206 52.0704 15.7683L53.593 10.4874C51.7891 9.56718 49.3672 8.70775 45.8173 8.5914V3.4375H41.1971V8.88228C35.5067 9.91624 32.125 13.7056 32.125 18.6453C32.125 23.9843 35.9563 26.7398 41.8729 28.9214C46.0434 30.471 48.0156 32.137 48.0156 34.7205C48.014 36.4477 47.0626 37.7901 45.4806 38.5524V44.306Z" fill="#17C3B2" />
               </g>
               <defs>
                    <clipPath id="clip0_823_7966">
                         <rect width="56" height="54" fill="white" />
                    </clipPath>
               </defs>
          </svg>

     )
}

export default ManageOffer