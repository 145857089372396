import React, { useContext } from 'react'
import Badge from '../../../assets/Icons/Badge'
import BadgeGray from '../../../assets/Icons/BadgeGray'
import Email from '../../../assets/Icons/Email'
import LineHeart from '../../../assets/Icons/LineHeart'
import Phone from '../../../assets/Icons/Phone'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Context from '../../../Store/Store'
import Verified from '../../../assets/Icons/Verified'
import WhatsappFlat from '../../../assets/Icons/WhatsappFlat'

const AdoptionForAnimal = (props) => {
     const { img, userImage, noImage, id, name, slug, badge, userId, weight, location, type, typeCard, myBid, myPrice, lastBid, price, gender, tags, isFav, inMarket, offer, oldPrice, action, phone, email, contact, question, averagePrice, title, locationAnimal, duration, whatsapp, photographer } = props
     const ctx = useContext(Context);
     const itemIsFavorite = ctx.itemIsFavorite(id, slug);

     function toggleFavoriteStatusHandler() {
          console.log(id, slug)
          if (itemIsFavorite) {
               ctx.removeFavorite(id, slug);
          } else {
               ctx.addFavorite(id, slug)
          }
     }
     return (
          <LazyLoadComponent delaytime='500'>
               <div className={`AuctionCard dir-rtl mx-2 my-2  flex items-start justify-center min-h-[20rem] bg-white rounded-2xl pb-4 ${photographer ? 'flex-col-reverse pb-0' : 'flex-col'}`}>
                    {img && !noImage &&
                         <Link to={`/details/${slug}/${id}`} className="w-full">
                              <img src={img} alt="" className='w-full object-cover rounded-2xl h-[25rem]' />
                         </Link>
                    }
                    <div className="w-full min-h-[10rem] max-h-[27rem]">
                         <div className="p-2 md:px-4 pt-4 flex items-center justify-between flex-wrap">
                              {(userImage || name || badge) &&
                                   <>
                                        <Link to={`/user-page/${userId}`} className="user flex items-center gap-2">
                                             {userImage && <div className="userImage w-12 h-12 rounded-full relative overflow-hidden">
                                                  <img src={userImage} alt="" className='h-full w-full object-cover' />
                                             </div>
                                             }
                                             {name && <div className="name">
                                                  {name}
                                             </div>
                                             }
                                             <div className="flex items-center">
                                                  {badge === 'gold' ?
                                                       <Badge />
                                                       :
                                                       <BadgeGray />
                                                  }
                                                  {props.is_verified === 1 && <div className=""><Verified /></div>}
                                             </div>
                                        </Link>

                                        <div className="no-wrap w-1/3 rtl:text-left ltr:text-right  ">
                                             {props.rate &&
                                                  <span className="text-[1.5rem] text-yellow-500 ltr:ml-4 rtl:mr-4 flex items-center justify-end">
                                                       {/* ★ <b className="text-black/50 text-[1.5rem]">({props.rate})</b> */}
                                                       {props.rate === '1' && '★☆☆☆☆'}
                                                       {props.rate === '2' && '★★☆☆☆'}
                                                       {props.rate === '3' && '★★★☆☆'}
                                                       {props.rate === '4' && '★★★★☆'}
                                                       {props.rate === '5' && '★★★★★'}
                                                       {(props.rate === '0' || !props.rate) && '☆☆☆☆☆'}
                                                  </span>}
                                        </div>
                                   </>
                              }
                         </div>
                         <ul className='px-2 md:px-4'>
                              {locationAnimal && <li className='mt-2 no-wrap'>{t('Location')}: <b>{locationAnimal}</b> </li>}
                              {price &&
                                   <li className='mt-2 no-wrap'>{t('Price')} : <b>{price} {t('AED')} / {duration}</b> </li>
                              }
                              <div className="w-full flex justify-between items-center flex-wrap mt-4">
                                   <div className="w-1/2 flex gap-3">
                                        {phone && <a href={`tel:${phone}`}   ><Phone /> </a>}
                                        {email && <a href={`mailto:${email}`}> <Email /> </a>}
                                        {whatsapp && <a target='_blank' rel='noreferrer' href={`https://api.whatsapp.com/send?phone=${whatsapp}`}> <WhatsappFlat /> </a>}
                                   </div>
                                   <button className='' onClick={toggleFavoriteStatusHandler}> <LineHeart className={` hover:fill-blue ${itemIsFavorite ? 'fill-blue' : 'fill-[#d9d9d9]'}`} /> </button>

                              </div>
                         </ul>
                    </div>
               </div>
          </LazyLoadComponent>
     )
}

export default AdoptionForAnimal