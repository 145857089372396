import React from 'react'

const DeleteIcon = (props) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 480 480" fill="none">
               <path d="M200 40L180 60H60V100H420V60H300L280 40H200ZM87.3047 140L117.852 405.273C120.492 425.073 137.54 440 157.5 440H322.461C342.421 440 359.488 425.097 362.148 405.117L392.695 140H87.3047Z" fill={props.fill} />
          </svg>

     )
}

export default DeleteIcon