import React, { useEffect, useState } from 'react'
import Hours from '../assets/Icons/UserIcons/Colors/Hours'
import WhiteHours from '../assets/Icons/UserIcons/White/Quick'
import WhiteQuickMarket from '../assets/Icons/UserIcons/White/WhiteQuickMarket'
import QuickMarket from '../assets/Icons/UserIcons/Colors/MarketIcon'
import OfferPrice from '../assets/Icons/UserIcons/Colors/OfferPrice'
import WhiteOfferPrice from '../assets/Icons/UserIcons/White/OfferPrice'
import Layout from '../Components/Layout'
import BlueButton from '../Components/UI/Buttons/BlueButton'
import DashboardAuction from '../Components/Seactions/DashboardAuction'
import DashboardMarket from '../Components/Seactions/DashboardMarket'
import DashboardOfferPrice from '../Components/Seactions/DashboardOfferPrice'
import { t } from 'i18next'
const Dashboard = () => {
     const [activeTap, setActiveTap] = useState('Auction')
     return (
          <Layout>
               <main className="container mx-auto pt-12 md:pt-36 lg:pt-28 px-4 md:px-0">
                    <div className="row flex items-start justify-start flex-wrap">
                         <h1 className='w-full mt-24 md:mt-12 md:my-8 text-[1.7rem] md:text-[3.2rem] font-bold text-center rtl:md:text-right ltr:md:text-left'>{t('Dashboard')}</h1>
                         <section className="md:mt-12 w-full lg:w-2/12 flex justify-center lg:flex-col ">
                              <div onClick={() => setActiveTap('Auction')} className={`w-1/3 md:w-full flex-col md:flex-row cursor-pointer py-4 md:py-2 my-4 shadow-sm md:p-4 px-2 md:px-6 mx-2 rounded-xl flex items-center gap-4 ${activeTap === 'Auction' ? 'bg-blue text-white' : 'bg-white'}`}>
                                   {activeTap === 'Auction' ? <WhiteHours /> : <Hours />}
                                   <h3 className='text-[1.1rem] md:text-[1.5rem]'>{t('Auction')}</h3>
                              </div>
                              <div onClick={() => setActiveTap('Market')} className={`w-1/3 md:w-full flex-col md:flex-row cursor-pointer py-4 md:py-2 my-4 shadow-sm md:p-4 px-2 md:px-6 mx-2 rounded-xl flex items-center gap-4 ${activeTap === 'Market' ? 'bg-blue text-white' : 'bg-white'}`}>
                                   {activeTap === 'Market' ? <WhiteQuickMarket /> : <QuickMarket />}
                                   <h3 className='text-[1.1rem] md:text-[1.5rem]'>{t('Market')}</h3>
                              </div>
                              {/* <div onClick={() => setActiveTap('OfferPrice')} className={`w-1/3 md:w-full flex-col md:flex-row cursor-pointer py-4 md:py-2 my-4 shadow-sm md:p-4 px-2 md:px-6 mx-2 rounded-xl flex items-center gap-4 ${activeTap === 'OfferPrice' ? 'bg-blue text-white' : 'bg-white'}`}>
                                   {activeTap === 'OfferPrice' ? <WhiteOfferPrice /> : <OfferPrice />}
                                   <h3 className='text-[1.1rem] md:text-[1.5rem]'>{t('Offer Price')}</h3>
                              </div> */}
                         </section>
                         <section className="md:mt-12 w-full lg:w-10/12 md:px-6"> 
                              {activeTap === 'Auction' && <DashboardAuction />}
                              {activeTap === 'Market' && <DashboardMarket />}
                              {/* {activeTap === 'OfferPrice' && <DashboardOfferPrice />} */}
                         </section>

                    </div>
               </main>
          </Layout>
     )
}

export default Dashboard