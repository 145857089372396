import Countdown from "react-countdown";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Ads from "../Components/Seactions/Ads";
import Search from "../Components/Search/Search";
import AdsBanner from "../Components/Seactions/AdsBanner";
import Videos from "../Components/Seactions/Videos";
import { config, url } from "../Components/Helpers/Help";
import axios from "axios";
import Questions from "../Components/Seactions/Questions";
import { Link } from "react-router-dom";
import {
  advertisesSlider,
  auctionSlider,
  productsSlider,
} from "../Components/Helpers/SlidersOptions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import VipAuction from "../Components/UI/AnimalCard/VipAuction";
import SectionTitle from "../Components/UI/Titles/SectionTitle";
import Vip from "../assets/Icons/Vip";
import { t } from "i18next";
import Hour24 from "../assets/Icons/Hour24";
import FridayMarket from "../Components/UI/AnimalCard/FridayMarket";
import WholesaleMarket from "../Components/UI/AnimalCard/WholesaleMarket";
import TodayMarket from "../Components/UI/AnimalCard/TodayMarket";
import SpecialOffer from "../Components/UI/AnimalCard/SpecialOffer";
import AdoptionForAnimal from "../Components/UI/AnimalCard/AdoptionForAnimal";
import publicBanner from "../assets/images/public.webp";
import Photographer from "../Components/UI/AnimalCard/Photographer";
import LostAnimal from "../Components/UI/AnimalCard/LostAnimal";
import VeterinaryClinic from "../Components/UI/AnimalCard/VeterinaryClinic";
import LookFor from "../Components/UI/AnimalCard/LookFor";
import Mating from "../Components/UI/AnimalCard/Mating";
import Delivery from "../Components/UI/AnimalCard/Delivery";
import Others from "../Components/UI/AnimalCard/Others";
import PublicCategories from "../Components/Seactions/PublicCategories";
import LiveStreaming from "../Components/Seactions/LiveStreaming";
import PublicAuction from "../assets/Icons/PublicAuction";
import CookieConsent from "react-cookie-consent";
import SalesCard from "../Components/UI/AnimalCard/SalesCard";
import AdsIcon from "../assets/Icons/AdsIcon";
import PreLoader from "../Components/UI/PreLoader";
import splash from "../assets/images/splash.jpg";
import AwaReels from "../assets/Icons/AwaReels";
import errorImage from "../assets/images/error.jpg";
import Rocket from "../assets/Icons/Rocket";
import { Player } from "video-react";

const countTime = Date.now() + 5000;

const  Home = () => {
  const [banner, setBanner] = useState();
  const [home1, setHome1] = useState();
  const [home2, setHome2] = useState();
  const [home3, setHome3] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [spash, setSplash] = useState(true);
  const [infoCounts, setInfoCounts] = useState();
  const [serverError, setServerError] = useState(false);
  const [startAd, setStartAd] = useState({});
  const splashVideoWatched = sessionStorage.getItem("splashVideo");
  const Completionist = () => (
    <span
      onClick={() => {
        setSplash(false);
        sessionStorage.setItem("splashVideo", "true");
      }}
      className="cursor-pointer"
    >
      x
    </span>
  );

  const renderer = ({ seconds, completed }) => {
    if (!completed) {
      return <span>{seconds}</span>;
    } else {
      // sessionStorage.setItem('splash', true)
      return <Completionist />;
    }
  };

  // useEffect(() => {
  //   if (sessionStorage.splash) {
  //     setSplash(false)
  //   } else {
  //     setSplash(true)
  //   }
  // }, []);

  useEffect(() => {
    axios(url + "start ", config)
      .then((response) => {
        localStorage.setItem(
          "__data_aW__a_start",
          JSON.stringify(response.data.data)
        );
        setStartAd(response.data.data.app_starter_ad);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const pathname = window.location.search;
    if (pathname.indexOf("msg=success-payment") > -1) {
      window.location.href = "/payment-success";
    }
    //  || 'msg=error-payment'
    if (pathname.indexOf("msg=error-payment") > -1) {
      window.location.href = "/payment-fail";
    }
    if (pathname.indexOf("msg=cancel") > -1) {
      window.location.href = "/payment-canceld";
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios(url + "banners ", config)
      .then((response) => {
        console.log(response.data.data.advertises.gold);
        setIsLoading(false);
        setBanner(response.data.data);
      })
      .catch((error) => {
        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios(url + "home-web1", config)
      .then((response) => {
        // console.log(response.data.data);
        setHome1(response.data.data);
      })
      .catch((error) => {
        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios(url + "home-web2 ", config)
      .then((response) => {
        // console.log(response.data.data);
        setHome2(response.data.data);
      })
      .catch((error) => {
        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios(url + "home-web3 ", config)
      .then((response) => {
        // console.log(response.data.data);
        setHome3(response.data.data);
      })
      .catch((error) => {
        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios(url + "content ", config)
      .then((response) => {
        localStorage.setItem(
          "websiteContent",
          JSON.stringify(response.data.data)
        );
      })
      .catch((error) => {
        console.log(error.response);

        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    axios(url + "types-count ", config)
      .then((response) => {
        setInfoCounts(response.data.data);
      })
      .catch((error) => {
        if (
          (error && error?.response?.status === 500) ||
          (error && error?.code === "ERR_NETWORK")
        ) {
          setServerError(true);
          setIsLoading(false);
        }
      });
  }, []);

  return (
    <Layout>
      <CookieConsent
        location="bottom"
        buttonText="I understand that"
        declineButtonText="I decline"
        setDeclineCookie={true}
        cookieName="myAwesomeCookieName3"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      {!splashVideoWatched && startAd.id && spash && (
        <div className="overlay fixed top-0 left-0 w-screen h-screen bg-black/80 z-[999999999]">
          <div className="relative top-1/2 rtl:right-1/2 rtl:translate-x-1/2 ltr:left-1/2 ltr:-translate-x-1/2 -translate-y-1/2 lg:w-[100rem] lg:h-[80rem] flex items-center justify-center">
            <Player
              height={650}
              width={"100%"}
              fluid={false}
              playsInline
              src={startAd.web_file}
              autoPlay={true}
              muted={true}
            />
            {/* <img src={splash} alt="" className="w-auto h-full mx-auto rounded-2xl object-cover" /> */}
            <div className="absolute top-8 right-8 text-[2rem] bg-white border-2 border-blue text-blue w-16 h-16 rounded-full flex items-center justify-center">
              <Countdown date={countTime} renderer={renderer}>
                <span className="PublicSans">
                  <Completionist />
                </span>
              </Countdown>
            </div>
          </div>
        </div>
      )}
      <main className="container mx-auto pt-12 md:pt-36 lg:pt-32 px-4 md:px-0 min-h-screen">
        <div className="mt-12">
          <PublicCategories />
        </div>
        <section className="mt-12">
          <Search />
        </section>
        {serverError ? (
          <img
            src={errorImage}
            alt="server error"
            className="w-[50rem] h-[50rem] mt-48 block mx-auto mix-blend-multiply"
          />
        ) : (
          <>
            {isLoading ? (
              <PreLoader />
            ) : (
              <>
                {banner && banner?.advertises?.gold.length > 0 && (
                  <section className="mt-12 ">
                    <Slider {...advertisesSlider}>
                      {banner?.advertises?.gold.map((item) => (
                        <div key={item.id} className="p-4">
                          <div className="h-[20rem] relative overflow-hidden my-8 mx-auto">
                            <span className="absolute top-[1rem]  -left-[6rem]  bg-white px-24 py-4 -rotate-45 flex items-center justify-center flex-wrap">
                              <Rocket className="w-12 h-12 -rotate-45" />{" "}
                              {t("gold")}
                            </span>
                            {item.type === "video" ? (
                              <Player
                                height={200}
                                width={"100%"}
                                fluid={false}
                                playsInline
                                poster={item.thumbnail}
                                src={item.banner}
                              />
                            ) : (
                              <img
                                src={item.banner}
                                alt={item.title_ad}
                                className="h-full w-full object-cover mx-auto rounded-2xl"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner.banners && (
                  <section className="mt-12">
                    <Ads adsData={banner.banners} />
                  </section>
                )}

                {banner &&
                  banner.auction_vip &&
                  banner.auction_vip.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.auction_vip
                        }`}
                        number={infoCounts?.vip_auctions}
                      >
                        <Vip /> {t("Upcoming VIP Auction")}
                      </SectionTitle>
                      <Slider {...auctionSlider}>
                        {banner.auction_vip.map((item) => (
                          <VipAuction
                            is_notify={item.is_notify}
                            key={item.id}
                            id={item.id}
                            isMine={item.is_mine}
                            slug={item.slug}
                            img={item.image}
                            end_minutes={item.end_minutes}
                            start={item.start_time}
                            type={item.category && item.category.title}
                            max_price={item.max_price}
                            gender={item.gender}
                            age={item.age}
                            users_count={item.users_count}
                            weight={item.weight}
                            isFav={item.isFav}
                            inMarket={
                              item.in_market && item.in_market_till !== 0
                            }
                          />
                        ))}
                      </Slider>
                    </section>
                  )}

                {home3 &&
                  home3.auction_quick &&
                  home3.auction_quick.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.auction_quick
                        }`}
                        number={infoCounts?.quick_auctions}
                      >
                        <Hour24 /> {t("Upcoming Quick Auction")}
                      </SectionTitle>
                      <Slider {...auctionSlider}>
                        {home3.auction_quick.map((item, index) => (
                          <VipAuction
                            key={item.id}
                            id={item.id}
                            is_notify={item.is_notify}
                            isMine={item.is_mine}
                            slug={item.slug}
                            img={item.image}
                            end_minutes={item.end_minutes}
                            start={item.start_time}
                            type={item.category && item.category.title}
                            max_price={item.max_price}
                            gender={item.gender}
                            age={item.age}
                            users_count={item.users_count}
                            weight={item.weight}
                            isFav={item.isFav}
                            inMarket={
                              item.in_market && item.in_market_till !== 0
                            }
                          />
                        ))}
                      </Slider>
                    </section>
                  )}

                {home3 &&
                  home3.public_auctions &&
                  home3.public_auctions.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.public_auctions
                        }`}
                        number={infoCounts?.public_auctions}
                      >
                        <PublicAuction /> {t("Public Auction")}
                      </SectionTitle>
                      <div className="flex justify-center items-center flex-wrap bg-white">
                        <div className="w-1/2 md:w-1/3">
                          <img
                            src={publicBanner}
                            alt=""
                            className="rtl:-scale-x-100 min-h-[15rem] w-auto object-cover"
                          />
                        </div>
                        <div className="w-1/2 md:w-1/3 ">
                          <div className="w-full h-full flex items-center justify-center flex-wrap gap-3">
                            <p className="w-full text-center">
                              {t("Auctions Available")}:
                            </p>
                            <h3 className="font-bold w-full text-center">
                              {" "}
                              {infoCounts?.public_auctions} {t("Auctions")}
                            </h3>
                            <Link
                              to={`public/animals-type/${
                                banner && banner.slugs.public_auctions
                              }`}
                              className="bg-blue py-4 px-6 text-white rounded-lg"
                            >
                              {t("View All Actions")}
                            </Link>
                          </div>
                        </div>
                        <div className="hidden md:flex md:w-1/3">
                          <img
                            src={publicBanner}
                            alt=""
                            className="ltr:-scale-x-100 min-h-[15rem] w-auto object-cover"
                          />
                        </div>
                      </div>
                      {/* <Slider {...auctionSlider}>
                {home3.public_auctions.map((item, index) => (
                  <VipAuction
                    key={item.id}
                    id={item.id}
                    is_notify={item.is_notify}
                    isMine={item.is_mine}
                    slug={item.slug}
                    img={item.image}
                    end_minutes={item.end_minutes}
                    start={item.start_time}
                    type={item.category && item.category.title}
                    max_price={item.max_price}
                    gender={item.gender}
                    age={item.age}
                    users_count={item.users_count}
                    weight={item.weight}
                    isFav={item.isFav}
                    inMarket={item.in_market && item.in_market_till !== 0}
                  />
                ))
                }
              </Slider> */}
                    </section>
                  )}

                {banner && banner.sale_page && banner.sale_page.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.sales_pages
                      }`}
                      number={infoCounts?.sales_pages}
                    >
                      {" "}
                      {t("Sale Page")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {banner.sale_page.map((item, index) => (
                        <SalesCard
                          slug={item.slug}
                          key={item.id}
                          id={item.id}
                          price={item.price}
                          max_price={item.max_price}
                          users_count={item.users_count}
                          img={item.image}
                          type={item.category && item.category.title}
                          badg={item.user && item.user.is_vip}
                          isFav={item.isFav}
                          phone={item.user && item.user.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          action={item.action}
                          location={item.city && item.city.name}
                          duration={item.duration}
                          inMarket={item.inMarket}
                          whatsapp={item.user && item.user.whatsapp}
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner?.advertises?.silver.length > 0 && (
                  <section className="mt-12 ">
                    <Slider {...advertisesSlider}>
                      {banner?.advertises?.silver.map((item) => (
                        <div key={item.id} className="p-4">
                          <div className="h-[20rem] relative overflow-hidden my-8 mx-auto">
                            <span className="absolute top-[1rem]  -left-[6rem]  bg-white px-24 py-4 -rotate-45 flex items-center justify-center flex-wrap">
                              <Rocket className="w-12 h-12 -rotate-45" />{" "}
                              {t("silver")}
                            </span>
                            {item.type === "video" ? (
                              <Player
                                height={200}
                                width={"100%"}
                                fluid={false}
                                playsInline
                                poster={item.thumbnail}
                                src={item.banner}
                              />
                            ) : (
                              <img
                                src={item.banner}
                                alt={item.title_ad}
                                className="h-full w-full object-cover mx-auto rounded-2xl"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner.banner_1 && (
                  <section className="mt-12">
                    <AdsBanner cardBannerData={banner.banner_1} />
                  </section>
                )}
                {home1 &&
                  home1.friday_market &&
                  home1.friday_market.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.friday_market
                        }`}
                        number={infoCounts?.friday_markets}
                      >
                        {" "}
                        {t("Friday Market")}{" "}
                      </SectionTitle>
                      <Slider {...productsSlider}>
                        {home1.friday_market.map((item, index) => (
                          <FridayMarket
                            slug={item.slug}
                            key={item.id}
                            id={item.id}
                            img={item.image}
                            type={item.category && item.category.title}
                            badge={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age={item.age}
                            weight={item.weight}
                            gender={item.gender}
                            action={item.action}
                            price={item.price}
                            location={item.city && item.city.name}
                            duration={item.duration}
                            inMarket={item.inMarket}
                            whatsapp={item.user?.whatsapp}
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        ))}
                      </Slider>
                    </section>
                  )}
                {banner && banner.banner_2 && (
                  <section className="mt-12">
                    <AdsBanner cardBannerData={banner.banner_2} />
                  </section>
                )}
                {home1 && home1.wholesale && home1.wholesale.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.wholesale
                      }`}
                      number={infoCounts?.wholesale_markes}
                    >
                      {" "}
                      {t("Wholesale Market")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {home1.wholesale.map((item, index) => (
                        <WholesaleMarket
                          slug={item.slug}
                          id={item.id}
                          key={item.id}
                          img={item.image}
                          type={item.category && item.category.title}
                          badge={item.user && item.user.badge}
                          isFav={item.isFav}
                          phone={item.user && item.user.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          action={item.action}
                          price={item.price}
                          location={item.city && item.city.name}
                          description={item.note}
                          inMarket={item.inMarket}
                          whatsapp={
                            item.user && item.whatsapp && item.user.whatsapp
                          }
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {home2 &&
                  home2.today_market &&
                  home2.today_market.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.today_market
                        }`}
                        number={infoCounts?.today_markets}
                      >
                        {" "}
                        {t("Today Market")}{" "}
                      </SectionTitle>
                      <Slider {...productsSlider}>
                        {home2.today_market.map((item, index) => (
                          <TodayMarket
                            key={item.id}
                            slug={item.slug}
                            id={item.id}
                            img={item.image}
                            type={item.category && item.category.title}
                            badge={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age={item.age}
                            weight={item.weight}
                            gender={item.gender}
                            location={item.city && item.city.name}
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        ))}
                      </Slider>
                    </section>
                  )}
                {/* daily_announcements */}
                {home1 &&
                  home1.daily_announcements &&
                  home1.daily_announcements.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.daily_announcements
                        }`}
                        number={infoCounts?.public_auctions}
                      >
                        <AwaReels /> {t("AWA Reels")}
                      </SectionTitle>
                      <div className="flex justify-center items-center flex-wrap bg-white">
                        <div className="w-1/2 md:w-1/3">
                          <img
                            src={publicBanner}
                            alt=""
                            className="rtl:-scale-x-100 min-h-[15rem] w-auto object-cover"
                          />
                        </div>
                        <div className="w-1/2 md:w-1/3 ">
                          <div className="w-full h-full flex items-center justify-center flex-wrap gap-3">
                            <p className="w-full text-center">
                              {t("AWA Reels")}:
                            </p>
                            <h3 className="font-bold w-full text-center">
                              {" "}
                              {home1.daily_announcements.length} {t("Reels")}
                            </h3>
                            <Link
                              to={`public/animals-type/${
                                banner && banner.slugs.daily_announcements
                              }`}
                              className="bg-blue py-4 px-6 text-white rounded-lg"
                            >
                              {t("View All Reels")}
                            </Link>
                          </div>
                        </div>
                        <div className="hidden md:flex md:w-1/3">
                          <img
                            src={publicBanner}
                            alt=""
                            className="ltr:-scale-x-100 min-h-[15rem] w-auto object-cover"
                          />
                        </div>
                      </div>
                      {/* <Slider {...auctionSlider}>
                {home3.public_auctions.map((item, index) => (
                  <VipAuction
                    key={item.id}
                    id={item.id}
                    is_notify={item.is_notify}
                    isMine={item.is_mine}
                    slug={item.slug}
                    img={item.image}
                    end_minutes={item.end_minutes}
                    start={item.start_time}
                    type={item.category && item.category.title}
                    max_price={item.max_price}
                    gender={item.gender}
                    age={item.age}
                    users_count={item.users_count}
                    weight={item.weight}
                    isFav={item.isFav}
                    inMarket={item.in_market && item.in_market_till !== 0}
                  />
                ))
                }
              </Slider> */}
                    </section>
                  )}
                {home1 && home1.videos && home1.videos.length > 0 && (
                  <section className="mt-12">
                    <Videos
                      title={t("Videos")}
                      vipAuctionData={home1.videos}
                      to={`public/${banner && banner.slugs.videos}`}
                      number={infoCounts?.videos}
                    />
                  </section>
                )}
                {home2 && home2.offers && home2.offers.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.offers
                      }`}
                      number={infoCounts?.special_offers}
                    >
                      {" "}
                      {t("Special Offers")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {home2.offers.map((item, index) => (
                        <SpecialOffer
                          id={item.id}
                          slug={item.slug}
                          img={item.image}
                          type={item.sub_category?.title}
                          badge={item.user?.badge}
                          price={item.offer_price}
                          oldPrice={
                            +item.price > +item.offer_price && item.price
                          }
                          isFav={item.isFav}
                          phone={item.user?.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city?.name}
                          email={item.user?.email}
                          name={item.user?.f_name}
                          whatsapp={item.user?.whatsapp}
                          userImage={item.user?.personal_picture}
                          userId={item.user?.id}
                          rate={item.user?.avg_rate_int}
                          is_verified={item.user?.is_verified}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {home2 && home2.adoptions && home2.adoptions.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.adoption
                      }`}
                      number={infoCounts?.adoption}
                    >
                      {" "}
                      {t("Adoption for animal")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {/* <Link to='/' className={`md:mx-2 flex items-start justify-center flex-col  relative overflow-hidden h-[37rem]`}>
                    <div className="w-11/12 mx-auto relative overflow-hidden h-full">
                      <img src={map} alt="" className='absolute top-0 h-full w-full object-cover rounded-2xl' />
                    </div>
                    <div className="data font-bold text-center flex items-center justify-center rounded-b-2xl w-11/12 bg-white absolute left-1/2 -translate-x-1/2 bottom-0 py-3">
                      View Map
                    </div>
                  </Link> */}
                      {home2.adoptions.map((item, index) => (
                        <AdoptionForAnimal
                          key={item.id}
                          id={item.id}
                          img={item.image}
                          slug={item.slug}
                          badg={item.badg}
                          price={item.price}
                          isFav={item.isFav}
                          age={item.age}
                          duration={item.price_unit_text}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city && item.city?.name}
                          locationAnimal={item.city && item.city?.name}
                          email={item.user && item.user.email}
                          name={item.user && item.user?.f_name}
                          phone={item.phone}
                          userImage={item.user && item.user.personal_picture}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner?.advertises?.bronze.length > 0 && (
                  <section className="mt-12 ">
                    <Slider {...advertisesSlider}>
                      {banner?.advertises?.bronze.map((item) => (
                        <div key={item.id} className="p-4">
                          <div className="h-[20rem] relative overflow-hidden my-8 mx-auto">
                            <span className="absolute top-[1rem]  -left-[6rem]  bg-white px-24 py-4 -rotate-45 flex items-center justify-center flex-wrap">
                              <Rocket className="w-12 h-12 -rotate-45" />{" "}
                              {t("bronze")}
                            </span>
                            {item.type === "video" ? (
                              <Player
                                height={200}
                                width={"100%"}
                                fluid={false}
                                playsInline
                                poster={item.thumbnail}
                                src={item.banner}
                              />
                            ) : (
                              <img
                                src={item.banner}
                                alt={item.title_ad}
                                className="h-full w-full object-cover mx-auto rounded-2xl"
                              />
                            )}{" "}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner.banner_3 && (
                  <section className="mt-12">
                    <AdsBanner cardBannerData={banner.banner_3} />
                  </section>
                )}
                {home2 && home2.mating && home2.mating.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.mating
                      }`}
                      number={infoCounts?.matings}
                    >
                      {" "}
                      {t("Mating")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {home2.mating.map((item, index) => (
                        <Mating
                          id={item.id}
                          key={item.id}
                          slug={item.slug}
                          img={item.image}
                          type={item.category && item.category.title}
                          badg={item.badg}
                          isFav={item.isFav}
                          phone={item.user && item.user?.phone}
                          age={item.age}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city && item.city?.name}
                          email={item.user && item.user.email}
                          name={item.user && item.user?.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {home2 &&
                  home2.photographer &&
                  home2.photographer.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.photographer
                        }`}
                        number={infoCounts?.photographers}
                      >
                        {" "}
                        {t("Photographer and artist")}{" "}
                      </SectionTitle>
                      <Slider {...productsSlider}>
                        {home2.photographer.map((item, index) => (
                          <Photographer
                            key={item.id}
                            id={item.id}
                            img={item.image}
                            slug={item.slug}
                            type={item.category && item.category.title}
                            badg={item.user && item.user.badge}
                            isFav={item.isFav}
                            phone={item.user && item.user.phone}
                            age={item.age}
                            weight={item.weight}
                            gender={item.gender}
                            location={item.user && item.user.city?.name}
                            email={item.user && item.user.email}
                            name={item.user && item.user.f_name}
                            whatsapp={item.user && item.user.whatsapp}
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        ))}
                      </Slider>
                    </section>
                  )}
                {home2 && home2.lost_animal && home2.lost_animal.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.lost_animal
                      }`}
                      number={infoCounts?.lost_animals}
                    >
                      {" "}
                      {t("Lost Animal")}{" "}
                    </SectionTitle>
                    <Slider {...auctionSlider}>
                      {home2.lost_animal.map((item) => (
                        <LostAnimal
                          key={item.id}
                          id={item.id}
                          type={item.category && item.category.title}
                          img={item.image}
                          loastDate={item.lost_date}
                          location={item.user && item.user.city?.name}
                          badge={item.user && item.user.badge}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner?.advertises?.cooper?.length > 0 && (
                  <section className="mt-12 ">
                    <Slider {...advertisesSlider}>
                      {banner?.advertises?.cooper.map((item) => (
                        <div key={item.id} className="p-4">
                          <div className="h-[20rem] relative overflow-hidden my-8 mx-auto">
                            <span className="absolute top-[1rem]  -left-[6rem]  bg-white px-24 py-4 -rotate-45 flex items-center justify-center flex-wrap">
                              <Rocket className="w-12 h-12 -rotate-45" />{" "}
                              {t("cooper")}
                            </span>
                            {item.type === "video" ? (
                              <Player
                                height={200}
                                width={"100%"}
                                fluid={false}
                                playsInline
                                poster={item.thumbnail}
                                src={item.banner}
                              />
                            ) : (
                              <img
                                src={item.banner}
                                alt={item.title_ad}
                                className="h-full w-full object-cover mx-auto rounded-2xl"
                              />
                            )}{" "}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner.banner_4 && (
                  <section className="mt-12">
                    <AdsBanner
                      action="false"
                      cardBannerData={banner.banner_4}
                    />
                  </section>
                )}
                {home3 && home3.awa_lives && home3.awa_lives.length > 0 && (
                  <section className="mt-12">
                    <LiveStreaming
                      title={t("Live Streaming for AWA")}
                      vipAuctionData={home3.awa_lives}
                      to={`public/animals-type/${
                        banner && banner.slugs.awa_lives
                      }`}
                      number={infoCounts?.awa_lives}
                    />
                  </section>
                )}
                {home3 &&
                  home3.veterinaries &&
                  home3.veterinaries.length > 0 && (
                    <section className="mt-12">
                      <SectionTitle
                        to={`public/animals-type/${
                          banner && banner.slugs.veterinaries
                        }`}
                        number={infoCounts?.veterinaries}
                      >
                        {" "}
                        {t("Veterinary Clinic")}{" "}
                      </SectionTitle>
                      <Slider {...productsSlider}>
                        {/* <div className="px-2">
                    <Link to='/' className={`md:mx-2 flex items-start justify-center flex-col bg-white rounded-2xl relative overflow-hidden  h-[33rem]`}>
                      <div className="w-full">
                        <img src={map} alt="" className='absolute top-0 h-full w-full object-cover' />
                      </div>
                      <div className="data font-bold  text-center flex items-center justify-center w-full bg-white absolute left-0 bottom-0 py-3">
                        View Map
                      </div>
                    </Link>
                  </div> */}
                        {home3.veterinaries.map((item) => (
                          <div key={item.id}>
                            <VeterinaryClinic
                              id={item.id}
                              slug={item.slug}
                              img={item.image}
                              title={item.title}
                              badge={item.user && item.user.badge}
                              location={item.address}
                              name={item.user && item.user.f_name}
                              phone={item.user && item.user.phone}
                              email={item.user && item.user.email}
                              whatsapp={item.user && item.user.whatsapp}
                            />
                          </div>
                        ))}
                      </Slider>
                    </section>
                  )}
                {home3 && home3.looking_for && home3.looking_for.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.looking_for
                      }`}
                      number={infoCounts?.looking_for}
                    >
                      {" "}
                      {t("Look for")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {home3.looking_for.map((item, index) => (
                        <LookFor
                          id={item.id}
                          key={item.id}
                          img={item.image}
                          type={item.category && item.category.title}
                          badge={item.user && item.user.badge}
                          isFav={item.isFav}
                          phone={item.user && item.user.phone}
                          age_range_from={item.age_range_to}
                          age_range_to={item.age_range_from}
                          price_range_from={item.price_range_from}
                          price_range_to={item.price_range_to}
                          weight={item.weight}
                          gender={item.gender}
                          address={item.address}
                          email={item.user && item.user.email}
                          name={item.user && item.user.f_name}
                          userImage={item.user && item.user.personal_picture}
                          userId={item.user && item.user.id}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
                {home3 && home3.deliveries && home3.deliveries.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.deliveries
                      }`}
                      number={infoCounts?.deliveries}
                    >
                      {" "}
                      {t("Delivery")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {home3 &&
                        home3.awa_deliveries.map((item) => (
                          <div key={item.id}>
                            <Delivery
                              id={item.id}
                              slug={item.slug}
                              rate={item.avg_rate_int}
                              className="h-[36.5rem]"
                              img={item.image}
                              location={item.address}
                              phone={item.user && item.user.phone}
                              email={item.user && item.user.email}
                              whatsapp={item.user && item.user.whatsapp}
                            />
                          </div>
                        ))}
                      {home3.deliveries.map((item, index) => (
                        <div key={item.id}>
                          <Delivery
                            id={item.id}
                            slug={item.slug}
                            rate={item.avg_rate_int}
                            className="h-[36.5rem]"
                            img={item.image}
                            location={item.address}
                            phone={item.user && item.user.phone}
                            email={item.user && item.user.email}
                            whatsapp={item.user && item.user.whatsapp}
                          />
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {banner && banner?.advertises?.tin.length > 0 && (
                  <section className="mt-12 ">
                    <Slider {...advertisesSlider}>
                      {banner?.advertises?.tin?.map((item) => (
                        <div key={item.id} className="p-4">
                          <div className="h-[20rem] relative overflow-hidden my-8 mx-auto">
                            <span className="absolute top-[1rem]  -left-[6rem]  bg-white px-24 py-4 -rotate-45 flex items-center justify-center flex-wrap">
                              <Rocket className="w-12 h-12 -rotate-45" />{" "}
                              {t("tin")}
                            </span>
                            {item.type === "video" ? (
                              <Player
                                height={200}
                                width={"100%"}
                                fluid={false}
                                playsInline
                                poster={item.thumbnail}
                                src={item.banner}
                              />
                            ) : (
                              <img
                                src={item.banner}
                                alt={item.title_ad}
                                className="h-full w-full object-cover mx-auto rounded-2xl"
                              />
                            )}{" "}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {home1 && home1.questions && home1.questions.length > 0 && (
                  <section className="mt-12">
                    <Questions
                      action={true}
                      question={true}
                      title={"Question"}
                      to={`public/${banner && banner.slugs.questions}`}
                      items={home1.questions}
                      number={infoCounts?.questions}
                    />
                  </section>
                )}
                {home3 && home3.other && home3.other.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${banner && banner.slugs.other}`}
                      number={infoCounts?.others}
                    >
                      {" "}
                      {t("Others")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {home3.other.map((item, index) => (
                        <div key={item.id}>
                          <Others
                            id={item.id}
                            img={item.image}
                            type={item.category && item.category.title}
                            badg={item.user && item.user.badge}
                            price={item.price}
                            name={item.user && item.user.f_name}
                            whatsapp={item.user && item.user.whatsapp}
                            location={
                              item.user && item.user.city && item.user.city?.name
                            }
                            userImage={item.user && item.user.personal_picture}
                            userId={item.user && item.user.id}
                            rate={item.user && item.user.avg_rate_int}
                            is_verified={item.user && item.user.is_verified}
                          />
                        </div>
                      ))}
                    </Slider>
                  </section>
                )}
                {home2 && home2.quarterings && home2.quarterings.length > 0 && (
                  <section className="mt-12">
                    <SectionTitle
                      to={`public/animals-type/${
                        banner && banner.slugs.quarterings
                      }`}
                      number={infoCounts?.quarterings}
                    >
                      {" "}
                      {t("Quarterings for animal")}{" "}
                    </SectionTitle>
                    <Slider {...productsSlider}>
                      {/* <Link to='/' className={`md:mx-2 flex items-start justify-center flex-col  relative overflow-hidden h-[37rem]`}>
                    <div className="w-11/12 mx-auto relative overflow-hidden h-full">
                      <img src={map} alt="" className='absolute top-0 h-full w-full object-cover rounded-2xl' />
                    </div>
                    <div className="data font-bold text-center flex items-center justify-center rounded-b-2xl w-11/12 bg-white absolute left-1/2 -translate-x-1/2 bottom-0 py-3">
                      View Map
                    </div>
                  </Link> */}
                      {home2.quarterings.map((item, index) => (
                        <AdoptionForAnimal
                          key={item.id}
                          id={item.id}
                          img={item.image}
                          slug={item.slug}
                          price={item.price}
                          isFav={item.isFav}
                          age={item.age}
                          duration={item.price_unit_text}
                          weight={item.weight}
                          gender={item.gender}
                          location={item.city && item.city.name}
                          locationAnimal={item.city && item.city.name}
                          email={item.user && item.user.email}
                          whatsapp={item.user && item.user.whatsapp}
                          name={item.user && item.user.f_name}
                          phone={item.phone}
                          userId={item.user && item.user.id}
                          badge={item.user && item.user.badge}
                          rate={item.user && item.user.avg_rate_int}
                          is_verified={item.user && item.user.is_verified}
                          userImage={item.user && item.user.personal_picture}
                        />
                      ))}
                    </Slider>
                  </section>
                )}
              </>
            )}
          </>
        )}
      </main>
    </Layout>
  );
};

export default Home;
