import React from 'react'
import Layout from '../Components/Layout'
import ErrorPages from '../assets/Icons/ErrorPage'
import PaymentErrorIcon from '../assets/Icons/PaymentErrorIcon'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

const PaymentCanceld = () => {
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0 min-h-[80vh] flex items-center justify-center">
                    <div className="flex items-center justify-center flex-wrap">
                         <PaymentErrorIcon color='#D10707' />
                         <div className="w-full flex items-center justify-center">
                              <Link to='/access-card' className='mt-12 bg-[#D10707] py-4 px-6 text-white rounded-lg'> {t('Back to home page')}</Link>
                         </div>
                    </div>
               </main>
          </Layout>
     )
}

export default PaymentCanceld