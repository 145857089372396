import { t } from 'i18next'
import { useEffect } from 'react';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import BlueButton from '../../UI/Buttons/BlueButton';

const Verification = (props) => {
     const [otp, setOtp] = useState()

     useEffect(() => {
          if (otp && otp.length === 4) {
               props.onVerifiction(otp)
          }
     }, [otp])

     return (
          <>

               <h3 className='w-full font-bold text-[3.4rem] Abril tracking-widest text-center'>{t('Enter 4 digit')}</h3>
               <p className='w-full font-light text-[1.5] mb-4 text-center'>{t('Check your email and enter the code.')}</p>
               <p className='w-full font-light text-[1.5] mb-4 text-center'>{t('Enter verification code')}</p>
               <form action="" className='mt-4 flex flex-wrap items-center justify-between'>
                    <div className="flex items-center justify-center gap-4 w-full">
                         <OtpInput
                              value={otp}
                              onChange={(e) => { setOtp(e); console.log(e) }}
                              numInputs={4}
                              className={`block justify-center rounded-lg border-2 border-gold text-center py-3 w-16  h-16 mx-1`}
                              focusStyle={{ display: 'block !important' }}
                              isInputNum={true}
                         />
                    </div>
                    <BlueButton onClick={() => props.onVerifiction(otp)} className='w-full text-[1.5rem] py-4 px-5 mt-8'>
                         {t('Submit')}
                    </BlueButton>
                    {/* <button className='bg-dark-gold w-full mt-4 text-[1.5rem] rounded-xl py-4 px-5  text-blue text-center'> {t('Resend')}</button> */}

               </form>
          </>
     )
}

export default Verification