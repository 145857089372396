import { t } from 'i18next'
import React from 'react'
import app from '../../assets/images/app.png'
import google from '../../assets/images/google.png'
import store from '../../assets/images/store.png'

const TopFooter = () => {
     return (
          <footer className='topfooter relative bg-blue mt-96 md:mt-72 py-4'>
               <div className="container mx-auto relative">
                    <div className="row flex items-center justify-center flex-wrap">
                         <div className="w-full md:w-1/6 relative">
                              <img src={app} alt="" className='absolute bottom-4 md:-bottom-[3.4rem] left-1/2 -translate-x-1/2 md:translate-x-0 md:left-0 z-20 w-96' />
                         </div>
                         <div className="w-full md:w-3/6">
                              <p className='text-white text-[1.5rem] lg:text-[2rem] font-semibold text-center capitalize'>{t('Get notifications from your favorite auctioneers')} </p>
                         </div>
                         <div className="w-full md:w-2/6">
                              <div className="flex items-center justify-center gap-4 mt-4">
                                   <img src={google} alt="" />
                                   <img src={store} alt="" />
                              </div>
                         </div>
                    </div>
               </div>
          </footer>
     )
}

export default TopFooter