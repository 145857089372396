import axios from 'axios'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Fridaymarket from '../../assets/Icons/Fridaymarket'
import LeftArrow from '../../assets/Icons/LeftArrow'
import MyAnimals from '../../assets/Icons/MyAnimals'
import MyRequest from '../../assets/Icons/MyRequest'
import Vip2 from '../../assets/Icons/Vip2'
import { config, url } from '../Helpers/Help'
import BlueButton from '../UI/Buttons/BlueButton'
import AnimalCard from '../UI/Cards/AnimalCard'
import RequestCard from '../UI/Cards/RequestCard'

const RegistrationMenu = (props) => {
     const [state, setState] = useState('Animals')
     const [my, setMy] = useState([])
     const [all, setAll] = useState([])
     useEffect(() => {
          axios(url + "animals/register/my", config).then((response) => {
               console.log(response.data.data);
               setMy(response.data.data);
          });
     }, []);
     useEffect(() => {
          axios(url + "animals/register/all", config).then((response) => {
               console.log(response.data.data);
               setAll(response.data.data);
          });
     }, []);

     return (
          <div className={`fixed shadow-xl pb-[6rem] md:pb-0 top-0 pt-16 md:pt-0 w-full md:w-[50rem] bg-[#F9F9F9] h-screen z-50 p-4 xl:p-8 overflow-y-scroll scroll-none ${props.className}`}>
               <h3 onClick={props.onClick} className='dir-ltr-ar cursor-pointer pb-4 font-bold text-[2.7rem] mt-8 mb-4 flex items-center justify-start gap-4'> <LeftArrow /> {t('Registration')} </h3>
               <ul className='text-[1.4rem] font-semibold flex items-center justify-around flex-wrap w-full mb-8'>
                    <li onClick={() => setState('Animals')} className={`cursor-pointer w-[48%] flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4 h-24 ${state === 'Animals' && 'border border-blue'}`}>
                         <MyAnimals /> {t('My Animals')}
                    </li>
                    <li onClick={() => setState('Request')} className={`cursor-pointer w-[48%] flex-wrap p-2 rounded-xl shadow-md md:p-4 my-2 bg-white relative flex items-center justify-start gap-4 h-24 ${state === 'Request' && 'border border-blue'}`}>
                         <MyRequest /> {t('My Request')}
                    </li>
               </ul>

               {
                    state === 'Animals' ?
                         <>
                              {
                                   my && my.length > 0 &&

                                   my.map(item => (
                                        <AnimalCard
                                             show_name={item.user?.show_name}
                                             color={item?.color}
                                             id={item?.id}
                                             end_at={item?.end_at}
                                             email={item.user?.email}
                                             category={item.category?.title}
                                             whatsapp={item.user?.whatsapp}
                                             phone={item.user?.phone}
                                             city={item.user?.city?.name}
                                             f_name={item.user?.f_name}
                                             l_name={item.user?.l_name}
                                             country={item.user?.country?.name}
                                             user={item.user?.personal_picture} qrCode={item.qr} key={item.id} img={item.image} type={item.category && item.sub_category.title} Birthday={item.date_of_birth} gender={item.gender} weight={item.weight} age={item.age} />

                                   ))
                              }
                         </>
                         :
                         <>
                              <Link to='/add-animal/animal-register' onClick={props.onClose}>
                                   <BlueButton className='w-full py-6 mb-6'>{t('Send Request')}</BlueButton>
                              </Link>

                              {
                                   all && all.length > 0 &&

                                   all.map(item => (
                                        <RequestCard reject_reason={item.reject_reason} created_at={item.created_at} status={item.status} qrCode={item.qr} key={item.id} img={item.image} type={item.category && item.sub_category.title} Birthday={item.date_of_birth} gender={item.gender} weight={item.weight} age={item.age} />

                                   ))
                              }
                         </>
               }

          </div>
     )
}

export default RegistrationMenu