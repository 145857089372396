import React from 'react'

const LineHeart = (props) => {
     return (
          <svg className={props.className} width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M14.8015 5.32033L14.9983 5.57172L15.1951 5.32033C16.7255 3.36588 18.5515 2.25 20.6233 2.25C24.2698 2.25 27.2483 5.24343 27.2483 8.91434C27.2483 10.1379 26.7899 11.3413 26.0275 12.5574C25.2647 13.7742 24.2084 14.9871 23.0345 16.2277C21.8637 17.4651 20.5564 18.7118 19.2501 19.942C19.0574 20.1235 18.8647 20.3047 18.6725 20.4854C17.5635 21.5279 16.4701 22.5558 15.4839 23.5476C15.3548 23.6774 15.1801 23.75 14.9983 23.75C14.8164 23.75 14.6417 23.6774 14.5127 23.5476C13.5265 22.5558 12.4331 21.5279 11.3241 20.4854C11.1319 20.3047 10.9392 20.1235 10.7465 19.942C9.44018 18.7118 8.13288 17.4651 6.96211 16.2277C5.78823 14.9871 4.73185 13.7742 3.96906 12.5574C3.20672 11.3413 2.74829 10.1379 2.74829 8.91434C2.74829 5.24343 5.72673 2.25 9.37329 2.25C11.445 2.25 13.2711 3.36588 14.8015 5.32033Z" fill={props.fill} stroke="#A8A8A8" strokeWidth="0.5" />
          </svg>

     )
}

export default LineHeart