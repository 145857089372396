import React from 'react'
import { Player } from 'video-react'
import PlaceholderImage from "../../..//assets/images/808080_.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BannerCard = (props) => {
     return (
          <div className="banner__card h-[20rem] md:h-[45rem] relative overflow-hidden my-4 md:my-0">
               {
                    props.type === 'image' ?
                         <LazyLoadImage width={300} height={300} src={props.img} alt="" className='h-full w-full object-cover brightness-95 hover:brightness-100 rounded-xl duration-700 transition-all' placeholdersrc={PlaceholderImage} effect="blur" />
                         :
                         <Player height={'100%'} width={'100%'} fluid={false} playsInline src={props.img} poster={props.poster} />

               }
               <div className="description absolute bottom-4 ltr:left-4 rtl:right-4 text-[1.6rem] font-semibold text-white textShadows">
                    {props.description} {props.id}
               </div>
          </div>
     )
}

export default BannerCard