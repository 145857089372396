import { t } from 'i18next'
import React from 'react'
import Button from '../../UI/Buttons/BlueButton'
import Input from '../../UI/Input'

const ForgotPassword = (props) => {

     return (
          <div className='mt-4 flex flex-wrap items-center justify-between'>
               <h3 className='w-full font-bold text-[3.4rem] Abril tracking-wide'>{t('Forgot Password')}</h3>
               <p className='w-full font-light text-[1.5] mb-4'>{t('Please enter your email and check it.')}</p>
               <Input onChange={(e) => props.onEmailChange(e.target.value)} type='email' placeholder={t('Your Email / Phone')} id='Email' label={t('Email address')} />
               <Button onClick={() => props.onForgotSubmit()} className={`w-full text-[1.5rem] p-5 ${props.isLoading && 'cursor-not-allowed'}`} disbled={props.isLoading} >
                    {t('Submit')}
               </Button>
               <p className='text-[1.5rem] font-light flex items-center justify-center gap-4 mt-8 text-center w-full'>{t('have account?')}
                    <button onClick={() => props.onBackToLogin()} className={`font-medium text-[#C99648]`}>{t('Log in')} </button>
               </p>
          </div>

     )
}

export default ForgotPassword