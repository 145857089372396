export const url = 'https://backend.awasell.co/api/v1/'


export const config = {
     headers: {
          'Accept': 'application/json',
          'lang': localStorage.getItem('awaLang') ? localStorage.getItem('awaLang') : 'en',
          "Authorization": `Bearer ${localStorage.__authtk__awasell_secure}`,
          'Content-Type': 'multipart/form-data',
     }
}
