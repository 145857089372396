import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import Fire from "../assets/Icons/Fire";
import Pomp from "../assets/Icons/Pomp";
import Rocket from "../assets/Icons/Rocket";
import { config, url } from "../Components/Helpers/Help";
import Layout from "../Components/Layout";
import AdvirtisingsForm from "../Components/Seactions/AdvirtisingsForm";
import BlueButton from "../Components/UI/Buttons/BlueButton";

const Advirtisings = () => {
  const [advirtisings, setAdvirtisings] = useState();
  useEffect(() => {
    axios(url + "advertising-page ", config).then((response) => {
      setAdvirtisings(response.data.data);
      console.log(response.data.data);
    });
  }, []);

  return (
    <Layout>
      <main className="container mx-auto pt-12 md:pt-36 lg:pt-28 px-4 md:px-0">
        <div className="w-full lg:w-8/12 mx-auto">
          {advirtisings && (
            <div className="row flex items-start justify-center flex-wrap">
              <div className="advertising-title banner relative overflow-hidden w-full rounded-3xl min-h-[40rem] mt-24">
                <div className=" overlay top-0 left-0 w-full h-full absolute bg-black/60 -z-[1]" />
                <img
                  src={advirtisings.banner}
                  width="500"
                  height="500"
                  className="absolute top-0 left-0 w-full h-full object-cover z-10 "
                  alt={advirtisings.banner_title}
                />
                <div className="absolute z-[2] text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 text-center w-10/12">
                  <h3 className="text-[4rem] ">{advirtisings.banner_title}</h3>
                  <p className="text-[1.8rem]">
                    {" "}
                    {advirtisings.banner_description}
                  </p>
                  <BlueButton className="mt-8 px-8 py-4 hover:bg-mint">
                    {advirtisings.button_text}
                  </BlueButton>
                </div>
              </div>

              <div className="data text-center relative overflow-hidden w-full rounded-3xl my-24">
                <h3 className="text-[3rem] font-bold">
                  {advirtisings.banner_title}
                </h3>
                <p className="text-[1.5rem]">
                  {advirtisings.banner_description}
                </p>
              </div>
              <div className="w-full">
                <div className="row flex items-start justify-center flex-wrap">
                  {advirtisings.budgets.map((item, index) => (
                    <div className="w-full md:w-1/2 lg:w-1/3 p-4" key={index}>
                      <div className="bg-white shadow flex items-start justify-center flex-wrap text-center p-4 md:p-8 rounded-3xl">
                        <img
                          src={item.logo && item.logo}
                          alt={item.title}
                          className="w-48"
                        />
                        <h3 className="font-bold w-full my-4">{item.title}</h3>
                        <p className="text-[1.5rem]">{item.description}</p>
                        {item.button_text && (
                          <BlueButton className="mt-8 px-8 py-4 hover:bg-mint">
                            {item.button_text}
                          </BlueButton>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="data text-center relative overflow-hidden w-full rounded-3xl my-24">
                <h3 className="text-[3rem] font-bold my-8">
                  {t("Why AWA Advertising?")}
                </h3>
                <p className="text-[1.5rem]">{advirtisings.why_advertising}</p>
              </div>

              <div className="w-full dir-ltr-ar">
                <div className="row flex items-start justify-center flex-wrap">
                  <div className="w-1/3 p-4">
                    <h3 className="text-6xl text-mint font-bold text-center">
                      {advirtisings.users_count}
                    </h3>
                    <p className="text-center font-bold">{t("Users")}</p>
                  </div>
                  <div className="w-1/3 p-4 border-r border-l border-mint">
                    <h3 className="text-6xl text-mint font-bold text-center">
                      {advirtisings.posts_count}
                    </h3>
                    <p className="text-center font-bold">{t("Posts")}</p>
                  </div>
                  <div className="w-1/3 p-4">
                    <h3 className="text-6xl text-mint font-bold text-center">
                      {advirtisings.auction_count}
                    </h3>
                    <p className="text-center font-bold">{t("Auction")}</p>
                  </div>
                </div>
              </div>

              {advirtisings.budgets && advirtisings.budgets.length > 0 && (
                <div className="w-full my-24">
                  <div className="row flex items-start justify-center flex-wrap">
                    <div className="w-full sm:w-4/5 md:w-2/3 xl:w-1/2 md:p-4">
                      <AdvirtisingsForm
                        option={advirtisings.budgets}
                        places={advirtisings.places}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default Advirtisings;
