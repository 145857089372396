import axios from 'axios';
import { t } from 'i18next'
import React from 'react'
import { useState, useEffect } from 'react';
import { config, url } from '../Components/Helpers/Help';
import Layout from '../Components/Layout'
import UserCard from '../Components/UI/AnimalCard/UserCard';
import SectionTitle from '../Components/UI/Titles/SectionTitle';
import NotItems from '../Components/UI/NotItems';
import Vip from '../assets/Icons/Vip';
import VipAuction from '../Components/UI/AnimalCard/VipAuction';
import Hour24 from '../assets/Icons/Hour24';
import SalesCard from '../Components/UI/AnimalCard/SalesCard';
import Others from '../Components/UI/AnimalCard/Others';
import QuestionsCard from '../Components/UI/Cards/Questions-card';
import Delivery from '../Components/UI/AnimalCard/Delivery';
import LookFor from './Public/LookFor';
import VeterinaryClinic from '../Components/UI/AnimalCard/VeterinaryClinic';
import LostAnimal from './Public/LostAnimal';
import Photographer from '../Components/UI/AnimalCard/Photographer';
import Mating from './Public/Mating';
import AdoptionForAnimal from '../Components/UI/AnimalCard/AdoptionForAnimal';
import SpecialOffer from '../Components/UI/AnimalCard/SpecialOffer';
import VideoCard from '../Components/UI/Cards/Video-card';
import TodayMarket from '../Components/UI/AnimalCard/TodayMarket';
import WholesaleMarket from '../Components/UI/AnimalCard/WholesaleMarket';
import FridayMarket from '../Components/UI/AnimalCard/FridayMarket';
import BlueButton from '../Components/UI/Buttons/BlueButton';
import { productsSlider } from '../Components/Helpers/SlidersOptions';
import errorImage from '../assets/images/error.jpg'
import PreLoader from '../Components/UI/PreLoader';

const FrindesAds = () => {
     const [ads, setAds] = useState();
     const [id, setId] = useState()
     const [modal, setModal] = useState()
     const [answaerMessage, setAnswaerMessage] = useState()
     const [isLoading, setIsLoading] = useState(false);
     const [serverError, setServerError] = useState(false);

     useEffect(() => {
          setIsLoading(true)
          axios(url + `users/friends-ads`, config).then((response) => {
               console.log(response.data.data.section)
               setAds(response.data.data.section)
               setIsLoading(false)
          }).catch((error) => {
               console.log(error);
               setIsLoading(false)
               if (error && error.response.status === 500) {
                    setServerError(true)
               } else {
                    setServerError(false)
               }
          });
     }, []);
     // Submit my answer
     const submitAnswer = (e) => {
          e.preventDefault()
          axios.post(url + 'questions/answer',
               //  Data
               {
                    // I mean with modal => id
                    question_id: id,
                    content: answaerMessage,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setModal(false)
                    setAnswaerMessage()
                    alert({
                         title: `${t('Done')}`,
                         text: `${t('Thanks for sharing your answer')}`,
                         icon: 'success',
                         timer: 2000
                    })

               })
               // Check Error
               .catch(error => {
                    console.log(error.response)
                    alert({
                         title: `${t('Error')}`,
                         text: error.response.data.message,
                         icon: 'error',
                         timer: 2000
                    })
               })
     }
     return (
          <Layout>
               <main className="container mx-auto pt-36 md:pt-28 px-4 md:px-0">
                    {modal &&
                         <>
                              <div className="overlay fixed right-0 top-0 bg-black/40 h-screen w-screen z-40" onClick={() => setModal(false)} />
                              <div className={`fixed shadow-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[50rem] bg-[#F9F9F9] rounded-2xl z-50 p-4 xl:p-8 overflow-y-scroll scroll-none `}>
                                   <button className='w-12 h-12 border-2 font-bold text-[2rem] flex items-center justify-center rounded-full absolute right-8 top-4 border-gray-600' onClick={() => setModal(false)}>x</button>
                                   <div className="flex flex-col justify-center items-center">
                                        <h3 className='font-bold text-[1.8rem] mb-8'>{t('Your Answer')}</h3>
                                        <textarea name="" id="" cols="30" rows="7" className='w-full mb-8 rounded-xl resize-none p-4' onChange={(e) => setAnswaerMessage(e.target.value)}></textarea>
                                        <BlueButton onClick={submitAnswer} className='py-6 px-8 xl:px-28'>{t('Submit')}</BlueButton>
                                   </div>
                              </div>
                         </>

                    }

                    <div className="row flex items-start justify-center flex-wrap min-h-[40vh]">
                         <SectionTitle className='w-full mt-12 justify-center capitalize flex'>
                              {t('Frindes Ads')}
                         </SectionTitle>
                         {serverError ?
                              <img src={errorImage} alt="server error" className="w-[50rem] h-[50rem] xl:mt-48 block mx-auto mix-blend-multiply object-contain" />
                              :
                              <>
                                   {
                                        isLoading ?
                                             <PreLoader />
                                             :
                                             <>
                                                  {ads && (
                                                       <>


                                                            {ads.auction_vip && ads.auction_vip.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           <Vip /> {t('Upcoming VIP Auction')}
                                                                      </SectionTitle>
                                                                      {ads.auction_vip.map((item, index) => (
                                                                           <div className="w-1/2 md:w-1/2 xl:w-1/3 my-3" key={item.id}>
                                                                                <VipAuction
                                                                                     is_notify={item.is_notify}
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     isMine={item.is_mine}
                                                                                     slug={item.slug}
                                                                                     img={item.image}
                                                                                     end_minutes={item.end_minutes}
                                                                                     start={item.start_time}
                                                                                     type={item.category && item.category.title}
                                                                                     max_price={item.max_price}
                                                                                     gender={item.gender}
                                                                                     age={item.age}
                                                                                     users_count={item.users_count}
                                                                                     weight={item.weight}
                                                                                     isFav={item.isFav}
                                                                                     inMarket={item.in_market && item.in_market_till !== 0}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.auction_quick && ads.auction_quick.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           <Hour24 /> {t('Upcoming Quick Auction')}
                                                                      </SectionTitle>
                                                                      {ads.auction_quick.map((item, index) => (
                                                                           <div className="w-1/2 md:w-1/2 xl:w-1/3 my-3" key={item.id}>
                                                                                <VipAuction
                                                                                     is_notify={item.is_notify}
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     isMine={item.is_mine}
                                                                                     slug={item.slug}
                                                                                     img={item.image}
                                                                                     end_minutes={item.end_minutes}
                                                                                     start={item.start_time}
                                                                                     type={item.category && item.category.title}
                                                                                     max_price={item.max_price}
                                                                                     gender={item.gender}
                                                                                     age={item.age}
                                                                                     users_count={item.users_count}
                                                                                     weight={item.weight}
                                                                                     isFav={item.isFav}
                                                                                     inMarket={item.in_market && item.in_market_till !== 0}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.friday_market && ads.friday_market.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Friday Market')} </SectionTitle>
                                                                      {ads.friday_market.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 2xl:w-1/4 my-3" key={item.id}>
                                                                                <FridayMarket
                                                                                     slug={item.slug}
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badge={item.user && item.user.badge}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     action={item.action}
                                                                                     price={item.price}
                                                                                     location={item.city && item.city.name}
                                                                                     duration={item.duration}
                                                                                     inMarket={item.inMarket}
                                                                                     whatsapp={(item.user && item.whatsapp) && item.user.whatsapp}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.wholesale && ads.wholesale.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Wholesale Market')} </SectionTitle>
                                                                      {ads.wholesale.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                                <WholesaleMarket
                                                                                     slug={item.slug}
                                                                                     id={item.id}
                                                                                     key={item.id}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badge={item.user && item.user.badge}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     action={item.action}
                                                                                     price={item.price}
                                                                                     location={item.city && item.city.name}
                                                                                     description={item.note}
                                                                                     inMarket={item.inMarket}
                                                                                     whatsapp={(item.user && item.whatsapp) && item.user.whatsapp}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.today_market && ads.today_market.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Today Market')}
                                                                      </SectionTitle>
                                                                      {ads.today_market.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" key={item.id}>
                                                                                <TodayMarket
                                                                                     key={item.id}
                                                                                     slug={item.slug}
                                                                                     id={item.id}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badge={item.user && item.user.badge}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     location={item.city && item.city.name}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.videos && ads.videos.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Videos')} </SectionTitle>
                                                                      {
                                                                           ads.videos.map((item, index) => (
                                                                                <div className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 my-3" key={index}>
                                                                                     <VideoCard  views_count={item.views_count} 
                                                                                          slug={item.slug}
                                                                                          key={item.id}
                                                                                          id={item.id}
                                                                                          likes_count={item.likes_count}
                                                                                          comment_count={item.comment_count}
                                                                                          description={item.content}
                                                                                          poster={item.thumbnail}
                                                                                          userImage={item.user && item.user.personal_picture}
                                                                                          name={(item.user && item.user.f_name) && item.user.f_name}
                                                                                          video={item.file}
                                                                                          badge={item.user && item.user.badge}
                                                                                          userId={item.user && item.user.id}
                                                                                          rate={item.user && item.user.avg_rate_int}
                                                                                          is_verified={item.user && item.user.is_verified}
                                                                                          location={(item.user && item.user.city) && item.user.city.name} />
                                                                                </div>
                                                                           ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.offers && ads.offers.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Special Offers')} </SectionTitle>
                                                                      {ads.offers.map((item, index) => (
                                                                           <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 2xl:w-1/5 my-3" key={item.id}>
                                                                                <SpecialOffer
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     slug={item.slug}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.badg}
                                                                                     price={item.offer_price}
                                                                                     oldPrice={+item.price > +item.offer_price && item.price}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     location={item.city && item.city.name}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.adoption && ads.adoption.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Adoption for animal')} </SectionTitle>
                                                                      {ads.adoption.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <AdoptionForAnimal
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     img={item.image}
                                                                                     slug={item.slug}
                                                                                     badg={item.badg}
                                                                                     price={item.price}
                                                                                     isFav={item.isFav}
                                                                                     age={item.age}
                                                                                     duration={item.price_unit_text}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     location={item.city && item.city.name}
                                                                                     locationAnimal={item.city && item.city.name}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     phone={item.phone}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.mating && ads.mating.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Mating')} </SectionTitle>
                                                                      {ads.mating.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <Mating
                                                                                     id={item.id}
                                                                                     key={item.id}
                                                                                     slug={item.slug}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.badg}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     location={item.city && item.city.name}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.photographer && ads.photographer.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Photographer')} </SectionTitle>
                                                                      {ads.photographer.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <Photographer
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     img={item.image}
                                                                                     slug={item.slug}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.user && item.user.badge}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     // location={item.user && item.user.city.name}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.lost_animal && ads.lost_animal.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Lost Animal')} </SectionTitle>
                                                                      {
                                                                           ads.lost_animal.map((item, index) => (
                                                                                <div className="w-full md:w-1/2 lg:w-1/3 my-3" key={item.id}>
                                                                                     <LostAnimal
                                                                                          id={item.id}
                                                                                          type={item.category && item.category.title}
                                                                                          img={item.image}
                                                                                          loastDate={item.lost_date}
                                                                                          // location={item.user && item.user.city.name}
                                                                                          badge={item.user && item.user.badge}
                                                                                          name={item.user && item.user.f_name}
                                                                                          userImage={item.user && item.user.personal_picture}
                                                                                          userId={item.user && item.user.id}
                                                                                          rate={item.user && item.user.avg_rate_int}
                                                                                          is_verified={item.user && item.user.is_verified}
                                                                                     />
                                                                                </div>
                                                                           ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.clinic && ads.clinic.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Veterinary Clinic')} </SectionTitle>

                                                                      {ads.clinic.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <VeterinaryClinic
                                                                                     id={item.id}
                                                                                     slug={item.slug}
                                                                                     img={item.image}
                                                                                     title={item.title}
                                                                                     badge={item.user && item.user.badge}
                                                                                     location={item.address}
                                                                                     name={item.user && item.user.f_name}
                                                                                     phone={item.user && item.user.phone}
                                                                                     email={item.user && item.user.email}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.looking_for && ads.looking_for.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Look for')} </SectionTitle>
                                                                      {
                                                                           ads.looking_for.map((item, index) => (
                                                                                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                     <LookFor
                                                                                          id={item.id}
                                                                                          key={item.id}
                                                                                          img={item.image}
                                                                                          type={item.category && item.category.title}
                                                                                          badge={item.user && item.user.badge}
                                                                                          isFav={item.isFav}
                                                                                          phone={item.user && item.user.phone}
                                                                                          age_range_from={item.age_range_to}
                                                                                          age_range_to={item.age_range_from}
                                                                                          price_range_from={item.price_range_from}
                                                                                          price_range_to={item.price_range_to}
                                                                                          weight={item.weight}
                                                                                          gender={item.gender}
                                                                                          address={item.address}
                                                                                          email={item.user && item.user.email}
                                                                                          name={item.user && item.user.f_name}
                                                                                          userImage={item.user && item.user.personal_picture}
                                                                                          userId={item.user && item.user.id}
                                                                                          rate={item.user && item.user.avg_rate_int}
                                                                                          is_verified={item.user && item.user.is_verified}
                                                                                     />
                                                                                </div>
                                                                           ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.clinic_delivery && ads.clinic_delivery.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Delivery')} </SectionTitle>
                                                                      {ads.clinic_delivery.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <Delivery
                                                                                     id={item.id}
                                                                                     slug={item.slug}
                                                                                     rate={item.avg_rate_int}
                                                                                     className="h-[36.5rem]"
                                                                                     img={item.image}
                                                                                     location={item.address}
                                                                                     phone={item.user && item.user.phone}
                                                                                     email={item.user && item.user.email}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }

                                                                 </section>
                                                            )}
                                                            {ads.questions && ads.questions.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Questions')} </SectionTitle>
                                                                      {
                                                                           ads.questions.map((item, index) => (
                                                                                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                     <QuestionsCard
                                                                                          name={item.user && item.user.f_name}
                                                                                          userImage={item.user && item.user.personal_picture}
                                                                                          location={item.user && item.user.city.name}
                                                                                          badge={item.user && item.user.badge}
                                                                                          tags={item.tags}
                                                                                          description={item.content}
                                                                                          hashtags={item.hashtags}
                                                                                          id={item.id}
                                                                                          slug={item.slug}
                                                                                          openModal={() => { setId(item.id); setModal(true) }}
                                                                                          userId={item.user && item.user.id}
                                                                                          rate={item.user && item.user.avg_rate_int}
                                                                                          is_verified={item.user && item.user.is_verified}
                                                                                     />
                                                                                </div>
                                                                           ))
                                                                      }
                                                                 </section>
                                                            )}
                                                            {ads.other && ads.other.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('Others')} </SectionTitle>
                                                                      {ads.other.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <Others
                                                                                     id={item.id}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.user && item.user.badge}
                                                                                     price={item.price}
                                                                                     name={item.user && item.user.f_name}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                     location={item.user && item.user.city && item.user.city.name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}

                                                            {ads.sale_page && ads.sale_page.length > 0 && (
                                                                 <section className="mt-12">
                                                                      <SectionTitle   > {t('Sale Page')} </SectionTitle>
                                                                      {ads.sale_page.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <SalesCard
                                                                                     slug={item.slug}
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     price={item.price}
                                                                                     max_price={item.max_price}
                                                                                     users_count={item.users_count}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.user && item.user.is_vip}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     action={item.action}
                                                                                     location={item.city && item.city.name}
                                                                                     duration={item.duration}
                                                                                     inMarket={item.inMarket}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}

                                                            {ads.registered_animal && ads.registered_animal.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'> {t('Registered Animal')} </SectionTitle>
                                                                      {ads.registered_animal.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <SalesCard
                                                                                     slug={item.slug}
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     price={item.price}
                                                                                     max_price={item.max_price}
                                                                                     users_count={item.users_count}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.user && item.user.is_vip}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     action={item.action}
                                                                                     location={item.city && item.city.name}
                                                                                     duration={item.duration}
                                                                                     inMarket={item.inMarket}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}



                                                            {ads.sales_pages && ads.sales_pages.length > 0 && (
                                                                 <section className="w-full row flex items-start justify-start flex-wrap mt-12">
                                                                      <SectionTitle className='w-full'>
                                                                           {t('sales pages')} </SectionTitle>
                                                                      {ads.sales_pages.map((item, index) => (
                                                                           <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-2" key={item.id}>
                                                                                <SalesCard
                                                                                     slug={item.slug}
                                                                                     key={item.id}
                                                                                     id={item.id}
                                                                                     price={item.price}
                                                                                     max_price={item.max_price}
                                                                                     users_count={item.users_count}
                                                                                     img={item.image}
                                                                                     type={item.category && item.category.title}
                                                                                     badg={item.user && item.user.is_vip}
                                                                                     isFav={item.isFav}
                                                                                     phone={item.user && item.user.phone}
                                                                                     age={item.age}
                                                                                     weight={item.weight}
                                                                                     gender={item.gender}
                                                                                     action={item.action}
                                                                                     location={item.city && item.city.name}
                                                                                     duration={item.duration}
                                                                                     inMarket={item.inMarket}
                                                                                     whatsapp={item.user && item.user.whatsapp}
                                                                                     email={item.user && item.user.email}
                                                                                     name={item.user && item.user.f_name}
                                                                                     userImage={item.user && item.user.personal_picture}
                                                                                     userId={item.user && item.user.id}
                                                                                     rate={item.user && item.user.avg_rate_int}
                                                                                     is_verified={item.user && item.user.is_verified}
                                                                                />
                                                                           </div>
                                                                      ))
                                                                      }
                                                                 </section>
                                                            )}
                                                       </>
                                                  )}
                                             </>
                                   }
                              </>
                         }

                    </div>
               </main>
          </Layout >

     )
}

export default FrindesAds