import React from 'react'
import { Player } from 'video-react';
import Vip from '../../../assets/Icons/Vip';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

const AdCard = (props) => {
     const { img, content, type, vip } = props

     return (
          <div className='rounded-xl relative overflow-hidden h-[15rem] md:h-[20rem] rtl:mr-2 ltr:ml-2 ltr:text-left rtl:text-right dir-rtl'>
               {vip === 'yes' &&
                    <div className="absolute top-0 rtl:right-4 ltr:left-4 z-10">
                         <Vip />
                    </div>
               }
               {
                    type === 'image' ?
                         <LazyLoadImage effect="blur" width={300} height={300} src={img} alt={content} className='min-w-full h-full object-cover brightness-75 hover:brightness-100 duration-700 transition-all' />
                         :
                         <Player height={'100%'} width={'100%'} fluid={false} playsInline src={img} />

               }
               {content &&
                    <div className="description absolute bottom-2 rtl:right-2 ltr:left-2 text-[1.6rem] font-semibold text-white textShadows">
                         {content}
                    </div>}
          </div>
     )
}

export default AdCard