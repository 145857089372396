export const userImage = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU'

export const CategoriesData = [
     { value: '', label: 'Categories' },
     { value: 'Action', label: 'Action' },
     { value: 'Market', label: 'Market' },
     { value: 'Daily Announce', label: 'Daily Announce' },
     { value: 'Videos', label: 'Videos' },
     { value: 'Special offer', label: 'Special offer' },
]

export const LocationData = [
     { value: '', label: 'Location' },
     { value: 'Dubai', label: 'Dubai' },
     { value: 'Sharjah', label: 'Sharjah' },
     { value: 'Ajman', label: 'Ajman' },
     { value: 'Al Ain', label: 'Al Ain' },
     { value: 'Abu Dhabi', label: 'Abu Dhabi' },
]

export const TypeData = [
     { value: '', label: 'Type' },
     { value: 'Quadrupeds', label: 'Quadrupeds' },
     { value: 'Fish', label: 'Fish' },
     { value: 'Bird', label: 'Bird' },
     { value: 'Rodents', label: 'Rodents' },
     { value: 'Reptiles', label: 'Reptiles' },
     { value: 'Insects', label: 'Insects' },
]

export const SortbyData = [
     { value: '', label: 'Sort By' },
     { value: 'Descending', label: 'Descending' },
     { value: 'Ascending ', label: 'Ascending ' },
     { value: 'Price high-low', label: 'Price high-low' },
     { value: 'Price low-high', label: 'Price low-high' },
]

export const adsData = [
     {
          id: 'a1',
          img: 'https://images.unsplash.com/photo-1515442304705-de3793c6cce0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGFuaW1hbCUyMGZhY2V8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a2',
          img: 'https://images.unsplash.com/photo-1518560695903-3179288251d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGFuaW1hbCUyMGZhY2V8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a3',
          img: 'https://images.unsplash.com/photo-1514888286974-6c03e2ca1dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGFuaW1hbCUyMGZhY2V8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a4',
          img: 'https://images.unsplash.com/photo-1522266542854-7aa6478c8643?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGFuaW1hbCUyMGZhY2V8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a5',
          img: 'https://images.unsplash.com/photo-1553054366-ebbf4296eb32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGFuaW1hbCUyMGZhY2V8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a6',
          img: 'https://images.unsplash.com/photo-1530300256-6756c6fe1b6c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YW5pbWFsJTIwZmFjZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a7',
          img: 'https://images.unsplash.com/photo-1502518667998-67891a206ee6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YW5pbWFsJTIwZmFjZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a8',
          img: 'https://images.unsplash.com/photo-1601247387431-7966d811f30b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YW5pbWFsJTIwZmFjZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a9',
          img: 'https://images.unsplash.com/photo-1485735662814-c4f66e49dbae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YW5pbWFsJTIwZmFjZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a11',
          img: 'https://images.unsplash.com/photo-1500373994708-4d781bd7bd15?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YW5pbWFsJTIwZmFjZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a12',
          img: 'https://images.unsplash.com/photo-1533748539407-cae4ed7f9260?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW5pbWFsJTIwZmFjZXxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
]

export const cardBannerData = [
     {
          id: 'a1',
          img: 'https://images.unsplash.com/photo-1553284965-83fd3e82fa5a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9yc2V8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a2',
          img: 'https://images.unsplash.com/photo-1576201836106-db1758fd1c97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZG9nfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
]

export const cardBannerData2 = [
     {
          id: 'a1',
          img: 'https://images.unsplash.com/photo-1437622368342-7a3d73a34c8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YW5pbWFsc3xlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a2',
          img: 'https://images.unsplash.com/photo-1597618747144-8dc42d22a778?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGNhbWVsfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
]

export const cardBannerData3 = [
     {
          id: 'a1',
          img: 'https://images.unsplash.com/photo-1532362091753-d53721a3e82c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHNoZWVwfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a2',
          img: 'https://images.unsplash.com/photo-1581888227599-779811939961?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGRvZ3xlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
]

export const cardBannerData4 = [
     {
          id: 'a1',
          img: 'https://images.unsplash.com/photo-1516749744210-1981409bd921?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGdpcmFmZmV8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
     {
          id: 'a2',
          img: 'https://images.unsplash.com/photo-1452857297128-d9c29adba80b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fGN1dGV8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
     },
]

export const vipAuctionData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/turtle-closeup-with-school-of-fish-picture-id1170804921?k=20&m=1170804921&s=612x612&w=0&h=W4zAOSr9DzJvJO-uib7GiwZ9kaleupO_LspfXvOT0HE=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: false,
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/closeup-of-female-arctic-fox-with-a-cute-little-cub-in-the-meadow-picture-id1026123076?b=1&k=20&m=1026123076&s=170667a&w=0&h=qaxR7pt1exGQBJ3bTjrTmDpTNuWvTXU45CAvp2zw5ZI=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: true,
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/arctic-fox-picture-id622048192?b=1&k=20&m=622048192&s=170667a&w=0&h=kYkTEjxUGjasMVkuKsvwL1u1t0uZzTaLpeOghQ5B9YI=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: false,
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/thoroughbred-wild-horse-running-free-in-the-field-picture-id1305910583?k=20&m=1305910583&s=612x612&w=0&h=3lhYoXmg_CQgkHt_bI9zuEhqvmPqYEgp86FUoAJTRX4=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: false,
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/pack-of-horses-picture-id145245304?k=20&m=145245304&s=612x612&w=0&h=YzrLwRtvxP-dwhBJ-54bPV243KVEvuQ25bA_ZK9wzE8=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: true,
     },
]

export const vipQuickAuctionData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
     },
]

export const productsData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'verify',
          inMarket: true
     },
]

export const MarketData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          price: '80,000 AED',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'verify',
          description: 'Lorem ipsum dolor amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          price: '10,000 AED',
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'not-verify',
          description: 'Lorem ipsum dolor amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          price: '3,000 AED',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'verify',
          description: 'Lorem ipsum dolor amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          price: '5,000 AED',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'not-verify',
          description: 'Lorem ipsum dolor amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          price: '50,000 AED',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'not-verify',
          description: 'Lorem ipsum dolor amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          price: '10,000 AED',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          badg: 'verify',
          description: 'Lorem ipsum dolor amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet',
     },
]

export const TodayMarketData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          type: 'Animals',
          tags: ['1 year', '3.5 KG', 'Black'],
          phone: '050000000',
          email: 'aa@gmail.com',
          gender: 'male',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          type: 'Animals',
          tags: ['4 years', '1 KG', 'Green'],
          phone: '050000000',
          email: 'aa@gmail.com',
          gender: 'female',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          type: 'Birds',
          tags: ['2 years', '3 KG', 'Black'],
          phone: '050000000',
          email: 'aa@gmail.com',
          gender: 'male',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          type: 'Animals',
          tags: ['2 years', '2 KG', 'White'],
          phone: '050000000',
          email: 'aa@gmail.com',
          gender: 'female',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          type: 'Birds',
          tags: ['2 Months', '3 KG', 'Blue'],
          phone: '050000000',
          email: 'aa@gmail.com',
          gender: 'male',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          type: 'Animals',
          tags: ['7 Months', '5 KG', 'Black'],
          phone: '050000000',
          email: 'aa@gmail.com',
          gender: 'female',
     },
]

export const videosData = [
     {
          id: 'a1',
          poster: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed ',
          location: 'Abu Dhabi',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a2',
          poster: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali ',
          location: 'Al Ain',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a3',
          poster: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr ',
          location: 'Dubai',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a4',
          poster: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel ',
          location: 'Ajman',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a5',
          poster: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan ',
          location: 'Sharjah',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a6',
          poster: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah ',
          location: 'Dubai',
          description: 'Lorem ipsum dolor amet',
     },
]

export const OffersData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
]

export const AdoptionData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          price: '80 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          price: '10 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          price: '3 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'hour',
          locationAnimal: 'Deira'
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          price: '5 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          price: '50 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          price: '10 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
]

export const photographerData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          badg: 'verify',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          badg: 'verify',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          badg: 'verify',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
]

export const lostData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/turtle-closeup-with-school-of-fish-picture-id1170804921?k=20&m=1170804921&s=612x612&w=0&h=W4zAOSr9DzJvJO-uib7GiwZ9kaleupO_LspfXvOT0HE=',
          location: 'Dubai - Deira',
          loastDate: '12/02/2022',
          type: 'Animals',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/closeup-of-female-arctic-fox-with-a-cute-little-cub-in-the-meadow-picture-id1026123076?b=1&k=20&m=1026123076&s=170667a&w=0&h=qaxR7pt1exGQBJ3bTjrTmDpTNuWvTXU45CAvp2zw5ZI=',
          location: 'Dubai - Business bay',
          loastDate: '08/02/2021',
          type: 'Animals',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/arctic-fox-picture-id622048192?b=1&k=20&m=622048192&s=170667a&w=0&h=kYkTEjxUGjasMVkuKsvwL1u1t0uZzTaLpeOghQ5B9YI=',
          location: 'Dubai - sport city',
          loastDate: '12/08/2021',
          type: 'Birds',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/thoroughbred-wild-horse-running-free-in-the-field-picture-id1305910583?k=20&m=1305910583&s=612x612&w=0&h=3lhYoXmg_CQgkHt_bI9zuEhqvmPqYEgp86FUoAJTRX4=',
          location: 'Dubai - Deira',
          loastDate: '06/05/2022',
          type: 'Animals',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          location: 'Dubai - sport city',
          loastDate: '12/02/2022',
          type: 'Birds',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/pack-of-horses-picture-id145245304?k=20&m=145245304&s=612x612&w=0&h=YzrLwRtvxP-dwhBJ-54bPV243KVEvuQ25bA_ZK9wzE8=',
          location: 'Dubai - Business bay',
          loastDate: '01/10/2022',
          type: 'Animals',
     },
]

export const clinicData = [
     {
          id: 'a1',
          img: 'https://plus.unsplash.com/premium_photo-1663133417924-e2d1fca514b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dmV0ZXJpbmFyeSUyMGNsaW5pY3xlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          title: 'This is pegion',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a2',
          img: 'https://images.unsplash.com/photo-1553688738-a278b9f063e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dmV0ZXJpbmFyeSUyMGNsaW5pY3xlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          title: 'This is dog',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a3',
          img: 'https://images.unsplash.com/photo-1580281780460-82d277b0e3f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHZldGVyaW5hcnklMjBjbGluaWN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          title: 'This is bird',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a4',
          img: 'https://images.unsplash.com/photo-1644675272883-0c4d582528d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fHZldGVyaW5hcnklMjBjbGluaWN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          title: 'This is  animal',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a5',
          img: 'https://images.unsplash.com/photo-1583911026798-907ec9f2241b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fHZldGVyaW5hcnklMjBjbGluaWN8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          title: 'This is ',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a6',
          img: 'https://plus.unsplash.com/premium_photo-1661963606290-34deef08c3c2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGFuaW1hbCUyMGNsaW5pY3xlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
          title: 'This is monkey',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
]

export const lookForData = [
     {
          id: 'a1',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['1-2 year'],
          name: 'Ahmed',
          location: 'Abu Dhabi',
          averagePrice: '600-1500 AED',
          badg: 'verify',
     },
     {
          id: 'a2',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2 year'],
          name: 'Ali',
          location: 'Abu Dhabi',
          averagePrice: '200-1000 AED',
          badg: 'verify',
     },
     {
          id: 'a3',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Birds',
          gender: 'male',
          tags: ['1 year'],
          name: 'Amr Ata',
          location: 'Abu Dhabi',
          averagePrice: '100-1100 AED',
          badg: 'verify',
     },
     {
          id: 'a4',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-4 year'],
          name: 'Adel',
          location: 'Abu Dhabi',
          averagePrice: '900-1200 AED',
          badg: 'verify',
     },
     {
          id: 'a5',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Birds',
          gender: 'male',
          tags: ['1-4 year'],
          name: 'Hassan Akram',
          location: 'Abu Dhabi',
          averagePrice: '100-1300 AED',
          badg: 'verify',
     },
     {
          id: 'a6',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-3 year'],
          name: 'Abdallah Atia',
          location: 'Abu Dhabi',
          averagePrice: '900-1500 AED',
          badg: 'verify',
     },
]

export const deliveryData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/beautiful-woman-doing-high-five-her-adorable-golden-retriever-dog-at-picture-id1204274923?k=20&m=1204274923&s=612x612&w=0&h=Ui8B5hTJ0ccS6MQw2nD_npjZYEVpEobkmjmjEoYzgNQ=',
          title: 'This is pegion',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/close-up-snowy-owl-eye-making-shock-or-funny-face-expression-with-picture-id1270992326?k=20&m=1270992326&s=612x612&w=0&h=OR1-0nUgTl9YzFQAzhTJnuvea5D1VHYunI2g3Y-gYvI=',
          title: 'This is dog',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/friends-red-cat-and-corgi-dog-walking-in-a-summer-meadow-under-the-picture-id1324099927?k=20&m=1324099927&s=612x612&w=0&h=fOqMXWIQkmrvrEUbIWJak6Ht-FJjmJdDgs4cKnbNuA0=',
          title: 'This is bird',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/barbary-macaque-picture-id824860820?k=20&m=824860820&s=612x612&w=0&h=W8783ZGcqMfDMJoXvBAyDFcSjnOWdKqKhgLGvf-VIuU=',
          title: 'This is  animal',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/grey-squirrel-yawning-picture-id473012660?k=20&m=473012660&s=612x612&w=0&h=9b1M5V6mc8RfGOocR1oE7ga2wUn5vIPDUqUY2Sa2wpc=',
          title: 'This is ',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/funny-burrowing-owl-athene-cunicularia-picture-id964611070?k=20&m=964611070&s=612x612&w=0&h=eVTyTsgISmn4Zll4W_j5NpSGLLqsCGUjovq4_60i_lQ=',
          title: 'This is monkey',
          whatsapp: '+971000000000',
          locationAnimal: 'Deira'
     },
]


export const questionData = [
     {
          id: 'a1',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          description: 'Lorem ipsum dolor  amet, consectetur adipiscing elit. Arcu gravida sed consectetur nibh nec eu vestibulum, adipiscing.',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
     },
     {
          id: 'a2',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          description: 'Lorem ipsum dolor  amet, consectetur adipiscing elit. Arcu gravida sed consectetur nibh nec eu vestibulum, adipiscing.',
          name: 'Ali',
          location: 'Abu Dhabi',
          badg: 'verify',
     },
     {
          id: 'a3',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          description: 'Lorem ipsum dolor  amet, consectetur adipiscing elit. Arcu gravida sed consectetur nibh nec eu vestibulum, adipiscing.',
          name: 'Amr Ata',
          location: 'Abu Dhabi',
          badg: 'verify',
     },
     {
          id: 'a4',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          description: 'Lorem ipsum dolor  amet, consectetur adipiscing elit. Arcu gravida sed consectetur nibh nec eu vestibulum, adipiscing.',
          name: 'Adel',
          location: 'Abu Dhabi',
          badg: 'verify',
     },
     {
          id: 'a5',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          description: 'Lorem ipsum dolor  amet, consectetur adipiscing elit. Arcu gravida sed consectetur nibh nec eu vestibulum, adipiscing.',
          name: 'Hassan Akram',
          location: 'Abu Dhabi',
          badg: 'verify',
     },
     {
          id: 'a6',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          description: 'Lorem ipsum dolor  amet, consectetur adipiscing elit. Arcu gravida sed consectetur nibh nec eu vestibulum, adipiscing.',
          name: 'Abdallah Atia',
          location: 'Abu Dhabi',
          badg: 'verify',
     },
]

export const othersData = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
]

export const liveAuctions = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          type: 'Animals',
          isFav: true,
          duration: 9,
          lastBid: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          type: 'Animals',
          isFav: false,
          duration: 7,
          lastBid: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          type: 'Birds',
          isFav: true,
          duration: 5,
          lastBid: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          type: 'Animals',
          isFav: false,
          duration: 3,
          lastBid: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          type: 'Birds',
          isFav: true,
          duration: 1,
          lastBid: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          type: 'Animals',
          isFav: false,
          duration: 2,
          lastBid: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
     },
]

export const MarketDataApi = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a10',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
]

export const OffersDataPage = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a10',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          phone: '050000000',
          email: 'aa@gmail.com',
          oldPrice: '10,000AED'
     },
]

export const videosDataPage = [
     {
          id: 'a1',
          poster: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed ',
          location: 'Abu Dhabi',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a2',
          poster: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali ',
          location: 'Al Ain',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a3',
          poster: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr ',
          location: 'Dubai',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a4',
          poster: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel ',
          location: 'Ajman',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a5',
          poster: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan ',
          location: 'Sharjah',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a6',
          poster: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah ',
          location: 'Dubai',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a7',
          poster: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed ',
          location: 'Abu Dhabi',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a8',
          poster: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          isFav: false,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali ',
          location: 'Al Ain',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a9',
          poster: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr ',
          location: 'Dubai',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a10',
          poster: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel ',
          location: 'Ajman',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a11',
          poster: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan ',
          location: 'Sharjah',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a12',
          poster: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah ',
          location: 'Dubai',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a13',
          poster: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel ',
          location: 'Ajman',
          description: 'Lorem ipsum dolor amet',
     },
     {
          id: 'a14',
          poster: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan ',
          location: 'Sharjah',
          description: 'Lorem ipsum dolor amet',
     }, {
          id: 'a15',
          poster: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan ',
          location: 'Sharjah',
          description: 'Lorem ipsum dolor amet',
     },
]

export const AdoptionDataPage = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          price: '80 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          price: '10 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          price: '3 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'hour',
          locationAnimal: 'Deira'
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          price: '5 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          price: '50 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          price: '10 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          price: '80 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          price: '10 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          price: '3 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'hour',
          locationAnimal: 'Deira'
     },
     {
          id: 'a10',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          price: '5 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          price: '50 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          price: '10 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a13',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          price: '80 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'day',
          locationAnimal: 'Deira'
     },
     {
          id: 'a14',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          price: '10 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
          duration: 'week',
          locationAnimal: 'Deira'
     },
     {
          id: 'a15',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          price: '3 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
          duration: 'hour',
          locationAnimal: 'Deira'
     },
]

export const lostDataPage = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/turtle-closeup-with-school-of-fish-picture-id1170804921?k=20&m=1170804921&s=612x612&w=0&h=W4zAOSr9DzJvJO-uib7GiwZ9kaleupO_LspfXvOT0HE=',
          location: 'Dubai - Deira',
          loastDate: '12/02/2022',
          type: 'Animals',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/closeup-of-female-arctic-fox-with-a-cute-little-cub-in-the-meadow-picture-id1026123076?b=1&k=20&m=1026123076&s=170667a&w=0&h=qaxR7pt1exGQBJ3bTjrTmDpTNuWvTXU45CAvp2zw5ZI=',
          location: 'Dubai - Business bay',
          loastDate: '08/02/2021',
          type: 'Animals',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/arctic-fox-picture-id622048192?b=1&k=20&m=622048192&s=170667a&w=0&h=kYkTEjxUGjasMVkuKsvwL1u1t0uZzTaLpeOghQ5B9YI=',
          location: 'Dubai - sport city',
          loastDate: '12/08/2021',
          type: 'Birds',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/thoroughbred-wild-horse-running-free-in-the-field-picture-id1305910583?k=20&m=1305910583&s=612x612&w=0&h=3lhYoXmg_CQgkHt_bI9zuEhqvmPqYEgp86FUoAJTRX4=',
          location: 'Dubai - Deira',
          loastDate: '06/05/2022',
          type: 'Animals',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          location: 'Dubai - sport city',
          loastDate: '12/02/2022',
          type: 'Birds',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/pack-of-horses-picture-id145245304?k=20&m=145245304&s=612x612&w=0&h=YzrLwRtvxP-dwhBJ-54bPV243KVEvuQ25bA_ZK9wzE8=',
          location: 'Dubai - Business bay',
          loastDate: '01/10/2022',
          type: 'Animals',
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/turtle-closeup-with-school-of-fish-picture-id1170804921?k=20&m=1170804921&s=612x612&w=0&h=W4zAOSr9DzJvJO-uib7GiwZ9kaleupO_LspfXvOT0HE=',
          location: 'Dubai - Deira',
          loastDate: '12/02/2022',
          type: 'Animals',
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/closeup-of-female-arctic-fox-with-a-cute-little-cub-in-the-meadow-picture-id1026123076?b=1&k=20&m=1026123076&s=170667a&w=0&h=qaxR7pt1exGQBJ3bTjrTmDpTNuWvTXU45CAvp2zw5ZI=',
          location: 'Dubai - Business bay',
          loastDate: '08/02/2021',
          type: 'Animals',
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/arctic-fox-picture-id622048192?b=1&k=20&m=622048192&s=170667a&w=0&h=kYkTEjxUGjasMVkuKsvwL1u1t0uZzTaLpeOghQ5B9YI=',
          location: 'Dubai - sport city',
          loastDate: '12/08/2021',
          type: 'Birds',
     },
     {
          id: 'a10',
          img: 'https://media.istockphoto.com/photos/thoroughbred-wild-horse-running-free-in-the-field-picture-id1305910583?k=20&m=1305910583&s=612x612&w=0&h=3lhYoXmg_CQgkHt_bI9zuEhqvmPqYEgp86FUoAJTRX4=',
          location: 'Dubai - Deira',
          loastDate: '06/05/2022',
          type: 'Animals',
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          location: 'Dubai - sport city',
          loastDate: '12/02/2022',
          type: 'Birds',
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/pack-of-horses-picture-id145245304?k=20&m=145245304&s=612x612&w=0&h=YzrLwRtvxP-dwhBJ-54bPV243KVEvuQ25bA_ZK9wzE8=',
          location: 'Dubai - Business bay',
          loastDate: '01/10/2022',
          type: 'Animals',
     },
]

export const othersDataPage = [

     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a13',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a14',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a15',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
]

export const othersDataPageTwo = [
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/monkey-eating-orange-fruit-and-sitting-picture-id1250006474?k=20&m=1250006474&s=612x612&w=0&h=tkyr30IiRRwFlc23BrHAStGEZEthW8KxDjpvEr75B8M=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/urban-pigeons-picture-id636191406?k=20&m=636191406&s=612x612&w=0&h=W9EewJ0ihgw5z2AkOEK4DTKUFm8HCrNHhSabWyLfx6o=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          name: 'Ali',
          location: 'Al Ain',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/british-short-hair-cat-and-golden-retriever-picture-id992637094?k=20&m=992637094&s=612x612&w=0&h=TsroJcfNHlnIuOsoyosl-NQJITGHAnI79ROvHU7lPs8=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },

     {
          id: 'a13',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a14',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          badg: 'not-verify',
          whatsapp: '+971000000000',
     },
     {
          id: 'a15',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          badg: 'verify',
          whatsapp: '+971000000000',
     },
]

export const lookForDataPage = [
     {
          id: 'a1',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['1-2 year'],
          name: 'Ahmed',
          location: 'Abu Dhabi',
          averagePrice: '600-1500 AED',
          badg: 'verify',
     },
     {
          id: 'a2',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2 year'],
          name: 'Ali',
          location: 'Abu Dhabi',
          averagePrice: '200-1000 AED',
          badg: 'verify',
     },
     {
          id: 'a3',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Birds',
          gender: 'male',
          tags: ['1 year'],
          name: 'Amr Ata',
          location: 'Abu Dhabi',
          averagePrice: '100-1100 AED',
          badg: 'verify',
     },
     {
          id: 'a4',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-4 year'],
          name: 'Adel',
          location: 'Abu Dhabi',
          averagePrice: '900-1200 AED',
          badg: 'verify',
     },
     {
          id: 'a5',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Birds',
          gender: 'male',
          tags: ['1-4 year'],
          name: 'Hassan Akram',
          location: 'Abu Dhabi',
          averagePrice: '100-1300 AED',
          badg: 'verify',
     },
     {
          id: 'a6',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-3 year'],
          name: 'Abdallah Atia',
          location: 'Abu Dhabi',
          averagePrice: '900-1500 AED',
          badg: 'verify',
     },
     {
          id: 'a7',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['1-2 year'],
          name: 'Ahmed',
          location: 'Abu Dhabi',
          averagePrice: '600-1500 AED',
          badg: 'verify',
     },
     {
          id: 'a8',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2 year'],
          name: 'Ali',
          location: 'Abu Dhabi',
          averagePrice: '200-1000 AED',
          badg: 'verify',
     },
     {
          id: 'a9',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Birds',
          gender: 'male',
          tags: ['1 year'],
          name: 'Amr Ata',
          location: 'Abu Dhabi',
          averagePrice: '100-1100 AED',
          badg: 'verify',
     },
     {
          id: 'a10',
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-4 year'],
          name: 'Adel',
          location: 'Abu Dhabi',
          averagePrice: '900-1200 AED',
          badg: 'verify',
     },
     {
          id: 'a11',
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Birds',
          gender: 'male',
          tags: ['1-4 year'],
          name: 'Hassan Akram',
          location: 'Abu Dhabi',
          averagePrice: '100-1300 AED',
          badg: 'verify',
     },
     {
          id: 'a12',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-3 year'],
          name: 'Abdallah Atia',
          location: 'Abu Dhabi',
          averagePrice: '900-1500 AED',
          badg: 'verify',
     }, {
          id: 'a13',
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2-3 year'],
          name: 'Abdallah Atia',
          location: 'Abu Dhabi',
          averagePrice: '900-1500 AED',
          badg: 'verify',
     },
     {
          id: 'a14',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ss2ddhusnG-mG2l1mQ3QA3wdP7vjI_Tuqe_u9gp6tLsQPQEo674zvsy6lTZ20Qg4RRw&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['1-2 year'],
          name: 'Ahmed',
          location: 'Abu Dhabi',
          averagePrice: '600-1500 AED',
          badg: 'verify',
     },
     {
          id: 'a15',
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEK9rIg0CG7Wcgd85WHT0L46lRXroN_QNJKfrt0in_6O9EpNS6x9RU6p26af3JBOmGGM8&usqp=CAU',
          phone: '050000000',
          email: 'aa@gmail.com',
          type: 'Animals',
          gender: 'male',
          tags: ['2 year'],
          name: 'Ali',
          location: 'Abu Dhabi',
          averagePrice: '200-1000 AED',
          badg: 'verify',
     },
]


export const MyBidsApi = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          myBid: '34.00 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myBid: '12.00 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          myBid: '76.550 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          myBid: '44.550 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          myBid: '23.550 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myBid: '25.00 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          myBid: '86.00 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myBid: '35.50 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          myBid: '21.46 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a10',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          myBid: '25.00 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          myBid: '120.550 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myBid: '15.550 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
]

export const MyOfferApi = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          myPrice: '34.00 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myPrice: '12.00 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          myPrice: '76.550 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          myPrice: '44.550 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a5',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          myPrice: '23.550 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a6',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myPrice: '25.00 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a7',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          start: '05:59:59',
          type: 'Animals',
          price: '80,000 AED',
          myPrice: '86.00 AED',
          gender: 'male',
          tags: ['1 year', '3.5 KG', 'Black'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-a-male-farmer-standing-on-his-dairy-farm-picture-id1297011499?k=20&m=1297011499&s=612x612&w=0&h=iO274UtElG4oyk_J1MxFBbve-9mo8AxNGeEAQblYxG4=',
          name: 'Ahmed',
          location: 'Abu Dhabi',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a8',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          start: '06:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myPrice: '35.50 AED',
          gender: 'female',
          tags: ['4 years', '1 KG', 'Green'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/elephants-touching-each-other-gently-picture-id177418652?k=20&m=177418652&s=612x612&w=0&h=or_xeZYsmKFaRgXdTnjpOFZnaMGBxhlyuQHAc8YFX48=',
          name: 'Ali',
          location: 'Al Ain',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a9',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          start: '12:00:00',
          type: 'Birds',
          price: '3,000 AED',
          myPrice: '21.46 AED',
          gender: 'male',
          tags: ['2 years', '3 KG', 'Black'],
          isFav: true,
          userImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZkC9WleOYbfXUBvdGoUaYVIzI-d6aps266_KKJe-zXutoXEF2aIr-AZxrzOtstTnbDE&usqp=CAU',
          name: 'Amr Ata',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
     {
          id: 'a10',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          start: '01:30:00',
          type: 'Animals',
          price: '5,000 AED',
          myPrice: '25.00 AED',
          gender: 'female',
          tags: ['2 years', '2 KG', 'White'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/portrait-of-smiling-caucasian-man-pose-in-office-picture-id1303206644?k=20&m=1303206644&s=612x612&w=0&h=B_CmLsEzLVKNb11awhk2S8HZkIoNpgBEe-dECLlYq0Y=',
          name: 'Adel',
          location: 'Ajman',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a11',
          img: 'https://media.istockphoto.com/photos/portrait-of-two-cute-cuddling-budgies-perched-on-branch-with-blue-as-picture-id1243410279?k=20&m=1243410279&s=612x612&w=0&h=MPBlyJfh7giIlfk7D18WZKaABEORx06GOzbllewOAto=',
          start: '10:59:59',
          type: 'Birds',
          price: '50,000 AED',
          myPrice: '120.550 AED',
          gender: 'male',
          tags: ['2 Months', '3 KG', 'Blue'],
          isFav: true,
          userImage: 'https://media.istockphoto.com/photos/headshot-studio-portrait-of-a-woman-in-profile-looking-at-the-camera-picture-id1305462732?k=20&m=1305462732&s=612x612&w=0&h=PSb7E99OQKJZ1XVr4BD_708517mNagc0adFFX_FfFGc=',
          name: 'Hassan Akram',
          location: 'Sharjah',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'not-verify',
          inMarket: false
     },
     {
          id: 'a12',
          img: 'https://media.istockphoto.com/photos/bonnet-monkey-picture-id629628952?k=20&m=629628952&s=612x612&w=0&h=1He7n0LfkKNfVzODisd3m3lBaWvlDPWcQXVjni0_0OY=',
          start: '08:00:00',
          type: 'Animals',
          price: '10,000 AED',
          myPrice: '15.550 AED',
          gender: 'female',
          tags: ['7 Months', '5 KG', 'Black'],
          isFav: false,
          userImage: 'https://media.istockphoto.com/photos/one-businesswoman-headshot-smiling-at-the-camera-picture-id1317804578?k=20&m=1317804578&s=612x612&w=0&h=_zfUaCtr9uA6M6YMABsNhohOruy4fkqZGZFYIBZvgUU=',
          name: 'Abdallah Atia',
          location: 'Dubai',
          phone: '050000000',
          email: 'aa@gmail.com',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          badg: 'verify',
          inMarket: true
     },
]

export const InboxApi = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          title: 'American White Duck',
          price: '5,000 AED',
          user: 'Amr Ata',

     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          title: 'Two White Goat',
          price: '185,000 AED',
          user: 'Ahme Mohamed',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          title: 'Russian Dog Duck',
          price: '77,000 AED',
          user: 'Fathi Assel',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          title: 'Green chameleon',
          price: '10,000 AED',
          user: 'Ali Hassan',
     },

]


export const NotificationApi = [
     {
          id: 'a1',
          img: 'https://media.istockphoto.com/photos/family-with-chickens-at-small-home-farm-picture-id1174000116?k=20&m=1174000116&s=612x612&w=0&h=9ukePTkhhyaZF1HrZNwxMEK0W-XbOdyM-7ZQzhd0JgA=',
          title: 'Added To videos',
          price: '5,000 AED',
          alert: 'American White Duck',
     },
     {
          id: 'a2',
          img: 'https://media.istockphoto.com/photos/alpacas-picture-id532529153?k=20&m=532529153&s=612x612&w=0&h=OBZDgABGLmDpsjT9zRct0aRj5bMq4hR5NzCxoLC1UYo=',
          title: 'Added to Wholsale Market',
          price: '185,000 AED',
          alert: 'Two White Goat',
     },
     {
          id: 'a3',
          img: 'https://media.istockphoto.com/photos/happy-puppy-dog-celebrating-christmas-with-a-red-santa-claus-hat-and-picture-id1267541412?k=20&m=1267541412&s=612x612&w=0&h=kUJVzDtTUaXF4QPRLi2E9JOT7hZCjJguCm23tKS-3bc=',
          title: 'Added to Market Auction',
          price: '77,000 AED',
          alert: 'Russian Dog Duck',
     },
     {
          id: 'a4',
          img: 'https://media.istockphoto.com/photos/redeyed-tree-frog-picture-id108348088?k=20&m=108348088&s=612x612&w=0&h=04JWKq3NfBNTEeP8GWv4GczVL5hOdEjha5OVqIPdYDk=',
          title: 'Added to Vip Auction',
          price: '10,000 AED',
          alert: 'Green chameleon',
     },

]