import React, { useState } from 'react'
import Verification from '../Verification/Verification'
import NewPassword from '../../Authentication/NewPassword/NewPassword'
import ForgotPassword from '../../Authentication/ForgotPassword/ForgotPassword'
import { config, url } from '../../Helpers/Help'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const ResetPassword = (props) => {
     const [forgotPassword, setForgotPassword] = useState(true)
     const [verification, setVerification] = useState(false)
     const [loading, setLoading] = useState(false)
     const [email, setEmail] = useState('')
     const [otp, setOtp] = useState('')
     const [errorMessageAlert, setErrorMessageAlert] = useState('')
     const [successMessageAlert, setSuccessMessageAlert] = useState('')

     const navigate = useNavigate()

     // Forogot Password Send user emai by this method
     const sendEmail = () => {
          if (!email.includes('@') || email.trim() === '') {
               setErrorMessageAlert('Please enter valid email')
               setTimeout(() => {
                    setErrorMessageAlert('')
               }, 3000);
               return
          }
          setLoading(true)
          axios.post(url + 'users/forget-password',
               //  Data
               {
                    email: email,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    console.log(response)
                    setVerification(true);
                    setForgotPassword(false)
                    setLoading(false)
               })
               // Check Error
               .catch(error => {
                    console.log(error)
                    setErrorMessageAlert(error.response.data.message)
                    setLoading(false)
                    setTimeout(() => {
                         setErrorMessageAlert('')
                    }, 3000);
               })
     }

     const handleVerifiction = (otps) => {
          setVerification(false);
          setForgotPassword(false)
          setOtp(otps)
          console.log(otps)
     }

     const resetPss = (password, rePassword) => {
          setLoading(true)
          axios.post(url + 'users/reset-password',
               //  Data
               {
                    email: email,
                    code: otp,
                    password: password,
                    password_confirmation: rePassword,
               },
               // Configrations
               config)
               // Response
               .then(response => {
                    setEmail('')
                    console.log(response)
                    setVerification(false);
                    setSuccessMessageAlert(response.data.message)
                    setLoading(false)
                    setTimeout(() => {
                         navigate('/login-signup')
                         setSuccessMessageAlert('')
                         props.onBackToLogin()
                    }, 2000);
               })
               // Check Error
               .catch(error => {
                    console.log(error)
                    setErrorMessageAlert(error.response.data.message)
                    setLoading(false)
                    setTimeout(() => {
                         setErrorMessageAlert('')
                    }, 3000);
               })
     }




     return (
          <>
               <h3 className='text-red font-bold text-left w-full '>{errorMessageAlert}</h3>
               <h3 className='text-green font-bold text-left w-full ' > {successMessageAlert}</h3>

               {
                    forgotPassword && !verification &&
                    <ForgotPassword isLoading={loading} onEmailChange={(email) => setEmail(email)} onForgotSubmit={sendEmail} onBackToLogin={() => props.onBackToLogin()} />
               }

               {
                    verification && !forgotPassword &&
                    <Verification isLoading={loading} onVerifiction={(otps) => handleVerifiction(otps)} />
               }

               {!verification && !forgotPassword &&
                    <NewPassword isLoading={loading} onChangePassword={(password, rePassword) => resetPss(password, rePassword)} />
               }
          </>
     )
}

export default ResetPassword