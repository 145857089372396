import Context from "../Store/Store";
import Layout from "../Components/Layout";
import React, { useContext, useEffect, useState } from "react";
import Favorites from "../assets/Icons/Activities/Favorites";
import VipAuction from "../Components/UI/AnimalCard/VipAuction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TodayMarket from "../Components/UI/AnimalCard/TodayMarket";
import QuestionsCard from "../Components/UI/Cards/Questions-card";
import axios from "axios";
import { config, url } from "../Components/Helpers/Help";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { categoriesSlider } from "../Components/Helpers/SlidersOptions";
import VideoCard from "../Components/UI/Cards/Video-card";
import SpecialOffer from "../Components/UI/AnimalCard/SpecialOffer";
import LookFor from "../Components/UI/AnimalCard/LookFor";
import Photographer from "../Components/UI/AnimalCard/Photographer";
import AdoptionForAnimal from "../Components/UI/AnimalCard/AdoptionForAnimal";
import Mating from "../Components/UI/AnimalCard/Mating";
import Others from "../Components/UI/AnimalCard/Others";
import VeterinaryClinic from "../Components/UI/AnimalCard/VeterinaryClinic";
import Delivery from "../Components/UI/AnimalCard/Delivery";
import LostAnimal from "../Components/UI/AnimalCard/LostAnimal";
import DailyAnnouncement from "../Components/UI/AnimalCard/DailyAnnouncement";
import NotItems from "../Components/UI/NotItems";
import PreLoader from "../Components/UI/PreLoader";
import { t } from "i18next";

const Favorite = () => {
  const [active, setActive] = useState("auctions");
  const [data, setData] = useState([]);
  const [animalsType, setAnimalsType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const favCtx = useContext(Context);
  const loadCtx = useContext(Context);

  useEffect(() => {
    const filterObj = { slug: active };
    const filteredData = favCtx.favorites.filter((item) => {
      return Object.keys(filterObj).every((key) => {
        return item[key] === filterObj[key];
      });
    });
    setData(filteredData);
    setIsLoading(false);
  }, [active, favCtx]);

  useEffect(() => {
    //     const animalsType = localStorage.getItem("__data_aW__a_start");
    //     const types = JSON.parse(animalsType);
    //     console.log("🚀 ~ useEffect ~ types:", types);
    //     setAnimalsType(types.animal_types);
    setIsLoading(true);
    axios(url + "start ", config)
      .then((response) => {
        setIsLoading(false);
        localStorage.setItem(
          "__data_aW__a_start",
          JSON.stringify(response.data.data)
        );
        setAnimalsType(response.data.data.animal_types);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Layout>
      <main className="container mx-auto pt-36 md:pt-32 px-4 md:px-0">
        <h3 className="flex items-center justify-start gap-4 font-bold text-[1.4rem] md:text-[2.4rem] mb-12">
          {" "}
          <Favorites /> {t("Favorite")}{" "}
        </h3>
        <div className="mb-6">
          <Slider {...categoriesSlider}>
            {animalsType &&
              animalsType.map((item, index) => (
                <button
                  key={index}
                  title={item.title}
                  id={item.id}
                  className="cursor-pointer w-1/12 lowercase"
                  onClick={() => {
                    setActive(item.slug);
                    console.log(item.slug);
                  }}
                >
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="mx-auto h-[3.6rem]"
                  />
                  <h3
                    className={`text-[1.4rem] text-center text-black font-medium no-wrap`}
                  >
                    {" "}
                    {item.title}{" "}
                  </h3>
                </button>
              ))}
          </Slider>
        </div>
        {isLoading || loadCtx.isLoad ? (
          <PreLoader />
        ) : (
          <div className="row w-full flex items-start justify-center flex-wrap">
            {!loadCtx.isLoad && data.length === 0 && (
              <div className="flex min-h-[50vh] items-center justify-center w-1/2 lg:w-full mx-auto">
                <NotItems />
              </div>
            )}

            {active === "videos" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 my-2"
                  key={item.id}
                >
                  <VideoCard
                    views_count={item.views_count}
                    slug={item.slug}
                    key={item.id}
                    id={item.id}
                    likes_count={item.likes_count}
                    comment_count={item.comment_count}
                    description={item.content}
                    poster={item.thumbnail}
                    userImage={item.user && item.user.personal_picture}
                    name={item.user && item.user.f_name && item.user.f_name}
                    userId={item.user && item.user.id}
                    video={item.file}
                    location={
                      item.user && item.user.city && item.user.city.name
                    }
                  />
                </div>
              ))}

            {active === "auctions" &&
              data &&
              data.map((item) => (
                <div className="w-1/2 md:w-1/2 xl:w-1/3 my-2" key={item.id}>
                  <VipAuction
                    is_notify={item.is_notify}
                    key={item.id}
                    id={item.id}
                    isMine={item.is_mine}
                    slug={item.slug}
                    img={item.image}
                    end_minutes={item.end_minutes}
                    start={item.start_time}
                    type={item.category && item.category.title}
                    max_price={item.max_price}
                    gender={item.gender}
                    age={item.age}
                    users_count={item.users_count}
                    weight={item.weight}
                    isFav={item.isFav}
                    inMarket={item.in_market && item.in_market_till !== 0}
                  />
                </div>
              ))}

            {active === "markets" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5"
                  id={item.id}
                >
                  <TodayMarket
                    id={item.id}
                    slug={item.slug}
                    img={item.image}
                    type={item.category && item.category.title}
                    badg={item.badg}
                    isFav={item.isFav}
                    phone={item.user && item.user.phone}
                    age={item.age}
                    weight={item.weight}
                    gender={item.gender}
                    location={item.city && item.city.name}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "questions" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <QuestionsCard
                    slug={item.slug}
                    id={item.id}
                    key={item.id}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                    badg={item.badg}
                    tags={item.tags}
                    description={item.content}
                    hashtags={item.hashtags}
                  />
                </div>
              ))}

            {active === "special-offers" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <SpecialOffer
                    key={item.id}
                    id={item.id}
                    slug={item.slug}
                    img={item.image}
                    type={item.category && item.category.title}
                    badg={item.badg}
                    price={item.offer_price}
                    oldPrice={+item.price > +item.offer_price && item.price}
                    isFav={item.isFav}
                    phone={item.user && item.user.phone}
                    age={item.age}
                    weight={item.weight}
                    gender={item.gender}
                    location={item.city && item.city.name}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "looking-for" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <LookFor
                    id={item.id}
                    key={item.id}
                    img={item.image}
                    type={item.category && item.category.title}
                    badg={item.badg}
                    isFav={item.isFav}
                    phone={item.user && item.user.phone}
                    age_range_from={item.age_range_to}
                    age_range_to={item.age_range_from}
                    price_range_from={item.price_range_from}
                    price_range_to={item.price_range_to}
                    weight={item.weight}
                    gender={item.gender}
                    address={item.address}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "photographers" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <Photographer
                    key={item.id}
                    id={item.id}
                    img={item.image}
                    slug={item.slug}
                    type={item.category && item.category.title}
                    badg={item.badg}
                    isFav={item.isFav}
                    phone={item.user && item.user.phone}
                    age={item.age}
                    weight={item.weight}
                    gender={item.gender}
                    // location={item.user && item.user.city.name}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    whatsapp={item.user && item.user.whatsapp}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "adoptions" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <AdoptionForAnimal
                    key={item.id}
                    id={item.id}
                    img={item.image}
                    slug={item.slug}
                    badg={item.badg}
                    price={item.price}
                    isFav={item.isFav}
                    age={item.age}
                    duration={item.price_unit_text}
                    weight={item.weight}
                    gender={item.gender}
                    location={item.city && item.city.name}
                    locationAnimal={item.city && item.city.name}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    phone={item.phone}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "matings" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <Mating
                    id={item.id}
                    key={item.id}
                    slug={item.slug}
                    img={item.image}
                    type={item.category && item.category.title}
                    badg={item.badg}
                    isFav={item.isFav}
                    phone={item.user && item.user.phone}
                    age={item.age}
                    weight={item.weight}
                    gender={item.gender}
                    location={item.city && item.city.name}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "others" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <Others
                    id={item.id}
                    img={item.image}
                    type={item.category && item.category.title}
                    badg={item.badg}
                    price={item.price + "AED"}
                    name={item.user && item.user.f_name}
                    whatsapp={item.user && item.user.whatsapp}
                    location={item.address}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "veterinaries" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <VeterinaryClinic
                    id={item.id}
                    slug={item.slug}
                    img={item.image}
                    title={item.title}
                    badg={item.badg}
                    location={item.address}
                    name={item.user && item.user.f_name}
                    phone={item.phone}
                    email={item.email}
                  />
                </div>
              ))}

            {active === "deliveries" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <Delivery
                    id={item.id}
                    slug={item.slug}
                    className="h-[36.5rem]"
                    img={item.image}
                    location={item.address}
                    phone={item.phone}
                    email={item.email}
                  />
                </div>
              ))}

            {active === "lost_animals" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <LostAnimal
                    id={item.id}
                    type={item.category && item.category.title}
                    img={item.image}
                    loastDate={item.lost_date}
                    // location={item.user && item.user.city.name}
                    badg={item.badg}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}

            {active === "daily-announcements" &&
              data &&
              data.map((item) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  id={item.id}
                  key={item.id}
                >
                  <DailyAnnouncement
                    id={item.id}
                    slug={item.slug}
                    img={item.image}
                    type={item.category && item.category.title}
                    badg={item.user && item.user.is_vip}
                    price={item.offer_price}
                    oldPrice={+item.price > +item.offer_price && item.price}
                    isFav={item.isFav}
                    phone={item.user && item.user.phone}
                    age={item.age}
                    weight={item.weight}
                    gender={item.gender}
                    location={item.city && item.city.name}
                    email={item.user && item.user.email}
                    name={item.user && item.user.f_name}
                    userImage={item.user && item.user.personal_picture}
                  />
                </div>
              ))}
          </div>
        )}
      </main>
    </Layout>
  );
};

export default Favorite;
