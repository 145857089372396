import React from 'react'

const Instagram = () => {
     return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M22.678 27.9934L9.34467 28.0061C6.41133 28.0087 4.00933 25.6114 4.006 22.6781L3.99333 9.34474C3.99067 6.41141 6.388 4.00941 9.32133 4.00608L22.6547 3.99341C25.588 3.99074 27.99 6.38808 27.9933 9.32141L28.006 22.6547C28.0093 25.5887 25.6113 27.9907 22.678 27.9934Z" fill="url(#paint0_radial_302_675)" />
               <path d="M22.678 27.9934L9.34467 28.0061C6.41133 28.0087 4.00933 25.6114 4.006 22.6781L3.99333 9.34474C3.99067 6.41141 6.388 4.00941 9.32133 4.00608L22.6547 3.99341C25.588 3.99074 27.99 6.38808 27.9933 9.32141L28.006 22.6547C28.0093 25.5887 25.6113 27.9907 22.678 27.9934Z" fill="url(#paint1_radial_302_675)" />
               <path d="M16 20.6666C13.4273 20.6666 11.3333 18.5733 11.3333 15.9999C11.3333 13.4266 13.4273 11.3333 16 11.3333C18.5727 11.3333 20.6667 13.4266 20.6667 15.9999C20.6667 18.5733 18.5727 20.6666 16 20.6666ZM16 12.6666C14.162 12.6666 12.6667 14.1619 12.6667 15.9999C12.6667 17.8379 14.162 19.3333 16 19.3333C17.838 19.3333 19.3333 17.8379 19.3333 15.9999C19.3333 14.1619 17.838 12.6666 16 12.6666Z" fill="white" />
               <path d="M21 12C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10C20.4477 10 20 10.4477 20 11C20 11.5523 20.4477 12 21 12Z" fill="white" />
               <path d="M20 24.6666H12C9.42733 24.6666 7.33333 22.5733 7.33333 19.9999V11.9999C7.33333 9.42659 9.42733 7.33325 12 7.33325H20C22.5727 7.33325 24.6667 9.42659 24.6667 11.9999V19.9999C24.6667 22.5733 22.5727 24.6666 20 24.6666ZM12 8.66659C10.162 8.66659 8.66666 10.1619 8.66666 11.9999V19.9999C8.66666 21.8379 10.162 23.3333 12 23.3333H20C21.838 23.3333 23.3333 21.8379 23.3333 19.9999V11.9999C23.3333 10.1619 21.838 8.66659 20 8.66659H12Z" fill="white" />
               <defs>
                    <radialGradient id="paint0_radial_302_675" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.92 28.0234) scale(29.9327)">
                         <stop stopColor="#FFDD55" />
                         <stop offset="0.328" stopColor="#FF543F" />
                         <stop offset="0.348" stopColor="#FC5245" />
                         <stop offset="0.504" stopColor="#E64771" />
                         <stop offset="0.643" stopColor="#D53E91" />
                         <stop offset="0.761" stopColor="#CC39A4" />
                         <stop offset="0.841" stopColor="#C837AB" />
                    </radialGradient>
                    <radialGradient id="paint1_radial_302_675" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.85733 3.69361) scale(19.8753 13.2429)">
                         <stop stopColor="#4168C9" />
                         <stop offset="0.999" stopColor="#4168C9" stopOpacity="0" />
                    </radialGradient>
               </defs>
          </svg>


     )
}

export default Instagram